import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  Field,
  DateTimeField,
  BooleanField,
} from '../../commons/DataTable';

function PartnerCondosTable({ data, total, offset, limit, onFilter }) {
  const [t] = useTranslation('IAF_INVOICES');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="Nome" source="name" className="text-left" />
      <Field title="Indirizzo" source="address" className="text-left" />
      <Field title="Partita IVA" source="vatCode" />
      <Field title="Numero unità fabbricato" source="manufacturedUnitNumber" />
    </DataTable>
  );
}

export default PartnerCondosTable;
