import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useDebouncedCallback } from 'use-debounce';
import Header from '../../commons/Header';
import BurgerMenu from '../../commons/BurgerMenu';
import { useAuth } from '../../commons/Auth';
import { ReactComponent as IconRendicontazioni } from '../../images/icn_rendicontazioni.svg';
import './MyFinancialReports.scss';
import FinancialReportsPresentational from '../ProfileDetail/FinancialReports/FinancialReportsPresentational';
import { getReports } from '../../lib/fetch/reports';
import { useLoading } from '../../commons/Loading';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import ProvidersDetail from '../ProfileDetail/ProvidersDetail';
import VariableRevenuesDetail from '../ProfileDetail/VariableRevenuesDetail';
import SubAdminsRevenuesDetail from '../ProfileDetail/SubAdminsRevenuesDetail';
import VarRevInsDetail from '../ProfileDetail/VarRevInsDetail';
import VarRevEnergyDetail from '../ProfileDetail/VarRevEnergyDetail';
import VarRevRecurrentEnergyDetail from '../ProfileDetail/VarRevRecurrentEnergyDetail';
import variables from '../../variables';
import VariableRevenuesDetailPaginated from '../ProfileDetail/VariableRevenuesDetailPaginated';
import VarRevInsDetailPaginated from '../ProfileDetail/VarRevInsDetailPaginated';
import VarRevEnergyDetailPaginated from '../ProfileDetail/VarRevEnergyDetailPaginated';
import VarRevRecurrentEnergyDetailPaginated from '../ProfileDetail/VarRevRecurrentEnergyDetailPaginated';
import SubAdminsRevenuesDetailPaginated from '../ProfileDetail/SubAdminsRevenuesDetailPaginated';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function MyFinancialReports({ location, match = {} }) {
  const [t] = useTranslation();
  const [{ isAdmin, profile = {}, token } = {}] = useAuth();
  const {
    _id: profileId,
    master: isProfileMaster,
    type: profileType = '',
  } = profile;
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [reports, setReports] = useState({});

  const [query, setQuery] = useQueryParams({
    year: withDefault(NumberParam, new Date().getFullYear()),
    supplier: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
    insuranceNumber: withDefault(StringParam, ''),
    insuranceAdmin: withDefault(StringParam, ''),
    insuranceSupplier: withDefault(StringParam, ''),
    subAdminsInvoicesNumber: withDefault(StringParam, ''),
    insuranceAccountabilityYear: NumberParam,
    insuranceAccountabilityMonth: NumberParam,
    subAdminsInvoicesAdmin: withDefault(StringParam, ''),
    subAdminsInvoicesSupplier: withDefault(StringParam, ''),
    subAdminsInvoicesAccountabilityYear: NumberParam,
    subAdminsInvoicesAccountabilityMonth: NumberParam,
    energyPod: withDefault(StringParam, ''),
    energyCondoVat: withDefault(StringParam, ''),
    energyAccountabilityYear: NumberParam,
    energyAccountabilityMonth: NumberParam,
    energyRecurrentPod: withDefault(StringParam, ''),
    energyRecurrentCondoVat: withDefault(StringParam, ''),
    energyRecurrentAccountabilityYear: NumberParam,
    energyRecurrentAccountabilityMonth: NumberParam,
  });

  const {
    supplier,
    number,
    year,
    accountabilityYear,
    accountabilityMonth,
    insuranceNumber,
    insuranceAdmin,
    insuranceSupplier,
    insuranceAccountabilityYear,
    insuranceAccountabilityMonth,
    subAdminsInvoicesAdmin,
    subAdminsInvoicesNumber,
    subAdminsInvoicesSupplier,
    subAdminsInvoicesAccountabilityYear,
    subAdminsInvoicesAccountabilityMonth,
    energyPod,
    energyCondoVat,
    energyAccountabilityYear,
    energyAccountabilityMonth,
    energyRecurrentPod,
    energyRecurrentCondoVat,
    energyRecurrentAccountabilityYear,
    energyRecurrentAccountabilityMonth,
  } = query;

  const [fetchReports] = useDebouncedCallback(async () => {
    showLoading();
    const { error, data } = await getReports({
      profileId,
      year,
      token,
      supplier,
      number,
      accountabilityYear,
      accountabilityMonth,
      insuranceNumber,
      insuranceAdmin,
      insuranceSupplier,
      insuranceAccountabilityYear,
      insuranceAccountabilityMonth,
      subAdminsInvoicesAdmin,
      subAdminsInvoicesNumber,
      subAdminsInvoicesSupplier,
      subAdminsInvoicesAccountabilityYear,
      subAdminsInvoicesAccountabilityMonth,
      energyPod,
      energyCondoVat,
      energyAccountabilityYear,
      energyAccountabilityMonth,
      energyRecurrentPod,
      energyRecurrentCondoVat,
      energyRecurrentAccountabilityYear,
      energyRecurrentAccountabilityMonth,
    });

    if (error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setReports(data);
    hideLoading();
  }, 1000);

  const path = useLocation();
  const condition = path.pathname.split('/');
  useEffect(() => {
    if (condition.length <= 4) {
      fetchReports();
    }
  }, [year, number]);

  if (isAdmin)
    return (
      // Super Admins do not have financial reports
      <Redirect
        push={false}
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );

  function onFilter(queryDiff) {
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
    }
    if (
      (queryDiff.insuranceAccountabilityYear &&
        queryDiff.insuranceAccountabilityYear !==
          insuranceAccountabilityYear) ||
      queryDiff.insuranceAccountabilityYear === ''
    ) {
      queryDiff.insuranceAccountabilityMonth = '';
    }
    if (
      (queryDiff.subAdminsInvoicesAccountabilityYear &&
        queryDiff.subAdminsInvoicesAccountabilityYear !==
          subAdminsInvoicesAccountabilityYear) ||
      queryDiff.subAdminsInvoicesAccountabilityYear === ''
    ) {
      queryDiff.subAdminsInvoicesAccountabilityMonth = '';
    }

    if (
      (queryDiff.energyAccountabilityYear &&
        queryDiff.energyAccountabilityYear !== energyAccountabilityYear) ||
      queryDiff.energyAccountabilityYear === ''
    ) {
      queryDiff.energyAccountabilityMonth = '';
    }

    if (
      (queryDiff.energyRecurrentAccountabilityYear &&
        queryDiff.energyRecurrentAccountabilityYear !==
          energyRecurrentAccountabilityYear) ||
      queryDiff.energyRecurrentAccountabilityYear === ''
    ) {
      queryDiff.energyRecurrentAccountabilityMonth = '';
    }
    setQuery(queryDiff);
  }

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:FINANCIAL_REPORTS')}
            icon={<IconRendicontazioni fill={variables.white} />}
          />
          <Switch>
            <Route
              exact
              path={match.url}
              component={(props) => (
                <FinancialReportsPresentational
                  data={reports}
                  profile={profile}
                  onAddBonus={() => {}}
                  onBonusDelete={() => {}}
                  onBonusUpdate={() => {}}
                  onFilter={onFilter}
                  query={query}
                  profileType={profileType}
                  isProfileMaster={isProfileMaster}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.url}/suppliers`}
              component={(props) => (
                <ProvidersDetail
                  id={profileId}
                  profile={profile}
                  reports={reports}
                  onFilter={onFilter}
                  query={query}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.url}/sub-admins-revenues`}
              component={(props) => (
                <SubAdminsRevenuesDetailPaginated
                  profile={profile}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.url}/variable-revenues`}
              component={(props) => (
                <VariableRevenuesDetailPaginated profile={profile} {...props} />
              )}
            />
            <Route
              path={`${match.url}/variable-revenues-insurances`}
              component={(props) => (
                <VarRevInsDetailPaginated profile={profile} {...props} />
              )}
            />
            <Route
              path={`${match.url}/variable-revenues-energy`}
              component={(props) => (
                <VarRevEnergyDetailPaginated profile={profile} {...props} />
              )}
            />
            <Route
              path={`${match.url}/variable-revenues-recurrent-energy`}
              component={(props) => (
                <VarRevRecurrentEnergyDetailPaginated
                  profile={profile}
                  {...props}
                />
              )}
            />
          </Switch>
        </section>
      </div>
    </div>
  );
}

export default withRouter(MyFinancialReports);
