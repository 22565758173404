import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { IS_MOBILE } from '../../lib/helpers/platformDetector';
import it from 'date-fns/locale/it';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';

function DateInput({
  name = '',
  className = '',
  value,
  onChange,
  disablePopup,
  readOnly,
  showOnlyMonth,
  setMidday,
  placeholder = '',
  returnDateObject,
  disabled = false,
}) {
  const pickerRef = useRef(null);

  const DatepickerInput = (props) => <input type="text" {...props} readOnly />;

  return IS_MOBILE ? (
    <Input
      name={name}
      className={classNames(className, disabled ? 'not-allowed ' : '')}
      type="date"
      onChange={(ev) => {
        if (!ev.target.value) return null;
        onChange(new Date(ev.target.value).toISOString());
      }}
      defaultValue={value ? moment(value).format('YYYY-MM-DD') : ''}
      readOnly={!!readOnly}
      disabled={disabled}
    />
  ) : (
    <DatePicker
      ref={pickerRef}
      className={classNames(className, disabled ? 'not-allowed' : '')}
      selected={value ? new Date(value) : ''}
      disabled={disabled}
      onChange={(date) => {
        if (setMidday) {
          date.setHours(12);
        }
        onChange(
          date ? (returnDateObject ? date : date.toISOString()) : undefined
        );
      }}
      dateFormat={showOnlyMonth ? 'MM/yyyy' : 'dd/MM/yyyy'}
      showMonthYearPicker={!!showOnlyMonth}
      placeholderText={
        value
          ? moment(value).format(showOnlyMonth ? 'MM/YYYY' : 'DD/MM/YYYY')
          : `${placeholder} es. ${
              showOnlyMonth ? '' : '01/'
            }01/${moment().format('YYYY')}`
      }
      locale={it}
      withPortal={!disablePopup}
      customInput={<DatepickerInput />}
      readOnly={!!readOnly}
      showYearDropdown
      showMonthDropdown
    />
  );
}

DateInput.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  disablePopup: PropTypes.bool,
  showOnlyMonth: PropTypes.bool,
  setMidday: PropTypes.bool,
};

export default DateInput;
