import React from 'react';
import { CSVLink } from 'react-csv';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';
import { useTranslation } from 'react-i18next';

export default ({ data = [], year, percentage, children }) => {
  const [t] = useTranslation('CONTACTS');

  const csvData = [['RAGIONE SOCIALE', 'TIPO UTENTE', 'DOVUTO']];

  data.forEach((master = {}) => {
    const { name = '', type = '', total = 0 } = master;

    // Dati principali
    csvData.push([
      // RAGIONE SOCIALE
      name,
      // TIPO UTENTE
      t(type),
      // DOVUTO
      formatCurrency(total || 0),
    ]);
  });

  return (
    <CSVLink
      data={csvData}
      filename={`MASTER_${percentage}%_${year}.csv`}
      separator={';'}
    >
      {children}
    </CSVLink>
  );
};
