import React from 'react';
import moment from 'moment';

export default function generateMonthOptions() {
  const options = [
    <option key={-1} value="">
      Tutti
    </option>,
  ];
  const months = moment.months();

  months.forEach((month, i) => {
    options.push(
      <option key={i} value={i}>
        {month}
      </option>
    );
  });

  return options;
}
