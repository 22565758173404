import React from 'react';
import Badge from '../DataTableFields/Badge';

const ValidatedBadge = ({ value }) => {
  return (
    <div className={value === true ? 'master-yes' : 'master-no'}>
      <Badge>{value === true ? 'Validata' : 'Non Validata'}</Badge>
    </div>
  );
};

export default ValidatedBadge;
