import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';
import { BtnOutlineOrange } from '../../styled-components';

const ExportExtraInvoiceCSVField = ({ data }) => {
  const [t] = useTranslation();

  const type = Object.hasOwn(data, 'invoices')
    ? 'invoices'
    : Object.hasOwn(data, 'contracts')
    ? 'contracts'
    : Object.hasOwn(data, 'insurances')
    ? 'insurances'
    : Object.hasOwn(data, 'recurrentContracts')
    ? 'recurrentContracts'
    : '';

  if (type === '') return '';

  return (
    <CSVExport data={{ data }} type={type}>
      <BtnOutlineOrange
        type="button"
        className="uppercase"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {t('COMMON:CSV_EXPORT')}
      </BtnOutlineOrange>
    </CSVExport>
  );
};

const getInvoicesCSVData = (data) => {
  if (data?.invoices && data?.invoices?.length > 0) {
    const CSVData = [
      [
        'NOME FORNITORE',
        'PARTITA IVA',
        'NUMERO FATTURA',
        'CLIENTE',
        'RESPONSABILE',
        'DATA FATTURA',
        'MESE RENDICONTAZIONE',
        'IMPONIBILE',
        'IMPORTO FATTURATO',
        'IMPORTO DA INCASSARE',
      ],
    ];

    const amount = data.invoices.reduce((acc, { extraNet }) => {
      return acc + +extraNet;
    }, 0);

    data.invoices.forEach(
      ({
        supplier,
        vatCode,
        number,
        customer,
        admin,
        date,
        accountability,
        net,
        extraNet,
        gross,
      }) => {
        CSVData.push([
          supplier.businessName,
          vatCode,
          number,
          customer,
          admin.businessName,
          moment(date).format('DD/MM/yyyy'),
          accountability ? moment(accountability).format('MM/yyyy') : '',
          formatCurrency(+net),
          formatCurrency(+gross),
          formatCurrency(+extraNet),
        ]);
      }
    );

    CSVData.push(['', '', '', '', '', '', '', '', '', formatCurrency(+amount)]);

    return CSVData || [];
  }
};

const getInsurancesCSVData = (data) => {
  if (data?.insurances && data?.insurances?.length > 0) {
    const CSVData = [
      [
        'CONTRACTOR',
        'PARTITA IVA CLIENTE',
        'TIPO',
        'DATA INIZIO',
        'DATA FINE',
        'IMPORTO DA INCASSARE',
      ],
    ];

    const amount = data.insurances.reduce((acc, { networkShare }) => {
      return acc + +networkShare;
    }, 0);

    data.insurances.forEach(
      ({
        contractor,
        cfCondominio,
        insuranceType,
        startDate,
        endDate,
        networkShare,
      }) => {
        CSVData.push([
          contractor,
          cfCondominio,
          insuranceType,
          moment(startDate).format('DD/MM/yyyy'),
          moment(endDate).format('DD/MM/yyyy'),
          formatCurrency(networkShare),
        ]);
      }
    );

    CSVData.push(['', '', '', '', '', formatCurrency(+amount)]);

    return CSVData || [];
  }
};

const getContractsCSVData = (data) => {
  if (data?.contracts && data?.contracts?.length > 0) {
    const CSVData = [
      [
        'POD/PDR',
        'NOME CLIENTE',
        'PARTITA IVA RESPONSABILE',
        'PARTITA IVA CLIENTE',
        'DATA CREAZIONE',
        'DATA ATTIVAZIONE',
        'CONSUMI DA CONTRATTO',
        'NOME PRODOTTO',
        'ADMIN',
        'IMPORTO DA INCASSARE',
      ],
    ];

    const amount = data.contracts.reduce((acc, { extraNet }) => {
      return acc + +extraNet;
    }, 0);

    data.contracts.forEach(
      ({
        pod_pdr,
        condo_name,
        admin,
        condo_admin_cf,
        condo_vat_cf,
        contract_signing_date,
        activation_start_date,
        contractual_consumption,
        product_name,
        extraNet,
      }) => {
        CSVData.push([
          pod_pdr,
          condo_name,
          condo_admin_cf,
          condo_vat_cf,
          moment(contract_signing_date).format('DD/MM/yyyy'),
          moment(activation_start_date).format('DD/MM/yyyy'),
          contractual_consumption,
          product_name,
          admin.businessName,
          formatCurrency(+extraNet),
        ]);
      }
    );

    CSVData.push(['', '', '', '', '', '', '', '', '', formatCurrency(+amount)]);

    return CSVData || [];
  }
};

const getRecurrentContractsCSVData = (data) => {
  if (data?.recurrentContracts && data?.recurrentContracts?.length > 0) {
    const CSVData = [
      [
        'POD/PDR',
        'NOME CLIENTE',
        'PARTITA IVA RESPONSABILE',
        'PARTITA IVA CLIENTE',
        'DATA CREAZIONE',
        'DATA ATTIVAZIONE',
        'CONSUMI DA CONTRATTO',
        'NOME PRODOTTO',
        'ADMIN',
        'PERCENTUALE COMPENSO',
        'DATA COMPENSO',
        'IMPORTO DA INCASSARE',
      ],
    ];

    const amount = data.recurrentContracts.reduce((acc, { extraNet }) => {
      return acc + +extraNet;
    }, 0);

    data.recurrentContracts.forEach(
      ({
        pod_pdr,
        condo_name,
        admin,
        condo_admin_cf,
        condo_vat_cf,
        contract_signing_date,
        activation_start_date,
        contractual_consumption,
        product_name,
        percentage,
        dateFee,
        extraNet,
      }) => {
        CSVData.push([
          pod_pdr,
          condo_name,
          condo_admin_cf,
          condo_vat_cf,
          moment(contract_signing_date).format('DD/MM/yyyy'),
          moment(activation_start_date).format('DD/MM/yyyy'),
          contractual_consumption,
          product_name,
          admin.businessName,
          percentage,
          moment(dateFee).format('DD/MM/yyyy'),
          formatCurrency(+extraNet),
        ]);
      }
    );

    CSVData.push([
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      formatCurrency(+amount),
    ]);

    return CSVData || [];
  }
};

const getCSVData = ({ data = {} }, type) => {
  const strategy = {
    invoices: getInvoicesCSVData,
    insurances: getInsurancesCSVData,
    contracts: getContractsCSVData,
    recurrentContracts: getRecurrentContractsCSVData,
  };

  return strategy[type](data);
};

const CSVExport = ({ data = {}, type, children }) => {
  const csvData = getCSVData(data, type) || [];

  return (
    <CSVLink
      data={csvData}
      filename={`Extra_Invoice_${data.data._id.toUpperCase()}.csv`}
      separator={';'}
    >
      {children}
    </CSVLink>
  );
};

export default ExportExtraInvoiceCSVField;
