import React from 'react';
import { isCheckable } from '../../lib/helpers/isCheckboxCheckable';

function CustomCheckbox({ data = {} }) {
  return (
    <>
      {isCheckable(data) ? (
        <input
          type="checkbox"
          onChange={(e) => {
            e.stopPropagation();
            data.checked = !data.checked;
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default CustomCheckbox;
