import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  Field,
  CurrencyField,
  DateTimeField,
  PaymentsDataField,
  BilledField,
} from '../../commons/DataTable';
import { useAuth } from '../../commons/Auth';
import IAFCustomCheckboxes from '../../commons/Checkboxes/IAFCustomCheckboxes';

function InsurancesRoyaltyTable({
  dataTable,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
  bottomRow,
  showEmptyTableMessage,
  truncateTable,
  onSelectChange,
  allowedRoles = [],
}) {
  const [t] = useTranslation('COMMON');
  const [{ isAdmin }] = useAuth();
  const { offset, limit } = query;

  const onCheck = (report) => {
    if (!allowedRoles.includes('superadmin')) {
      return;
    }
    onSelectChange(report);
  };

  return (
    <DataTable
      offset={offset}
      limit={limit}
      total={total}
      dataTable={dataTable}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
      bottomRow={bottomRow}
      showEmptyTableMessage={showEmptyTableMessage}
    >
      {!truncateTable && (
        <Field
          title="Nome Fornitore"
          source="supplier.businessName"
          className="text-left"
        />
      )}
      {!truncateTable && (
        <Field title="Numero assicurazione" source="insuranceNumber" />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Data inizio"
          source="startDate"
          format="DD/MM/YYYY"
        />
      )}
      {!truncateTable && (
        <DateTimeField title="Data fine" source="endDate" format="DD/MM/YYYY" />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Mese Rend."
          source="accountability"
          format="MM/YYYY"
        />
      )}
      {!truncateTable && (
        <Field
          title="Amministratore di Condominio"
          source="admin.businessName"
        />
      )}

      <CurrencyField
        title="Imponibile"
        source="net"
        className="txt-table-right"
      />
      <CurrencyField
        title="Importo da Incassare"
        source="gross"
        className="txt-table-right"
      />
      <PaymentsDataField title="Pagato" source="payments" type="total" />
      <CurrencyField
        title="Quota commerciale"
        source="commercialShare"
        className="txt-table-right"
      />
      <CurrencyField
        title="Quota commerciale maturata"
        source="earnedCommercialShare"
        className="txt-table-right"
      />
      <CurrencyField
        title="Diritto a Fatturare"
        source="billableCommercialShare"
        className="txt-table-right"
      />
    </DataTable>
  );
}

export default withRouter(InsurancesRoyaltyTable);
