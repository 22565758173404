export const refactoringAdminLevelsAddRegistry = (adminType) => {
  const adminLevels = [
    'Amministratore Livello Triangolo',
    'Amministratore Livello Quadrato',
    'Amministratore Livello Pentagono',
    'Amministratore Livello Esagono',
    'Amministratore Livello Ottagono',
  ];
  let adminLevel = '';
  switch (adminType) {
    case 'BUILDING_ADMIN_1':
      adminLevel = adminLevels[0];
      return adminLevel;
    case 'BUILDING_ADMIN_2':
      adminLevel = adminLevels[1];
      return adminLevel;
    case 'BUILDING_ADMIN_3':
      adminLevel = adminLevels[2];
      return adminLevel;
    case 'BUILDING_ADMIN_4':
      adminLevel = adminLevels[3];
      return adminLevel;
    case 'BUILDING_ADMIN_5':
      adminLevel = adminLevels[4];
      return adminLevel;
    default:
      return adminType;
  }
};
