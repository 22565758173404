import React from 'react';

function InvoiceNumberField({ data }) {
  return (
    <>
      {data.pinvInvoice[0]?.invoicePinv?.status === 'delivered'
        ? data.pinvInvoice[0]?.invoicePinv?.fullnumber
        : '-'}
    </>
  );
}

export default InvoiceNumberField;
