import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import InsurancesPresentational from './InsurancesPresentational';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT } = process.env;

const Insurances = ({ profile = {} }) => {
  const [insurances, setInsurances] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const [selectedInsurances, setSelectedInsurances] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    adminName: withDefault(StringParam, ''),
    contractor: withDefault(StringParam, ''),
    insuranceNumber: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT || 50),
    paid: withDefault(StringParam, ''),
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
    year: NumberParam,
  });

  const {
    limit,
    offset,
    search,
    year,
    accountabilityYear,
    accountabilityMonth,
    adminName,
    contractor,
    insuranceNumber,
    supplier,
    paid,
  } = query;

  useEffect(() => {
    if (profile._id) fetchInsurances();
  }, [
    limit,
    offset,
    search,
    year,
    accountabilityYear,
    accountabilityMonth,
    profile._id,
    adminName,
    contractor,
    insuranceNumber,
    supplier,
    paid,
  ]);

  const fetchInsurances = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/insurances/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          search,
          year,
          accountabilityYear,
          accountabilityMonth,
          adminName,
          contractor,
          insuranceNumber,
          supplier,
          paid,
        },
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { headers, data } = response;

    setInsurances(data);
    setTotal(+headers['x-total-count']);
    hideLoading();
  };

  function setFilterYear(value) {
    setQuery({ year: value });
  }

  function onFilter(queryDiff) {
    if (queryDiff && typeof queryDiff.search === 'string') {
      localStorage.setItem(
        'myCompensiVariabiliAssicurazioniSearch',
        queryDiff.search
      );
    }
    if (
      (queryDiff.search && queryDiff.search !== search) || // first search
      (search && queryDiff.search === '') || // search deleted
      (queryDiff.adminName && queryDiff.adminName !== adminName) || // first search
      (adminName && queryDiff.adminName === '') || // search deleted
      (queryDiff.contractor && queryDiff.contractor !== contractor) || // first search
      (contractor && queryDiff.contractor === '') || // search deleted
      (queryDiff.insuranceNumber &&
        queryDiff.insuranceNumber !== insuranceNumber) || // first search
      (insuranceNumber && queryDiff.insuranceNumber === '') || // search deleted
      (queryDiff.supplier && queryDiff.supplier !== supplier) || // first search
      (supplier && queryDiff.supplier === '') || // search deleted
      (queryDiff.paid && queryDiff.paid !== paid) ||
      (queryDiff.accountabilityMonth &&
        queryDiff.accountabilityMonth !== accountabilityMonth) ||
      queryDiff.accountabilityMonth === ''
    ) {
      queryDiff.offset = 0; // return to first page
    }
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  const onSelectChange = (_id) => {
    if (selectedInsurances.includes(_id)) {
      setSelectedAll(false);
      return setSelectedInsurances((selectedInsurances) =>
        selectedInsurances.filter((invoiceId) => invoiceId !== _id)
      );
    }
    setSelectedInsurances((selectedInsurances) => [...selectedInsurances, _id]);
  };

  const onSelectAll = (selectAll) => {
    setSelectedAll(selectAll);
    if (!selectAll) {
      return setSelectedInsurances([]);
    }
    setSelectedInsurances(
      insurances
        .filter((insurance) => insurance.pinvInvoice?.length === 0 )
        .map(({ _id }) => _id)
    );
  };

  const onInvoice = async () => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/extra-invoices/${profile._id}/insurances`,
      {
        selectedInsurances,
      },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));

    fetchInsurances({ limit,
      offset,
      search,
      year,
      accountabilityYear,
      accountabilityMonth,
      adminName,
      contractor,
      insuranceNumber,
      supplier,
      paid });
    setSelectedInsurances([]);
    setSelectedAll(false);
  };

  return (
    <InsurancesPresentational
      data={insurances}
      query={query}
      total={total}
      onFilter={onFilter}
      setFilterYear={setFilterYear}
      selectedInsurances={selectedInsurances}
      selectedAll={selectedAll}
      onSelectAll={onSelectAll}
      onSelectChange={onSelectChange}
      onInvoice={onInvoice}
      onStatusChanged={() => fetchInsurances()}
    />
  );
};

export default Insurances;
