import React from 'react';
import {
  ContractEONType,
  DataTable,
  DateTimeField,
  Field,
} from '../../../commons/DataTable';
import { useTranslation } from 'react-i18next';
import { refactoringAdminLevels } from '../../../lib/helpers/refactoringAdminLevels';
import { BadgeField } from '../../../commons/DataTableFields';

function CollaboratorsAdminTable({ data, total, offset, limit, onFilter }) {
  const [t] = useTranslation('ALBO');

  const refactorData = data.map((profile) => {
    return {
      ...profile,
      type: refactoringAdminLevels(profile.type),
    };
  });

  return (
    <DataTable
      data={refactorData}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="Nome" source="name" />
      <Field title="Cognome" source="surname" />
      <Field title="Tipologia" source="type" className="type" />
      <BadgeField title="Utente attivo" source="isActive" />
    </DataTable>
  );
}

export default CollaboratorsAdminTable;
