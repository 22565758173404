import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';
import { BtnOutlineOrange } from '../../styled-components';
import { IAFType } from '../../lib/constants';

const ExportIAFCSVField = ({ data }) => {
  const [t] = useTranslation();

  if (!data.iafType) return '';

  return (
    <CSVExport data={{ data }}>
      <BtnOutlineOrange type="button" className="uppercase">
        {t('COMMON:CSV_EXPORT')}
      </BtnOutlineOrange>
    </CSVExport>
  );
};

const getCSVData = ({ data = {} }) => {
  const { amount, iafType } = data;

  if (iafType === IAFType.INVOICES || iafType === IAFType.INVOICES_SUB_ADMINS) {
    const CSVData = [
      [
        'NOME FORNITORE',
        'NUMERO FATTURA',
        'DATA',
        'MESE RENDICONTAZIONE',
        'NOME RESPONSABILE CLIENTE',
        'CLIENTE',
        'IMPONIBILE',
        'QUOTA RESPONSABILE (%)',
        'QUOTA RESPONSABILE MATURATA',
        'INVITATO A FATTURARE',
      ],
    ];
    data.invoices.forEach(
      ({
        supplier: { businessName: supplierName } = {},
        number,
        date,
        accountability,
        admin: { businessName: adminName } = {},
        net,
        adminShare,
        earnedAdminShare,
        customer,
      }) => {
        CSVData.push([
          supplierName,
          number,
          moment(date).format('DD/MM/yyyy'),
          accountability ? moment(accountability).format('MM/yyyy') : '',
          adminName,
          customer,
          formatCurrency(+net),
          formatCurrency(iafType === IAFType.INVOICES ? 10 : 1),
          formatCurrency(+adminShare),
          formatCurrency(+earnedAdminShare),
        ]);
      }
    );
    CSVData.push(['', '', '', '', '', '', '', '', '', formatCurrency(+amount)]);

    return CSVData || [];
  }

  if (iafType === IAFType.ENERGY) {
    const profileName = data.profile[0].name;
    const CSVData = [
      [
        'NOME CLIENTE',
        'PARTITA IVA CLIENTE',
        'NOME RESPONSABILE',
        'RESPONSABILE',
        'QUOTA RESPONSABILE MATURATA',
        'INVITATO A FATTURARE',
      ],
    ];
    data.contracts.forEach(
      ({
        condo: { name: condoName } = {},
        condo: { vatCode } = {},
        admin: { businessName: adminName } = {},
        adminShare,
        earnedAdminShare,
      }) => {
        CSVData.push([
          condoName,
          vatCode,
          profileName,
          adminName,
          formatCurrency(+adminShare),
          formatCurrency(+earnedAdminShare),
        ]);
      }
    );
    CSVData.push(['', '', '', '', '', formatCurrency(+amount)]);

    return CSVData || [];
  }

  if (iafType === IAFType.RECURRENT_ENERGY) {
    const CSVData = [
      [
        'NOME CLIENTE',
        'PARTITA IVA CLIENTE',
        'NOME RESPONSABILE',
        'RESPONSABILE',
        'QUOTA RESPONSABILE MATURATA',
        'INVITATO A FATTURARE',
      ],
    ];
    const profileName = data.profile[0].name;
    data.recurrentContracts.forEach(
      ({
        condo: { name: condoName } = {},
        condo: { vatCode } = {},
        admin: { businessName: adminName } = {},
        adminShare,
        earnedAdminShare,
      }) => {
        CSVData.push([
          condoName,
          vatCode,
          profileName,
          adminName,
          formatCurrency(+adminShare),
          formatCurrency(+earnedAdminShare),
        ]);
      }
    );
    CSVData.push(['', '', '', '', '', formatCurrency(+amount)]);

    return CSVData || [];
  }

  if (iafType === IAFType.INSURANCES) {
    const CSVData = [
      [
        'NOME FORNITORE',
        'NUMERO ASSICURAZIONE',
        'DATA',
        'MESE RENDICONTAZIONE',
        'DATA INIZIO',
        'DATA FINE',
        'NOME RESPONSABILE CLIENTE',
        'IMPONIBILE',
        'QUOTA RESPONSABILE (%)',
        'QUOTA RESPONSABILE MATURATA',
        'INVITATO A FATTURARE',
      ],
    ];
    data.insurances.forEach(
      ({
        supplier: { businessName: supplierName } = {},
        insuranceNumber,
        date,
        accountability,
        startDate,
        endDate,
        admin: { businessName: adminName } = {},
        net,
        adminShare,
        earnedAdminShare,
      }) => {
        CSVData.push([
          supplierName,
          insuranceNumber,
          moment(date).format('DD/MM/yyyy'),
          accountability ? moment(accountability).format('MM/YYYY') : '',
          moment(startDate).format('DD/MM/yyyy'),
          moment(endDate).format('DD/MM/yyyy'),
          adminName,
          formatCurrency(+net),
          formatCurrency(10),
          formatCurrency(+adminShare),
          formatCurrency(+earnedAdminShare),
        ]);
      }
    );
    CSVData.push([
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      formatCurrency(+amount),
    ]);
    return CSVData || [];
  }

  return [['NO DATA']];
};

const getCSVNetworkData = ({ data = {} }) => {
  const { amount, iafType } = data;

  if (iafType === IAFType.INVOICES || iafType === IAFType.INVOICES_SUB_ADMINS) {
    const CSVData = [
      [
        'NOME FORNITORE',
        'NUMERO FATTURA',
        'DATA',
        'MESE RENDICONTAZIONE',
        'NOME RESPONSABILE CLIENTE',
        'CLIENTE',
        'IMPONIBILE',
        'QUOTA RESPONSABILE (%)',
        'QUOTA RESPONSABILE MATURATA',
        'INVITATO A FATTURARE',
      ],
    ];
    data.invoices.forEach(
      ({
        supplier: { businessName: supplierName } = {},
        number,
        date,
        accountability,
        admin: { businessName: adminName } = {},
        net,
        adminShare,
        earnedCommercialShare,
        customer,
      }) => {
        CSVData.push([
          supplierName,
          number,
          moment(date).format('DD/MM/yyyy'),
          accountability ? moment(accountability).format('MM/yyyy') : '',
          adminName,
          customer,
          formatCurrency(+net),
          formatCurrency(iafType === IAFType.INVOICES ? 10 : 1),
          formatCurrency(+adminShare),
          formatCurrency(+earnedCommercialShare),
        ]);
      }
    );
    CSVData.push(['', '', '', '', '', '', '', '', '', formatCurrency(+amount)]);

    return CSVData || [];
  }

  if (iafType === IAFType.ENERGY) {
    const profileName = data.profile[0].name;
    const CSVData = [
      [
        'NOME CLIENTE',
        'PARTITA IVA CLIENTE',
        'NOME RESPONSABILE',
        'RESPONSABILE',
        'QUOTA RESPONSABILE MATURATA',
        'INVITATO A FATTURARE',
      ],
    ];
    data.contracts.forEach(
      ({
        condo: { name: condoName } = {},
        condo: { vatCode } = {},
        admin: { businessName: adminName } = {},
        adminShare,
        earnedCommercialShare,
      }) => {
        CSVData.push([
          condoName,
          vatCode,
          profileName,
          adminName,
          formatCurrency(+adminShare),
          formatCurrency(+earnedCommercialShare),
        ]);
      }
    );
    CSVData.push(['', '', '', '', '', formatCurrency(+amount)]);

    return CSVData || [];
  }

  if (iafType === IAFType.RECURRENT_ENERGY) {
    const CSVData = [
      [
        'NOME CLIENTE',
        'PARTITA IVA CLIENTE',
        'NOME RESPONSABILE',
        'RESPONSABILE',
        'QUOTA RESPONSABILE MATURATA',
        'INVITATO A FATTURARE',
      ],
    ];
    const profileName = data.profile[0].name;
    data.recurrentContracts.forEach(
      ({
        condo: { name: condoName } = {},
        condo: { vatCode } = {},
        admin: { businessName: adminName } = {},
        adminShare,
        earnedCommercialShare,
      }) => {
        CSVData.push([
          condoName,
          vatCode,
          profileName,
          adminName,
          formatCurrency(+adminShare),
          formatCurrency(+earnedCommercialShare),
        ]);
      }
    );
    CSVData.push(['', '', '', '', '', formatCurrency(+amount)]);

    return CSVData || [];
  }

  if (iafType === IAFType.INSURANCES) {
    const CSVData = [
      [
        'NOME FORNITORE',
        'NUMERO ASSICURAZIONE',
        'DATA',
        'MESE RENDICONTAZIONE',
        'DATA INIZIO',
        'DATA FINE',
        'NOME RESPONSABILE CLIENTE',
        'IMPONIBILE',
        'QUOTA RESPONSABILE (%)',
        'QUOTA RESPONSABILE MATURATA',
        'INVITATO A FATTURARE',
      ],
    ];
    data.insurances.forEach(
      ({
        supplier: { businessName: supplierName } = {},
        insuranceNumber,
        date,
        accountability,
        startDate,
        endDate,
        admin: { businessName: adminName } = {},
        net,
        adminShare,
        earnedCommercialShare,
      }) => {
        CSVData.push([
          supplierName,
          insuranceNumber,
          moment(date).format('DD/MM/yyyy'),
          accountability ? moment(accountability).format('MM/YYYY') : '',
          moment(startDate).format('DD/MM/yyyy'),
          moment(endDate).format('DD/MM/yyyy'),
          adminName,
          formatCurrency(+net),
          formatCurrency(10),
          formatCurrency(+adminShare),
          formatCurrency(+earnedCommercialShare),
        ]);
      }
    );
    CSVData.push([
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      formatCurrency(+amount),
    ]);
    return CSVData || [];
  }

  return [['NO DATA']];
};

const CSVExport = ({ data = {}, children }) => {
  const csvData =
    data.data.commercial === true
      ? getCSVNetworkData(data)
      : getCSVData(data) || [];
  return (
    <CSVLink
      data={csvData}
      filename={`IAF_${data.data._id.toUpperCase()}.csv`}
      separator={';'}
    >
      {children}
    </CSVLink>
  );
};

export default ExportIAFCSVField;
