import React from 'react';
import Proptypes from 'prop-types';
import {
  Card,
  Button,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import LogoHeader from '../../commons/LogoHeader';
import FormErrorMessage from '../../commons/FormErrorMessage';
import './Verification.scss';

function VerificationPresentational({ match, onVerify }) {
  const { t } = useTranslation();
  const { handleSubmit, register, errors } = useForm();

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">
          <Card className="u-center-horizontal login-card">
            <CardHeader>
              <CardTitle>
                {t('COMMON:VERIFICATION')}
                <br />
                <span>{t('COMMON:VERIFICATION_DESCRIPTION')}</span>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onVerify)}>
                <FormGroup>
                  <Label>{t('COMMON:CODE')}</Label>
                  <Input
                    type="text"
                    name="code"
                    autoComplete="off"
                    innerRef={register({
                      required: 'Campo obbligatorio',
                      pattern: {
                        value: /^[A-Z0-9]{4,6}$/i,
                        message: 'Codice non valido',
                      },
                    })}
                  />
                  <FormErrorMessage errors={errors} name="code" />
                </FormGroup>

                <Button className="u-center-horizontal">
                  {t('COMMON:VERIFICATION')}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </div>
      </section>
    </div>
  );
}

VerificationPresentational.propTypes = {
  onVerify: Proptypes.func.isRequired,
};

export default VerificationPresentational;
