import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { DataTable, Field, CurrencyField } from '../../commons/DataTable';

function DashboardDetailTable({ data = [], topRow, bottomRow }) {
  const [t] = useTranslation('COMMON');

  return (
    <DataTable
      history
      data={data}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      topRow={topRow}
      bottomRow={bottomRow}
    >
      <Field
        title="Ragione sociale / Nome"
        source="businessName"
        className="title text-left"
      />
      <Field title="Periodo di affiliazione" source="affiliationPeriod" />
      <CurrencyField
        title="Dovuto"
        source="contractAmount"
        className="txt-table-right"
      />
    </DataTable>
  );
}

export default withRouter(DashboardDetailTable);
