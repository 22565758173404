import axios from 'axios';

export const resendEmailBonus = async ({ token, query = {}, _id }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/bonuses/${_id}`,
      headers: {
        Authorization: token,
      },
      params: query,
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};
