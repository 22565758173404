import React from 'react';
import { EON_PRODUCT_TYPE_LABELS } from '../../lib/constants';

function ContractEONType({ value, maxLength }) {
  if (!value) return <>-</>;
  value = EON_PRODUCT_TYPE_LABELS[value];

  if (maxLength && typeof value === 'string' && value.length > maxLength) {
    return <>{value.substr(0, maxLength)}...</>;
  }

  return <>{value}</>;
}

export default ContractEONType;
