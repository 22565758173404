import './MyCsutomersExtra.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, Button } from 'reactstrap';
import SearchInput from '../../commons/SearchInput';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconProfile } from '../../images/icn_profilo.svg';
import {
  ContFlexLeft,
  ContFlexRight,
  ContPaginationPage,
} from '../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import ModalNewCustomerExtra from './ModalNewCustomerExtra';
import CustomersExtraTable from './CustomersExtraTable';
import CustomersImportFeedbackModal from './CustomersImportFeedbackModal';

const CustomersExtraPresentational = ({
  data,
  profile,
  isAdmin,
  query,
  total,
  onFilter,
  onNewCustomerExtraConfirm,
  onCustomerExtraUpdate,
  onImportCustomers,
  toggleShowImportFeedback,
  customersImportFeedbackData,
  hasImportedCustomers,
  checkTaxCode,
}) => {
  const [t] = useTranslation('CUSTOMER_EXTRA');
  const { offset, limit } = query;

  function handleImportFile(event) {
    const file = event.target?.files[0];

    const fileSize = (Number(file?.size || 0) / 1024 ** 2).toFixed(2);

    if (fileSize > 20) {
      alert.info('File troppo grande. Sono ammessi file di massimo 20MB');
    } else {
      const data = new FormData();
      data.append('file', file);
      onImportCustomers(data);
    }
  }

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <CustomersImportFeedbackModal
          data={customersImportFeedbackData}
          toggle={toggleShowImportFeedback}
          isOpen={hasImportedCustomers}
        />
        <section>
          <Header
            title={t('PAGES:CUSTOMER_EXTRA')}
            icon={<IconProfile fill="#ffffff" style={{ height: '25px' }} />}
          />
          <Row className="align-items-center">
            <Col className="">
              <ContFlexRight>
                Numero di clienti:&nbsp;<strong>{total}</strong>
              </ContFlexRight>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col xs={12} style={{ marginBottom: 30 }}>
              <Button
                href={`/examples/Template_Clienti_Amministratore.csv`}
                target="_blank"
                color="primary"
              >
                SCARICA TEMPLATE IMPORTAZIONE
              </Button>
              <Button
                href={`/documentation/Extra_Importazione_clienti_amministratori.pdf`}
                target="_blank"
                color="primary"
                style={{ marginLeft: '2rem' }}
              >
                SCARICA DOCUMENTAZIONE IMPORTAZIONE
              </Button>
            </Col>
            <Col sm="12" md="4">
              <ContFlexLeft>
                <ModalNewCustomerExtra
                  administrator={profile}
                  onConfirm={onNewCustomerExtraConfirm}
                  checkTaxCode={checkTaxCode}
                />
              </ContFlexLeft>
            </Col>
          </Row>

          <Row className="search-add-cont m-t-20">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.businessName }}
                onFilter={({ search }) => onFilter({ businessName: search })}
                label={t('SEARCH:BUSINESS_NAME')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.taxCode }}
                onFilter={({ search }) => onFilter({ taxCode: search })}
                label={t('SEARCH:TAX_CODE')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <FormGroup>
                <CustomInput
                  type="file"
                  id="importFilee"
                  name="importFilee"
                  onChange={(event) => handleImportFile(event)}
                  accept=".csv"
                />
                <Label for="selectFile">
                  {t('CHOOSE_FILE')}
                  &nbsp;({t('MAX_UPLOAD_FILE_SIZE')})
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </section>

        <section className="m-t-20">
          <CustomersExtraTable
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            onCustomerExtraUpdate={onCustomerExtraUpdate}
            profile={profile}
            truncateTable={profile && profile._id}
          />

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default CustomersExtraPresentational;
