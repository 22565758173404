import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { compensi, compensiExport } from '../../lib/fetch/dashboard';
import DashboardDetailCompensiPresentational from './DashboardDetailCompensiPresentational';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
} from 'use-query-params';
import axios from 'axios';
import { createBonus, getAllOverdueInvoices } from '../../lib/fetch/reports';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT } = process.env;

function DashboardDetailCompensi(props) {
  const { showLoading, hideLoading } = useLoading();
  const [data, setData] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [total, setTotal] = useState(0);
  const [{ token } = {}] = useAuth();
  const { t } = useTranslation('DASHBOARD');
  const alert = useAlert();

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    admin: withDefault(StringParam, ''),
    customer: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT || 50),
    paid: withDefault(StringParam, ''),
    gabettiInvoice: withDefault(StringParam, ''),
    year: NumberParam,
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
    invoiceRef: StringParam,
  });

  const {
    limit,
    offset,
    search,
    gabettiInvoice,
    paid,
    admin,
    customer,
    number,
    supplier,
    year,
    accountabilityYear,
    accountabilityMonth,
    invoiceRef,
  } = query;

  const fetchCompensi = async () => {
    showLoading();

    const { error, headers, data } = await compensi({
      token,
      year,
      search,
      limit,
      offset,
      gabettiInvoice,
      paid,
      admin,
      customer,
      number,
      supplier,
      accountabilityYear,
      accountabilityMonth,
      invoiceRef,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setData(data);
    setSelectedInvoices([]);
    setTotal(Number(headers['x-total-count']));
    hideLoading();
  };

  const fetchCompensiExport = async () => {
    showLoading();
    setIsReady(false);

    const { error, headers, data } = await compensi({
      offset: 0,
      limit: 5000,
      token,
      year,
      search,
      gabettiInvoice,
      paid,
      admin,
      customer,
      number,
      supplier,
      accountabilityYear,
      accountabilityMonth,
      invoiceRef,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setDataExport(data);
    setIsReady(true);
    hideLoading();
  };

  useEffect(() => {
    fetchCompensi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    year,
    search,
    offset,
    limit,
    gabettiInvoice,
    paid,
    admin,
    customer,
    number,
    supplier,
    accountabilityYear,
    accountabilityMonth,
    invoiceRef,
  ]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.search && queryDiff.search !== search) || // first search
      (search && queryDiff.search === '') || // search deleted
      (queryDiff.admin && queryDiff.admin !== admin) || // first search
      (admin && queryDiff.admin === '') || // search deleted
      (queryDiff.customer && queryDiff.customer !== customer) || // first search
      (customer && queryDiff.customer === '') || // search deleted
      (queryDiff.number && queryDiff.number !== number) || // first search
      (number && queryDiff.number === '') || // search deleted
      (queryDiff.supplier && queryDiff.supplier !== supplier) || // first search
      (supplier && queryDiff.supplier === '') ||
      (queryDiff.invoiceRef && queryDiff.invoiceRef !== invoiceRef) ||
      (invoiceRef && queryDiff.invoiceRef === '') ||
      (queryDiff.accountabilityMonth &&
        queryDiff.accountabilityMonth !== accountabilityMonth) ||
      queryDiff.accountabilityMonth === '' ||
      (queryDiff.paid && queryDiff.paid !== paid) ||
      (paid && queryDiff.paid === '') ||
      (queryDiff.gabettiInvoice &&
        queryDiff.gabettiInvoice !== gabettiInvoice) ||
      (gabettiInvoice && queryDiff.gabettiInvoice === '') ||
      (queryDiff.limit && queryDiff.limit !== limit) ||
      (queryDiff.year && queryDiff.year !== year) ||
      (search && queryDiff.year === '')
    ) {
      queryDiff.offset = 0; // return to first page
    }
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = ''; // reset month
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  function onSelectChange(_id) {
    if (selectedInvoices.includes(_id)) {
      return setSelectedInvoices((selectedInvoices) =>
        selectedInvoices.filter((invoiceId) => invoiceId !== _id)
      );
    }
    setSelectedInvoices((selectedInvoices) => [...selectedInvoices, _id]);
  }

  const onSelectAll = (data) => {
    setSelectedInvoices(data.map(({ _id }) => _id));
  };

  const emailOnSelectChange = (invoice) => {
    const filteredDataIndex = data.findIndex(
      (elem) => elem._id === invoice._id
    );

    const updatedInvoice = {
      ...invoice,
      checked: !data[filteredDataIndex].checked,
    };

    const updatedData = [
      ...data.slice(0, filteredDataIndex),
      updatedInvoice,
      ...data.slice(filteredDataIndex + 1),
    ];

    setData(updatedData);
  };

  async function handleSendEmails(invoices) {
    showLoading();

    const adminMap = {};
    for (const invoice of invoices) {
      if (!adminMap[invoice.adminId]) {
        adminMap[invoice.adminId] = [];
      }
      adminMap[invoice.adminId].push(invoice);
    }

    const adminsInvoicesCollection = Object.keys(adminMap).map((adminId) => ({
      adminId,
      invoices: adminMap[adminId],
    }));

    const supplierMap = {};
    for (const invoice of invoices) {
      if (!supplierMap[invoice.supplierId]) {
        supplierMap[invoice.supplierId] = [];
      }
      supplierMap[invoice.supplierId].push(invoice);
    }

    const suppliersInvoicesCollection = Object.keys(supplierMap).map(
      (supplierId) => ({
        supplierId,
        invoices: supplierMap[supplierId],
      })
    );

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/invoices/sendOverdueInvoicePaymentEmail`,
      {
        adminsInvoicesCollection,
        suppliersInvoicesCollection,
      },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }
    alert.success(t('COMMON:SUCCESS_RESEND_BONUS'));
  }

  return (
    <DashboardDetailCompensiPresentational
      data={data}
      dataExport={dataExport}
      fetchCompensiExport={fetchCompensiExport}
      isReady={isReady}
      setIsReady={setIsReady}
      year={year}
      query={query}
      total={total}
      onFilter={onFilter}
      selectedInvoices={selectedInvoices}
      onSelectChange={onSelectChange}
      onSelectAll={onSelectAll}
      handleSendEmails={handleSendEmails}
      emailOnSelectChange={emailOnSelectChange}
      {...props}
    />
  );
}

export default DashboardDetailCompensi;
