import React from 'react';

function StatusField(props) {
  const { data } = props;

  return (
    <>
      {data.invoicePinv?.status === 'to_deliver'
        ? data.invoicePinv?.einvoice?.status
          ? data.invoicePinv?.einvoice?.status
          : 'Bozza'
        : data.invoicePinv?.einvoice?.status
        ? data.invoicePinv?.einvoice?.status
        : 'Inviata'}
    </>
  );
}

export default StatusField;
