export default {
  background: '#F3F3F3',
  black: '#333333',
  darkBlack: '#000000',
  white: '#FFFFFF',
  primary: '#115E96',
  lightPrimary: '#FFF9E2',
  darkPrimary: '#17344d',
  grey: '#E0E0E0',
  darkGrey: '#666666',
  success: '#4CAF50',
  warning: '#FF8155',
  blue: '#6BA8B2',
  alert: '#F0C755',
  red: '#ff5252',
  neutral: '#8A8C8D',
  text: '#000501',
  secondary: '#607584',

  primary_op: '#115E960A',
  success_op: '#4CAF500A',
  success_border: '#4CAF5080',
  red_op: '#ff52520A',
  red_border: '#ff525280',
  warning_op: '#FF81550A',
  warning_border: '#FF815580',
  secondary_op: '#6075840A',

  Roboto: 'Roboto, sans-serif',
  RobotoSlab: 'Roboto Slab, serif',
};
