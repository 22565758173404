import './MyCondos.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label } from 'reactstrap';
import SearchInput from '../../../commons/SearchInput';
import { ReactComponent as IconBuilding } from '../../../images/icn_buildings.svg';
import {
  ContFilterCheckbox,
  ContFilterYear,
  ContFlexLeft,
  ContFlexRight,
  ContPaginationPage,
} from '../../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import CondosTableEnergy from './CondosTableEnergy';

const CondosPresentational = ({ data, profile, query, total, onFilter }) => {
  const [t] = useTranslation('CONDOS');
  const { offset, limit } = query;

  return (
    <>
      <section>
        <Row className="align-items-center">
          <Col className="">
            <ContFlexRight>
              Numero di condomini:&nbsp;<strong>{total}</strong>
            </ContFlexRight>
          </Col>
        </Row>

        <Row className="search-add-cont m-t-20">
          {!(profile && profile._id) && (
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.admin }}
                onFilter={({ search }) => onFilter({ admin: search })}
                label={t('SEARCH:ADMINISTRATOR')}
              />
            </Col>
          )}
          <Col sm="6" md="6" lg="3" xl="3">
            <SearchInput
              query={{ search: query && query.name }}
              onFilter={({ search }) => onFilter({ name: search })}
              label={t('SEARCH:NAME')}
            />
          </Col>
          <Col sm="6" md="6" lg="3" xl="3">
            <SearchInput
              query={{ search: query && query.vatCode }}
              onFilter={({ search }) => onFilter({ vatCode: search })}
              label={t('SEARCH:VAT_CODE')}
            />
          </Col>
          <Col sm="6" md="6" lg="3" xl="3">
            <SearchInput
              query={{ search: query && query.address }}
              onFilter={({ search }) => onFilter({ address: search })}
              label={t('SEARCH:ADDRESS')}
            />
          </Col>
          <Col sm="6" md="6" lg="3" xl="3">
            <SearchInput
              query={{ search: query && query.studioManager }}
              onFilter={({ search }) => onFilter({ studioManager: search })}
              label={t('SEARCH:STUDIO_MANAGER')}
            />
          </Col>
          <Col sm="12" md="6" lg="3">
            <ContFilterYear className="no-width" style={{ marginTop: '1rem' }}>
              <Row>
                <Col xs="6">
                  <Label>Gestioni Attive</Label>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <CustomInput
                      type="select"
                      id="condosActive"
                      name="condosActive"
                      onChange={(e) =>
                        onFilter({
                          condosActive: e.target.value,
                        })
                      }
                      value={query.condosActive || ''}
                    >
                      <option value="" defaultChecked>
                        Tutti
                      </option>
                      <option value="ACTIVE">Attivi</option>
                      <option value="NOT_ACTIVE">Non Attivi</option>
                    </CustomInput>
                  </FormGroup>
                </Col>
              </Row>
            </ContFilterYear>
          </Col>
        </Row>
      </section>

      <section className="m-t-20">
        <CondosTableEnergy
          data={data}
          total={total}
          offset={offset}
          limit={limit}
          onFilter={onFilter}
        />

        <ContPaginationPage>
          <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

          <FormGroup>
            <CustomInput
              type="select"
              className="pagination-select"
              id="perPage"
              name="perPage"
              value={query.limit}
              onChange={({ target: { value: limit } }) => {
                onFilter({ limit });
              }}
            >
              {generatePaginationOptions()}
            </CustomInput>
          </FormGroup>
        </ContPaginationPage>
      </section>
    </>
  );
};

export default CondosPresentational;
