import React from 'react';
import { formatCurrency } from '../../../lib/helpers/formatters';
import { useAuth } from '../../../commons/Auth';
import { USER_ADMIN_TYPES } from '../../../App';

const TotalTr = ({
  totals = {},
  route = '',
  activeRoute = false,
  truncateTotals = false,
}) => {
  const {
    cashedTotals = 0,
    netTotals = 0,
    grossTotals = 0,
    commercialShareTotals = 0,
    earnedCommercialShareTotals = 0,
    billableCommercialShareTotals = 0,
  } = totals;

  const [{ userType = '' }] = useAuth();

  return (
    <tr
      className="summary-tr"
      onClick={() => {
        return activeRoute === true
          ? (window.location.href = `${
              window.location.href.split('?')[0]
            }/${route}`)
          : null;
      }}
    >
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}

      <td
        className={
          userType === USER_ADMIN_TYPES.SUPER_ADMIN
            ? `txt-table-right`
            : `txt-table-center`
        }
      >
        {formatCurrency(netTotals)}
      </td>
      <td
        className={
          userType === USER_ADMIN_TYPES.SUPER_ADMIN
            ? `txt-table-right`
            : `txt-table-center`
        }
      >
        {formatCurrency(grossTotals)}
      </td>
      <td
        className={
          userType === USER_ADMIN_TYPES.SUPER_ADMIN
            ? `txt-table-right`
            : `txt-table-center`
        }
      >
        {formatCurrency(cashedTotals)}{' '}
      </td>
      {userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
        <td className="txt-table-right">
          {formatCurrency(commercialShareTotals)}
        </td>
      )}
      {userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
        <td className="txt-table-right">
          {formatCurrency(earnedCommercialShareTotals)}
        </td>
      )}
      {userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
        <td className="txt-table-right">
          {formatCurrency(billableCommercialShareTotals)}{' '}
        </td>
      )}
      {!truncateTotals && userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
        <td>-</td>
      )}
      {!truncateTotals && userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
        <td>-</td>
      )}
    </tr>
  );
};

export default TotalTr;
