import React, { useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';

export default (props) => {
  const {
    allColumns,
    children,
    data = [],
    selectedInvoices = [],
    year,
    fetchCompensiExport,
    isReady,
    setIsReady,
  } = props;
  const hasSelectedInvoices = selectedInvoices && selectedInvoices.length;
  let csvData, invoices;

  if (!allColumns || hasSelectedInvoices) {
    csvData = [
      [
        'AMMINISTRATORE DI CONDOMINIO',
        'TOTALE IMPONIBILE DA FATTURE STUDIO',
        '1% TOTALE IMPONIBILE DA FATTURE STUDIO',
        '% EXTRA (€)',
        'QUOTA SOGLIA',
      ],
    ];
    invoices = hasSelectedInvoices
      ? data.filter(({ _id }) => selectedInvoices.includes(_id))
      : data;
  } else {
    csvData = [
      [
        'AMMINISTRATORE DI CONDOMINIO',
        'TOTALE IMPONIBILE DA FATTURE STUDIO',
        '1% TOTALE IMPONIBILE DA FATTURE STUDIO',
        '% EXTRA (€)',
        'QUOTA SOGLIA',
      ],
    ];
    invoices = data;
  }

  invoices.forEach((compenso = {}) => {
    const {
      admin: { businessName: adminName = '', type: adminType = '' } = {},
      total,
      onePercentOfTotalGrossPaid,
      totalGross,
      isThreshold,
    } = compenso;

    if (!allColumns || hasSelectedInvoices) {
      csvData.push([
        // 'AMMINISTRATORE DI CONDOMINIO',
        adminName,
        // 'TOTALE IMPONIBILE DA FATTURE STUDIO',
        formatCurrency(totalGross),
        // '1% TOTALE IMPONIBILE DA FATTURE STUDIO',
        formatCurrency(onePercentOfTotalGrossPaid),
        // '% EXTRA (€)',
        formatCurrency(total),
        // 'QUOTA SOGLIA',
        isThreshold ? 'Si' : 'No',
      ]);
    } else {
      csvData.push([
        // 'AMMINISTRATORE DI CONDOMINIO',
        adminName,
        // 'TOTALE IMPONIBILE DA FATTURE STUDIO',
        formatCurrency(totalGross),
        // '1% TOTALE IMPONIBILE DA FATTURE STUDIO',
        formatCurrency(onePercentOfTotalGrossPaid),
        // '% EXTRA (€)',
        formatCurrency(total),
        // 'QUOTA SOGLIA',
        isThreshold ? 'Si' : 'No',
      ]);
    }
  });

  const handleClick = (event) => {
    if (!isReady) {
      event.preventDefault();
      fetchCompensiExport();
    }
  };

  const csvLinkRef = useRef();
  useEffect(() => {
    if (isReady && csvLinkRef.current) {
      csvLinkRef.current.link.click();
      setIsReady(false);
    }
  }, [isReady]);

  return (
    <CSVLink
      data={csvData}
      filename={`COMPENSI_VARIABILI_STUDIO_${year}.csv`}
      separator={';'}
      onClick={handleClick}
      ref={csvLinkRef}
    >
      {children}
    </CSVLink>
  );
};
