import axios from 'axios';

const verification = async (code) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_VERIFY_CODE}/${code}?appId=${process.env.REACT_APP_ID}`,
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export default verification;
