import './MyRoyalties.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import {
  CurrencyField,
  DataTable,
  DateTimeField,
  Field,
  PaidBadge,
  PaymentsDataField,
} from '../../commons/DataTable';
import SearchInput from '../../commons/SearchInput';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import TotalRows from './TotalTr';
import {
  BtnOutlineOrange,
  BtnRadiusWhite,
  ContFilterCheckbox,
  ContFilterYear,
  ContFlexLeft,
  ContFlexRight,
  ContPaginationPage,
} from '../../styled-components';
import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../lib/helpers/generateMonthOptions';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import variables from '../../variables';
import BilledField from './BilledField';
import { ADMIN_TYPES, AGENT_TYPES } from '../../App';
import CSVRoyaltiesExport from './CSVRoyaltiesExport';

function getTotals(data) {
  let cashed = 0,
    net = 0,
    iafTotal = 0;
  data.forEach((res) => {
    cashed += (res.payments || []).reduce((acc, payment) => {
      return acc + ((payment && payment.gross) || 0);
    }, 0);
    net += res.net;
    iafTotal += res.earnedAdminShare ? res.earnedAdminShare : 0;
  });
  return { net, cashed, iafTotal };
}
const RoyaltiesPresentational = ({
  data,
  dataExport = [],
  profileScore,
  query,
  total,
  onFilter,
  selectedInvoices,
  onSelectChange,
  onAddPayment,
  profile,
  fetchInvoicesExport,
  isReady = false,
  setIsReady,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [allColumns, setAllColumns] = useState(false);
  const [t] = useTranslation('ENERGY');
  const { offset, limit } = query;
  const [selectedNet, setSelectedNet] = useState(0);
  const [populatedSelected, setPopulatedSelected] = useState([]);

  const onFilterPaidInvoices = (e) => {
    onFilter({
      paid: e.paid,
    });
  };

  const onRowClick = ({ _id, regular }) => {
    !regular && onSelectChange(_id);
  };

  useEffect(() => {
    setPopulatedSelected(
      selectedInvoices.length
        ? data.filter(({ _id }) => selectedInvoices.includes(_id))
        : data
    );
  }, [selectedInvoices, data]);

  useEffect(() => {
    setSelectedNet(populatedSelected.reduce((acc, { net }) => acc + net, 0));
  }, [populatedSelected]);

  data = data?.map((elem) => {
    return {
      ...elem,
      customerType:
        elem.customerType === 'condos'
          ? 'CONDOMINIO'
          : !elem.customerType
          ? 'CONDOMINIO'
          : 'CLIENTE',
    };
  });

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:INVOICES_AND_PAYMENTS')}
            icon={
              <IconInvoices fill={variables.white} style={{ height: '25px' }} />
            }
            profileScore={profileScore}
          />

          <Row>
            <Col className="">
              {ADMIN_TYPES.includes(profile.type) && (
                <Col className="">
                  <ContFlexLeft>
                    {t('SCORE:SUPPLIERS_SCORE')}:&nbsp;
                    <strong>{profileScore.supplierScore || 0}</strong>
                  </ContFlexLeft>
                </Col>
              )}
            </Col>

            <Col>
              <ContFlexRight className="show-invoices">
                <BtnRadiusWhite
                  className={
                    query.all === 'false' ? 'eye-toggle show-me' : 'eye-toggle'
                  }
                  onClick={() =>
                    onFilter({ all: query.all === 'true' ? 'false' : 'true' })
                  }
                >
                  <i className="far fa-eye"></i>
                </BtnRadiusWhite>
              </ContFlexRight>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <CSVRoyaltiesExport
                allColumns={allColumns}
                data={dataExport}
                fetchInvoicesExport={fetchInvoicesExport}
                isReady={isReady}
                setIsReady={setIsReady}
                selectedInvoices={selectedInvoices}
                getTotals={getTotals}
              >
                <Button target="_blank" color="primary">
                  {t('CONDOS:EXPORT_ROYALTIES')}
                </Button>
              </CSVRoyaltiesExport>
            </Col>
          </Row>
          <Row className="m-t-20">
            <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        className="year-select"
                        value={query && query.year}
                        onChange={({ target: { value: year } }) =>
                          onFilter({ year })
                        }
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>

            <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectAccountabilityYear"
                        name="accountabilityYear"
                        className="year-select"
                        value={query && query.accountabilityYear}
                        onChange={({
                          target: { value: accountabilityYear },
                        }) => {
                          onFilter({ accountabilityYear });
                        }}
                      >
                        {generateYearOptions(true)}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="accountabilityMonth"
                        name="accountabilityMonth"
                        className="year-select"
                        disabled={!query.accountabilityYear}
                        value={
                          query && query.accountabilityMonth === null
                            ? ''
                            : query.accountabilityMonth
                        }
                        onChange={({
                          target: { value: accountabilityMonth },
                        }) => onFilter({ accountabilityMonth })}
                      >
                        {generateMonthOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
          </Row>
          <Row className="search-add-cont">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.supplier }}
                onFilter={({ search }) => onFilter({ supplier: search })}
                label={t('SEARCH:BY_SUPPLIER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.number }}
                onFilter={({ search }) => onFilter({ number: search })}
                label={t('SEARCH:INVOICE_NUMBER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.customer }}
                onFilter={({ search }) => onFilter({ customer: search })}
                label={t('SEARCH:CUSTOMER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.studioManager }}
                onFilter={({ search }) => onFilter({ studioManager: search })}
                label={t('SEARCH:STUDIO_MANAGER')}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: '20px', paddingTop: '20px' }}>
            <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>Stato Fatture</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="paid"
                        name="paid"
                        onChange={({ target: { value: paid } }) =>
                          onFilterPaidInvoices({ paid })
                        }
                      >
                        <option value="" defaultChecked>
                          Tutti
                        </option>
                        <option value="PAID">Pagate</option>
                        <option value="NOT_PAID">Non Pagate</option>
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
          </Row>
        </section>

        <section className="m-t-20">
          <DataTable
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            onRowClick={onRowClick}
            emptyText={t('COMMON:NO_DATA')}
            bottomRow={<TotalRows totals={getTotals(data)} />}
          >
            <Field
              title="Nome Fornitore"
              source="supplier.businessName"
              className="text-left"
            />
            <Field title="Cliente" source="customer" className="text-left" />
            <Field title="Tipo Cliente" source="customerType" />
            <Field title="Numero Fattura" source="number" />
            <Field title="Codice fiscale / partita IVA" source="vatCode" />
            <DateTimeField
              title="Data Fattura"
              source="date"
              format="DD/MM/YYYY"
            />
            <DateTimeField
              title="Mese Rend."
              source="accountability"
              format="MM/YYYY"
            />
            <DateTimeField
              title="Data prevista incassi"
              source="supposedPaymentDate"
              format="DD/MM/YYYY"
            />
            <Field title="Gestore studio" source="studioManager" />
            <CurrencyField title="Importo NETTO" source="net" />
            <PaymentsDataField title="Pagamenti" source="payments" />
            <PaidBadge title="Pagata" source="paid" />
            <Field title="Numero IAF" source="invoiceIaf[0].number" />
            <BilledField
              title="Invitato a Fatturare"
              className="txt-table-center"
            />
          </DataTable>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default RoyaltiesPresentational;
