import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

const TotalTr = ({ totals = {} }) => {
  const { net = 0 } = totals;

  return (
    <tr className="summary-tr">
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>{formatCurrency(net)}</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
    </tr>
  );
};

export default TotalTr;
