import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Row, Col, FormGroup, Label, Input, FormText } from 'reactstrap';
import DateInput from '../../../commons/DateInput';
import '../../Condos/MyCondos.scss';
import { useAuth } from '../../../commons/Auth';

const AdministratorRow = ({
  admin,
  errors,
  onChangeStartDate,
  onChangeEndDate,
  idx,
}) => {
  const [t] = useTranslation('CONDOS');
  const [{ isAdmin, profile = {} } = {}] = useAuth();

  const isStrangerAdmin = useMemo(
    () => (isAdmin ? false : profile._id !== admin.id),
    [profile, admin]
  );

  if (!admin) return <></>;

  return (
    <Row>
      <Col xs="12" md="6" lg="6">
        <FormGroup>
          <Label>{t('START_DATE')}</Label>
          <DateInput
            name={`admins[${idx}].startdate`}
            value={admin.startDate}
            onChange={(val) => onChangeStartDate(val, admin.id)}
            disabled={isStrangerAdmin}
          />
          {errors.startDate && (
            <FormText className="error-message">{t('DATES_ERROR')}</FormText>
          )}
        </FormGroup>
      </Col>
      <Col xs="12" md="6" lg="6">
        <FormGroup>
          <Label>{t('END_DATE')}</Label>
          <DateInput
            name={`admins[${idx}].endDate`}
            value={admin.endDate}
            onChange={(val) => onChangeEndDate(val, admin.id)}
            disabled={isStrangerAdmin}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default AdministratorRow;
