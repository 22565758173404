import './MyContracts.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import SearchInput from '../../commons/SearchInput';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconBuilding } from '../../images/icn_buildings.svg';
import {
  BtnRadiusWhite,
  ContFlexLeft,
  ContFlexRight,
  ContPaginationPage,
  H4Styled,
} from '../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import ContractsTable from './ContractsTable';
import TotalTr from './TotalTr';
import variables from '../../variables';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';

const ContractsPresentational = ({
  data,
  profile,
  query,
  total,
  onFilter,
  onCondoUpdate,
  onChangeAdmin,
  onExtendAdminManagement,
  checkVatCode,
}) => {
  const [t] = useTranslation('CONTRACTS');
  const { offset, limit, year } = query;
  const history = useHistory();

  const totals = Array.isArray(data)
    ? data.reduce(
        (acc, contracts) => {
          return {
            totalExtraShare:
              acc.totalExtraShare + Number(contracts?.extraShare || 0),
            totalAdminShare:
              acc.totalAdminShare + Number(contracts?.adminShare || 0),
          };
        },
        { totalExtraShare: 0, totalAdminShare: 0 }
      )
    : { totalExtraShare: 0, totalAdminShare: 0 };

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={`RENDICONTAZIONE RICORRENTI CONTRATTI - ${year}`}
            icon={<IconBuilding fill="#ffffff" style={{ height: '25px' }} />}
          />
        </section>
        <section>
          <Row className="align-items-center">
            <Col md="12" lg="6">
              <ContFlexLeft>
                <BtnRadiusWhite onClick={() => history.replace('/dashboard')}>
                  <IconBack fill={variables.primary} />
                </BtnRadiusWhite>

                <H4Styled>Compensi Ricorrenti Energia Anyway Green</H4Styled>
              </ContFlexLeft>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col className="">
              <ContFlexRight>
                Numero di contratti:&nbsp;<strong>{total}</strong>
              </ContFlexRight>
            </Col>
          </Row>

          <Row className="search-add-cont m-t-20">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.customer }}
                onFilter={({ search }) => onFilter({ customer: search })}
                label={t('SEARCH:CUSTOMER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.pod_pdr }}
                onFilter={({ search }) => onFilter({ pod_pdr: search })}
                label={t('SEARCH:POD_PDR')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.condo_vat_cf }}
                onFilter={({ search }) => onFilter({ condo_vat_cf: search })}
                label={t('SEARCH:CONDO_VAT_CODE')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.condo_admin_cf }}
                onFilter={({ search }) => onFilter({ condo_admin_cf: search })}
                label={t('SEARCH:ADMIN_VAT_CODE')}
              />
            </Col>
          </Row>
        </section>

        <section className="m-t-20">
          <ContractsTable
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            onCondoUpdate={onCondoUpdate}
            onChangeAdmin={onChangeAdmin}
            profile={profile}
            onExtendAdminManagement={onExtendAdminManagement}
            truncateTable={profile && profile._id}
            checkVatCode={checkVatCode}
            bottomRow={<TotalTr totals={totals} />}
          />

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit: limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default ContractsPresentational;
