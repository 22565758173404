import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  Field,
  DateTimeField,
  CurrencyField,
} from '../../commons/DataTable';

function ReceiptsTable({ data, readOnly, handleDeletePayment }) {
  const [t] = useTranslation('COMMON');

  return (
    <DataTable data={data} loadingText={t('LOADING')} emptyText={t('NO_DATA')}>
      <DateTimeField title="Data incasso" format="DD/MM/YYYY" source="date" />
      <Field title="Tipologia incasso" source="type" />
      <CurrencyField title="Importo pagato" source="gross" />
    </DataTable>
  );
}

export default withRouter(ReceiptsTable);
