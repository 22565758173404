import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  CurrencyField,
  DataTable,
  DateTimeField,
  Field,
  InvoicesByMonthField,
  ExportExtraInvoiceCSVField,
} from '../../commons/DataTable';
import ActionsField from './ActionsField';
import InvoiceNumberField from './InvoiceNumberField';
import StatusField from './StatusField';


function SailInvoicesByYearTable(props) {
  const {
    data = [],
    total = 0,
    query = {},
    onFilter = () => {},
    onCollectInvoice = () => {},
    setModalState,
  } = props;
  const [t] = useTranslation('COMMON');


  return (
    <DataTable
      history
      data={data}
      total={total}
      offset={query.offset}
      limit={query.limit}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
      onRowClick={({ _id }) => setModalState({ isOpen: true, selectedId: _id })}
    >
      <InvoiceNumberField className="txt-table-left" title="Numero fattura" />
      <DateTimeField
        title="Data Fattura"
        source="invoicePinv.date"
        format="DD/MM/YYYY"
      />
      <Field
        className="txt-table-left"
        title="Destinatario"
        source="invoicePinv.customer_legal_info.company_name"
      />
      <CurrencyField
        source="invoicePinv.total_price.subtotal"
        title="Importo netto"
      />
      <CurrencyField
        source="invoicePinv.total_price.total"
        title="Importo lordo"
      />
      <InvoicesByMonthField
        title="Anno"
        month={1}
        year={query.year}
        source="invoicePinv.year"
      />
      <StatusField title="Stato" />
      <DateTimeField
        title="Data Incasso"
        source="sailInvoice.collectedDate"
        format="DD/MM/YYYY"
      />
      <ActionsField title="Azioni" onCollectInvoice={onCollectInvoice} />
      <ExportExtraInvoiceCSVField title={t('COMMON:CSV_EXPORT_EXTRA_INVOICE_DETAILS')} />
      
    </DataTable>
  );
}

export default withRouter(SailInvoicesByYearTable);
