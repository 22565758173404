import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

const TotalTr = ({ data = [] } = {}) => {
  if (!Array.isArray(data)) return null;

  const totals = data.reduce(
    (acc, row = {}) => {
      const {
        totalGross = 0,
        total = 0,
        invoiceCount = 0,
        onePercentOfTotalGrossPaid = 0,
      } = row;
      return {
        gross: acc.gross + totalGross,
        net: acc.net + total,
        invoiceCount: acc.invoiceCount + invoiceCount,
        onePercentOfTotalGrossPaid:
          acc.onePercentOfTotalGrossPaid + onePercentOfTotalGrossPaid,
      };
    },
    {
      gross: 0,
      net: 0,
      invoiceCount: 0,
      onePercentOfTotalGrossPaid: 0,
    }
  );

  return (
    <tr className="summary-tr">
      <td>-</td>
      <td>-</td>
      <td>{parseInt(totals.invoiceCount)}</td>
      <td>{formatCurrency(totals.gross)}</td>
      <td>{formatCurrency(totals.onePercentOfTotalGrossPaid)}</td>
      <td>{formatCurrency(totals.net)}</td>
      <td>-</td>
    </tr>
  );
};

export default TotalTr;
