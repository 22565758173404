import React from 'react';
import { ReactComponent as CaretUp } from '../../images/icn_caret_up.svg';
import { ReactComponent as CaretDown } from '../../images/icn_caret_down.svg';

const getSortableStyle = (isSortable = false) => {
  if (isSortable) {
    return { cursor: 'pointer' };
  }
};

const getTitles = (components = []) => {
  if (!Array.isArray(components)) {
    return [];
  }

  const titles = components.map((component = {}) => {
    const { props = {} } = component;
    const { title = '', sortable = false, source = '' } = props;
    return { title, sortable, source };
  });

  return titles;
};

const DataTableHeader = (props) => {
  const {
    children = {},
    onSort = () => {},
    orderBy = '',
    order = 'asc',
  } = props;
  const titles = getTitles(children);

  const onClickSortable = (newOrderBy, isSortable) => {
    isSortable &&
      onSort({
        orderBy: newOrderBy,
        order: newOrderBy !== orderBy || order === 'asc' ? 'desc' : 'asc',
      });
  };
  return (
    <thead>
      <tr>
        {titles.map(({ title = '', sortable = false, source = '' }, key) => (
          <th
            style={getSortableStyle(sortable)}
            onClick={() => onClickSortable(source, sortable)}
            key={key}
          >
            {title}
            <br />
            {sortable && orderBy === source ? (
              order === 'asc' ? (
                <CaretUp />
              ) : (
                <CaretDown />
              )
            ) : (
              ''
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export { getTitles, DataTableHeader };
