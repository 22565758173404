import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabContent, TabPane, Form, Row, Col } from 'reactstrap';
import {
  BtnModalTable,
  ContAffiliation,
  ContFilterYear,
  ContPaginationPage,
  H5Styled,
} from '../../../styled-components';
import {
  DataTable,
  Field,
  DateTimeField,
  CurrencyField,
} from '../../../commons/DataTable';
import SearchInput from '../../../commons/SearchInput';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import Label from 'reactstrap/lib/Label';
import TotalTr from './TotalTr';
import generateYearOptions from '../../../lib/helpers/generateYearOptions';
import ExtraInvoicesStudio from './ExtraInvoicesStudio';
import ActionsField from './ActionsField';
import ModalInvoiceDetail from '../../../commons/ModalInvoiceDetail';
import CustomerTypeField from '../../../commons/DataTable/CustomerTypeField';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';

const RoyaltiesPresentational = ({
  data,
  query,
  total,
  onFilter,
  setFilterYear,
  affiliation,
  invoiceStudio,
  onStatusChanged,
  retrocession,
}) => {
  const [t] = useTranslation('ENERGY');
  const { offset, limit, year } = query;

  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedId: null,
  });

  const getTotals = (data) => {
    const netTotals = data.reduce((prev, cur) => prev + cur.net, 0);
    const retrocessionTotals = data.reduce(
      (prev, cur) => prev + cur.retrocession,
      0
    );

    return {
      netTotals,
      retrocessionTotals,
    };
  };

  const totals = getTotals(data);

  return (
    <>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12" md="6" lg="4">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        value={year}
                        onChange={(e) => setFilterYear(e.target.value)}
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="6" md="6" lg="4" xl="4">
              <SearchInput
                query={{ search: query && query.number }}
                onFilter={({ search }) => onFilter({ number: search })}
                label={t('SEARCH:INVOICE_NUMBER')}
                autoFocus={false}
              />
            </Col>
            <Col sm="6" md="6" lg="4" xl="4">
              <SearchInput
                query={{ search: query && query.customer }}
                onFilter={({ search }) => onFilter({ customer: search })}
                label={t('SEARCH:CONDOMINIUM')}
                autoFocus={false}
              />
            </Col>
          </Row>

          <ContAffiliation className="m-t-20">
            <DataTable
              data={data}
              total={total}
              offset={offset}
              limit={limit}
              onFilter={onFilter}
              emptyText={t('COMMON:NO_DATA')}
              bottomRow={<TotalTr totals={totals} />}
            >
              <Field title="Cliente" source="customer" className="text-left" />
              <CustomerTypeField
                title="Tipologia Cliente"
                source="customerType"
              />
              <Field title="Numero Fattura" source="number" />
              <Field title="Codice fiscale / partita IVA" source="vatCode" />
              <DateTimeField
                title="Data Fattura"
                source="date"
                format="DD/MM/YYYY"
              />
              <CurrencyField
                title="Imponibile"
                source="net"
                class="txt-table-right"
              />
              <CurrencyField
                title="Retrocessione 1%"
                source="retrocession"
                class="txt-table-right"
              />
              <ActionsField
                title="Fattura Extra"
                onViewInvoice={(extraInvoiceId) =>
                  setModalState({ isOpen: true, selectedId: extraInvoiceId })
                }
              ></ActionsField>
            </DataTable>

            <ContPaginationPage>
              <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

              <FormGroup>
                <CustomInput
                  type="select"
                  className="pagination-select"
                  id="perPage"
                  name="perPage"
                  value={limit}
                  onChange={({ target: { value: limit } }) => {
                    onFilter({ limit: limit });
                  }}
                >
                  {generatePaginationOptions()}
                </CustomInput>
              </FormGroup>
            </ContPaginationPage>

            <ModalInvoiceDetail
              modalState={modalState}
              setModalState={setModalState}
              onStatusChanged={onStatusChanged}
            />
          </ContAffiliation>

          <ContAffiliation className="m-t-20">
            <ExtraInvoicesStudio
              invoiceStudio={invoiceStudio}
              retrocession={retrocession}
              data={data}
              affiliation={affiliation}
            ></ExtraInvoicesStudio>
          </ContAffiliation>
        </TabPane>
      </TabContent>
    </>
  );
};

export default RoyaltiesPresentational;
