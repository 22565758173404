import React from 'react';

export default function generateYearOptions(allowUnsetting) {
  const options = [];
  if (allowUnsetting) {
    options.push(
      <option key={-1} value="">
        Tutti
      </option>
    );
  }
  const startYear = 2018;
  const currentYear = new Date().getFullYear();

  for (let i = currentYear + 1; i >= startYear; i--) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return options;
}
