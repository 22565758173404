import { useAlert } from 'react-alert';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
} from '../../styled-components';

const { REACT_APP_TAX_PERCENTAGE } = process.env;

const ModalSendEmail = (props) => {
  const [t] = useTranslation('CONTACTS');
  const {
    data: invoice,
    className = '',
    onConfirm = () => {},
    toggle = () => {},
    isOpen,
  } = props;

  const [errors, setErrors] = useState({});
  const [modal, setModal] = useState(false);
  const alert = useAlert();

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Invia Sollecito Per Questa Fattura
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onConfirm();
          }}
        >
          <ModalBody>
            <div className="max-width-form" style={{ textAlign: 'center' }}>
              Sei sicuro di voler inviare un sollecito per questa fattura?
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModalSendEmail;
