import React from 'react';
import { withRouter } from 'react-router-dom';
import { Table as ReactstrapTable } from 'reactstrap';
import TopRow from './TopRow';
import TableRow from './TableRow';
import TotalsRow from './TotalTr';
import { formatCurrency } from '../../lib/helpers/formatters';

function DashboardTable({ data = {}, year, history, totals }) {
  const {
    subscriptions = [],
    masters = [],
    masterShares = {},
    adminShare = {},
    insuranceAdminShare = {},
    gabettiPerc = {},
    studioVariableRevenues = {},
    insuranceMasterShares = {},
    insuranceGabettiPerc = {},
    contractsMasterShares = {},
    contractsRecurrencyMasterShares = {},
    contractsRecurrencyAdminShare = {},
    bottomRow = {},
  } = data;

  return (
    <ReactstrapTable responsive hover className="data-table">
      <thead>
        <tr>
          <th />
          <th>Imponibile totale</th>
          <th>Dovuto</th>
          <th>EC/Banca</th>
        </tr>
      </thead>
      <tbody>
        <TopRow totals={totals} />

        {subscriptions.map(
          ({ type, title = '', total = 0, cashed = 0 }, index) => (
            <tr
              key={index}
              onClick={() => history.push(`/dashboard/${type}?year=${year}`)}
            >
              <td className="title">{title}</td>
              <td className="txt-table-right">-</td>
              <td className="txt-table-right">{formatCurrency(total)}</td>
              <td className="txt-table-right">{formatCurrency(cashed)}</td>
            </tr>
          )
        )}

        <TableRow data={masters} />
        <tr
          onClick={({ perc }) =>
            history.push(`/dashboard/compensi-studio?year=${year}`)
          }
        >
          <td className="title">
            Compensi variabili da fatture studio (1000€ o 1%)
          </td>
          <td className="txt-table-right">-</td>
          <td className="txt-table-right">
            <strong>{formatCurrency(studioVariableRevenues.total || 0)}</strong>
          </td>
          <td className="txt-table-right">
            <strong>
              {formatCurrency(studioVariableRevenues.cashed || 0)}
            </strong>
          </td>
        </tr>

        <TableRow
          data={masters}
          onClick={({ perc }) =>
            history.push(`/dashboard/masters?percentage=${perc}&year=${year}`)
          }
        />
        <tr onClick={() => history.push(`/dashboard/compensi?year=${year}`)}>
          <td className="title">Compensi variabili da fatture (% Extra)</td>
          <td className="txt-table-right">
            {formatCurrency(gabettiPerc.net || 0)}
          </td>
          <td className="txt-table-right">
            <strong>{formatCurrency(gabettiPerc.total || 0)}</strong>
          </td>
          <td className="txt-table-right">
            <strong>{formatCurrency(gabettiPerc.cashed || 0)}</strong>
          </td>
        </tr>
        <tr
          onClick={() => history.push(`/dashboard/assicurazioni?year=${year}`)}
        >
          <td className="title">
            Compensi variabili da assicurazioni (% Extra)
          </td>
          <td className="txt-table-right">
            {formatCurrency(insuranceGabettiPerc.net || 0)}
          </td>
          <td className="txt-table-right">
            <strong>{formatCurrency(insuranceGabettiPerc.total || 0)}</strong>
          </td>
          <td className="txt-table-right">
            <strong>{formatCurrency(insuranceGabettiPerc.cashed || 0)}</strong>
          </td>
        </tr>

        <tr onClick={() => history.push(`/contracts-resume?year=${year}`)}>
          <td className="title">Compensi Energia Anyway Green</td>
          <td className="txt-table-right">
            {formatCurrency(contractsMasterShares.net || 0)}
          </td>
          <td className="txt-table-right">
            <strong>{formatCurrency(contractsMasterShares.total || 0)}</strong>
          </td>
          <td className="txt-table-right">
            <strong>{formatCurrency(contractsMasterShares.cashed || 0)}</strong>
          </td>
        </tr>

        <tr
          onClick={() =>
            history.push(`/contracts-recurrency-resume?year=${year}`)
          }
        >
          <td className="title">Compensi Ricorrenti Energia Anyway Green</td>
          <td className="txt-table-right">
            {formatCurrency(contractsRecurrencyMasterShares.net || 0)}
          </td>
          <td className="txt-table-right">
            <strong>
              {formatCurrency(contractsRecurrencyMasterShares.total || 0)}
            </strong>
          </td>
          <td className="txt-table-right">
            <strong>
              {formatCurrency(contractsRecurrencyMasterShares.cashed || 0)}
            </strong>
          </td>
        </tr>

        <TotalsRow totals={totals} />
      </tbody>
    </ReactstrapTable>
  );
}

export default withRouter(DashboardTable);
