import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {
  DataTable,
  Field,
  CurrencyField,
  DateTimeField,
  BooleanField,
  PaymentsDataField,
  PercentageField,
} from '../../commons/DataTable';
import InvoiceCheckbox from './InvoiceCheckbox';

function DashboardDetailTable(props) {
  const {
    data = [],
    total = 0,
    selectedInvoices = [],
    topRow,
    bottomRow,
    onSelectChange,
    onFilter,
    query = {},
    onSelectAll = () => {},
  } = props;
  const alert = useAlert();
  const [t] = useTranslation('COMMON');
  const { offset, limit } = query;
  const [selectedAll, setSelectedAll] = useState(false);
  const onRowClick = ({ _id, gabettiInvoice, paid, supplier }) => {
    if (!_id || gabettiInvoice) return;
    if (!paid) return alert.error(t('ROYALTY:NOT_PAID_ERROR'));

    const { _id: supplierId } = supplier;
    const [firstSelectedInvoiceId] = selectedInvoices;
    if (!firstSelectedInvoiceId) return onSelectChange(_id);

    const firstSelectedInvoice =
      data.find(({ _id }) => {
        return _id === firstSelectedInvoiceId;
      }) || {};
    const { supplier: firstSelectedSupplier } = firstSelectedInvoice;
    const { _id: firstSelectedSupplierId } = firstSelectedSupplier || {};
    if (supplierId === firstSelectedSupplierId) return onSelectChange(_id);

    alert.error(t('ROYALTY:DIFFERENT_SUPPLIER_ERROR'));
  };

  const paidInvoices = [...data];

  useEffect(() => {
    if (!selectedAll) {
      selectedInvoices.length === paidInvoices.length && onSelectAll([]);
      return;
    }
    if (!paidInvoices || !paidInvoices.length) {
      setSelectedAll(false);
      return;
    }

    const isSameSupplier = paidInvoices
      .map(({ supplier: { _id } = {} }) => _id)
      .every((val, i, arr) => val === arr[0]);

    if (!isSameSupplier) {
      alert.error(t('ROYALTY:DIFFERENT_SUPPLIER_ERROR'));
      setSelectedAll(false);
      return;
    }

    onSelectAll(paidInvoices);
  }, [selectedAll]);

  useEffect(() => {
    if (selectedInvoices.length === paidInvoices.length) return;
    setSelectedAll(false);
  }, [selectedInvoices]);

  return (
    <DataTable
      history
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      topRow={topRow}
      bottomRow={bottomRow}
      onRowClick={onRowClick}
      onFilter={onFilter}
    >
      <InvoiceCheckbox
        selected={selectedInvoices}
        onSelectChange={onRowClick}
        onClick={(e) => e.stopPropagation()}
        title={
          <input
            type="checkbox"
            checked={selectedAll}
            onChange={({ target: { checked } }) => setSelectedAll(checked)}
          />
        }
      />
      <Field
        title="Nome Responabile"
        source="admin.businessName"
        className="text-left"
      />
      <Field title="N. Fatture Considerate" source="invoiceCount" />
      <CurrencyField
        title="Totale Imponibile Fatture Studio"
        source="totalGross"
      />
      <CurrencyField
        title="1% Totale Imponibile Fatture Studio"
        source="onePercentOfTotalGrossPaid"
      />
      <CurrencyField title="% Extra (€)" source="total" />
      <BooleanField title="Quota Soglia" source="isThreshold" />
    </DataTable>
  );
}

export default withRouter(DashboardDetailTable);
