import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from 'reactstrap';
import {
  ContFlexRight,
  ButtonSave,
  ButtonDelete,
  H5Styled,
  BtnRadiusRed,
  InputLink,
  ContFilterCheckbox,
} from '../../../styled-components';
import ModalConfirm from '../../../commons/ModalConfirm';
import regions from '../../../lib/helpers/regions';
import FilesList from './FilesList';
import OpenIcon from '../../../images/icn-open.svg';
import { useAlert } from 'react-alert';
import { URL_REGEX } from '../../../lib/helpers/url';
import {
  ADMIN_AFFILIATION_NET,
  AdminTypes,
  ProfileTypes,
} from '../../../lib/constants';
import { useAuth } from '../../../commons/Auth';
import FormErrorMessage from '../../../commons/FormErrorMessage';
import { ADMIN_TYPES, AGENT_TYPES, CERTIFIER_TYPE } from '../../../App';
import DateInput from '../../../commons/DateInput';
import {
  FISCAL_CODE_REGEX,
  POSTAL_CODE_REGEX,
  VAT_CODE_REGEX,
} from '../../../lib/regex';
import { validateFiscalCode } from '../../../lib/fetch/profiles';
import { refactoringAdminLevels } from '../../../lib/helpers/refactoringAdminLevels';
import { refactoringAdminLevelsAddRegistry } from '../../../lib/helpers/refactoringAdminLevelsAddRegistry';
import { ReactComponent as IconOpen } from '../../../images/icn-open.svg';

const ProfileInfoPresentational = ({
  data = {},
  isUpdating = false,
  updateProfile,
  createProfile,
  deleteProfile,
  uploadFile,
  handleDelete,
  handleFileListView,
  activeAffiliation = [],
  managers = [],
}) => {
  const [t] = useTranslation('CONTACTS');
  const alert = useAlert();
  const [{ isAdmin = false, token }] = useAuth();
  const storedFiscalCode = data.fiscalCode;

  const [deadlineCertificateDM140, setDeadlineCertificateDM140] = useState(
    data.deadlineCertificateDM140
  );
  const [isAgent, setIsAgent] = useState(false);
  const [isCommercial, setIsCommercial] = useState(data.commercial);
  const [isMaster, setIsMaster] = useState(false);
  const [studioManager, setStudioManager] = useState(data.studioManager || '');
  const {
    register,
    errors,
    handleSubmit,
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: data,
  });

  const region = watch('region');
  const UserType = watch('type');

  useEffect(() => {
    setIsAgent(data.agent);
    setIsMaster(data.master);
    setIsCommercial(data.commercial);
  }, [data]);

  function onSubmit(values) {
    const formData = {
      ...values,
      agent: isAgent,
      master: isMaster,
      commercial: UserType === 'COMMERCIAL' ? true : isCommercial,
      credits: ADMIN_TYPES.includes(UserType) ? Number(values.credits) : 0,
      studioManager,
      shareContract: AGENT_TYPES.includes(UserType)
        ? Number(values.shareContract)
        : 0,
      // fileAffiliationContract: isUpdating ? data.fileAffiliationContract : [],
      // fileCommercialContract: isUpdating ? data.fileCommercialContract : [],
      // certificateDM140: isUpdating ? data.certificateDM140 : [],
      // attach49SelfDeclaration: isUpdating ? data.attach49SelfDeclaration : [],
      // files: isUpdating ? data.files : [],
      ...(deadlineCertificateDM140 && { deadlineCertificateDM140 }),
    };

    isUpdating ? updateProfile(formData) : createProfile(formData);
  }

  function getDistricts() {
    const currentRegion = isUpdating && !region ? data.region : region;
    const { capoluoghi = [] } =
      regions.find((r) => r.nome === currentRegion) || {};
    return capoluoghi;
  }

  function handleFileChange(event, field = '') {
    const file = event.target.files[0];

    const fileSize = (file.size / 1024 ** 2).toFixed(2);

    if (fileSize > 20) {
      alert.info('File troppo grande. Max 20Mb');
    } else {
      const data = new FormData();
      data.append('file', file);
      data.append('field', field);
      uploadFile(data);
    }
  }

  function openUrl(url) {
    if (url.match(URL_REGEX)) return window.open(url, '_blank');

    alert.info(t('INVALID_URL'));
  }

  return (
    <Form>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <Row>
            {AdminTypes.includes(UserType) && (
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('IS_COMMERCIAL')}</Label>
                  <div
                    onChange={({ target }) => {
                      setIsCommercial(target.value === '1');
                    }}
                  >
                    <CustomInput
                      type="radio"
                      id="commercialRadio1"
                      label="SI"
                      name="isCommercial"
                      value="1"
                      inline
                      checked={isCommercial}
                    />
                    <CustomInput
                      type="radio"
                      id="commercialRadio2"
                      label="NO"
                      name="isCommercial"
                      value="0"
                      inline
                      checked={!isCommercial}
                    />
                  </div>
                </FormGroup>
              </Col>
            )}
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('CUSTOMER_TYPE')}</Label>
                <CustomInput
                  type="select"
                  id="exampleCustomSelect"
                  name="type"
                  innerRef={register({ required: t('COMMON:REQUIRED') })}
                  defaultValue={data.type}
                  disabled={!!data.type}
                >
                  {ProfileTypes.filter(
                    (elem) =>
                      !!data.type ||
                      ![
                        'BUILDING_ADMIN_2',
                        'BUILDING_ADMIN_3',
                        'BUILDING_ADMIN_4',
                        'BUILDING_ADMIN_5',
                      ].includes(elem)
                  ).map((type) => (
                    <option value={type} key={type}>
                      {t(refactoringAdminLevelsAddRegistry(type))}
                    </option>
                  ))}
                </CustomInput>
                <FormErrorMessage errors={errors} name="type" />
              </FormGroup>
            </Col>

            {AdminTypes.includes(UserType) && (
              <Col sm="12" md="6" lg="3">
                <FormGroup>
                  <ContFilterCheckbox>
                    <CustomInput
                      type="checkbox"
                      id="isGoldCertified"
                      name="isGoldCertified"
                      label="Certificazione Gold"
                      inline
                      innerRef={register('isGoldCertified')}
                    />
                  </ContFilterCheckbox>
                </FormGroup>
                <FormGroup>
                  <ContFilterCheckbox>
                    <CustomInput
                      type="checkbox"
                      id="isPlatinumCertified"
                      name="isPlatinumCertified"
                      label="Certificazione Platino"
                      inline
                      innerRef={register('isPlatinumCertified')}
                    />
                  </ContFilterCheckbox>
                </FormGroup>
              </Col>
            )}
          </Row>
          <br />
          <Row>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <Label>{t('BUSINESS_NAME')}</Label>
                <Input
                  type="text"
                  name="businessName"
                  defaultValue={data.businessName}
                  innerRef={register({
                    required: t('COMMON:REQUIRED'),
                    maxLength: 500,
                  })}
                />
                <FormErrorMessage errors={errors} name="businessName" />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <Label>{t('NAME')}</Label>
                <Input
                  type="text"
                  name="name"
                  maxLength="50"
                  defaultValue={data.name}
                  innerRef={register({
                    required: t('COMMON:REQUIRED'),
                  })}
                />
                <FormErrorMessage errors={errors} name="name" />
              </FormGroup>
            </Col>
            {UserType !== 'CERTIFIER' && (
              <>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('ADDRESS')} *</Label>
                    <Input
                      type="text"
                      name="address"
                      maxLength="100"
                      innerRef={register({
                        required: t('COMMON:REQUIRED'),
                      })}
                      defaultValue={data.address}
                    />
                    <FormErrorMessage errors={errors} name="address" />
                  </FormGroup>
                </Col>

                <Col sm="12" md="6" lg="2">
                  <FormGroup>
                    <Label>{t('CITY')} *</Label>
                    <Input
                      type="text"
                      name="city"
                      innerRef={register({
                        required: t('COMMON:REQUIRED'),
                      })}
                      defaultValue={data.city}
                    />
                    <FormErrorMessage errors={errors} name="city" />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="2">
                  <FormGroup>
                    <Label>{t('REGION')} *</Label>
                    <CustomInput
                      id="regionSelect"
                      type="select"
                      name="region"
                      innerRef={register({
                        required: t('COMMON:REQUIRED'),
                      })}
                      defaultValue={data.region}
                      onChange={() => setValue('district', '')}
                    >
                      <option value="">-</option>
                      {regions.map((region, i) => (
                        <option key={i} value={region.nome}>
                          {region.nome}
                        </option>
                      ))}
                    </CustomInput>
                    <FormErrorMessage errors={errors} name="region" />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="2">
                  <FormGroup>
                    <Label>{t('PROVINCE')} *</Label>
                    <CustomInput
                      id="districtSelect"
                      type="select"
                      name="district"
                      defaultValue={data.district}
                      innerRef={register({
                        required: t('COMMON:REQUIRED'),
                      })}
                    >
                      <option value="">-</option>
                      {getDistricts().map((district, i) => (
                        <option key={i} value={district}>
                          {district}
                        </option>
                      ))}
                    </CustomInput>
                    <FormErrorMessage errors={errors} name="district" />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="2">
                  <FormGroup>
                    <Label>{t('CAP')} *</Label>
                    <Input
                      type="number"
                      name="cap"
                      innerRef={register({
                        required: t('COMMON:REQUIRED'),
                        pattern: {
                          value: POSTAL_CODE_REGEX,
                          message: t('COMMON:INVALID_POSTAL_CODE'),
                        },
                      })}
                      defaultValue={data.cap}
                    />
                    <FormErrorMessage errors={errors} name="cap" />
                  </FormGroup>
                </Col>
              </>
            )}
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('VAT')} *</Label>
                <Input
                  type="text"
                  name="taxCode"
                  maxLength="20"
                  innerRef={register({
                    required: t('COMMON:REQUIRED'),
                    pattern: {
                      value: VAT_CODE_REGEX,
                      message: t('COMMON:INVALID_TAX_CODE'),
                    },
                  })}
                  defaultValue={data.taxCode}
                />
                <FormErrorMessage errors={errors} name="taxCode" />
              </FormGroup>
            </Col>
            {UserType !== 'CERTIFIER' && (
              <>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('FISCAL_CODE')} *</Label>
                    <Input
                      type="text"
                      name="fiscalCode"
                      maxLength="20"
                      innerRef={register({
                        required: t('COMMON:REQUIRED'),
                        pattern: {
                          value: FISCAL_CODE_REGEX,
                          message: t('COMMON:INVALID_FISCAL_CODE'),
                        },
                        validate: {
                          checkFiscalCode: async (fiscalCode) => {
                            const {
                              data: { isDuplicated },
                            } = await validateFiscalCode(fiscalCode, token);

                            if (
                              isUpdating &&
                              storedFiscalCode.toLowerCase() ===
                                watch('fiscalCode').toLowerCase()
                            )
                              return true;

                            return !isDuplicated || t('FISCAL_CODE_ERROR');
                          },
                        },
                      })}
                      defaultValue={data.fiscalCode}
                    />
                    <FormErrorMessage errors={errors} name="fiscalCode" />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('CELL')}</Label>
                    <Input
                      type="text"
                      name="cell"
                      maxLength="20"
                      innerRef={register}
                      defaultValue={data.cell}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('PHONE')}</Label>
                    <Input
                      type="text"
                      name="phone"
                      maxLength="20"
                      innerRef={register}
                      defaultValue={data.phone}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('EMAIL')}</Label>
                    <Input
                      type="email"
                      name="emailReference"
                      maxLength="320"
                      innerRef={register({
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: t('EMAIL_INVALID'),
                        },
                      })}
                      defaultValue={data.emailReference}
                    />
                    <FormErrorMessage errors={errors} name="emailReference" />
                  </FormGroup>
                </Col>
              </>
            )}
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('EMAIL_COMUNICATION')} *</Label>
                <Input
                  type="email"
                  name="emailCommunication"
                  maxLength="320"
                  innerRef={register({
                    required: t('COMMON:REQUIRED'),
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t('EMAIL_INVALID'),
                    },
                  })}
                  defaultValue={data.emailCommunication}
                />
                <FormErrorMessage errors={errors} name="emailCommunication" />
              </FormGroup>
            </Col>
            {UserType !== 'CERTIFIER' && (
              <>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('EMAIL_PEC')}</Label>
                    <Input
                      type="email"
                      name="emailPec"
                      maxLength="320"
                      innerRef={register({
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: t('EMAIL_INVALID'),
                        },
                      })}
                      defaultValue={data.emailPec}
                    />
                    <FormErrorMessage errors={errors} name="emailPec" />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('SDI_CODE')} *</Label>
                    <Input
                      type="text"
                      name="sdiCode"
                      className="uppercase"
                      innerRef={register({
                        minLength: {
                          value: 7,
                          message: t('COMMON:INVALID_SDI_CODE'),
                        },
                        maxLength: {
                          value: 7,
                          message: t('COMMON:INVALID_SDI_CODE'),
                        },
                        required: t('COMMON:REQUIRED'),
                      })}
                      defaultValue={data.sdiCode}
                    />
                    <FormErrorMessage errors={errors} name="sdiCode" />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('ID_EXTRA')}</Label>
                    <Input
                      type="text"
                      name="gabettiId"
                      maxLength="50"
                      innerRef={register}
                      defaultValue={data.gabettiId}
                    />
                  </FormGroup>
                </Col>
              </>
            )}
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('STUDIO_MANAGER')}</Label>
                <CustomInput
                  id="studioManager"
                  type="select"
                  name="studioManager"
                  value={studioManager}
                  onChange={(e) => {
                    setStudioManager(e.target.value);
                  }}
                >
                  <option value="">-</option>
                  {managers.map((manager, i) => (
                    <option key={i} value={manager._id}>
                      {manager.name}
                    </option>
                  ))}
                </CustomInput>
                <FormErrorMessage errors={errors} name="studioManager" />
              </FormGroup>
            </Col>
          </Row>
          {UserType !== 'CERTIFIER' && (
            <Row>
              <Col sm="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('ID_AFFILIATE')}</Label>
                  <Input
                    type="text"
                    name="affiliationId"
                    maxLength="50"
                    innerRef={register}
                    defaultValue={data.affiliationId}
                  />
                </FormGroup>
              </Col>

              {UserType === 'SUPPLIER_INS' && (
                <>
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('EXTRA_SHARE_INSURANCE')}</Label>
                      <Input
                        type="number"
                        name="extraShareInsurance"
                        maxLength="50"
                        innerRef={register({
                          min: {
                            value: 0,
                            message: t('COMMON:MIN_SHARE_CONTRACT_PERCENTAGE'),
                          },
                          max: {
                            value: 100,
                            message: t('COMMON:MAX_SHARE_CONTRACT_PERCENTAGE'),
                          },
                        })}
                        defaultValue={data.extraShareInsurance || 0}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}

              {ADMIN_TYPES.includes(UserType) && (
                <>
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CREDITS')}</Label>
                      <Input
                        type="number"
                        name="credits"
                        maxLength="50"
                        innerRef={register}
                        defaultValue={data.credits}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              {AGENT_TYPES.includes(UserType) && (
                <>
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('SHARE_CONTRACT')}</Label>
                      <Input
                        type="number"
                        name="shareContract"
                        innerRef={register({
                          min: {
                            value: 0,
                            message: t('COMMON:MIN_SHARE_CONTRACT_PERCENTAGE'),
                          },
                          max: {
                            value: 100,
                            message: t('COMMON:MAX_SHARE_CONTRACT_PERCENTAGE'),
                          },
                        })}
                        defaultValue={data.shareContract}
                      />
                      <FormErrorMessage errors={errors} name="shareContract" />
                    </FormGroup>
                  </Col>

                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('ADMIN_SHARE_INSURANCE')}</Label>
                      <Input
                        type="number"
                        name="adminShareInsurance"
                        maxLength="50"
                        innerRef={register({
                          min: {
                            value: 0,
                            message: t('COMMON:MIN_SHARE_CONTRACT_PERCENTAGE'),
                          },
                          max: {
                            value: 100,
                            message: t('COMMON:MAX_SHARE_CONTRACT_PERCENTAGE'),
                          },
                        })}
                        defaultValue={data.adminShareInsurance || 0}
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
            </Row>
          )}
          {isAdmin && UserType !== 'CERTIFIER' && (
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label>{t('OPERATIVE_NOTES')}</Label>
                  <Input
                    rows={6}
                    type="textarea"
                    name="notes"
                    innerRef={register({
                      maxLength: {
                        value: 2000,
                        message: t('OPERATIVE_NOTES_MAX_ERROR'),
                      },
                    })}
                    defaultValue={data.notes}
                  />
                  <FormErrorMessage errors={errors} name="notes" />
                </FormGroup>
              </Col>
            </Row>
          )}
          {isAdmin && isUpdating && UserType !== 'CERTIFIER' && (
            <>
              {/* <Row>
                <Col sm="12" md="6" lg="6">
                  <FormGroup>
                    <Label for="selectFile">
                      {t('CHOOSE_FILE_COMMERCIAL_CONTRACT')}
                      &nbsp;({t('MAX_UPLOAD_FILE_SIZE')})
                    </Label>
                    <CustomInput
                      type="file"
                      id="fileCommercialContract"
                      name="fileCommercialContract"
                      onChange={(event) =>
                        handleFileChange(event, 'fileCommercialContract')
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="6">
                  <FilesList
                    files={data.fileCommercialContract}
                    handleDelete={(key) =>
                      handleDelete({ key, field: 'fileCommercialContract' })
                    }
                    handleFileListView={handleFileListView}
                  />
                </Col>
              </Row> */}
              {/* <Row>
                <Col sm="12" md="6" lg="6">
                  <FormGroup>
                    <Label for="selectFile">
                      {t('CHOOSE_FILE_AFFILIATION_CONTRACT')}
                      &nbsp;({t('MAX_UPLOAD_FILE_SIZE')})
                    </Label>
                    <CustomInput
                      type="file"
                      id="fileAffiliationContract"
                      name="fileAffiliationContract"
                      onChange={(event) =>
                        handleFileChange(event, 'fileAffiliationContract')
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="6">
                  <FilesList
                    files={data.fileAffiliationContract}
                    handleDelete={(key) =>
                      handleDelete({ key, field: 'fileAffiliationContract' })
                    }
                    handleFileListView={handleFileListView}
                  />
                </Col>
              </Row> */}

              {/* <Row>
                <Col sm="12" md="6" lg="6">
                  <FormGroup>
                    <Label for="selectFile">
                      {t('ATTACHED_49_SELF_DECLARATION')}
                      &nbsp;({t('MAX_UPLOAD_FILE_SIZE')})
                    </Label>
                    <CustomInput
                      type="file"
                      id="attach49SelfDeclaration"
                      name="attach49SelfDeclaration"
                      onChange={(event) =>
                        handleFileChange(event, 'attach49SelfDeclaration')
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="6">
                  <FilesList
                    files={data.attach49SelfDeclaration}
                    handleDelete={(key) =>
                      handleDelete({ key, field: 'attach49SelfDeclaration' })
                    }
                    handleFileListView={handleFileListView}
                  />
                </Col>
              </Row> */}

              {/* <Row>
                <Col sm="12" md="5" lg="5">
                  <FormGroup>
                    <Label for="selectFile">
                      {t('CERTIFICATE_DM_140')}
                      &nbsp;({t('MAX_UPLOAD_FILE_SIZE')})
                    </Label>
                    <CustomInput
                      type="file"
                      id="certificateDM140"
                      name="certificateDM140"
                      onChange={(event) =>
                        handleFileChange(event, 'certificateDM140')
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="5" lg="5">
                  <FilesList
                    files={data.certificateDM140}
                    handleDelete={(key) =>
                      handleDelete({ key, field: 'certificateDM140' })
                    }
                    handleFileListView={handleFileListView}
                  />
                </Col>
                <Col sm="12" md="2" lg="2">
                  <FormGroup>
                    <Label>{t('DEADLINE_CERTIFICATE_DM_140')}</Label>
                    <DateInput
                      id="deadlineCertificateDM140"
                      name="deadlineCertificateDM140"
                      value={deadlineCertificateDM140}
                      onChange={(date) => {
                        setDeadlineCertificateDM140(date);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row> */}
            </>
          )}
          {UserType !== 'CERTIFIER' && (
            <Row className="link-cont">
              <Col sm="12" md="12" lg="6">
                <H5Styled>{t('AREA_INTERVENTION')}</H5Styled>
                <InputLink>
                  <FormGroup>
                    <Label>{t('LINK')}</Label>
                    <Input
                      type="url"
                      name="interventionArea"
                      innerRef={register}
                      defaultValue={data.interventionArea}
                    />
                  </FormGroup>
                  <BtnRadiusRed
                    type="button"
                    title="Apri"
                    onClick={() => openUrl(data.interventionArea)}
                  >
                    <IconOpen />
                  </BtnRadiusRed>
                </InputLink>
              </Col>
              <Col sm="12" md="12" lg="6">
                <H5Styled>{t('TYPE_INTERVENTION')}</H5Styled>
                <InputLink>
                  <FormGroup>
                    <Label>{t('LINK')}</Label>
                    <Input
                      type="url"
                      name="interventionType"
                      innerRef={register}
                      defaultValue={data.interventionType}
                    />
                  </FormGroup>
                  <BtnRadiusRed
                    type="button"
                    title="Apri"
                    onClick={() => openUrl(data.interventionType)}
                  >
                    <IconOpen />
                  </BtnRadiusRed>
                </InputLink>
              </Col>
            </Row>
          )}

          {/* {isUpdating && UserType !== 'CERTIFIER' && (
            <Row>
              <Col sm="12" md="6" lg="6">
                <FormGroup>
                  <Label for="selectFile">
                    {t('CHOOSE_FILE')}
                    &nbsp;({t('MAX_UPLOAD_FILE_SIZE')})
                  </Label>
                  <CustomInput
                    type="file"
                    id="selectFile"
                    name="customFile"
                    onChange={(event) => handleFileChange(event, 'files')}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="6">
                <FilesList
                  files={data.files}
                  handleDelete={(key) => handleDelete({ key, field: 'files' })}
                  handleFileListView={handleFileListView}
                />
              </Col>
            </Row>
          )} */}
        </TabPane>
      </TabContent>

      <br />

      <ContFlexRight>
        {isUpdating ? (
          <React.Fragment>
            <ModalConfirm onConfirm={deleteProfile} text={t('DELETE_REQUEST')}>
              <ButtonDelete type="button">{t('BTN_DELETE')}</ButtonDelete>
            </ModalConfirm>
            <ButtonSave
              type="button"
              onClick={(e) => {
                if (Object.keys(errors).length > 0) window.scrollTo({ top: 0 });
                handleSubmit(onSubmit)(e);
              }}
            >
              {t('BTN_SAVE')}
            </ButtonSave>
          </React.Fragment>
        ) : (
          <ButtonSave
            type="button"
            onClick={(e) => {
              if (Object.keys(errors).length > 0) window.scrollTo({ top: 0 });
              handleSubmit(onSubmit)(e);
            }}
          >
            {t('BTN_CREATE')}
          </ButtonSave>
        )}
      </ContFlexRight>
    </Form>
  );
};

export default ProfileInfoPresentational;
