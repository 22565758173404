import React from 'react';
import { Badge } from 'reactstrap';
import './Badge.scss';

export default (props) => {
  const className = props.children === 0 ? 'to-do-green' : '';

  return (
    <Badge {...props} className={`to-do ${className}`}>
      {props.children}
    </Badge>
  );
};
