export const getValidationErrorMessage = (error) => {
  const { message = {} } = error;
  const { message: messageText = '', path = ['', 0], context = {}, type } = message;
  let result = ''
  if(type === 'any.only'){
    result = insert(messageText?.split('#label'), 1, context.value).join('');
  }
  else result = insert(messageText?.split('#label'), 1, path[1]).join('');

  if (result.includes('#index')) {
    result = insert(result.split('#index'), 1, path[0] + 2).join('');
  }
  return result;
};

const insert = (arr, index, ...newItems) => [
  ...arr.slice(0, index),
  ...newItems,
  ...arr.slice(index),
];
