import React from 'react';

function CustomerTypeField({ value, maxLength }) {
  if (!value) return <>-</>;

  if (maxLength && typeof value === 'string' && value.length > maxLength) {
    return <>{value.substr(0, maxLength)}...</>;
  }

  return (
    <>
      {value === 'condos'
        ? 'CONDOMINIO'
        : value === undefined
        ? 'CONDOMINIO'
        : 'CLIENTE'}
    </>
  );
}

export default CustomerTypeField;
