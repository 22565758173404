import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnRadiusRed,
  PNewItem,
} from '../../styled-components';
import './Insurances.scss';
import { ReactComponent as IconOpen } from '../../images/icn-open.svg';

const CSV_HEADER = [
  'Contraente',
  'CF Cliente',
  'Fornitore',
  'Responsabile',
  'NumAssicurazione',
  'Tipo',
  'Data inizio',
  'Data fine',
  'Mese rendicontazione',
  'Anno rendicontazione',
  'Importo netto',
  'Importo da incassare',
  'Incassato',
  'Data Incasso',
  'Numero di sinistri',
  'Importo sinistri',
  'Tipo Responsabile',
];

const CSV_ROW = [
  'CONDOMINIO ABC',
  '1234567890',
  '1234567890',
  '9876543210',
  '2021/12/1234567',
  'Globale Fabbricati',
  '01/01/2021',
  '01/01/2022',
  'ottobre',
  '2021',
  '100.00',
  '122.00',
  'sì',
  '01/01/2021',
  '2',
  '150.00',
  'Amministratore',
];

const ModalImportInsurances = () => {
  const [t] = useTranslation('ROYALTY');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const listenForEscapeEvent = () => {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  };

  return (
    <React.Fragment>
      <BtnRadiusRed onClick={toggle} type="button">
        <IconOpen />
      </BtnRadiusRed>
      <PNewItem
        onClick={toggle}
        style={{ marginLeft: '7px', marginRight: '0', cursor: 'pointer' }}
      >
        {t('INSURANCES:IMPORT_INSURANCES_HELP')}
      </PNewItem>
      <Modal
        isOpen={modal}
        className="new-insurance-modal"
        onOpened={listenForEscapeEvent}
      >
        <ModalHeader toggle={toggle}>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: t('INSURANCES:IMPORT_INSURANCES_HELP_CONTENT_1'),
            }}
          />
          <br />
          <br />
          {t('INSURANCES:IMPORT_INSURANCES_HELP_CONTENT_2')}
          <br />
          <br />
          <h5 style={{ color: 'red' }}>
            <i>
              <u>{t('INSURANCES:IMPORT_INSURANCES_HELP_CONTENT_3')}</u>
            </i>
          </h5>
          <br />
          {t('INSURANCES:IMPORT_INSURANCES_HELP_CONTENT_4')}
        </ModalHeader>
        <ModalBody></ModalBody>
        <ModalFooter>
          <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
            {t('COMMON:CLOSE')}
          </BtnOutlineRed>
          <CSVLink
            headers={CSV_HEADER}
            data={[CSV_ROW]}
            filename={'Template_Insurance.csv'}
            separator={';'}
          >
            <BtnOutlineGreen
              type="button"
              className="uppercase"
              onClick={toggle}
            >
              {t('INSURANCES:IMPORT_INSURANCES_DOWNLOAD_EXAMPLE_CSV')}
            </BtnOutlineGreen>
          </CSVLink>
          <BtnOutlineGreen type="button" className="uppercase">
            <a
              href="/examples/Template_Insurance.xlsx"
              style={{ color: 'inherit' }}
            >
              {t('INSURANCES:IMPORT_INSURANCES_DOWNLOAD_EXAMPLE_XLSX')}
            </a>
          </BtnOutlineGreen>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ModalImportInsurances;
