import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  CurrencyField,
  Field,
  DateTimeField,
} from '../../commons/DataTable';
import { useAuth } from '../../commons/Auth';
import TotalTrainingTr from './TotalTrainingTr';

function TrainingCalculationTable({
  dataTable,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
  bottomRow,
  showEmptyTableMessage,
  truncateTable,
  onSelectChange,
  allowedRoles = [],
  subAdmins = false,
}) {
  const [t] = useTranslation('COMMON');
  const [{ isAdmin }] = useAuth();
  const { offset, limit } = query;

  let totalCalculatedData = 0;
  for (let index = 0; index < data?.trainings?.length; index++) {
    totalCalculatedData += Number(data?.trainings[index]?.credits);
  }
  if (totalCalculatedData > 100) totalCalculatedData = 100;

  return (
    <DataTable
      offset={offset}
      limit={limit}
      data={data?.trainings}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      bottomRow={
        <TotalTrainingTr
          totals={{
            creditsTotal: data?.trainingScoreTotal
              ? data?.trainingScoreTotal
              : totalCalculatedData,
          }}
        />
      }
    >
      <Field
        title="Nome Corso"
        source="nominative"
        className="txt-table-center"
      />
      <DateTimeField
        title="Data Svolgimento"
        source="date"
        className="txt-table-center"
        format="DD/MM/YYYY"
      />
      <Field title="Crediti" source="credits" className="txt-table-center" />
    </DataTable>
  );
}

export default withRouter(TrainingCalculationTable);
