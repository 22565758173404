import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  FormText,
} from 'reactstrap';
import { BtnOutlineGreen, BtnOutlineRed } from '../../styled-components';
import DateInput from '../../commons/DateInput';
import CustomInput from 'reactstrap/lib/CustomInput';
import generateYearOptions from '../../lib/helpers/generateYearOptions';

const ReportingModal = (props) => {
  const { toggle, isOpen, onSubmit, amount = 0, query = {} } = props;
  const { year = '' } = query;
  const [t] = useTranslation('REPORT');
  const [date, setDate] = useState(new Date());
  const [accountability, setAccountability] = useState(year);
  const [formErrors, setFormErrors] = useState({
    date: false,
    accountability: false,
  });

  const onConfirm = () => {
    const errors = {};

    if (!date) errors.date = true;
    if (!accountability) errors.accountability = true;

    if (Object.values(errors).some(Boolean)) return setFormErrors(errors);

    onSubmit({
      date,
      accountability,
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t('ROYALTY:INVITE_TO_INVOICE')}
      </ModalHeader>
      <Form onSubmit={onConfirm}>
        <ModalBody>
          <FormGroup>
            <Label>{t('ROYALTY:IAF_NUMBER')}</Label>
            <Input
              type="text"
              name="number"
              value={'Campo Autogenerato'}
              disabled={true}
            />
          </FormGroup>
          <FormGroup>
            <Label>{t('ROYALTY:IAF_ACCOUNTABILITY')}</Label>
            <CustomInput
              type="select"
              id="selectAccountability"
              name="accountability"
              value={accountability}
              onChange={({ target: { value } }) => setAccountability(value)}
            >
              {generateYearOptions()}
            </CustomInput>
            {formErrors.accountability && (
              <FormText className="error-message">
                {t('COMMON:REQUIRED')}
              </FormText>
            )}
          </FormGroup>
          <FormGroup>
            <Label>{t('ROYALTY:IAF_DATE')}</Label>
            <DateInput name="date" value={date} readOnly={true} />
            {formErrors.date && (
              <FormText className="error-message">
                {t('COMMON:REQUIRED')}
              </FormText>
            )}
          </FormGroup>
          <FormGroup>
            <Label>{t('ROYALTY:AMOUNT')}</Label>
            <Input
              type="text"
              name="amount"
              value={`${amount} €`}
              disabled={true}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
            {t('COMMON:CANCEL')}
          </BtnOutlineRed>
          <BtnOutlineGreen type="submit" className="uppercase">
            {t('COMMON:CONFIRM')}
          </BtnOutlineGreen>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ReportingModal;
