import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import {
  ContAffiliation,
  H5Styled,
  ContFilterYear,
  BtnOutlineOrange,
  BtnOutlineGreen,
  ContPaginationPage,
} from '../../../styled-components';
import SearchInput from '../../../commons/SearchInput';
import generateMonthOptions from '../../../lib/helpers/generateMonthOptions';
import CSVEnergyRevenuesExport from '../FinancialReports/EnergyDetails/CSVEnergyRevenuesExport';
import RecurrentEnergyRoyaltyTable from '../FinancialReports/RecurrentEnergyDetails/RecurrentEnergyRoyaltyTable';
import TotalTrRecurrentEnergyRevenue from '../FinancialReports/RecurrentEnergyDetails/TotalTrRecurrentEnergyRevenue';
import generateYearOptions from '../../../lib/helpers/generateYearOptions';
import { useAuth } from '../../../commons/Auth';
import { isCheckable } from '../../../lib/helpers/isCheckboxCheckable';
import variables from '../../../variables';
import ReportingModal from '../../../commons/ReportingModal';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';

const VarRevRecurrentEnergyDetailPaginatedPresentational = ({
  data = {},
  profile,
  total,
  query,
  onFilter,
  onAddBonus,
}) => {
  const [t] = useTranslation('CONTACTS');
  const [{ isAdmin }] = useAuth();
  const { categories = {} } = data;
  const { variableRevenuesRecurrentEnergy = {} } = categories;
  const [amount, setAmount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const alert = useAlert();
  const { data: dataRevRecurrentEnergy = [] } = variableRevenuesRecurrentEnergy;

  const origin = window.location.origin;
  let hash = window.location.hash.split('/');
  hash.pop();
  const prevPath =
    origin +
    '/' +
    hash.join('/') +
    `${query.year ? '?year=' + query.year : ''}`;

  const getVariableRevenuesTotals = (dataRev) => {
    const cashedTotals = dataRev.reduce((prev, cur) => {
      const { payments } = cur || {};
      return (
        prev +
        (payments || []).reduce((acc, payment) => {
          return acc + ((payment && payment.gross) || 0);
        }, 0)
      );
    }, 0);
    const netTotals = dataRev.reduce((prev, cur) => prev + cur.net, 0);
    const grossTotals = dataRev.reduce((prev, cur) => prev + cur.gross, 0);
    const adminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur.adminShare,
      0
    );
    const earnedAdminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur.earnedAdminShare,
      0
    );
    const billableAdminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur.billableAdminShare,
      0
    );
    return {
      cashedTotals,
      netTotals,
      grossTotals,
      adminShareTotals,
      earnedAdminShareTotals,
      billableAdminShareTotals,
    };
  };
  const toggle = ({ selectAll: toggleSelectAll = false } = {}) => {
    if (isOpen) {
      setSelectAll(false);
      return setIsOpen(false);
    }

    setSelectAll(toggleSelectAll);

    const validRows = dataRevRecurrentEnergy.map((row, index) => {
      if (row.checked || toggleSelectAll) {
        return {
          ...row,
          checked:
            row.billableAdminShare -
              row?.iafs?.reduce((acc, iaf) => acc + iaf.amount, 0) >
            0,
        };
      }
      return row;
    });

    const checkedRows = validRows.filter(({ checked }) => checked);

    if (checkedRows.length) {
      const billableTotal = checkedRows.reduce(
        (acc, { billableAdminShare = 0 }) => acc + billableAdminShare,
        0
      );
      const alreadyBilled = checkedRows.reduce((acc, { iafs = [] }) => {
        return (
          acc +
            iafs.reduce((acc, { amount = 0 }) => {
              return acc + amount || 0;
            }, 0) || 0
        );
      }, 0);
      setAmount(+(billableTotal - alreadyBilled).toFixed(2));
      setIsOpen(!isOpen);
    } else {
      const message = toggleSelectAll
        ? t('ROYALTY:NO_ROW_IAF_SELECTABLE')
        : t('ROYALTY:SELECT_AT_LEAST_ONE');
      alert.error(message);
    }
  };

  const onSubmit = (formData) => {
    const validRows = dataRevRecurrentEnergy.map((row, index) => {
      if (row.checked || selectAll) {
        return {
          ...row,
          checked:
            row.billableAdminShare -
              row?.iafs?.reduce((acc, iaf) => acc + iaf.amount, 0) >
            0,
        };
      }
      return row;
    });

    const checkedRows = validRows.filter(({ checked }) => checked);

    const data = {
      ...formData,
      iafType: 'RECURRENT_ENERGY',
      recurrentContracts: checkedRows.map(
        ({
          _id,
          admin,
          condo,
          earnedAdminShare,
          adminShare,
          net,
          date,
          number,
        }) => ({
          _id,
          admin,
          condo,
          earnedAdminShare,
          adminShare,
          net,
          date,
          number,
        })
      ),
      amount,
    };
    onAddBonus(data);
    toggle();
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <ContFilterYear>
            <Row>
              <Col xs="6">
                <Label>{t('TITLE_FILTER_YEAR')}</Label>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <CustomInput
                    type="select"
                    id="selectYear"
                    name="year"
                    value={query && query.year}
                    onChange={({ target: { value: year } }) =>
                      onFilter({ year })
                    }
                  >
                    {generateYearOptions()}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </ContFilterYear>
          <div
            style={{
              fontWeight: 700,
              color: '#333333',
              marginBottom: '20px',
              fontSize: '20px',
            }}
          >
            <a
              style={{
                color: variables.primary,
              }}
              href={prevPath}
            >
              Rendicontazioni
            </a>{' '}
            / <a>{t('ENERGY_RECURRENT:ROYALTIES_TITLE')}</a>
          </div>
          <ContAffiliation key={2}>
            <Row>
              <Col xs="6">
                <H5Styled>{t('ENERGY_RECURRENT:ROYALTIES_TITLE')}</H5Styled>
              </Col>
              <Col xs="6" className="text-right">
                {isAdmin && (
                  <BtnOutlineGreen
                    type="button"
                    onClick={toggle}
                    style={{ marginRight: '16px' }}
                  >
                    {t('ROYALTY:INVITE_TO_INVOICE')}
                  </BtnOutlineGreen>
                )}
                {isAdmin && (
                  <BtnOutlineGreen
                    type="button"
                    onClick={() => toggle({ selectAll: true })}
                    style={{ marginRight: '16px' }}
                  >
                    {t('ROYALTY:INVITE_TO_INVOICE_ALL')}
                  </BtnOutlineGreen>
                )}
                {/*<CSVEnergyRevenuesExport*/}
                {/*  data={dataRevEnergy}*/}
                {/*  year={query && query.year}*/}
                {/*  businessName={profile ? profile.businessName : ''}*/}
                {/*>*/}
                {/*  <BtnOutlineOrange type="button" className="uppercase">*/}
                {/*    {t('COMMON:CSV_EXPORT')}*/}
                {/*  </BtnOutlineOrange>*/}
                {/*</CSVEnergyRevenuesExport>*/}
              </Col>
            </Row>
            <br />
            <Row className="compensi-variabili-resp">
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.energyRecurrentPod }}
                  onFilter={({ search }) =>
                    onFilter({ energyRecurrentPod: search })
                  }
                  label={t('SEARCH:POD_PDR')}
                />
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <SearchInput
                  query={{ search: query && query.energyRecurrentCondoVat }}
                  onFilter={({ search }) =>
                    onFilter({ energyRecurrentCondoVat: search })
                  }
                  label={t('SEARCH:VAT_CODE')}
                  autoFocus={false}
                />
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectRecurrentEnergyAccountabilityYear"
                          name="energyRecurrentAccountabilityYear"
                          value={
                            query && query.energyRecurrentAccountabilityYear
                          }
                          onChange={({
                            target: {
                              value: energyRecurrentAccountabilityYear,
                            },
                          }) => {
                            onFilter({ energyRecurrentAccountabilityYear });
                          }}
                        >
                          {generateYearOptions(true)}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
              <Col sm="6" md="6" lg="6" xl="4">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="energyRecurrentAccountabilityMonth"
                          name="energyRecurrentAccountabilityMonth"
                          disabled={!query.energyRecurrentAccountabilityYear}
                          value={
                            query &&
                            query.energyRecurrentAccountabilityMonth === null
                              ? ''
                              : query.energyRecurrentAccountabilityMonth
                          }
                          onChange={({
                            target: {
                              value: energyRecurrentAccountabilityMonth,
                            },
                          }) =>
                            onFilter({ energyRecurrentAccountabilityMonth })
                          }
                        >
                          {generateMonthOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
            </Row>
            <br />
            <RecurrentEnergyRoyaltyTable
              data={dataRevRecurrentEnergy || []}
              total={total}
              query={query}
              onFilter={onFilter}
              showEmptyTableMessage={true}
              bottomRow={
                <TotalTrRecurrentEnergyRevenue
                  totals={getVariableRevenuesTotals(dataRevRecurrentEnergy)}
                  route="variable-revenues-recurrent-energy"
                  activeRoute={false}
                />
              }
            />
            <ContPaginationPage>
              <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

              <FormGroup>
                <CustomInput
                  type="select"
                  className="pagination-select"
                  id="perPage"
                  name="perPage"
                  value={query.limit}
                  onChange={({ target: { value: limit } }) => {
                    onFilter({ limit });
                  }}
                >
                  {generatePaginationOptions()}
                </CustomInput>
              </FormGroup>
            </ContPaginationPage>
          </ContAffiliation>
        </TabPane>
      </TabContent>

      <ReportingModal
        toggle={toggle}
        isOpen={isOpen}
        onSubmit={onSubmit}
        amount={amount}
        query={query}
      />
    </Form>
  );
};

export default VarRevRecurrentEnergyDetailPaginatedPresentational;
