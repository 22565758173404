import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';
import { list, typesCount } from '../../lib/fetch/profiles';
import MyCollaboratorsPresentational from './MyCollaboratorsPresentational';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import axios from 'axios';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import variables from '../../variables';
import { ReactComponent as IconRegistries } from '../../images/icn_anagrafiche.svg';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import MyCollaboratorsDetail from '../MyCollaboratorsDetail';
import { createPassword } from '../../lib/helpers/password';
import { useLocation } from 'react-router-dom';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function MyCollaborators(props) {
  const { profile, match = {} } = props;
  const [{ token } = {}] = useAuth();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [t] = useTranslation('CONTACTS');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const [query, setQuery] = useQueryParams({
    profileStatus: withDefault(
      StringParam,
      localStorage.getItem('profileStatus')
    ),
    search: withDefault(StringParam, ''),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
  });

  const { search, offset, limit, profileStatus } = query;

  const fetchCollaborators = async () => {
    showLoading();

    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/collaborators/${profile._id}`,
      headers: {
        Authorization: token,
      },
      params: {
        search,
        offset,
        limit,
        profileStatus,
      },
    });

    if (response instanceof Error) {
      hideLoading();
      setData([]);
      setTotal(0);
      alert.error(t('COMMON:GENERIC_ERROR'));
      return { error: response };
    }

    setData(response.data);
    setTotal(Number(response.headers['x-total-count']));
    hideLoading();
  };

  const location = useLocation();

  useEffect(() => {
    fetchCollaborators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, offset, limit, profileStatus]);

  useEffect(() => {
    fetchCollaborators();
  }, [location]);

  function onFilter(query) {
    if (query && typeof query.search === 'string') {
      localStorage.setItem('profileSearch', query.search);
    }
    if (query && typeof query.profileStatus === 'string') {
      localStorage.setItem('profileStatus', query.profileStatus);
    }
    if (
      (query.search && query.search !== search) || // first search
      (search && query.search === '') || // search deleted
      (query.profileStatus && query.profileStatus !== profileStatus) ||
      (query.limit && query.limit !== limit)
    ) {
      query.offset = 0; // return to first page
    }
    setQuery(query);
  }

  const createCollaborator = async (data) => {
    showLoading();

    const password = createPassword();
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/collaborators`,
      {
        ...data,
        password,
      },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COLLABORATORS:ALLERT_NEW_COLLABORATOR'));
    fetchCollaborators();
  };

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:COLLABORATORS')}
            icon={<IconRegistries fill={variables.white} />}
          />
          <Switch>
            <Route
              exact
              path={match.url}
              component={(props) => (
                <MyCollaboratorsPresentational
                  data={data}
                  total={total}
                  query={query}
                  onFilter={onFilter}
                  createCollaborator={createCollaborator}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.url}/:id`}
              component={(props) => (
                <MyCollaboratorsDetail
                  data={data}
                  total={total}
                  query={query}
                  onFilter={onFilter}
                  createCollaborator={createCollaborator}
                  {...props}
                />
              )}
            />
          </Switch>
        </section>
      </div>
    </div>
  );
}

export default withRouter(MyCollaborators);
