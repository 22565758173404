import React from 'react';
import { CSVLink } from 'react-csv';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';

export default ({
  data: { affiliations = [], totals = {} },
  clientType = '',
  year,
  children,
}) => {
  const csvData = [
    [
      'RAGIONE SOCIALE / NOME',
      'PERIODO DI AFFILIAZIONE',
      'DOVUTO',
    ],
  ];

  affiliations.forEach((affiliation = {}) => {
    const {
      businessName,
      affiliationPeriod,
      contractAmount,
    } = affiliation;

    // Dati principali
    csvData.push([
      businessName,
      affiliationPeriod,
      formatCurrency(contractAmount),
    ]);
  });

  // Totali

  csvData.push([
    '',
    '',
    formatCurrency(totals.contractAmount),
  ]);

  return (
    <CSVLink
      data={csvData}
      filename={`ISCRIZIONI_${clientType.toUpperCase()}_${year}.csv`}
      separator={';'}
    >
      {children}
    </CSVLink>
  );
};
