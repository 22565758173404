import React from 'react';
import { useAlert } from 'react-alert';
import BurgerMenu from '../../../commons/BurgerMenu';
import { Col, CustomInput, Form, FormGroup, Label, Row } from 'reactstrap';
import Header from '../../../commons/Header';
import {
  ContFilterYear,
  ContFlexBetween,
  ContFlexRight,
  ContPaginationPage,
} from '../../../styled-components';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import Footer from '../../../commons/Footer';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconProfile } from '../../../images/icn_profilo.svg';
import { ProfileStatus, ProfileTypes } from '../../../lib/constants';
import SearchInput from '../../../commons/SearchInput';
import { refactoringAdminLevels } from '../../../lib/helpers/refactoringAdminLevels';
import CollaboratorsAdminTable from './CollaboratorsAdminTable';

const CollaboratorsAdminPresentational = ({ data, total, query, onFilter }) => {
  const [t] = useTranslation('CONTACTS');
  const { offset, limit } = query;
  const alert = useAlert();

  return (
    <div style={{ marginTop: '1.5rem' }}>
      <Form>
        <section>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
              <Form>
                <Row>
                  <Col xs="5">
                    <ContFilterYear>
                      <Row>
                        <Col xs="6">
                          <Label>{t('CONTACTS:ACTIVE_USER_FILTER_TYPE')}</Label>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <CustomInput
                              type="select"
                              id="selectProfileStatus"
                              name="profileStatus"
                              value={query.profileStatus || ''}
                              onChange={(e) =>
                                onFilter({ profileStatus: e.target.value })
                              }
                            >
                              <option value="">Tutti</option>
                              {ProfileStatus.map((type) => (
                                <option value={type} key={type}>
                                  {t(type)}
                                </option>
                              ))}
                            </CustomInput>
                          </FormGroup>
                        </Col>
                      </Row>
                    </ContFilterYear>
                  </Col>

                  <Col xs="7">
                    <SearchInput
                      onFilter={onFilter}
                      query={query}
                      label={t('SEARCH:NAME')}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </section>
      </Form>

      <section className="m-t-20">
        <CollaboratorsAdminTable
          data={data}
          total={total}
          offset={offset}
          limit={limit}
          onFilter={onFilter}
        />

        <ContPaginationPage>
          <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

          <FormGroup>
            <CustomInput
              type="select"
              className="pagination-select"
              id="perPage"
              name="perPage"
              value={limit}
              onChange={({ target: { value: limit } }) => {
                onFilter({ limit: limit });
              }}
            >
              {generatePaginationOptions()}
            </CustomInput>
          </FormGroup>
        </ContPaginationPage>
      </section>
      <Footer />
    </div>
  );
};

export default CollaboratorsAdminPresentational;
