import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DateInput from '../../commons/DateInput';
import { ContAffiliation, HeaderCollapse } from '../../styled-components';

import {
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Collapse,
} from 'reactstrap';
import Form from 'reactstrap/lib/Form';

const AffiliationCard = ({ affiliation }) => {
  const [t] = useTranslation('CONTACTS');
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  const {
    startDate = '',
    endDate = '',
    totalGross = 0,
    totalNet = 0,
    totalTax = 0,
  } = affiliation;

  return (
    <React.Fragment>
      <HeaderCollapse onClick={toggle}>
        <Row>
          <Col xs="8">
            <h6>
              {t('PAST_AFFILIATION_TITLE')}{' '}
              <i>
                da {moment(startDate).format('DD/MM/YYYY')} a{' '}
                {moment(endDate).format('DD/MM/YYYY')}
              </i>
            </h6>
          </Col>
          <Col xs="4" className="text-right">
            <i className="fas fa-chevron-down"></i>
          </Col>
        </Row>
      </HeaderCollapse>
      <Collapse isOpen={isOpen}>
        <ContAffiliation>
          <Form>
            <Row>
              <Col sm="12" md="6" lg="3">
                <FormGroup>
                  <Label>{t('AFFILIATION_START')}</Label>
                  <DateInput value={startDate} readOnly />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="3">
                <FormGroup>
                  <Label>{t('AFFILIATION_END')}</Label>
                  <DateInput value={endDate} readOnly />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('IMPORT_NET')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="totalNet"
                      value={totalNet.toFixed(2)}
                      readOnly
                    />
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('TAX_PERCENTAGE')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="totalTax"
                      value={totalTax.toFixed(2)}
                      readOnly
                    />
                    <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('IMPORT_GROSS')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="totalGross"
                      value={totalGross.toFixed(2)}
                      readOnly
                    />
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ContAffiliation>
      </Collapse>
    </React.Fragment>
  );
};

export default AffiliationCard;
