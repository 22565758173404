import React, { useState, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useDebouncedCallback } from 'use-debounce';
import NetworkFinancialReportsPresentational from './NetworkFinancialReportsPresentational';
import { getReports } from '../../lib/fetch/network-report';
import {
  createBonus,
  deleteBonus,
  updateBonus,
  addBonusPaymentDate,
} from '../../lib/fetch/reports';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
} from 'use-query-params';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import VariableRevenuesDetailPaginated from './VariableRevenuesDetailPaginated';
import VarRevInsDetail from './VarRevInsDetailPaginated';
import VarRevEnergyDetail from './VarRevEnergyDetailPaginated';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IconRendicontazioni } from '../../images/icn_rendicontazioni.svg';
import variables from '../../variables';

const NetworkFinancialReports = (props) => {
  const { profile = {}, match = {} } = props;
  const { _id: profileId, type: profileType = '' } = profile;
  const [{ token } = {}] = useAuth();
  const [reports, setReports] = useState({
    categories: { bonuses: { data: [] } },
    totals: {},
  });
  const alert = useAlert();
  const [t] = useTranslation('CONTACTS');
  const { showLoading, hideLoading } = useLoading();

  const [query, setQuery] = useQueryParams({
    year: withDefault(NumberParam, new Date().getFullYear()),
    number: withDefault(StringParam, ''),
  });

  const { year, number } = query;

  const path = useLocation();
  const condition = path.pathname.split('/');
  useEffect(() => {
    if (condition.length <= 4) {
      fetchReports();
    }
  }, [year, number]);

  const [fetchReports] = useDebouncedCallback(async () => {
    showLoading();
    const { error, data } = await getReports({
      year,
      number,
      token,
      profileId,
      iafs: false,
    });

    if (error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setReports(data);
    hideLoading();
  }, 1000);

  function onFilter(queryDiff) {
    setQuery(queryDiff);
  }

  async function onAddBonus(bonus) {
    const { error } = await createBonus({
      token,
      bonus: { ...bonus, profileId },
    });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    fetchReports();
    alert.success(t('COMMON:SUCCESS_CREATE'));
  }

  async function onBonusDelete(_id) {
    const { error } = await deleteBonus({ token, _id });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    fetchReports();
    alert.success(t('COMMON:SUCCESS_DELETE'));
  }

  async function onBonusUpdate(data) {
    const { error } = await updateBonus({ token, data });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    fetchReports();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
  }

  async function addPaymentDate(data) {
    const { error } = await addBonusPaymentDate({ token, data });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    fetchReports();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
  }

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:FINANCIAL_REPORTS')}
            icon={<IconRendicontazioni fill={variables.white} />}
          />
          <Switch>
            <Route
              exact
              path={match.url}
              component={(props) => (
                <NetworkFinancialReportsPresentational
                  data={reports}
                  onAddBonus={onAddBonus}
                  onBonusDelete={onBonusDelete}
                  onBonusUpdate={onBonusUpdate}
                  onFilter={onFilter}
                  query={query}
                  profileType={profileType}
                  addPaymentDate={addPaymentDate}
                  {...props}
                />
              )}
            />
            <Route
              path={`${match.url}/variable-revenues`}
              component={(props) => (
                <VariableRevenuesDetailPaginated
                  profile={profile}
                  {...props}
                  onAddBonus={onAddBonus}
                />
              )}
            />
            <Route
              path={`${match.url}/variable-revenues-insurances`}
              component={(props) => (
                <VarRevInsDetail
                  profile={profile}
                  onAddBonus={onAddBonus}
                  {...props}
                />
              )}
            />

            <Route
              path={`${match.url}/variable-revenues-energy`}
              component={(props) => (
                <VarRevEnergyDetail
                  profile={profile}
                  onAddBonus={onAddBonus}
                  {...props}
                />
              )}
            />
          </Switch>
        </section>
      </div>
    </div>
  );
};

export default withRouter(NetworkFinancialReports);
