import React from 'react';
import { formatCurrency } from '../../../lib/helpers/formatters';

const TotalTr = ({ data = [] }) => {
  if (!Array.isArray(data)) return null;

  const totals = data.reduce(
    (acc, row = {}) => {
      const {
        gross = 0,
        net = 0,
        adminShare = 0,
        networkShare = 0,
        extraShare = 0,
      } = row;
      return {
        gross: acc.gross + gross,
        net: acc.net + net,
        adminShare: acc.adminShare + adminShare,
        networkShare: acc.networkShare + networkShare,
        extraShare: acc.extraShare + extraShare,
      };
    },
    {
      gross: 0,
      net: 0,
      adminShare: 0,
      networkShare: 0,
      extraShare: 0,
    }
  );

  return (
    <tr className="summary-tr">
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td className="txt-table-right">{formatCurrency(totals.gross)}</td>
      <td className="txt-table-right">{formatCurrency(totals.networkShare)}</td>
      <td className="txt-table-right">{formatCurrency(totals.adminShare)}</td>
      <td>-</td>
      <td>-</td>
    </tr>
  );
};

export default TotalTr;
