import React, { useState, useEffect } from 'react';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';

import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';

import contractsAPIs from '../../../lib/fetch/contractsAPIs';
import EnergyPresentational from './EnergyPresentational';
import { useAuth } from '../../../commons/Auth';
import axios from 'axios';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const Contracts = ({ profile }) => {
  const [contracts, setContracts] = useState([]);
  const [total, setTotal] = useState(0);
  const [totPod, setTotPod] = useState(0);
  const [totPdr, setTotPdr] = useState(0);
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();

  const [selectedContracts, setSelectedContracts] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const alert = useAlert();
  const [totalContractsValue, setTotalContractsValue] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    pod_pdr: withDefault(StringParam, ''),
    condo_admin_cf: withDefault(StringParam, ''),
    condo_vat_cf: withDefault(StringParam, ''),
    address: withDefault(StringParam, ''),
    customer: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    admin,
    condo_vat_cf,
    condo_admin_cf,
    pod_pdr,
    customer,
  } = query;

  function onFilter(queryDiff) {
    if (
      (queryDiff.admin && queryDiff.admin !== admin) || // first search
      (admin && queryDiff.admin === '') || // search deleted
      (queryDiff.pod_pdr && queryDiff.pod_pdr !== pod_pdr) || // first search
      (pod_pdr && queryDiff.pod_pdr === '') || // search deleted
      (queryDiff.customer && queryDiff.customer !== customer) || // first search
      (customer && queryDiff.customer === '') || // search deleted
      (queryDiff.condo_vat_cf && queryDiff.condo_vat_cf !== condo_vat_cf) || // first search
      (condo_vat_cf && queryDiff.condo_vat_cf === '') || // search deleted
      (queryDiff.condo_admin_cf &&
        queryDiff.condo_admin_cf !== condo_admin_cf) || // first search
      (condo_admin_cf && queryDiff.condo_admin_cf === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  const fetchContracts = async (query) => {
    showLoading();

    const {
      data,
      total: _total,
      totPod,
      totPdr,
    } = await contractsAPIs.findUserContracts({
      token,
      query,
      userId: profile._id,
    });
    setTotal(Number(_total || 0));
    setTotPod(totPod);
    setTotPdr(totPdr);
    setContracts(data);

    hideLoading();
  };

  useEffect(() => {
    fetchContracts({
      pod_pdr,
      condo_vat_cf,
      condo_admin_cf,
      offset,
      limit,
      customer,
    });
  }, [condo_vat_cf, pod_pdr, condo_admin_cf, offset, limit, customer]);

  const onSelectChange = (_id) => {
    if (selectedContracts.includes(_id)) {
      setSelectedAll(false);
      return setSelectedContracts((selectedContracts) =>
        selectedContracts.filter((invoiceId) => invoiceId !== _id)
      );
    }
    setSelectedContracts((selectedContracts) => [...selectedContracts, _id]);
  };

  const onSelectAll = (selectAll) => {
    setSelectedAll(selectAll);
    if (!selectAll) {
      return setSelectedContracts([]);
    }
    setSelectedContracts(
      contracts
        .filter((contract) => contract.pinvInvoice?.length === 0)
        .map(({ _id }) => _id)
    );
  };

  const onInvoice = async () => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/extra-invoices/${profile._id}/contracts`,
      {
        selectedContracts,
      },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));

    fetchContracts({ pod_pdr, condo_vat_cf, condo_admin_cf, offset, limit });
    setSelectedContracts([]);
    setSelectedAll(false);
  };

  return (
    <EnergyPresentational
      data={contracts}
      profile={profile}
      query={query}
      total={total}
      totPod={totPod}
      totPdr={totPdr}
      onFilter={onFilter}
      selectedContracts={selectedContracts}
      onInvoice={onInvoice}
      selectedAll={selectedAll}
      onSelectAll={onSelectAll}
      onSelectChange={onSelectChange}
      onStatusChanged={() => fetchContracts()}
    />
  );
};

export default Contracts;
