import React from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../commons/Auth';
import useForm from 'react-hook-form';
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { refactoringAdminLevelsAddRegistry } from '../../../lib/helpers/refactoringAdminLevelsAddRegistry';
import FormErrorMessage from '../../../commons/FormErrorMessage';
import {
  BtnOutlineOrange,
  ButtonDelete,
  ButtonSave,
  ContFlexRight,
} from '../../../styled-components';
import ModalConfirm from '../../../commons/ModalConfirm';
import {
  AdminTypes,
  ProfileTypes,
  SupplierTypes,
} from '../../../lib/constants';
import { createPassword } from '../../../lib/helpers/password';

const CollaboratorInfoPresentational = ({
  data = {},
  updateProfile,
  forgotPassword,
  enable,
}) => {
  const [t] = useTranslation('COLLABORATORS');
  const {
    register,
    errors,
    handleSubmit,
    watch,
    getValues,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: data,
  });
  const { authId, authEmail } = data;
  const alert = useAlert();

  function onSubmit(values) {
    const formData = {
      ...values,
    };

    updateProfile(formData);
  }

  watch(['emailReference']);

  const { emailReference } = getValues();

  const enableLogin = async () => {
    if (authId && authEmail) return;
    const password = createPassword();
    enable({ email: emailReference, active: true, password });
  };

  const disableLogin = () => {
    if (!authId || !authEmail) return;
    enable({ active: false });
  };

  const ProfileTypes = [
    ...AdminTypes,
    ...SupplierTypes,
    'SUPPLIER_INS',
    'COMMERCIAL',
    'AGENCY',
    'GENERIC_CUSTOMER',
    'EON_ENERGY',
    'ADMIN_COLLAB',
  ];

  return (
    <Form>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <Label>{t('TYPE')} *</Label>
                <CustomInput
                  type="select"
                  id="exampleCustomSelect"
                  name="type"
                  innerRef={register({ required: t('COMMON:REQUIRED') })}
                  defaultValue={data.type}
                  disabled={!!data.type}
                >
                  {ProfileTypes.filter(
                    (elem) =>
                      !!data.type ||
                      ![
                        'BUILDING_ADMIN_2',
                        'BUILDING_ADMIN_3',
                        'BUILDING_ADMIN_4',
                        'BUILDING_ADMIN_5',
                      ].includes(elem)
                  ).map((type) => (
                    <option value={type} key={type}>
                      {t(refactoringAdminLevelsAddRegistry(type))}
                    </option>
                  ))}
                </CustomInput>
                <FormErrorMessage errors={errors} name="type" />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <Label>{t('NAME')} *</Label>
                <Input
                  type="text"
                  name="name"
                  maxLength="50"
                  defaultValue={data.name}
                  innerRef={register({
                    required: t('COMMON:REQUIRED'),
                  })}
                />
                <FormErrorMessage errors={errors} name="name" />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <Label>{t('SURNAME')} *</Label>
                <Input
                  type="text"
                  name="surname"
                  maxLength="50"
                  defaultValue={data.surname}
                  innerRef={register({
                    required: t('COMMON:REQUIRED'),
                  })}
                />
                <FormErrorMessage errors={errors} name="surname" />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>{t('EMAIL')} *</Label>
                <Input
                  type="email"
                  name="emailReference"
                  maxLength="320"
                  innerRef={register({
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t('EMAIL_INVALID'),
                    },
                  })}
                  defaultValue={data.emailReference}
                  disabled={true}
                />
                <FormErrorMessage errors={errors} name="emailReference" />
              </FormGroup>
            </Col>
          </Row>
        </TabPane>
      </TabContent>

      <br />

      <ContFlexRight>
        <React.Fragment>
          {authId && authEmail && (
            <ModalConfirm
              onConfirm={() => disableLogin()}
              text={t('USERS:DISABLE_CONFIRM')}
            >
              <ButtonDelete type="button">{t('USERS:DISABLE')}</ButtonDelete>
            </ModalConfirm>
          )}
          {!(authId && authEmail) && (
            <ModalConfirm
              onConfirm={() => {
                if (!emailReference)
                  return alert.error(t('USERS:USER_EMAIL_REQUIRED_ERROR'));
                else return enableLogin();
              }}
              text={t('USERS:ENABLE_CONFIRM')}
            >
              <ButtonSave type="button">{t('USERS:ENABLE')}</ButtonSave>
            </ModalConfirm>
          )}
          <ButtonSave
            type="button"
            onClick={(e) => {
              if (Object.keys(errors).length > 0) window.scrollTo({ top: 0 });
              handleSubmit(onSubmit)(e);
            }}
          >
            {t('BTN_SAVE')}
          </ButtonSave>
        </React.Fragment>
      </ContFlexRight>
      <br />
      <ContFlexRight>
        <ModalConfirm
          onConfirm={forgotPassword}
          text={t('USERS:FORGOT_PASSWORD_BUTTON_CONFIRM')}
        >
          <ButtonSave type="button">
            {t('USERS:FORGOT_PASSWORD_BUTTON')}
          </ButtonSave>
        </ModalConfirm>
      </ContFlexRight>
    </Form>
  );
};

export default CollaboratorInfoPresentational;
