import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import ScorePresentational from './ScorePresentational';
import axios from 'axios';
import { profileScoreFormatter } from '../../../lib/helpers/profileScoreFormatter';
import { profileScoreFormatterV2 } from '../../../lib/helpers/profileScoreFormatterV2';

const ScoreAdmin = (props) => {
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [profileScore, setProfileScore] = useState({});
  const [isAuto, setIsAuto] = useState(true);
  const [EonScoreIsAuto, setEonScoreIsAuto] = useState(true);
  const [insuranceScoreType1IsAuto, setInsuranceScoreType1IsAuto] = useState(
    true
  );
  const [insuranceScoreType2IsAuto, setInsuranceScoreType2IsAuto] = useState(
    true
  );
  const [supplierScoreIsAuto, setSupplierScoreIsAuto] = useState(true);

  const { history, id, profile, setProfile } = props;

  async function fetchProfileScore() {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/scores/admin/${profile._id}`,
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    if (response.data.EonScoreIsAuto === false) {
      setEonScoreIsAuto(false);
    }

    if (response.data.insuranceScoreType1IsAuto === false) {
      setInsuranceScoreType1IsAuto(false);
    }

    if (response.data.insuranceScoreType2IsAuto === false) {
      setInsuranceScoreType2IsAuto(false);
    }

    if (response.data.supplierScoreIsAuto === false) {
      setSupplierScoreIsAuto(false);
    }

    setProfileScore(
      profileScoreFormatterV2(response.data, {
        EonScoreIsAuto,
        insuranceScoreType1IsAuto,
        insuranceScoreType2IsAuto,
        supplierScoreIsAuto,
      })
    );
  }

  async function fetchProfileScoreOnChange() {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/scores/admin/${profile._id}`,
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setProfileScore(
      profileScoreFormatterV2(response.data, {
        EonScoreIsAuto,
        insuranceScoreType1IsAuto,
        insuranceScoreType2IsAuto,
        supplierScoreIsAuto,
      })
    );
  }

  useEffect(() => {
    fetchProfileScore();
  }, []);

  useEffect(() => {
    fetchProfileScoreOnChange();
  }, [EonScoreIsAuto]);

  useEffect(() => {
    fetchProfileScoreOnChange();
  }, [insuranceScoreType1IsAuto]);

  useEffect(() => {
    fetchProfileScoreOnChange();
  }, [insuranceScoreType2IsAuto]);

  useEffect(() => {
    fetchProfileScoreOnChange();
  }, [supplierScoreIsAuto]);

  async function saveProfileScoreSettings({ formData }) {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/scores/${profile._id}`,
      { ...formData },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_CREATE'));
    fetchProfileScoreOnChange();
  }

  return (
    <ScorePresentational
      data={profile}
      profileScore={profileScore}
      saveProfileScoreSettings={saveProfileScoreSettings}
      isAuto={isAuto}
      setIsAuto={setIsAuto}
      EonScoreIsAuto={EonScoreIsAuto}
      setEonScoreIsAuto={setEonScoreIsAuto}
      insuranceScoreType1IsAuto={insuranceScoreType1IsAuto}
      setInsuranceScoreType1IsAuto={setInsuranceScoreType1IsAuto}
      insuranceScoreType2IsAuto={insuranceScoreType2IsAuto}
      setInsuranceScoreType2IsAuto={setInsuranceScoreType2IsAuto}
      supplierScoreIsAuto={supplierScoreIsAuto}
      setSupplierScoreIsAuto={setSupplierScoreIsAuto}
      {...props}
    />
  );
};

export default ScoreAdmin;
