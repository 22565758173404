import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  Field,
  InvoicesByMonthField,
} from '../../commons/DataTable';
import ModalNoInvoice from './ModalNoInvoice';
import ModalExtraInvoice from './ModalExtraInvoice';

function InvoicesByMonthTable(props) {
  const {
    data = [],
    query = {},
    onFilter = () => {},
    onNoInvoiceConfirm = () => {},
    fetchTotalInvoices = () => {},
  } = props;
  const [t] = useTranslation('COMMON');

  return (
    <DataTable
      history
      data={data}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      offset={query.offset}
      limit={query.limit}
      onFilter={onFilter}
    >
      <Field
        className="txt-table-left"
        title="Fornitore"
        source="businessName"
      />
      <ModalExtraInvoice
        title="Gennaio"
        month={1}
        year={query.year}
        source="months[0].count"
        fetchTotalInvoices={fetchTotalInvoices}
      />
      <ModalExtraInvoice
        title="Febbraio"
        month={2}
        year={query.year}
        source="months[1].count"
        fetchTotalInvoices={fetchTotalInvoices}
      />
      <ModalExtraInvoice
        title="Marzo"
        month={3}
        year={query.year}
        source="months[2].count"
        fetchTotalInvoices={fetchTotalInvoices}
      />
      <ModalExtraInvoice
        title="Aprile"
        month={4}
        year={query.year}
        source="months[3].count"
        fetchTotalInvoices={fetchTotalInvoices}
      />
      <ModalExtraInvoice
        title="Maggio"
        month={5}
        year={query.year}
        source="months[4].count"
        fetchTotalInvoices={fetchTotalInvoices}
      />
      <ModalExtraInvoice
        title="Giugno"
        month={6}
        year={query.year}
        source="months[5].count"
        fetchTotalInvoices={fetchTotalInvoices}
      />
      <ModalExtraInvoice
        title="Luglio"
        month={7}
        year={query.year}
        source="months[6].count"
        fetchTotalInvoices={fetchTotalInvoices}
      />
      <ModalExtraInvoice
        title="Agosto"
        month={8}
        year={query.year}
        source="months[7].count"
        fetchTotalInvoices={fetchTotalInvoices}
      />
      <ModalExtraInvoice
        title="Settembre"
        month={9}
        year={query.year}
        source="months[8].count"
        fetchTotalInvoices={fetchTotalInvoices}
      />
      <ModalExtraInvoice
        title="Ottobre"
        month={10}
        year={query.year}
        source="months[9].count"
        fetchTotalInvoices={fetchTotalInvoices}
      />
      <ModalExtraInvoice
        title="Novembre"
        month={11}
        year={query.year}
        source="months[10].count"
        fetchTotalInvoices={fetchTotalInvoices}
      />
      <ModalExtraInvoice
        title="Dicembre"
        month={12}
        year={query.year}
        source="months[11].count"
        fetchTotalInvoices={fetchTotalInvoices}
      />
      <ModalNoInvoice
        onConfirm={onNoInvoiceConfirm}
        year={query.year}
        fetchTotalInvoices={fetchTotalInvoices}
      />
    </DataTable>
  );
}

export default withRouter(InvoicesByMonthTable);
