import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { formatCurrency } from '../../../lib/helpers/formatters';

function Summary({ totals = {} }) {
  const [t] = useTranslation('CONTACTS');

  const {
    variableRevenues = 0,
    billableVariableRevenues = 0,
    variableRevenuesInsurances = 0,
    billableVariableRevenuesInsurances = 0,
    variableRevenuesEnergy = 0,
    billableVariableRevenuesEnergy = 0,
    variableRevenuesRecurrentEnergy = 0,
    billableVariableRevenuesRecurrentEnergy = 0,
    totalNet = 0,
    billableTotalNet = 0,
    bonuses = 0,
    total = 0,
    billableTotal = 0,
  } = totals;

  return (
    <div className="summary-table">
      {/* TABLE HEADING */}
      <Row className="heading-row">
        <Col xs="4"></Col>
        <Col xs="4">
          <p>{t('EARNED_SHARE')}</p>
        </Col>
        <Col xs="4">
          <p>{t('BILLABLE_SHARE')}</p>
        </Col>
      </Row>

      {/* COMPENSI FATTURE FORNITORE */}
      {variableRevenues && variableRevenues > 0 ? (
        <Row className="normal-row">
          <Col xs="4">
            <p>{t('FINANCIAL_REPORT_TOTAL6')}</p>
          </Col>
          <Col xs="4">
            <p>{formatCurrency(variableRevenues)}</p>
          </Col>
          <Col xs="4">
            <p>{formatCurrency(billableVariableRevenues)}</p>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {/* COMPENSI ASSICURAZIONI */}
      {variableRevenuesInsurances && variableRevenuesInsurances > 0 ? (
        <Row className="normal-row">
          <Col xs="4">
            <p>{t('FINANCIAL_REPORT_TOTAL7')}</p>
          </Col>
          <Col xs="4">
            <p>{formatCurrency(variableRevenuesInsurances)}</p>
          </Col>
          <Col xs="4">
            <p>{formatCurrency(billableVariableRevenuesInsurances)}</p>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {/* COMPENSI ENERGIA */}
      {variableRevenuesEnergy && variableRevenuesEnergy > 0 ? (
        <Row className="normal-row">
          <Col xs="4">
            <p>{t('FINANCIAL_REPORT_TOTAL9')}</p>
          </Col>
          <Col xs="4">
            <p>{formatCurrency(variableRevenuesEnergy)}</p>
          </Col>
          <Col xs="4">
            <p>{formatCurrency(billableVariableRevenuesEnergy)}</p>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {/* IMPONIBILE NETTO */}
      <Row className="highlighted-row">
        <Col xs="4">
          <p>{t('FINANCIAL_REPORT_TOTAL3')}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(totalNet)}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(billableTotalNet)}</p>
        </Col>
      </Row>

      {/* INVITI A FATTURARE */}
      <Row className="highlighted-row">
        <Col xs="4" className="flex-between">
          <p>{t('CONTACTS:LABEL_INVOICE_BONUS').toUpperCase()}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(bonuses)}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(bonuses)}</p>
        </Col>
      </Row>

      {/* SALDO */}
      <Row className="highlighted-row">
        <Col xs="4">
          <p>{t('FINANCIAL_REPORT_TOTAL5')}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(total)}</p>
        </Col>
        <Col xs="4">
          <p>{formatCurrency(billableTotal)}</p>
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(Summary);
