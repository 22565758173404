import React from 'react';
import moment from 'moment';
import { formatCurrency } from '../../lib/helpers/formatters';

function PaymentsDataField({ value = [], type = 'list', field = 'gross' }) {
  if (!Array.isArray(value)) return '-';

  const total = value.reduce((acc, val) => acc + val[field], 0);
  if (type === 'total') {
    return formatCurrency(total);
  }

  const list = value.map((payment, index) => {
    const date = payment.date;
    const amount = payment[field];

    return (
      <p key={index}>
        {`${
          moment(date).isValid() ? `${moment(date).format('DD-MM-YYYY')}: ` : ''
        }${formatCurrency(amount)}`}
      </p>
    );
  });
  list.push(<p key={value.length}>{`Totale: ${formatCurrency(total)}`}</p>);
  return list;
}

export default PaymentsDataField;
