import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { save, remove, create, upload } from '../../../lib/fetch/profiles';
import { deleteFile, getSignedUrl } from '../../../lib/fetch/files';
import ProfileInfoPresentational from './ProfileInfoPresentational';
import { useLoading } from '../../../commons/Loading';
import { useAuth } from '../../../commons/Auth';
import { list as listAffiliations } from '../../../lib/fetch/affiliations';
import moment from 'moment';
import axios from 'axios';

const ProfileInfo = (props) => {
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [activeAffiliation, setActiveAffiliation] = React.useState({});

  const { history, id, profile, setProfile, managers } = props;

  const hasNetwork = false;

  const isUpdating = id !== 'create';

  async function updateProfile(updates) {
    showLoading();

    const { error, data } = await save({ id, data: updates, token });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setProfile(data);
    hideLoading();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
  }

  async function deleteProfile() {
    showLoading();
    const { error } = await remove({ id, token });

    if (error) {
      hideLoading();
      const { response = {} } = error;
      const { data = {} } = response;
      const { message = '' } = data;
      alert.error(message || t('COMMON:GENERIC_ERROR'));
      return;
    }

    setProfile({});
    hideLoading();
    alert.success(t('COMMON:SUCCESS_DELETE'));
    history.push('/profiles');
  }

  async function createProfile(profile) {
    showLoading();
    const { error, data } = await create({ id, data: profile, token });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();

    alert.success(t('COMMON:SUCCESS_CREATE'));
    history.replace(`/profiles/${data._id}/info`);
  }

  async function uploadFile(file) {
    showLoading();

    const { error, data } = await upload({ id, file, token });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    setProfile({ ...profile, ...data });
    alert.success(t('COMMON:SUCCESS_UPLOAD'));
  }

  async function handleDelete({ key, field }) {
    showLoading();

    const { error, data } = await deleteFile({ id, key, field, token });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    setProfile({ ...profile, ...data });
    alert.success(t('COMMON:SUCCESS_DELETE'));
  }

  async function handleFileListView(key) {
    showLoading();

    const { data, error } = await getSignedUrl(key, token);

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    window.open(data.url, '_blank');
  }

  const fetchAffiliations = async () => {
    showLoading();

    const { error, data } = await listAffiliations({
      profileId: profile._id,
      token,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    const activeAffiliations = data.filter((affiliation) => {
      const today = moment();
      const endData = moment(affiliation.endDate);
      return endData.isAfter(today);
    });

    setActiveAffiliation(activeAffiliations[0] || {});
    hideLoading();
  };

  React.useEffect(() => {
    if (profile._id) fetchAffiliations();
  }, [profile]);

  return (
    <ProfileInfoPresentational
      data={profile}
      isUpdating={isUpdating}
      updateProfile={updateProfile}
      deleteProfile={deleteProfile}
      createProfile={createProfile}
      uploadFile={uploadFile}
      handleDelete={handleDelete}
      handleFileListView={handleFileListView}
      activeAffiliation={activeAffiliation}
      managers={managers}
      {...props}
    />
  );
};

export default ProfileInfo;
