import React, { useState } from 'react';
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  ButtonGroup,
} from 'reactstrap';
import FormErrorMessage from '../../commons/FormErrorMessage';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import {
  BtnRadiusRed,
  ButtonSave,
  H5Styled,
  ContFlexRight,
} from '../../styled-components';
import plusIcon from '../../images/icn-plus.svg';
import plusTrash from '../../images/icn-trash.svg';
import { ReactComponent as IconPlus } from '../../images/icn-plus.svg';

const SailInvoice = ({ data = {}, subjects = [], onSendInvoice }) => {
  const [t] = useTranslation('SAIL_INVOICES');
  const initialOrderRow = {
    product: {
      title: '',
      pricing: {
        list: 0,
      },
    },
    quantity: 1,
  };

  const [orderRow, setOrderRow] = useState([initialOrderRow]);

  const handleCheck = (index, e, selected) => {
    let temp = [...orderRow];
    setNestedProperty(temp[index], selected, e.target.value);
    setOrderRow(temp);
  };

  const setNestedProperty = (obj, path, value) =>
    path
      .split('.')
      .reduce(
        (o, k, i, a) =>
          i === a.length - 1 ? (o[k] = value) : (o[k] = o[k] || {}),
        obj
      );

  const handleNewRow = () => {
    setOrderRow([
      ...orderRow,
      {
        product: {
          title: '',
          pricing: {
            list: 0,
          },
        },
        quantity: 1,
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    orderRow.splice(index, 1);
    setOrderRow([...orderRow]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSendInvoice({
      profileId: e.target.profileId.value,
      item: orderRow,
    });
    setOrderRow([initialOrderRow]);
    e.target.reset();
  };

  const { register, errors } = useForm({
    mode: 'onChange',
    defaultValues: data,
  });

  return (
    <Form onSubmit={handleSubmit}>
      <div className="m-tb-20">
        <Nav tabs>
          <NavItem>
            <NavLink className="active">Nuova fattura</NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={'1'}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12" md="6" lg="3">
                <FormGroup>
                  <Label>{t('SUBJECT')}</Label>
                  <CustomInput
                    type="select"
                    id="profileId"
                    name="type"
                    innerRef={register({ required: t('COMMON:REQUIRED') })}
                  >
                    {subjects.map((subject) => (
                      <option value={subject._id} key={subject._id}>
                        {subject.businessName}
                      </option>
                    ))}
                  </CustomInput>
                  <FormErrorMessage errors={errors} name="type" />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <H5Styled>{t('ORDER_ROW')}</H5Styled>
            {orderRow.map((row, index) => (
              <Row key={index}>
                <Col sm="12" md="6" lg="6">
                  <FormGroup>
                    <Label>{t('TEXT')}</Label>
                    <Input
                      type="text"
                      name="title"
                      onChange={(e) => handleCheck(index, e, 'product.title')}
                      innerRef={register({
                        required: t('COMMON:REQUIRED'),
                        maxLength: 500,
                      })}
                    />
                    <FormErrorMessage errors={errors} name="businessName" />
                  </FormGroup>
                </Col>
                <Col sm="12" md="2" lg="2">
                  <FormGroup>
                    <Label>{t('AMOUNT')}</Label>
                    <Input
                      type="number"
                      step={0.01}
                      min={0}
                      name="list"
                      onChange={(e) =>
                        handleCheck(index, e, 'product.pricing.list')
                      }
                      innerRef={register({
                        required: t('COMMON:REQUIRED'),
                      })}
                    />
                    <FormErrorMessage errors={errors} name="name" />
                  </FormGroup>
                </Col>
                <Col sm="12" md="2" lg="2">
                  <ButtonGroup className="mt-4">
                    {index !== 0 && (
                      <BtnRadiusRed type="button" className="mr-3">
                        <img
                          src={plusTrash}
                          alt="Elimina"
                          onClick={() => handleRemoveRow(index)}
                        />
                      </BtnRadiusRed>
                    )}
                    {index === orderRow.length - 1 && (
                      <BtnRadiusRed type="button" onClick={handleNewRow}>
                        <IconPlus />
                      </BtnRadiusRed>
                    )}
                  </ButtonGroup>
                </Col>
              </Row>
            ))}
          </TabPane>
        </TabContent>
      </div>
      <ContFlexRight>
        <div className="m-b-30">
          <ButtonSave type="submit">{t('BTN_SAVE')}</ButtonSave>
        </div>
      </ContFlexRight>
    </Form>
  );
};

export default SailInvoice;
