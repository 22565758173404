import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  FormText,
  CustomInput,
  Input,
  Form,
} from 'reactstrap';
import { BtnOutlineRed, BtnOutlineGreen } from '../../../styled-components';
import { ReactComponent as IconPlus } from '../../../images/icn_plus_green.svg';
import DateInput from '../../../commons/DateInput';
const DEFAULT_VAT = 0.22;

const MODAL_VALUES_INITIALS = {
  amount: 0,
  gross: 0,
  vat: 0,
  gabettiPerc: 0,
  adminPerc: 0,
  masterShares: 0,
};

const ModalInvoiceRef = (props) => {
  const { data = [], onConfirm, selectedDocuments = [] } = props;
  const [t] = useTranslation('ROYALTY');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [invoiceRef, setInvoiceRef] = useState('');
  const [modalValues, setModalValues] = useState(MODAL_VALUES_INITIALS);
  const [date, setDate] = useState('');
  const [cashed, setCashed] = useState(false);
  const [formErrors, setFormErrors] = useState({
    invoiceRef: false,
    date: false,
  });

  useEffect(() => {
    setModalValues(MODAL_VALUES_INITIALS);
    const modalValuesData = data
      .filter(({ _id }) => selectedDocuments.includes(_id))
      .reduce((acc, invoice = {}) => {
        const { adminPerc = 0, gabettiPerc = 0, masterShares = [] } = invoice;

        const masterSharesSum = +masterShares.reduce(
          (acc, value) => acc + (value || 0),
          0
        );

        acc.adminPerc += +adminPerc;
        acc.gabettiPerc += +gabettiPerc;
        acc.masterShares += masterSharesSum;
        acc.amount += adminPerc + gabettiPerc + masterSharesSum;

        return acc;
      }, Object.assign({}, MODAL_VALUES_INITIALS));

    modalValuesData.vat = modalValuesData.amount * DEFAULT_VAT;
    modalValuesData.gross = modalValuesData.amount + modalValuesData.vat;

    setModalValues(modalValuesData);
  }, [data, selectedDocuments]);

  function onSubmit() {
    const errors = {};

    modalValues.amount = modalValues.amount.toFixed(2);
    modalValues.vat = modalValues.vat.toFixed(2);
    modalValues.gross = modalValues.gross.toFixed(2);
    modalValues.gabettiPerc = modalValues.gabettiPerc.toFixed(2);
    modalValues.adminPerc = modalValues.adminPerc.toFixed(2);
    modalValues.masterShares = modalValues.masterShares.toFixed(2);

    if (!invoiceRef) errors.invoiceRef = true;
    if (!date) errors.date = true;

    if (Object.values(errors).some(Boolean)) return setFormErrors(errors);

    onConfirm({
      ...modalValues,
      cashed,
      date,
      invoiceRef,
    });
  }

  return (
    <React.Fragment>
      <BtnOutlineGreen type="button" onClick={toggle}>
        <IconPlus /> {t('BTN_ADD_INVOICE_REF')}
      </BtnOutlineGreen>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('MODAL_INVOICE_TITLE')}</ModalHeader>
        <Form>
          <ModalBody>
            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('INVOICE_REF')}</Label>
                    <Input
                      type="text"
                      name="invoiceRef"
                      value={invoiceRef}
                      onChange={({ target }) => setInvoiceRef(target.value)}
                    />
                    {formErrors.invoiceRef && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('INVOICE_DATE')}</Label>
                    <DateInput value={date} onChange={setDate} />
                    {formErrors.date && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('CONTACTS:EXTRA_PERC')}</Label>
                    <Input
                      type="input"
                      name="gabettiPerc"
                      value={modalValues.gabettiPerc.toFixed(2)}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('CONTACTS:MASTER_SHARES')}</Label>
                    <Input
                      type="input"
                      name="masterShares"
                      value={modalValues.masterShares.toFixed(2)}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('CONTACTS:ADMIN_PERC')}</Label>
                    <Input
                      type="input"
                      name="adminPerc"
                      value={modalValues.adminPerc.toFixed(2)}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('CONTACTS:IMPORT_INVOICE')}</Label>
                    <Input
                      type="input"
                      name="amount"
                      value={modalValues.amount.toFixed(2)}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('CONTACTS:IMPORT_INVOICE_VAT')}</Label>
                    <Input
                      type="input"
                      name="vat"
                      disabled
                      value={modalValues.vat.toFixed(2)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('INVOICE_CASHED')}</Label>
                    <div className="radio-modal-table">
                      <CustomInput
                        type="radio"
                        name="cashed"
                        id="cashedTrue"
                        label="Si"
                        value="1"
                        checked={cashed}
                        onChange={({ target }) => {
                          setCashed(target.value === '1');
                        }}
                      />
                      <CustomInput
                        type="radio"
                        name="cashedFalse"
                        id="cashed"
                        label="No"
                        value="0"
                        checked={!cashed}
                        onChange={({ target }) => {
                          setCashed(target.value === '1');
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen
              type="button"
              className="uppercase"
              onClick={onSubmit}
            >
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModalInvoiceRef;
