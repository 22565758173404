import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Footer from '../../commons/Footer';
import { useAuth } from '../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import { get } from '../../lib/fetch/profiles';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
import variables from '../../variables';
import { Col, Nav, NavItem, NavLink, Row, Badge } from 'reactstrap';
import {
  BtnRadiusWhite,
  ContFlexLeft,
  ContFlexRight,
  H4Styled,
} from '../../styled-components';
import { refactoringAdminLevels } from '../../lib/helpers/refactoringAdminLevels';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import classnames from 'classnames';
import CollaboratorInfo from './CollabInfo';
import './CollabDetail.scss';
import './TabsProfile.scss';

function MyCollaboratorsDetail(props) {
  const [{ token, isAdmin, userType = '' } = {}] = useAuth();
  const [t] = useTranslation('COLLABORATORS');
  const [profile, setProfile] = useState({});
  const [pageBack, setPageBack] = useState('');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const { history, location, match = {} } = props;
  const { params: { id } = {}, url = '' } = match;
  const { pathname = '', state = {} } = location;
  const tab = pathname.split('/').pop();

  useEffect(() => {
    if (state.pageBack) setPageBack(state.pageBack);
  }, [state]);

  useEffect(() => {
    if (id !== 'create') {
      fetchProfile(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchProfile = async (id) => {
    showLoading();

    const { error, data } = await get({ id, token });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setProfile(data);
    hideLoading();
  };

  if (pathname === url) {
    return <Redirect to={`${url}/info`} />;
  }

  const isUpdating = id !== 'create';

  const { authEmail = '', type = '', name = '', surname = '' } = profile;

  return (
    <div>
      <Row>
        <Col sm="12" md="6">
          <ContFlexLeft>
            <BtnRadiusWhite
              onClick={() => history.replace(`/my-collaborators`)}
            >
              <IconBack fill={variables.primary} />
            </BtnRadiusWhite>
            <H4Styled>{t('TITLE_DETAIL')}</H4Styled>
          </ContFlexLeft>
        </Col>
        {isUpdating && (
          <Col sm="12" md="6">
            <ContFlexRight>
              <H4Styled className="registry-name">
                <span>
                  {name} {surname} -{' '}
                  <small>{t(refactoringAdminLevels(type))}</small>
                </span>
              </H4Styled>
              <Badge
                className={`affiliation${
                  authEmail?.length > 0 ? ' active' : ' not-active'
                }`}
              >
                {t(
                  `${authEmail?.length > 0 ? 'ACTIVE' : 'INACTIVE'}`
                ).toUpperCase()}
              </Badge>
            </ContFlexRight>
          </Col>
        )}
      </Row>

      <div className="m-tb-20">
        <Nav tabs>
          <NavItem>
            <NavLink
              tag={Link}
              to={`${match.url}/info`}
              className={classnames({ active: tab === 'info' })}
            >
              {t('GENERAL_INFO')}
            </NavLink>
          </NavItem>
        </Nav>

        <Switch>
          <Route
            path={`${match.url}/info`}
            component={(props) => (
              <CollaboratorInfo
                id={id}
                profile={profile}
                setProfile={setProfile}
                {...props}
              />
            )}
          />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(MyCollaboratorsDetail);
