import axios from 'axios';

export const addLog = async (data, token) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/logs`,
      data,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};
