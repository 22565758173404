import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import { useState } from 'react';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useDebouncedCallback } from 'use-debounce';
import { getAllOverdueInvoices } from '../../lib/fetch/reports';
import OverdueInvoicesPresentational from './OverdueInvoicesPresentational';

const MyOverdueInvoices = ({ location }) => {
  const [t] = useTranslation();
  const [{ isAdmin, profile = {}, token } = {}] = useAuth();
  const { _id: profileId } = profile;
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [overdueInvoices, setOverdueInvoices] = useState([]);
  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    supplier: withDefault(StringParam, ''),
    invoiceNumber: withDefault(StringParam, ''),
    admin: withDefault(StringParam, ''),
    limit: withDefault(StringParam, '50'),
    offset: withDefault(StringParam, '0'),
    type: withDefault(StringParam, ''),
    numberAutoOverdue: withDefault(StringParam, ''),
  });

  const [fetchOverdueInvoices] = useDebouncedCallback(async () => {
    showLoading();
    const { error, data, headers = {} } = await getAllOverdueInvoices({
      token,
      query,
    });

    if (error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setOverdueInvoices(data);
    setTotal(+headers['x-total-count']);
    hideLoading();
  }, 1000);

  useEffect(() => {
    setQuery({
      supplier: '',
      invoiceNumber: '',
      admin: '',
      limit: '50',
      offset: '0',
      type: '',
      numberAutoOverdue: '',
    });
  }, []);

  useEffect(() => {
    fetchOverdueInvoices();
  }, [query]);

  function onFilter(queryDiff) {
    setQuery(queryDiff);
  }

  return (
    <OverdueInvoicesPresentational
      data={overdueInvoices}
      query={query}
      total={total}
      onFilter={onFilter}
    />
  );
};

export default MyOverdueInvoices;
