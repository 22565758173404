import React from 'react';
import {
  ContractEONType,
  DataTable,
  DateTimeField,
  Field,
} from '../../commons/DataTable';
import CustomerTypeField from '../../commons/DataTable/CustomerTypeField';
import { useTranslation } from 'react-i18next';
import { refactoringAdminLevels } from '../../lib/helpers/refactoringAdminLevels';

function AlboTable({ data, total, offset, limit, onFilter }) {
  const [t] = useTranslation('ALBO');

  const refactorData = data.map((profile) => {
    return {
      ...profile,
      type: refactoringAdminLevels(profile.type),
    };
  });

  return (
    <DataTable
      data={refactorData}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field
        title="Ragione Sociale"
        source="businessName"
        className="text-left"
      />
      <Field title="Indirizzo" source="address" />
      <Field title="P.Iva/Codice Fiscale" source="taxCode" />
      {/* <Field title="Email" source="emailReference" /> */}
      <Field title="Tipologia" source="type" className="type" />
    </DataTable>
  );
}

export default AlboTable;
