import React from 'react';
import { BtnModalTable } from '../../../styled-components';

function ActionsField({ data, onViewInvoice }) {
  return (
    <>
      {data.pinvInvoice[0]?.invoicePinv?.number && (
        <BtnModalTable
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onViewInvoice(data.pinvInvoice[0]._id);
          }}
        >
          {data.pinvInvoice[0]?.invoicePinv?.status === 'to_deliver'
            ? 'BOZZA '
            : ''}
          FATTURA {data.pinvInvoice[0]?.invoicePinv?.number}
        </BtnModalTable>
      )}
    </>
  );
}

export default ActionsField;
