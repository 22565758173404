import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { usePagination, DOTS } from './usePagination';
import './Pagination.scss';

const REACT_APP_DEFAULT_PAGINATION_LIMIT = parseInt(
  process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT || 20
);

function PaginationCustom({
  total = 0,
  offset = 0,
  limit = REACT_APP_DEFAULT_PAGINATION_LIMIT,
  onFilter,
}) {
  const currentPage = offset / limit + 1;

  const paginationRange = usePagination({
    currentPage: offset / limit + 1,
    totalCount: total,
    siblingCount: 3,
    pageSize: limit,
  });

  const setOffset = (offset = 0) => onFilter({ offset, limit });

  if (total <= REACT_APP_DEFAULT_PAGINATION_LIMIT) {
    return null;
  }

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  return (
    <div className="cont-pagination">
      <Pagination>
        <ul className="pagination-container">
          <PaginationItem disabled={parseInt(offset) === 0}>
            <PaginationLink
              previous
              onClick={() => setOffset(offset - limit)}
            />
          </PaginationItem>
          {paginationRange.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return (
                <PaginationItem disabled={true} key={index}>
                  <PaginationLink>...</PaginationLink>
                </PaginationItem>
              );
            }
            return (
              <PaginationItem key={index} active={pageNumber === currentPage}>
                <PaginationLink
                  onClick={() => setOffset((pageNumber - 1) * limit)}
                >
                  {pageNumber}
                </PaginationLink>
              </PaginationItem>
            );
          })}
          <PaginationItem disabled={offset + limit >= total}>
            <PaginationLink next onClick={() => setOffset(offset + limit)} />
          </PaginationItem>
        </ul>
      </Pagination>
    </div>
  );
}

PaginationCustom.propTypes = {
  total: PropTypes.number,
  offset: PropTypes.number,
  limit: PropTypes.number,
  onFilter: PropTypes.func.isRequired,
};

export default PaginationCustom;
