import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Row,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
} from '../../styled-components';
import { FormText } from 'reactstrap/lib';
import DateInput from '../../commons/DateInput';

function ActionsField(props) {
  const { onCollectInvoice, data } = props;
  const [t] = useTranslation('PAGES');
  const [formError, setFormError] = useState(false);
  const [modal, setModal] = useState(false);
  const [collectedDate, setCollectedDate] = useState(
    data?.sailInvoice?.collectedDate || undefined
  );

  const toggle = () => {
    setModal(!modal);
    setCollectedDate(undefined);
    setFormError(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!collectedDate) return setFormError(true);
    onCollectInvoice({
      invoice: {
        ...data,
        sailInvoice: { ...data.sailInvoice, collected: true, collectedDate },
      },
    });
    toggle();
  };

  return (
    <React.Fragment>
      <div className="flex-check">
        <BtnModalTable
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            toggle();
          }}
          disabled={
            !!data?.sailInvoice?.collectedDate ||
            data?.invoicePinv?.status === 'to_deliver'
          }
        >
          INCASSA
        </BtnModalTable>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('COLLECT_INVOICE')}</ModalHeader>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <div className="max-width-form">
              <FormGroup>
                <Row>
                  <Label>{t('CONTACTS:RECEIPT_DATE')}</Label>
                </Row>
                <Row>
                  <DateInput
                    className="text-center"
                    name="collectedDate"
                    value={collectedDate}
                    setMidday
                    returnDateObject
                    onChange={(date) => {
                      setCollectedDate(date);
                    }}
                    disablePopup
                  />
                  {formError && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </Row>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default ActionsField;
