import React from 'react';
import { isIAFCheckboxCheckable } from '../../lib/helpers/isCheckboxCheckable';

function IAFCustomCheckboxes({ data = {} }) {
  return (
    <>
      {isIAFCheckboxCheckable(data) ? (
        <input
          type="checkbox"
          onChange={(e) => {
            e.stopPropagation();
            data.checked = !data.checked;
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default IAFCustomCheckboxes;
