import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';

import { BtnOutlineGreen } from '../../styled-components';
import _ from 'lodash';
import { getValidationErrorMessage } from './helpers';
import ExportCSVRow from './ExportCSVRow';

function CustomersImportFeedbackModal({ toggle, isOpen, data = {} }) {
  const [t] = useTranslation('CUSTOMER_EXTRA');

  const {
    errors = {},
    insertedCustomers,
    validationErrors: topValidationErrors,
    updatedCustomers,
  } = data;
  let validationErrors = false;
  if (topValidationErrors && Array.isArray(topValidationErrors.message)) {
    validationErrors = topValidationErrors.message;
  }
  if (
    topValidationErrors &&
    topValidationErrors.message &&
    !Array.isArray(topValidationErrors.message)
  ) {
    validationErrors = [topValidationErrors.message];
  }

  const { customersAlredyInserted = [], customersWithNoAdmin = [] } = errors;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {validationErrors
          ? t('MALFORMED_FILE')
          : t('IMPORTED_CUSTOMERS_FEEDBACK')}
      </ModalHeader>

      {!validationErrors && (
        <ModalBody>
          <Row>
            <Col sm="12">
              <h5>
                {t('INSERTED_CUSTOMERS_NUMBER')}{' '}
                {insertedCustomers?.length || 0}
              </h5>
            </Col>
          </Row>
          {/* <Row>
            <Col sm="12">
              <h5>
                {t('UPDATED_CUSTOMERS_NUMBER')} {updatedCustomers?.length || 0}
              </h5>
            </Col>
          </Row> */}
          <Row>
            <Col sm="12">
              <h5>
                {t('NOT_INSERTED_CUSTOMERS_NUMBER')}:{' '}
                {_.uniqWith(
                  [...customersAlredyInserted, ...customersWithNoAdmin],
                  _.isEqual
                ).length || 0}
              </h5>
              <ul>
                <ExportCSVRow
                  data={customersAlredyInserted}
                  text={t('CUSTOMERS_ALREDY_INSERTED')}
                />
                <ExportCSVRow
                  data={customersWithNoAdmin}
                  text={t('CUSTOMERS_ADMIN_NOT_FOUND')}
                />
              </ul>
            </Col>
          </Row>
        </ModalBody>
      )}
      {validationErrors && (
        <ModalBody>
          <Row>
            <Col sm="12">
              {validationErrors.map((v) => (
                <h5>{getValidationErrorMessage({ message: v })}</h5>
              ))}
            </Col>
          </Row>
        </ModalBody>
      )}
      <ModalFooter>
        <BtnOutlineGreen type="button" className="uppercase" onClick={toggle}>
          {t('COMMON:CLOSE')}
        </BtnOutlineGreen>
      </ModalFooter>
    </Modal>
  );
}

export default CustomersImportFeedbackModal;
