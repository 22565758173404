import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  CurrencyField,
  PaymentsDataField,
  Field,
  ContractEONType,
  DateTimeField,
} from '../../../commons/DataTable';
import { useAuth } from '../../../commons/Auth';
import IAFCustomCheckboxes from '../../../commons/Checkboxes/IAFCustomCheckboxes';

function EnergyRoyaltyTableBackoffice({
  dataTable,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
  bottomRow,
  showEmptyTableMessage,
  truncateTable,
  allowedRoles = [],
  onSelectChange,
}) {
  const [t] = useTranslation('COMMON');
  const { offset, limit } = query;
  const [{ isAdmin, userType = '' }] = useAuth();

  const onCheck = (report) => {
    if (!allowedRoles.includes('superadmin')) {
      return;
    }
    onSelectChange(report);
  };

  return (
    <DataTable
      offset={offset}
      limit={limit}
      total={total}
      dataTable={dataTable}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
      bottomRow={bottomRow}
      showEmptyTableMessage={showEmptyTableMessage}
    >
      {!truncateTable &&
        (isAdmin ? (
          <IAFCustomCheckboxes
            onSelectChange={onCheck}
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <></>
        ))}

      {!truncateTable && (
        <Field title="Nome Cliente" source="condo_name" className="text-left" />
      )}
      {!truncateTable && (
        <Field title="Partita IVA Cliente" source="condo_vat_cf" />
      )}
      {!truncateTable && (
        <ContractEONType title="Nome prodotto" source="product_name" />
      )}
      {!truncateTable && <Field title="POD/PDR" source="pod_pdr" />}
      {!truncateTable && (
        <Field title="Consumo attuale" source="actual_consumption" />
      )}
      {!truncateTable && (
        <Field title="Consumo da contratto" source="contractual_consumption" />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Data creazione"
          source="contract_signing_date"
          format="DD/MM/YYYY"
        />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Data attivazione"
          source="activation_start_date"
          format="DD/MM/YYYY"
        />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Data Scadenza"
          source="contract_end_date"
          format="DD/MM/YYYY"
        />
      )}

      <CurrencyField
        title="Imponibile"
        source="net"
        className="txt-table-right"
      />
      <CurrencyField
        title="Importo da Incassare"
        source="net"
        className="txt-table-right"
      />
      <PaymentsDataField title="Pagato" source="payments" type="total" />
    </DataTable>
  );
}

export default withRouter(EnergyRoyaltyTableBackoffice);
