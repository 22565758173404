import React from 'react';
import { formatCurrency } from '../../../lib/helpers/formatters';

const TotalTr = ({
  totals = {},
  route = '',
  activeRoute = false,
  truncateTotals = false,
}) => {
  const { contractAmount = 0, masterContractAmount = 0, revenue = 0 } = totals;

  return (
    <tr
      className="summary-tr"
      onClick={() => {
        return activeRoute === true
          ? (window.location.href = `${
              window.location.href.split('?')[0]
            }/${route}`)
          : null;
      }}
    >
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      <td className="txt-table-right">{formatCurrency(contractAmount)}</td>
      {!truncateTotals && <td>-</td>}
      <td className="txt-table-right">
        {formatCurrency(masterContractAmount)}
      </td>
      <td className="txt-table-right">{formatCurrency(revenue)}</td>
      <td>-</td>
    </tr>
  );
};

export default TotalTr;
