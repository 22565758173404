import React from 'react';
import styled from 'styled-components';
import variables from '../../variables';

const Icon = styled.i`
  color: ${variables.red};
  font-size: 1.3rem;
`;

export default (props) => {
  return <Icon className={props.icon} />;
};
