import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card,
  Button,
  CardFooter,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CustomInput,
} from 'reactstrap';
import useForm from 'react-hook-form';
import LogoHeader from '../../commons/LogoHeader';
import './Signup.scss';
import { H1Login } from '../../styled-components';
import { useTranslation } from 'react-i18next';

function SignupPresentational({ match, onSignup }) {
  const { handleSubmit, register, errors } = useForm();
  const [t] = useTranslation();
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">
          <H1Login>
            Non hai ancora un account?
            <br />
            <span>Registrati ora</span>
          </H1Login>

          <Card className="u-center-horizontal login-card">
            <CardBody>
              <Form onSubmit={handleSubmit(onSignup)}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    name="email"
                    innerRef={register({
                      required: 'Campo obbligatorio',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Indirizzo email non valido',
                      },
                    })}
                  />
                  {errors.email && errors.email.message && (
                    <FormText>{errors.email.message}</FormText>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label>Password</Label>
                  <div style={{ position: 'relative' }}>
                    <Input
                      type={isPasswordVisible ? 'text' : 'password'}
                      name="password"
                      innerRef={register({
                        required: 'Campo obbligatorio',
                        minLength: {
                          value: 8,
                          message: t('COMMON:INVALID_LENGTH_PASSWORD'),
                        },
                        maxLength: {
                          value: 20,
                          message: t('COMMON:INVALID_LENGTH_PASSWORD'),
                        },
                        pattern: {
                          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/,
                          message:
                            'La password deve contenere almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale',
                        },
                      })}
                    />

                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="visible-password-button"
                    >
                      {isPasswordVisible ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-eye-slash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                          <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                          <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-eye"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                        </svg>
                      )}
                    </button>
                  </div>
                  {errors.password && errors.password.message && (
                    <FormText className="error-message">
                      {errors.password.message}
                    </FormText>
                  )}
                </FormGroup>

                <FormGroup>
                  <div>
                    <CustomInput
                      type="checkbox"
                      id="Checkbox"
                      label="Accetta i termini e le condizioni di utilizzo"
                    />
                  </div>
                </FormGroup>
                <Button className="u-center-horizontal">Registrati</Button>
              </Form>
            </CardBody>
            <CardFooter>
              <div className="row">
                <div className="col-6">Hai già un account?</div>
                <div className="col-6 text-right">
                  <Link to={'/'}>
                    Login <i className="fas fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </CardFooter>
          </Card>
        </div>
      </section>
    </div>
  );
}

SignupPresentational.propTypes = {
  onSignup: Proptypes.func.isRequired,
};

export default SignupPresentational;
