import React from 'react';
import ProvidersPresentational from './ProvidersPresentational';

const ProvidersDetail = (props) => {
  const { profile = {}, reports = {}, query = {}, onFilter = () => {} } = props;

  return (
    <ProvidersPresentational
      data={reports}
      onFilter={onFilter}
      query={query}
      profileType={profile.type}
      {...props}
    />
  );
};

export default ProvidersDetail;
