import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import InsurancesTable from '../Insurances/InsurancesTable';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnRadiusRed,
  PNewItem,
} from '../../styled-components';
import './Insurances.scss';
import { ReactComponent as IconPlus } from '../../images/icn-plus.svg';

const MAX_FILE_SIZE_MB = 1; // 1 MB
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // B

const ModalImportInsurances = (props) => {
  const { onUpload = () => {}, onUploadEnd = () => {} } = props;
  const [t] = useTranslation('ROYALTY');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState();
  const [invoicesPreview, setInvoicesPreview] = useState([]);
  const fileInputRef = useRef();
  const toggle = () => {
    if (modal) {
      // Clean state on close!
      setFile(undefined);
      setInvoicesPreview([]);
    }
    setModal(!modal);
  };

  const selectFile = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const handleFileChange = async ({ target = {} }) => {
    const { files = [] } = target;
    const [file] = files;
    target.value = '';
    if (!file) return;

    const { size } = file;
    if (size > MAX_FILE_SIZE) {
      return alert.error(
        t('INSURANCES:IMPORT_INSURANCES_FILE_SIZE_ERROR', {
          MAX_FILE_SIZE_MB,
        })
      );
    }
    showLoading();
    setFile(file);
    const formData = new FormData();
    formData.append('file', file);
    const uploadResult = await onUpload(formData);
    if (!uploadResult) return hideLoading();

    const { data = [], error } = uploadResult;
    if (error) {
      const { response: errorResponse, message: errorMessage } = error;
      const { data: errorData } = errorResponse || {};
      const { message: dataMessage } = errorData || {};
      alert.error(dataMessage || errorMessage || t('COMMON:GENERIC_ERROR'));
      return hideLoading();
    }

    setInvoicesPreview(data);
    hideLoading();
    toggle();
  };

  const confirmPreview = async () => {
    if (!file) return toggle();

    showLoading();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('writeToDB', 'true');
    const uploadResult = await onUpload(formData);
    hideLoading();
    if (!uploadResult) return toggle();

    const { data, error } = uploadResult;
    const howMany = (data && data.imported) || 0;
    if (error) {
      const { response: errorResponse, message: errorMessage } = error;
      const { data: errorData } = errorResponse || {};
      const { message: dataMessage } = errorData || {};
      alert.error(dataMessage || errorMessage || t('COMMON:GENERIC_ERROR'));
    } else {
      alert.success(
        howMany === 1
          ? t('INSURANCES:IMPORT_SUCCESS_SINGULAR')
          : t('INSURANCES:IMPORT_SUCCESS', { howMany })
      );
    }

    onUploadEnd();
    toggle();
  };

  const listenForEscapeEvent = () => {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  };

  return (
    <React.Fragment>
      <BtnRadiusRed onClick={selectFile} type="button">
        <IconPlus />
      </BtnRadiusRed>
      <PNewItem
        onClick={selectFile}
        style={{ marginLeft: '7px', marginRight: '0', cursor: 'pointer' }}
      >
        {t('INSURANCES:IMPORT_INSURANCES')}
      </PNewItem>
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv,text/csv"
        id="importInsurancesFileInput"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <Modal
        isOpen={modal}
        className="new-insurance-modal"
        onOpened={listenForEscapeEvent}
      >
        <ModalHeader toggle={toggle}>
          {t('INSURANCES:IMPORT_INSURANCES_PREVIEW')}
          <br />
          <i>{t('INSURANCES:IMPORT_INSURANCES_PREVIEW_SUBTITLE')}</i>
        </ModalHeader>
        <ModalBody>
          <InsurancesTable
            data={invoicesPreview}
            onFilter={() => {}}
            query={{
              limit: invoicesPreview.length,
              offset: 0,
            }}
            total={invoicesPreview.length}
          />
        </ModalBody>
        <ModalFooter>
          <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
            {t('COMMON:CANCEL')}
          </BtnOutlineRed>
          <BtnOutlineGreen
            type="button"
            className="uppercase"
            onClick={confirmPreview}
          >
            {t('COMMON:CONFIRM_AND_IMPORT')}
          </BtnOutlineGreen>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ModalImportInsurances;
