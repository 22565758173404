import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import { ContFlexLeft, ContFilterYear } from '../../styled-components';
import SearchInput from '../../commons/SearchInput';
import InvoiceCollapse from './InvoiceCollapse';
import NewInvoiceModal from './NewInvoiceModal';
import { Row, Col, Button } from 'reactstrap';
import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import Form from 'reactstrap/lib/Form';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import { useAuth } from '../../commons/Auth';
import StudioInvoicesImportFeedbackModal from './StudioInvoicesImportFeedbackModal';

function InvoicesPresentational({
  data = [],
  query,
  onFilter,
  searchAdministrators,
  fetchAdminCondos,
  condos,
  customersExtra,
  onNewInvoiceConfirm,
  onInvoiceUpdate,
  onAddPayment,
  onDeletePayment,
  onValidateInvoice,
  onInvoiceDelete,
  toggleShowImportFeedback,
  onImportStudioInvoices,
  studioInvoicesImportFeedbackData,
  hasImportedStudioInvoices,
}) {
  const [t] = useTranslation('INVOICES');
  const [{ profile }] = useAuth();

  function handleImportFile(event) {
    const file = event.target?.files[0];

    const fileSize = (Number(file?.size || 0) / 1024 ** 2).toFixed(2);

    if (fileSize > 20) {
      alert.info('File troppo grande. Sono ammessi file di massimo 20MB');
    } else {
      const data = new FormData();
      data.append('file', file);
      onImportStudioInvoices(data);
    }
  }

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <StudioInvoicesImportFeedbackModal
          data={studioInvoicesImportFeedbackData}
          toggle={toggleShowImportFeedback}
          isOpen={hasImportedStudioInvoices}
        />
        <section>
          <Header
            title={t('PAGES:STUDIO_INVOICES')}
            icon={<IconInvoices fill="#ffffff" style={{ height: '25px' }} />}
          />

          <Row>
            <Col xs={12} style={{ marginBottom: 30 }}>
              <Button
                href={`/examples/Template_Studio_Fatture.csv`}
                target="_blank"
                color="primary"
              >
                SCARICA TEMPLATE IMPORTAZIONE
              </Button>
              <Button
                href={`/documentation/Extra_Importazione_fatture_studio.pdf`}
                target="_blank"
                color="primary"
                style={{ marginLeft: '2rem' }}
              >
                SCARICA DOCUMENTAZIONE IMPORTAZIONE
              </Button>
            </Col>
            <Col sm="12" md="4">
              <ContFlexLeft>
                <NewInvoiceModal
                  administrator={profile}
                  fetchAdminCondos={fetchAdminCondos}
                  condos={condos}
                  customersExtra={customersExtra}
                  onConfirm={onNewInvoiceConfirm}
                />
              </ContFlexLeft>
            </Col>
          </Row>
          <Form className="m-t-20">
            <Row className="compensi-variabili-resp">
              <Col lg="6" xl="3">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectYear"
                          name="year"
                          value={query && query.year}
                          onChange={({ target: { value: year } }) =>
                            onFilter({ year })
                          }
                        >
                          {generateYearOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
              <Col lg="6" xl="3">
                <SearchInput
                  query={{ search: query && query.number }}
                  onFilter={({ search }) => onFilter({ number: search })}
                  label={t('SEARCH:INVOICE_NUMBER')}
                />
              </Col>
              <Col lg="6" xl="3">
                <SearchInput
                  query={{ search: query && query.customer }}
                  onFilter={({ search }) => onFilter({ customer: search })}
                  label={t('SEARCH:CUSTOMER')}
                />
              </Col>
              <Col sm="6" md="6" lg="3" xl="3">
                <FormGroup>
                  <CustomInput
                    type="file"
                    id="importFilee"
                    name="importFilee"
                    onChange={(event) => handleImportFile(event)}
                    accept=".csv"
                  />
                  <Label for="selectFile">
                    {t('ENERGY_CONTRACT:CHOOSE_FILE')}
                    &nbsp;({t('ENERGY_CONTRACT:MAX_UPLOAD_FILE_SIZE')})
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </section>

        <section>
          {data.map((invoice, index) => (
            <InvoiceCollapse
              key={index}
              invoice={invoice}
              condos={condos}
              customersExtra={customersExtra}
              searchAdministrators={searchAdministrators}
              onUpdate={onInvoiceUpdate}
              onAddPayment={onAddPayment}
              onDeletePayment={onDeletePayment}
              onValidateInvoice={onValidateInvoice}
              onInvoiceDelete={onInvoiceDelete}
            />
          ))}
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default InvoicesPresentational;
