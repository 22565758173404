import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import forgot from '../../lib/fetch/forgot';
import ForgotPresentational from './ForgotPresentational';

function Forgot(props) {
  const [forgotSuccess, setForgotSuccess] = useState(false);
  const alert = useAlert();
  const { t } = useTranslation('FORGOT');
  const onForgot = async ({ email = '' }) => {
    const { error } = await forgot(email);

    if (error) {
      setForgotSuccess(false);
      alert.error(t('ERROR'));
      return;
    }

    setForgotSuccess(true);
  };

  return (
    <ForgotPresentational
      onForgot={onForgot}
      forgotSuccess={forgotSuccess}
      {...props}
    />
  );
}

export default Forgot;
