import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  Field,
  CurrencyField,
  DateTimeField,
  PaymentsDataField,
  BilledField,
} from '../../../commons/DataTable';
import IAFCustomCheckboxes from '../../../commons/Checkboxes/IAFCustomCheckboxes';
import { useAuth } from '../../../commons/Auth';
import CommercialBilledField from '../../../commons/DataTable/CommercialBilledField';
import { USER_ADMIN_TYPES } from '../../../App';

function RoyaltyTable({
  dataTable,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
  bottomRow,
  showEmptyTableMessage,
  truncateTable,
  onSelectChange,
  allowedRoles = [],
}) {
  const [t] = useTranslation('COMMON');
  const [{ isAdmin, userType = '' }] = useAuth();
  const { offset, limit } = query;

  const onCheck = (report) => {
    if (!allowedRoles.includes('superadmin')) {
      return;
    }
    onSelectChange(report);
  };

  return (
    <DataTable
      offset={offset}
      limit={limit}
      total={total}
      dataTable={dataTable}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
      bottomRow={bottomRow}
      showEmptyTableMessage={showEmptyTableMessage}
    >
      {!truncateTable &&
        (isAdmin ? (
          <IAFCustomCheckboxes
            onSelectChange={onCheck}
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <></>
        ))}
      {!truncateTable && (
        <Field
          title="Nome Fornitore"
          source="supplier.businessName"
          className="text-left"
        />
      )}
      {!truncateTable && <Field title="Numero fattura" source="number" />}
      {!truncateTable && (
        <DateTimeField
          title="Data inizio"
          source="startDate"
          format="DD/MM/YYYY"
        />
      )}
      {!truncateTable && (
        <DateTimeField title="Data fine" source="endDate" format="DD/MM/YYYY" />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Mese Rend."
          source="accountability"
          format="MM/YYYY"
        />
      )}
      {!truncateTable && (
        <Field
          title="Amministratore di Condominio"
          source="admin.businessName"
        />
      )}
      <CurrencyField
        title="Imponibile"
        source="net"
        className="txt-table-right"
      />
      <CurrencyField
        title="Importo da Incassare"
        source="gross"
        className="txt-table-right"
      />
      <PaymentsDataField title="Pagato" source="payments" type="total" />
      {(userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
        <CurrencyField
          title={`Quota commerciale`}
          source="commercialShare"
          className="txt-table-right"
        />
      )) || <></>}
      {(userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
        <CurrencyField
          title="Quota commerciale maturata"
          source="earnedCommercialShare"
          className="txt-table-right"
        />
      )) || <></>}
      {(userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
        <CurrencyField
          title="Diritto a Fatturare"
          source="billableCommercialShare"
          className="txt-table-right"
        />
      )) || <></>}

      <CommercialBilledField
        title="Invitato a Fatturare"
        className="txt-table-right"
        visible={
          !truncateTable && isAdmin && userType === USER_ADMIN_TYPES.SUPER_ADMIN
        }
      />
      <Field
        title="Numero IAF"
        className="txt-table-right"
        source="iafs.0.number"
        visible={
          !truncateTable && isAdmin && userType === USER_ADMIN_TYPES.SUPER_ADMIN
        }
      />
    </DataTable>
  );
}

export default withRouter(RoyaltyTable);
