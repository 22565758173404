import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  FormText,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
} from '../../styled-components';
import DateInput from '../DateInput';

function AddPaymentDateField(props) {
  const { data, onAddPaymentDate } = props;
  const [t] = useTranslation('IAF_INVOICES');
  const [modal, setModal] = useState(false);
  const [date, setDate] = useState(data.paymentDate);
  const toggle = () => setModal(!modal);

  function onConfirm() {
    if (date) {
      const { _id: bonusId } = data;
      onAddPaymentDate({ bonusId, date });
      toggle();
    }
  }

  const { paymentDate } = data;

  return (
    <React.Fragment>
      {!paymentDate && (
        <div className="flex-check">
          <BtnModalTable
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              toggle();
            }}
          >
            {t('IAF_PAYMENT_INCOME')}
          </BtnModalTable>
        </div>
      )}

      {!!paymentDate ? (
        <p className="regolarizzata">{t('PAID')}</p>
      ) : (
        <p className="non-regolarizzata">{t('NOT_PAID')}</p>
      )}

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('MODAL_PAYMENT_DATE_TITLE')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onConfirm();
          }}
        >
          <ModalBody>
            <div className="max-width-form">
              <FormGroup>
                <Label>{t('PAYMENT_DATE_LABEL')} *</Label>
                <DateInput
                  name="paymentDate"
                  value={date}
                  onChange={(paymentDate) => setDate(paymentDate)}
                />
                {!date && (
                  <FormText className="error-message">
                    {t('COMMON:REQUIRED')}
                  </FormText>
                )}
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default AddPaymentDateField;
