import React from 'react';
import plusIcon from '../../images/icn-plus.svg';
import Spinner from './../Spinner';
import './SearchAddCont.scss';
import { ContFlexRight, PNewItem, BtnRadiusRed } from '../../styled-components';

function AddButton({ isLoading, onAdd, addLabel }) {
  return isLoading ? (
    <Spinner />
  ) : (
    <ContFlexRight>
      <PNewItem>{addLabel}</PNewItem>
      <BtnRadiusRed onClick={onAdd}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
          class="bi bi-plus-lg"
          viewBox="0 0 16 16"
          style={{ fill: 'white', width: '25px', height: '25px' }}
        >
          <path
            fill-rule="evenodd"
            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
          />
        </svg>
      </BtnRadiusRed>
    </ContFlexRight>
  );
}

export default AddButton;
