import React from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { formatCurrencyForCSV as formatCurrency } from '../../../lib/helpers/formatters';
import { useAuth } from '../../../commons/Auth';

export default (props) => {
  const [{ isAdmin }] = useAuth();
  const { children, data = [], year, businessName, subAdmins = false } = props;

  let csvData, invoices;

  csvData = [
    [
      'NOME FORNITORE',
      'NUMERO FATTURA',
      'DATA FATTURA',
      'MESE RENDICONTAZIONE',
      'AMMINISTRATORE DI CONDOMINIO',
      'IMPONIBILE',
      'IMPORTO DA INCASSARE',
      'INCASSI',
      'QUOTA AMMINISTRATORE',
      '% QUOTA AMMINISTRATORE',
      'QUOTA AMMINISTRATORE MATURATA',
      'DIRITTO A FATTURARE',
    ],
  ];

  if (isAdmin) csvData[0].push('INVITATO A FATTURARE');

  invoices = data;

  invoices.forEach((compenso = {}) => {
    const {
      supplier: { businessName: supplierName = '' } = {},
      number,
      date,
      accountability,
      admin: { businessName: adminName = '' } = {},
      gross,
      net,
      payments = [],
      adminShare,
      earnedAdminShare,
      billableAdminShare,
      iafs = [],
    } = compenso;

    const row = [
      // 'NOME FORNITORE',
      supplierName,
      // 'NUMERO FATTURA',
      number,
      // 'DATA FATTURA',
      moment(date).format('DD/MM/YYYY'),
      // 'MESE RENDICONTAZIONE',
      accountability ? moment(accountability).format('MM/YYYY') : '',
      // 'AMMINISTRATORE DI CONDOMINIO',
      adminName,
      // 'IMPONIBILE',
      formatCurrency(net),
      // 'IMPORTO DA INCASSARE',
      formatCurrency(gross),
      // 'INCASSI',
      formatCurrency(payments.reduce((acc, val) => acc + val.gross, 0)),
      // 'QUOTA AMMINISTRATORE',
      formatCurrency(adminShare || 0),
      // '% QUOTA AMMINISTRATORE',
      subAdmins ? '1' : '10',
      // 'QUOTA AMMINISTRATORE MATURATA',
      formatCurrency(earnedAdminShare || 0),
      // 'DIRITTO A FATTURARE (AMMINISTRATORE)'
      formatCurrency(billableAdminShare || 0),
    ];

    if (isAdmin) {
      const iafAmount = (iafs || []).reduce((acc, iaf = {}) => {
        return acc + ((iaf && iaf.amount) || 0);
      }, 0);
      row.push(formatCurrency(iafAmount));
    }

    csvData.push(row);
  });

  return (
    <CSVLink
      data={csvData}
      filename={
        subAdmins
          ? `COMPENSI_DA_FATTURE_SUBORDINATI_${businessName}_${year}.csv`
          : `COMPENSI_DA_FATTURE_FORNITORI_${businessName}_${year}.csv`
      }
      separator={';'}
    >
      {children}
    </CSVLink>
  );
};
