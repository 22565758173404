import React from 'react';
import { useEffect, useState } from 'react';
import { useAuth } from '../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import axios from 'axios';
import ClientsPresentational from './ClientsPresentational';
import clientsAPIs from '../../lib/fetch/clientsApi';

const Clients = ({ profile }) => {
  const [customers, setCustomers] = useState([]);

  const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;
  const [total, setTotal] = useState(0);
  const [{ token, isAdmin } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  //la query me la vedo dopo
  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    profileId: withDefault(StringParam, ''),
    referenceId: withDefault(StringParam, ''),
    businessName: withDefault(StringParam, ''),
    taxCode: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    profileId,
    referenceId,
    businessName,
    taxCode,
  } = query;

  useEffect(() => {
    fetchCustomers();
  }, [limit, offset, profileId, referenceId, businessName, taxCode]);

  const fetchCustomers = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/customer-extra`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          businessName,
          taxCode,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setCustomers(data);
    setTotal(+headers['x-total-count']);
  };

  function onFilter(queryDiff) {
    if (
      (queryDiff.businessName && queryDiff.businessName !== businessName) || // first search
      (businessName && queryDiff.businessName === '') || // search deleted
      (queryDiff.taxCode && queryDiff.taxCode !== taxCode) || // first search
      (taxCode && queryDiff.taxCode === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  const createClient = async (data) => {
    delete data.admin;
    delete data.estateAgent;
    data = {
      ...data,
      referenceId: profile._id,
    };
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/customer-extra`,
      data,
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchCustomers();
  };

  const checkTaxCode = async (taxCode, profileId) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/customer-extra/checkTaxCode`,
      {
        taxCode: taxCode,
        profileId: profileId,
      },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      throw new Error('Errore nel controllo della partita IVA');
    }

    return response.data;
  };

  const searchAdministrators = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/admins`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  const [hasImportedClients, setHasImportedClients] = useState(false);
  const [clientsImportFeedbackData, setClientsImportFeedbackData] = useState(
    {}
  );
  const toggleShowImportFeedback = () => setHasImportedClients((v) => !v);

  const onImportClients = async (file) => {
    showLoading();
    const { error, data } = await clientsAPIs.importClients({
      token,
      file,
    });
    hideLoading();
    toggleShowImportFeedback();
    setClientsImportFeedbackData({
      ...data,
      validationErrors: error?.response?.data,
    });
    fetchCustomers();
    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
    } else {
      alert.success(t('COMMON:SUCCESS_UPLOAD'));
    }
  };

  const searchEstateAgents = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/estateAgents`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  return (
    <ClientsPresentational
      data={customers}
      profile={profile}
      isAdmin={isAdmin}
      query={query}
      total={total}
      onFilter={onFilter}
      searchAdministrators={searchAdministrators}
      onNewClientConfirm={createClient}
      checkTaxCode={checkTaxCode}
      onImportClients={onImportClients}
      toggleShowImportFeedback={toggleShowImportFeedback}
      clientsImportFeedbackData={clientsImportFeedbackData}
      hasImportedClients={hasImportedClients}
      searchEstateAgents={searchEstateAgents}
    />
  );
};

export default Clients;
