import './Table.scss';
import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Table as ReactstrapTable } from 'reactstrap';
import get from 'lodash.get';
import { DataTableHeader } from './DataTableHeader';
import { useTranslation } from 'react-i18next';

function EmptyRow({ colSpan = 1, text = '' }) {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="text-center">{text}</div>
      </td>
    </tr>
  );
}

function Row({ children, data, index, onRowClick }) {
  const [t] = useTranslation('CONTACTS');
  const childrenComponents = Array.isArray(children) ? children : [children];
  const hasOnRowClick = typeof onRowClick === 'function';

  return (
    <tr
      className={hasOnRowClick ? '' : 'no-pointer'}
      onClick={() => hasOnRowClick && onRowClick(data, index)}
    >
      {childrenComponents.map((child = {}, key) => {
        let { props = {} } = child;
        const { source = {} } = props;
        let value = source && typeof data === 'object' && get(data, source, '');

        if (value === '') value = '-';

        if (source === 'type') {
          value = t(value);
        }
        if (source === 'action') {
          value = t(`LOGS:${value.toUpperCase()}`);
        }

        if (
          source === 'master' ||
          source === 'activeAffiliation' ||
          source === 'isActive'
        ) {
          const newProps = { ...props };

          if (value) {
            value = 'SI';
            newProps.className = 'master-yes';
          } else {
            value = 'NO';
            newProps.className = 'master-no';
          }

          props = newProps;
        }

        if (typeof props.clickCallback === 'function') {
          const newProps = { ...props };

          newProps.onClick = (e) => {
            if (!e.target.title) {
              props.clickCallback(index);
            }
          };

          props = newProps;
        }

        return (
          <td key={key} {...props}>
            {cloneElement(child, { ...props, value, data, index })}
          </td>
        );
      })}
    </tr>
  );
}

function Table(props = {}) {
  const {
    children: childrenProp,
    data = [],
    isLoading = false,
    loadingText = 'Loading',
    emptyText = 'No data',
    onRowClick,
    bottomRow,
    topRow,
    showEmptyTableMessage,
    onSort,
    orderBy = '',
    order = 'asc',
  } = props;

  const children =
    !childrenProp || Array.isArray(childrenProp)
      ? childrenProp
      : [childrenProp];

  const getData = () => {
    return data.length > 0 ? (
      data.map((row, key) => (
        <Row key={key} data={row} index={key} onRowClick={onRowClick}>
          {children.filter(
            (child) => child.props.visible || child.props.visible === undefined
          )}
        </Row>
      ))
    ) : showEmptyTableMessage === false ? null : (
      <EmptyRow colSpan={children.length} text={emptyText} />
    );
  };

  return (
    <ReactstrapTable responsive hover className="data-table">
      <DataTableHeader onSort={onSort} orderBy={orderBy} order={order}>
        {children.filter(
          (child) =>
            child !== false &&
            (child.props.visible || child.props.visible === undefined)
        )}
      </DataTableHeader>

      <tbody>
        {isLoading ? (
          <EmptyRow colSpan={children.length} text={loadingText} />
        ) : (
          <React.Fragment>
            {topRow && topRow}
            {getData()}
            {bottomRow && bottomRow}
          </React.Fragment>
        )}
      </tbody>
    </ReactstrapTable>
  );
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  emptyText: PropTypes.string,
  onRowClick: PropTypes.func,
  showEmptyTableMessage: PropTypes.bool,
};

export default Table;
