import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconPolizza } from '../../images/icn_polizza.svg';
import {
  ContFlexLeft,
  BtnRadiusWhite,
  ContFilterYear,
  ContPaginationPage,
} from '../../styled-components';
import SearchInput from '../../commons/SearchInput';
import { Row, Col } from 'reactstrap';

import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../lib/helpers/generateMonthOptions';
import InsurancesTable from './InsurancesTable';
import variables from '../../variables';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import { ADMIN_TYPES, AGENT_TYPES } from '../../App';

function InsurancesPresentational({
  data = [],
  profileScore,
  query,
  total,
  onFilter,
  profile,
}) {
  const [t] = useTranslation('INVOICES');
  const { offset, limit } = query;

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('INSURANCES:TITLE')}
            icon={<IconPolizza fill={variables.white} />}
            profileScore={profileScore}
          />
        </section>

        <section>
          <Row className="align-items-center">
            {ADMIN_TYPES.includes(profile.type) && (
              <Col className="">
                <ContFlexLeft>
                  {t('SCORE:INSURANCES_SCORE')}:&nbsp;
                  <strong>
                    {(profileScore.insuranceScoreType1 || 0) +
                      (profileScore.insuranceScoreType2 || 0)}
                  </strong>
                </ContFlexLeft>
              </Col>
            )}
          </Row>
          <Row className="compensi-variabili-resp">
            <Col sm="12" md="6">
              <ContFlexLeft className="show-invoices">
                <BtnRadiusWhite
                  className={
                    query.all === 'false' ? 'eye-toggle show-me' : 'eye-toggle'
                  }
                  onClick={() =>
                    onFilter({
                      all: query.all === 'true' ? 'false' : 'true',
                    })
                  }
                >
                  <i className="far fa-eye"></i>
                </BtnRadiusWhite>
              </ContFlexLeft>
            </Col>
            <Col sm="12" lg="6" xl="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        value={query && query.year}
                        onChange={({ target: { value: year } }) =>
                          onFilter({ year })
                        }
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>

            <Col lg="6" xl="3">
              <SearchInput
                query={{ search: query && query.insuranceNumber }}
                onFilter={({ search }) => onFilter({ insuranceNumber: search })}
                label={t('SEARCH:INSURANCE_NUMBER')}
              />
            </Col>
            <Col lg="6" xl="4">
              <SearchInput
                query={{ search: query && query.supplier }}
                onFilter={({ search }) => onFilter({ supplier: search })}
                label={t('SEARCH:BY_SUPPLIER')}
              />
            </Col>
            <Col lg="6" xl="4">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectAccountabilityYear"
                        name="accountabilityYear"
                        className="year-select"
                        value={query && query.accountabilityYear}
                        onChange={({
                          target: { value: accountabilityYear },
                        }) => {
                          onFilter({ accountabilityYear });
                        }}
                      >
                        {generateYearOptions(true)}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col lg="6" xl="4">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="accountabilityMonth"
                        name="accountabilityMonth"
                        className="year-select"
                        disabled={!query.accountabilityYear}
                        value={
                          query && query.accountabilityMonth === null
                            ? ''
                            : query.accountabilityMonth
                        }
                        onChange={({
                          target: { value: accountabilityMonth },
                        }) => onFilter({ accountabilityMonth })}
                      >
                        {generateMonthOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
          </Row>
        </section>

        <section className="m-t-20">
          <InsurancesTable
            data={data}
            total={total}
            query={query}
            onFilter={onFilter}
          />

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default InsurancesPresentational;
