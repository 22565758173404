export const isCheckable = (data = {}) => {
  const { iafs = [{}] } = data;

  if (iafs.length > 0) {
    const section = iafs[0].hasOwnProperty('suppliers')
      ? 'suppliers'
      : iafs[0].hasOwnProperty('gnet_admin')
      ? 'gnet_admin'
      : iafs[0].hasOwnProperty('invoices')
      ? 'invoices'
      : iafs[0].hasOwnProperty('insurances')
      ? 'insurances'
      : iafs[0].hasOwnProperty('insurancesStudio')
      ? 'insurancesStudio'
      : '';

    if (section === 'suppliers' || section === 'gnet_admin') {
      const { revenue } = data;

      const totalPaid =
        +iafs
          .reduce(
            (acc, iaf = {}) => acc + (iaf[section][0]?.iafRevenue || 0),
            0
          )
          .toFixed(2) || 0;

      if (totalPaid >= revenue) {
        return false;
      }
    }

    if (section === 'insurances' || section === 'insurancesStudio') {
      const { earnedMasterShare } = data;
      const totalPaid = iafs.reduce(
        (acc, iaf = {}) => acc + (iaf[section][0]?.earnedMasterShare || 0),
        0
      );

      if (totalPaid >= earnedMasterShare) {
        return false;
      }
    }
  }

  return true;
};

export const isIAFCheckboxCheckable = (data = {}) => {
  const { iafs = [{}] } = data;

  if (iafs.length > 0) {
    if (
      iafs[0].hasOwnProperty('invoices') ||
      iafs[0].hasOwnProperty('contracts') ||
      iafs[0].hasOwnProperty('recurrentContracts') ||
      iafs[0].hasOwnProperty('insurances')
    ) {
      if (iafs[0].number) {
        return false;
      }
    }
  } else {
    //Nella rendicontazione bonus, se il campo billableAdminShare è 0, non deve essere selezionabile
    if (
      data.hasOwnProperty('billableAdminShare') ||
      data.hasOwnProperty('billableCommercialShare')
    ) {
      const { billableAdminShare, billableCommercialShare } = data;
      if (billableAdminShare === 0 || billableCommercialShare === 0) {
        return false;
      }
    }
  }
  return true;
};
