import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { DataTable, CurrencyField, Field } from '../../../commons/DataTable';
import { useAuth } from '../../../commons/Auth';

function RevenueCalculationTable({
  dataTable,
  data = {},
  total = 0,
  query = {},
  onFilter,
  isLoading,
  bottomRow,
  showEmptyTableMessage,
  truncateTable,
  onSelectChange,
  allowedRoles = [],
  subAdmins = false,
}) {
  const [t] = useTranslation('COMMON');
  const [{ isAdmin }] = useAuth();
  const { offset, limit } = query;

  data.totInsurancesScore = data?.type1 + data?.type2;
  return (
    <DataTable
      offset={offset}
      limit={limit}
      data={[data]}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
    >
      <Field
        title="N.Condomini"
        source="totalCondos"
        className="txt-table-center"
      />
      <Field
        title="Coefficiente"
        source="INSURANCE_COEFFICIENT"
        className="txt-table-center"
      />
      <CurrencyField
        title="Entrate Totali [Σ(Importo da incassare)]"
        source="totalRevenue"
        className="txt-table-center"
      />
      <CurrencyField
        title="Entrate Attese [N.Condomini * Coefficiente]"
        source="expectedRevenue"
        className="txt-table-center"
      />
      <Field
        title="Tot. Crediti Previsti [(Entrate Totali / Entrate Attese) * 100]"
        source="expectedTotInsurancesScore"
        className="txt-table-center"
      />
      <Field
        title="Tot. Crediti Attuali"
        source="totInsurancesScore"
        className="txt-table-center"
      />
      <Field
        title="Crediti Massimi"
        source="INSURANCE_MAX_POINT"
        className="txt-table-center"
      />
    </DataTable>
  );
}

export default withRouter(RevenueCalculationTable);
