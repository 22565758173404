import React, { useState, useContext, createContext, useMemo } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import './Loading.scss';

const LoadingContext = createContext();

const LoadingProvider = ({ children }) => {
  const [blocking, setBlocking] = useState(false);

  const showLoading = () => setBlocking(true);
  const hideLoading = () => setBlocking(false);

  const loadingContext = useMemo(
    () => ({
      showLoading,
      hideLoading,
    }),
    []
  );

  return (
    <LoadingContext.Provider value={loadingContext}>
      <Modal
        isOpen={blocking}
        backdrop="static"
        keyboard={false}
        centered={true}
        zIndex="10000"
        fade={false}
        contentClassName="blocking-content-modal"
      >
        <ModalBody>
          <div className="blocking-modal-spinner">
            <Spinner />
          </div>
        </ModalBody>
      </Modal>
      {children}
    </LoadingContext.Provider>
  );
};

const useLoading = () => useContext(LoadingContext);

export { LoadingProvider, useLoading };
