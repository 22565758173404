import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useAuth } from '../../commons/Auth';
import ReadonlyAffiliationsPresentational from './ReadonlyAffiliationsPresentational';

function Invoices() {
  const [affiliations, setAffiliations] = useState([]);

  const [t] = useTranslation('DEALS');
  const [auth = {}] = useAuth();
  const { token, profile = {} } = auth;
  const alert = useAlert();

  useEffect(() => {
    const fetchAffiliations = async () => {
      try {
        const {
          data,
        } = await axios.get(
          `${process.env.REACT_APP_API_URL}/affiliations/${profile._id}`,
          { headers: { Authorization: token } }
        );

        setAffiliations(data);
      } catch (error) {
        alert.error(t('COMMON:GENERIC_ERROR'));
      }
    };

    fetchAffiliations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ReadonlyAffiliationsPresentational data={affiliations} />;
}

export default Invoices;
