export const getSection = (data = {}) => {
  const { iafs = [] } = data;

  if (iafs.length === 0) return null;

  if (iafs[0].hasOwnProperty('invoices')) return 'invoices';

  if (iafs[0].hasOwnProperty('insurances')) return 'insurances';

  if (iafs[0].hasOwnProperty('contracts')) return 'contracts';

  if (iafs[0].hasOwnProperty('recurrentContracts')) return 'recurrentContracts';
};

export const getBonusCreationSection = (data = {}) => {
  const section = data.hasOwnProperty('suppliers')
    ? 'suppliers'
    : data.hasOwnProperty('gnet_admin')
    ? 'gnet_admin'
    : data.hasOwnProperty('invoices')
    ? 'invoices'
    : data.hasOwnProperty('insurances')
    ? 'insurances'
    : data.hasOwnProperty('insurancesStudio')
    ? 'insurancesStudio'
    : data.hasOwnProperty('contracts')
    ? 'contracts'
    : data.hasOwnProperty('recurrentContracts')
    ? 'recurrentContracts'
    : '';

  return section;
};
