import React from 'react';
import { DataTable, Field } from '../../../commons/DataTable';
import { useTranslation } from 'react-i18next';

function NetworkTable({ data, total, offset, limit, onFilter }) {
  const [t] = useTranslation('COMMERCIAL');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field
        title="Ragione Sociale"
        source="businessName"
        className="text-left"
      />
      <Field title="Indirizzo" source="address" />
      <Field title="Email" source="emailReference" />
      <Field title="Tipologia" source="type" className="type" />
      <Field
        title="% Fornitori"
        source="commercial.percSuppliers"
        className="type"
      />
      <Field
        title="% Energia"
        source="commercial.percEnergy"
        className="type"
      />
      <Field
        title="% Assicurazioni"
        source="commercial.percInsurance"
        className="type"
      />
    </DataTable>
  );
}

export default NetworkTable;
