import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BtnModalTable,
  BtnOutlineGreen,
  BtnOutlineRed,
} from '../../styled-components';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classNames from 'classnames';

const ModalViewLastMail = (props) => {
  const [t] = useTranslation('OVERDUE_INVOICES');

  const { data } = props;
  const [modal, setModal] = useState(false);
  const [emailInSight, setEmailInSight] = useState('adminMail');

  const toggle = () => {
    setModal(!modal);
  };

  const changeEmailInSight = (email) => {
    setEmailInSight(email);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ marginLeft: '10px' }}>
          {data.adminMailText && data.supplierMailText ? (
            <BtnModalTable
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                toggle();
              }}
            >
              {t('VIEW_MAILS')}
            </BtnModalTable>
          ) : (
            <>-</>
          )}
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('MODAL_TITLE')}</ModalHeader>

        <ModalBody>
          <Nav tabs>
            <NavItem onClick={() => changeEmailInSight('adminMail')}>
              <NavLink
                className={classNames({ active: emailInSight === 'adminMail' })}
              >
                Amministratore Mail
              </NavLink>
            </NavItem>
            <NavItem onClick={() => changeEmailInSight('supplierMail')}>
              <NavLink
                className={classNames({
                  active: emailInSight === 'supplierMail',
                })}
              >
                Fornitore Mail
              </NavLink>
            </NavItem>
          </Nav>
          <div style={{ marginTop: '15px' }}>
            <div
              dangerouslySetInnerHTML={{ __html: data.adminMailText }}
              style={{
                display: emailInSight === 'supplierMail' ? 'none' : 'block',
              }}
            />
            <div
              dangerouslySetInnerHTML={{ __html: data.supplierMailText }}
              style={{
                display: emailInSight === 'adminMail' ? 'none' : 'block',
              }}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
            {t('COMMON:CANCEL')}
          </BtnOutlineRed>
          {/* <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen> */}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalViewLastMail;
