import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportCSVContracts from './ExportCSVContracts';
import ExportCSVValidContracts from './ExportCSVValidContracts';

function ExportCSVButton({ data = [], text }) {
  const [t] = useTranslation();

  if (data.length === 0) return null;
  return <ExportCSVValidContracts data={data} />;
}

export default ExportCSVButton;
