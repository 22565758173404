import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CardFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import LogoHeader from '../../commons/LogoHeader';
import './Reset.scss';
import { ButtonLogin, H1Login, BackButton } from '../../styled-components';
import { ReactComponent as IconBack } from '../../images/icn_back.svg';
import variables from '../../variables';

function ResetPresentational({ match, onReset, resetSuccess }) {
  const { t } = useTranslation('RESET');
  const { errors, handleSubmit, getValues, register } = useForm();
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">
          <H1Login>
            {t('RESET')}
            <br />
            <span>{t('USE_CODE')}</span>
          </H1Login>

          <Card className="u-center-horizontal login-card">
            <Form onSubmit={handleSubmit(onReset)}>
              <CardBody>
                <Link to={'/'}>
                  <BackButton type="button">
                    <IconBack fill={variables.primary} /> Torna al Login
                  </BackButton>
                </Link>
                <br />
                <br />
                <FormGroup>
                  <Label>{t('CODE')}</Label>
                  <Input
                    type="text"
                    name="code"
                    innerRef={register({
                      required: t('COMMON:REQUIRED'),
                      minLength: {
                        value: 5,
                        message: t('INVALID_CODE'),
                      },
                      maxLength: {
                        value: 5,
                        message: t('INVALID_CODE'),
                      },
                    })}
                  />
                  {errors.code && errors.code.message && (
                    <FormText className="error-message">
                      {errors.code.message}
                    </FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>{t('COMMON:PASSWORD')}</Label>
                  <div style={{ position: 'relative' }}>
                    <Input
                      type={isPasswordVisible ? 'text' : 'password'}
                      name="password"
                      innerRef={register({
                        required: 'Campo obbligatorio',
                        minLength: {
                          value: 8,
                          message: t('COMMON:INVALID_LENGTH_PASSWORD'),
                        },
                        maxLength: {
                          value: 20,
                          message: t('COMMON:INVALID_LENGTH_PASSWORD'),
                        },
                        pattern: {
                          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).+$/,
                          message: t('COMMON:ERROR_PASSWORD_STRUCTURE'),
                        },
                      })}
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="visible-password-button"
                    >
                      {isPasswordVisible ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-eye-slash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                          <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                          <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-eye"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                        </svg>
                      )}
                    </button>
                  </div>
                  {errors.password && errors.password.message && (
                    <FormText className="error-message">
                      {errors.password.message}
                    </FormText>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>{t('CONFIRM_PASSWORD')}</Label>
                  <Input
                    type="password"
                    name="confirm"
                    innerRef={register({
                      validate: (value) =>
                        value === getValues().password || t('INVALID_CONFIRM'),
                    })}
                  />
                  {errors.confirm && errors.confirm.message && (
                    <FormText className="error-message">
                      {errors.confirm.message}
                    </FormText>
                  )}
                </FormGroup>

                {resetSuccess && (
                  <FormText className="result-message">
                    {t('SUCCESS_1')}
                    <br />
                    <Link to="/">{t('SUCCESS_2')}</Link>
                  </FormText>
                )}
              </CardBody>
              <CardFooter>
                <ButtonLogin type="submit" className="u-center-horizontal">
                  {t('RESET')}
                </ButtonLogin>
              </CardFooter>
            </Form>
          </Card>
        </div>
      </section>
    </div>
  );
}

ResetPresentational.propTypes = {
  onReset: Proptypes.func.isRequired,
};

export default ResetPresentational;
