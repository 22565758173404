import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  Field,
  DateTimeField,
  CurrencyField,
  DeleteIcon,
} from '../../../commons/DataTable';

function ReceiptsTable({
  data,
  total = 0,
  query = {},
  onFilter = () => {},
  isLoading,
  handleDeletePayment,
}) {
  const [t] = useTranslation('COMMON');
  const { offset } = query;

  return (
    <DataTable
      offset={offset}
      total={total}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
    >
      <DateTimeField title="Data incasso" format="DD/MM/YYYY" source="date" />
      <Field title="Tipologia incasso" source="type" />
      <CurrencyField title="Importo pagato NETTO" source="amountNet" />
      <CurrencyField title="Importo pagato LORDO" source="amountGross" />
      <DeleteIcon
        title="Elimina"
        icon="far fa-trash-alt"
        clickCallback={handleDeletePayment}
      />
    </DataTable>
  );
}

export default withRouter(ReceiptsTable);
