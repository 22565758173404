import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { insurances, insurancesExport } from '../../lib/fetch/dashboard';
import DashboardDetailAssicurazioniPresentational from './DashboardDetailAssicurazioniPresentational';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
} from 'use-query-params';
import axios from 'axios';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT } = process.env;

function DashboardDetailAssicurazioni(props) {
  const { showLoading, hideLoading } = useLoading();
  const [data, setData] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [total, setTotal] = useState(0);
  const [{ token } = {}] = useAuth();
  const { t } = useTranslation('DASHBOARD');
  const alert = useAlert();

  const [selectedInsurances, setSelectedInsurances] = useState([]);

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    adminName: withDefault(StringParam, ''),
    contractor: withDefault(StringParam, ''),
    insuranceNumber: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT || 50),
    paid: withDefault(StringParam, ''),
    gabettiInvoice: withDefault(StringParam, ''),
    year: NumberParam,
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
    invoiceRef: StringParam,
  });

  const {
    limit,
    offset,
    search,
    paid,
    gabettiInvoice,
    adminName,
    contractor,
    insuranceNumber,
    supplier,
    year,
    accountabilityYear,
    accountabilityMonth,
    invoiceRef,
  } = query;

  const fetchInsurances = async () => {
    showLoading();

    const { error, headers, data } = await insurances({
      token,
      year,
      search,
      limit,
      offset,
      paid,
      gabettiInvoice,
      adminName,
      contractor,
      insuranceNumber,
      supplier,
      accountabilityYear,
      accountabilityMonth,
      invoiceRef,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setData(data);
    setSelectedInsurances([]);
    setTotal(Number(headers['x-total-count']));
    hideLoading();
  };

  const fetchInsurancesExport = async () => {
    showLoading();
    setIsReady(false);

    const { error, headers, data } = await insurances({
      limit: 5000,
      offset: 0,
      token,
      year,
      search,
      paid,
      gabettiInvoice,
      adminName,
      contractor,
      insuranceNumber,
      supplier,
      accountabilityYear,
      accountabilityMonth,
      invoiceRef,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setDataExport(data);
    setIsReady(true);
    hideLoading();
  };

  useEffect(() => {
    fetchInsurances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    year,
    search,
    offset,
    limit,
    paid,
    adminName,
    gabettiInvoice,
    contractor,
    insuranceNumber,
    supplier,
    accountabilityYear,
    accountabilityMonth,
    invoiceRef,
  ]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.search && queryDiff.search !== search) || // first search
      (search && queryDiff.search === '') || // search deleted
      (queryDiff.adminName && queryDiff.adminName !== adminName) || // first search
      (adminName && queryDiff.adminName === '') || // search deleted
      (queryDiff.contractor && queryDiff.contractor !== contractor) || // first search
      (contractor && queryDiff.contractor === '') || // search deleted
      (queryDiff.insuranceNumber &&
        queryDiff.insuranceNumber !== insuranceNumber) || // first search
      (insuranceNumber && queryDiff.insuranceNumber === '') || // search deleted
      (queryDiff.supplier && queryDiff.supplier !== supplier) || // first search
      (supplier && queryDiff.supplier === '') ||
      (queryDiff.invoiceRef && queryDiff.invoiceRef !== invoiceRef) ||
      (invoiceRef && queryDiff.invoiceRef === '') ||
      (queryDiff.accountabilityMonth &&
        queryDiff.accountabilityMonth !== accountabilityMonth) ||
      queryDiff.accountabilityMonth === '' ||
      (queryDiff.paid && queryDiff.paid !== paid) ||
      (paid && queryDiff.paid === '') |
        (queryDiff.gabettiInvoice &&
          queryDiff.gabettiInvoice !== gabettiInvoice) ||
      (gabettiInvoice && queryDiff.gabettiInvoice === '') ||
      (queryDiff.limit && queryDiff.limit !== limit) ||
      (queryDiff.year && queryDiff.year !== year) ||
      (search && queryDiff.year === '')
    ) {
      queryDiff.offset = 0; // return to first page
    }
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = ''; // reset month
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  const createGabettiInvoice = async (invoice) => {
    setSelectedInsurances([]);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/gabetti-invoices/insurances`,
      {
        ...invoice,
        insurances: selectedInsurances,
      },
      {
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_CREATE'));

    fetchInsurances();
  };

  function onSelectChange(_id) {
    if (selectedInsurances.includes(_id)) {
      return setSelectedInsurances((selectedInsurances) =>
        selectedInsurances.filter((invoiceId) => invoiceId !== _id)
      );
    }
    setSelectedInsurances((selectedInsurances) => [...selectedInsurances, _id]);
  }

  const onSelectAll = (data) => {
    setSelectedInsurances(data.map(({ _id }) => _id));
  };

  return (
    <DashboardDetailAssicurazioniPresentational
      data={data}
      dataExport={dataExport}
      fetchInsurancesExport={fetchInsurancesExport}
      isReady={isReady}
      setIsReady={setIsReady}
      year={year}
      query={query}
      total={total}
      onFilter={onFilter}
      createGabettiInvoice={createGabettiInvoice}
      selectedInsurances={selectedInsurances}
      onSelectChange={onSelectChange}
      onSelectAll={onSelectAll}
      {...props}
    />
  );
}

export default DashboardDetailAssicurazioni;
