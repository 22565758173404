export const profileScoreFormatterV2 = (profileScore, checkBoxSettings) => {
  const {
    EonScoreIsAuto,
    insuranceScoreType1IsAuto,
    insuranceScoreType2IsAuto,
    supplierScoreIsAuto,
  } = checkBoxSettings;

  let formattedObject = {
    EONScore: profileScore.EONScoreAuto,
    insuranceScore: profileScore.insuranceScoreAuto,
    insuranceScoreType1: profileScore.insuranceScoreType1Auto,
    insuranceScoreType2: profileScore.insuranceScoreType2Auto,
    supplierScore: profileScore.supplierScoreAuto,
    bonusScore: profileScore.bonusScoreAuto,
    energyAndConsumptionScore: profileScore.energyAndConsumptionScoreAuto,
    trainingScore: profileScore.trainingScoreAuto,
    profileId: profileScore.profileId,
    type: profileScore.type,
    EonScoreIsAuto: EonScoreIsAuto,
    insuranceScoreType1IsAuto: insuranceScoreType1IsAuto,
    insuranceScoreType2IsAuto: insuranceScoreType2IsAuto,
    supplierScoreIsAuto: supplierScoreIsAuto,
    trainingCourses: profileScore.trainingCourses || [],
    trainingScore: profileScore.trainingScore || 0,
  };

  if (EonScoreIsAuto === false) {
    formattedObject = {
      ...formattedObject,
      EONScore: profileScore.EONScoreManual,
    };
  }

  if (insuranceScoreType1IsAuto === false) {
    formattedObject = {
      ...formattedObject,
      insuranceScoreType1: profileScore.insuranceScoreType1Manual,
    };
  }

  if (insuranceScoreType2IsAuto === false) {
    formattedObject = {
      ...formattedObject,
      insuranceScoreType2: profileScore.insuranceScoreType2Manual,
    };
  }

  formattedObject = {
    ...formattedObject,
    insuranceScore:
      profileScore.insuranceScoreType1Manual +
      profileScore.insuranceScoreType2Manual,
  };

  if (supplierScoreIsAuto === false) {
    formattedObject = {
      ...formattedObject,
      supplierScore: profileScore.supplierScoreManual,
    };
  }

  return formattedObject;
};
