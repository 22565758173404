import React, { useState } from 'react';
import { Col, Form, Label, Row } from 'reactstrap';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import './SailInvoices.scss';
import {
  ContFilterYear,
  ContPaginationPage,
  H4Styled,
} from '../../styled-components';
import variables from '../../variables';
import { useTranslation } from 'react-i18next';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import Table from './Table';
import SailInvoice from './SailInvoice';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import SearchInput from '../../commons/SearchInput';
import ModalInvoiceDetail from '../../commons/ModalInvoiceDetail';

function SailInvoicesPresentational({
  data = [],
  total,
  query = {},
  onFilter,
  onSendInvoice,
  onCollectInvoice,
  subjects = [],
  onStatusChanged,
}) {
  const [t] = useTranslation();
  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedId: null,
  });

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:SAIL_INVOICES')}
            icon={
              <IconInvoices fill={variables.white} style={{ height: '30px' }} />
            }
          />
        </section>

        <section>
          <SailInvoice subjects={subjects} onSendInvoice={onSendInvoice} />
        </section>

        <section>
          <Row>
            <Col sm="12">
              <H4Styled>{t('SAIL_INVOICES:TITLE')}</H4Styled>
            </Col>
          </Row>

          <Form>
            <Row>
              <Col sm="12" md="12" lg="6">
                <div className="mb-20">
                  <SearchInput
                    query={query}
                    onFilter={onFilter}
                    label={t('SAIL_INVOICES:NUMBER')}
                  />
                </div>
              </Col>
              <Col sm="12" md="6" lg="3">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('SAIL_INVOICES:CUSTOMER')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectYear"
                          name="customer"
                          value={query.customer}
                          onChange={({ target: { value: customer } }) =>
                            onFilter({ customer })
                          }
                        >
                          <option value="">Tutti</option>
                          {subjects.map((subject) => (
                            <option
                              value={subject.businessName}
                              key={subject._id}
                            >
                              {subject.businessName}
                            </option>
                          ))}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
              <Col sm="12" md="6" lg="3">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectYear"
                          name="year"
                          value={query.year}
                          onChange={({ target: { value: year } }) =>
                            onFilter({ year })
                          }
                        >
                          {generateYearOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
            </Row>
          </Form>
        </section>

        <section>
          <Table
            data={data}
            total={total}
            query={query}
            onFilter={onFilter}
            onCollectInvoice={onCollectInvoice}
            setModalState={setModalState}
          />
          <ModalInvoiceDetail
            modalState={modalState}
            setModalState={setModalState}
            onStatusChanged={onStatusChanged}
          />
          <ContPaginationPage>
            <p className="m-tb-20">{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default SailInvoicesPresentational;
