import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BtnDisabled,
  BtnModalTable,
  BtnOutlineGreen,
  BtnOutlineRed,
} from '../../../styled-components';
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ModalDeleteInvoice = (props) => {
  const [t] = useTranslation('CONTACTS');
  const { data: invoice, className = '', deleteInvoice = () => {} } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const onDelete = () => {
    deleteInvoice(invoice._id);
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ marginLeft: '10px' }}>
          {(invoice.paid === true && (
            <BtnDisabled type="button" disabled={true}>
              {t('ROYALTY:DELETE')}
            </BtnDisabled>
          )) || (
            <BtnModalTable
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                toggle();
              }}
              style={{
                borderColor: '#ff5252',
                color: '#ff5252',
              }}
            >
              {t('ROYALTY:DELETE')}
            </BtnModalTable>
          )}
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>
          {t('ROYALTY:MODAL_DELETE_INVOICE')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onDelete();
            toggle();
          }}
        >
          <ModalBody>
            <div className="max-width-form" style={{ textAlign: 'center' }}>
              Si è sicuri di voler cancellare questa fattura?
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModalDeleteInvoice;
