import axios from 'axios';

export const getExtraInvoices = async (props) => {
  const { limit, offset, token, _id, year, search, all = '' } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/extra-invoices/profile/received`,
      headers: {
        Authorization: token,
      },
      params: {
        all,
        limit,
        offset,
        search,
        year,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const csvExtraInvoice = async (props) => {
  const { _id, token } = props;

  try {
    const response = await axios({
      method: 'post',
      responseType: 'blob',
      url: `${process.env.REACT_APP_API_URL}/extra-invoices/profile/export`,
      headers: {
        Authorization: token,
      },
      data: {
        _id,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

const extraInvoiceAPIs = { getExtraInvoices };

export default extraInvoiceAPIs;
