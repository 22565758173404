import React from 'react';
import { CSVLink } from 'react-csv';
import { formatCurrencyForCSV as formatCurrency } from '../../../lib/helpers/formatters';
import { useAuth } from '../../../commons/Auth';

export default ({
  data: { data = [], totals = {} },
  clientType,
  businessName = '',
  year,
  children,
}) => {
  const [{ isAdmin }] = useAuth();
  const csvData = [
    [
      'NOME AFFILIATO',
      'IMPORTO A CONTRATTO',
      'PERIODO AFFILIAZIONE',
      'QUOTA MASTER A CONTRATTO',
      'DIRITTO A FATTURARE',
    ],
  ];

  if (isAdmin) csvData[0].push('INVITATO A FATTURARE');

  data.forEach((report = {}) => {
    const {
      name,
      contractAmount,
      affiliationPeriod,
      masterContractAmount,
      revenue,
      iafs = [],
    } = report;

    // Dati principali
    const row = [
      name,
      formatCurrency(contractAmount),
      affiliationPeriod,
      formatCurrency(masterContractAmount),
      formatCurrency(revenue),
    ];

    if (isAdmin) {
      const iafAmount = (iafs || []).reduce((acc, iaf = {}) => {
        const iafDetail = iaf.gnet_admin || iaf.suppliers || [];
        const [firstIAFDetail = {}] = iafDetail;
        return acc + ((firstIAFDetail && firstIAFDetail.revenue) || 0);
      }, 0);
      row.push(formatCurrency(iafAmount));
    }

    csvData.push(row);
  });

  // Totali

  const totalsRow = [
    '',
    formatCurrency(totals.contractAmount),
    '',
    formatCurrency(totals.masterContractAmount),
    formatCurrency(totals.revenue),
  ];

  if (isAdmin) totalsRow.push('');

  csvData.push(totalsRow);

  return (
    <CSVLink
      data={csvData}
      filename={`RENDICONTAZIONE_${clientType.toUpperCase()}_${businessName.replace(
        /\s/g,
        '_'
      )}_${year}.csv`}
      separator={';'}
    >
      {children}
    </CSVLink>
  );
};
