import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

const TotalTr = ({ totals = {} }) => {
  const { subTotals = 0, vatTotals = 0, amountTotals = 0 } = totals;

  return (
    <tr className="summary-tr">
      <td className="txt-table-left">-</td>
      <td className="txt-table-right">{formatCurrency(subTotals)}</td>
      <td className="txt-table-right">-</td>
      <td className="txt-table-right">{formatCurrency(vatTotals)}</td>
      <td className="txt-table-right">{formatCurrency(amountTotals)}</td>
    </tr>
  );
};

export default TotalTr;
