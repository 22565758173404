import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import CondosPresentational from './CondosPresentational';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IconBuilding } from '../../images/icn_buildings.svg';
import { Route, Switch, withRouter } from 'react-router-dom';
import CondosDetail from '../CondosDetail';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const Condos = ({ profile, match = {} }) => {
  const [condos, setCondos] = useState([]);
  const [formattedCSVCondos, setFormattedCSVCondos] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ token, isAdmin } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    admin: withDefault(StringParam, ''),
    name: withDefault(StringParam, ''),
    vatCode: withDefault(StringParam, ''),
    address: withDefault(StringParam, ''),
    studioManager: withDefault(StringParam, ''),
    condosActive: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    admin,
    name,
    vatCode,
    address,
    studioManager,
    condosActive,
  } = query;

  useEffect(() => {
    fetchCondos();
  }, [
    limit,
    offset,
    admin,
    name,
    vatCode,
    address,
    studioManager,
    condosActive,
  ]);

  useEffect(() => {
    fetchCondosForExport();
  }, [admin, name, vatCode, address, studioManager, condosActive]);

  const fetchCondos = async () => {
    showLoading();

    const response = await axios.get(
      isAdmin
        ? `${process.env.REACT_APP_API_URL}/condos`
        : `${process.env.REACT_APP_API_URL}/condos/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          admin,
          name,
          vatCode,
          address,
          studioManager,
          condosActive,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setCondos(data.condos);
    setTotal(+headers['x-total-count']);
    // setFormattedCSVCondos(data.formattedCSVCondos);
  };

  const fetchCondosForExport = async () => {
    showLoading();

    const response = await axios.get(
      isAdmin
        ? `${process.env.REACT_APP_API_URL}/condos/export`
        : `${process.env.REACT_APP_API_URL}/condos/export/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          admin,
          name,
          vatCode,
          address,
          studioManager,
          condosActive,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setFormattedCSVCondos(data.formattedCSVCondos);
  };

  function onFilter(queryDiff) {
    if (
      (queryDiff.admin && queryDiff.admin !== admin) || // first search
      (admin && queryDiff.admin === '') || // search deleted
      (queryDiff.name && queryDiff.name !== name) || // first search
      (name && queryDiff.name === '') || // search deleted
      (queryDiff.vatCode && queryDiff.vatCode !== vatCode) || // first search
      (vatCode && queryDiff.vatCode === '') || // search deleted
      (queryDiff.address && queryDiff.address !== address) || // first search
      (address && queryDiff.address === '') || // search deleted
      (queryDiff.condosActive && queryDiff.condosActive !== condosActive) || // first search
      (condosActive && queryDiff.condosActive === 'true') || // search deleted
      (queryDiff.active && queryDiff.active !== studioManager) || // first search
      (studioManager && queryDiff.studioManager === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  const createCondo = async (data) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/condos`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchCondos();
  };

  const checkVatCode = async (vatCode) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/condos/checkVatCode`,
      {
        vatCode: vatCode,
      },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      throw new Error('Errore nel controllo della partita IVA');
    }

    return response.data;
  };

  const searchAdministrators = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/admins`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  const changeAdmin = async ({ id, admin, endDate, startDate }) => {
    showLoading();

    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/condos/changeAdmin/${id}`,
      { newAdminId: admin, endDate: endDate, startDate: startDate },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      const message = JSON.parse(response?.request?.response).message;

      return alert.error(message || t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchCondos();
  };

  const extendAdminManagement = async ({ id, endDate }) => {
    showLoading();

    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/condos/${id}/extends`,
      { endDate: endDate },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      const message = JSON.parse(response?.request?.response).message;

      return alert.error(message || t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchCondos();
  };

  const updateCondo = async (data, id) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/condos/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      const message = JSON.parse(response?.request?.response).message;
      return alert.error(message || t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchCondos();
  };

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:CONDOS')}
            icon={<IconBuilding fill="#ffffff" style={{ height: '25px' }} />}
          />
          <Switch>
            <Route
              exact
              path={match.url}
              component={(props) => (
                <CondosPresentational
                  data={condos}
                  formattedData={formattedCSVCondos}
                  profile={profile}
                  isAdmin={isAdmin}
                  query={query}
                  total={total}
                  onFilter={onFilter}
                  searchAdministrators={searchAdministrators}
                  onNewCondoConfirm={createCondo}
                  checkVatCode={checkVatCode}
                  onCondoUpdate={updateCondo}
                  onChangeAdmin={changeAdmin}
                  onExtendAdminManagement={extendAdminManagement}
                  {...props}
                />
              )}
            />

            <Route
              path={`${match.url}/:id`}
              component={(props) => (
                <CondosDetail
                  // data={data}
                  total={total}
                  query={query}
                  onFilter={onFilter}
                  // createCollaborator={createCollaborator}
                  {...props}
                />
              )}
            />
          </Switch>
        </section>
      </div>
    </div>
  );
};

export default withRouter(Condos);
