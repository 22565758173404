import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { CurrencyField, DataTable, Field } from '../../commons/DataTable';
import { BadgeField } from '../../commons/DataTableFields';

function ReportProfilesTable({
  history,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
  onSort,
}) {
  const [t] = useTranslation('COMMON');
  const { limit, offset } = query;

  return (
    <DataTable
      limit={limit}
      offset={offset}
      total={total}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
      onRowClick={({ profileId } = {}) =>
        history.push({
          pathname: `/profiles/${profileId}`,
          state: { pageBack: 'report-profiles' },
        })
      }
      onSort={onSort}
      orderBy={query.orderBy}
      order={query.order}
    >
      <BadgeField title="Master" source="master" />
      <Field
        title="Ragione Sociale"
        source="businessName"
        className="text-left"
        sortable
      />
      <Field title="Tipologia" source="type" className="type" />
      {/*<CurrencyField*/}
      {/*  title="Totale affiliazione albo fornitori"*/}
      {/*  source="totals.supplier.revenue"*/}
      {/*  sortable*/}
      {/*/>*/}
      <CurrencyField
        title="Totale amministratori di livello 1-4"
        source="totals.gnet_admin.revenue"
        sortable
      />
      <CurrencyField
        title="Compensi variabili"
        source="totals.billableVariableRevenues"
        sortable
      />
      <CurrencyField
        title="Partner assicurazione"
        source="totals.billableVariableRevenuesInsurancesStudio"
        sortable
      />
      <CurrencyField
        title="Partner energia"
        source="totals.billableVariableRevenuesEnergy"
        sortable
      />
      <CurrencyField
        title="Totale imponibile netto"
        source="totals.billableTotalNet"
        sortable
      />
      <CurrencyField
        title="Saldo anno precedente"
        source="totals.lastYearRevenue"
        sortable
      />
      <CurrencyField title="Fattura / bonus" source="totals.bonuses" sortable />
      <CurrencyField title="Totale" source="totals.billableTotal" sortable />
    </DataTable>
  );
}

export default withRouter(ReportProfilesTable);
