import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

function CurrencyField({ value }) {
  if (typeof Number(value) !== 'number') return <>{formatCurrency(0)}</>;

  return <>{formatCurrency(value)}</>;
}

export default CurrencyField;
