import React from 'react';
import { useTranslation } from 'react-i18next';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT } = process.env;
const defaultLimit = +REACT_APP_DEFAULT_PAGINATION_LIMIT || 50;
const options = [
  defaultLimit,
  defaultLimit * 2,
  defaultLimit * 4,
  defaultLimit * 8,
];

export default () => {
  const [t] = useTranslation();
  return [
    ...options.map((perPage, i) => (
      <option key={i} value={perPage}>
        {perPage}
      </option>
    )),
    <option key={options.length} value={100000}>
      {t('COMMON:PAGINATION_ALL')}
    </option>,
  ];
};
