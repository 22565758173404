import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

const TotalTr = ({ totals = {} }) => {
  const { total = 0, cashed = 0, net = 0 } = totals;

  return (
    <tr className="summary-tr">
      <td>-</td>
      <td className="txt-table-right">{formatCurrency(net)}</td>
      <td className="txt-table-right">{formatCurrency(total)}</td>
      <td className="txt-table-right">{formatCurrency(cashed)}</td>
    </tr>
  );
};

export default TotalTr;
