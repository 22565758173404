import axios from 'axios';

export const getReports = async (props) => {
  const { year, number, token, profileId, iafs = true } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/network/report/${profileId}`,
      headers: {
        Authorization: token,
      },
      params: {
        year,
        number,
        iafs,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const getMyReport = async (props) => {
  const { year, number, token, profileId } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/network/my-report`,
      headers: {
        Authorization: token,
      },
      params: {
        year,
        number,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};
