import React, { useState, useEffect } from 'react';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import { useDebouncedCallback } from 'use-debounce';
import { getExtraInvoices } from '../../lib/fetch/extraInvoice';
import ReceivedExtraInvoicePresentational from './ReceivedExtraInvoicePresentational';

const MyReceivedExtraInvoice = ({ location }) => {
  const [t] = useTranslation();
  const [{ isAdmin, profile = {}, token } = {}] = useAuth();
  const { _id: profileId } = profile;
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [extraInvoices, setExtraInvoices] = useState([]);

  const [query, setQuery] = useQueryParams({
    all: withDefault(StringParam, ''),
    search: withDefault(StringParam, ''),
    limit: withDefault(StringParam, '50'),
    offset: NumberParam,
    year: NumberParam,
  });

  const [fetchExtraInvoices] = useDebouncedCallback(async () => {
    showLoading();

    const { error, data } = await getExtraInvoices({
      token,
      _id: profileId,
      ...query,
    });

    if (error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setExtraInvoices(data);
    hideLoading();
  }, 1000);

  useEffect(() => {
    fetchExtraInvoices();
  }, [query]);

  function onFilter(queryDiff) {
    setQuery(queryDiff);
  }

  return (
    <ReceivedExtraInvoicePresentational
      data={extraInvoices}
      query={query}
      total={extraInvoices.length}
      onFilter={onFilter}
    />
  );
};

export default MyReceivedExtraInvoice;
