import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

const TotalTrainingTr = ({ totals = {} }) => {
  const { creditsTotal } = totals;

  return (
    <tr className="summary-tr">
      <td className="txt-table-center">-</td>
      <td className="txt-table-center">-</td>
      <td className="txt-table-center">{creditsTotal}</td>
    </tr>
  );
};

export default TotalTrainingTr;
