import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Collapse,
  Badge,
} from 'reactstrap';
import {
  ContAffiliation,
  H5Styled,
  ContTitleAction,
  ContFlexRight,
  SummaryAmountCont,
  HeaderCollapse,
  BtnOutlineRed,
  BtnOutlineGreen,
} from '../../../styled-components';
import DateInput from '../../../commons/DateInput';
import ModalReceipt from './ModalReceipt';
import ReceiptsTable from './ReceiptsTable';
import moment from 'moment';
import ModalConfirm from '../../../commons/ModalConfirm';
import { SupplierTypes } from '../../../lib/constants';

const { REACT_APP_TAX_PERCENTAGE = '22' } = process.env;

const Affiliation = ({
  profile,
  affiliation = {},
  invoiceAffiliation,
  updateAffiliation,
  deleteAffiliation,
  deletePayment,
  showInvoicePinv,
}) => {
  const [t] = useTranslation('CONTACTS');
  const [isOpen, setIsOpen] = useState(false);

  const {
    startDate = '',
    endDate = '',
    totalGross = 0,
    totalNet = 0,
    totalTax = Number(REACT_APP_TAX_PERCENTAGE),
    admin,
    commercial,
    invoiceNumber,
    masters = [],
    payments = [],
  } = affiliation;

  const toggle = () => setIsOpen(!isOpen);

  function calculateToReceive() {
    const receivedPaymentsTotal = payments.reduce(
      (acc, val) => acc + val.amountGross,
      0
    );

    return (totalGross - receivedPaymentsTotal).toFixed(2);
  }

  function calculatePercentage(masterIndex) {
    return masters[masterIndex]
      ? Number(
          (Number(totalNet) * masters[masterIndex].percentage) / 100
        ).toFixed(2)
      : 0;
  }

  const onAddPayment = async (payment) => {
    const amountNet = Number(
      ((Number(payment.amountGross) * 100) / (100 + totalTax)).toFixed(2)
    );
    const newPayments = [...payments, { ...payment, amountNet }];

    const { pinvInvoice, ...aff } = affiliation;

    updateAffiliation({
      id: affiliation._id,
      data: { ...aff, payments: newPayments },
    });
  };

  const handleDeleteAffiliation = () => {
    deleteAffiliation(affiliation._id);
  };

  function handleDeletePayment(paymentIndex) {
    deletePayment(affiliation._id, paymentIndex);
  }

  const isAffiliationPaid =
    payments.reduce((acc, { amountGross = 0 } = {}) => acc + amountGross, 0) >=
    totalGross;

  const affiliationHasPayments =
    payments.reduce((acc, { amountGross = 0 } = {}) => acc + amountGross, 0) >
    0;

  return (
    <React.Fragment>
      <HeaderCollapse onClick={toggle}>
        <Row>
          <Col xs="8">
            <h6>
              {t('PAST_AFFILIATION_TITLE')}{' '}
              <i>
                da {moment(startDate).format('DD/MM/YYYY')} a{' '}
                {moment(endDate).format('DD/MM/YYYY')}
              </i>
            </h6>
          </Col>
          <Col xs="4" className="text-right">
            {isAffiliationPaid ? (
              <Badge className="pay">PAGATA</Badge>
            ) : affiliationHasPayments ? (
              <Badge className="partial-paid">PARZIALMENTE PAGATA</Badge>
            ) : (
              <Badge className="not-pay">NON PAGATA</Badge>
            )}

            {affiliation?.pinvInvoice.length > 0 ? (
              <Badge className="pay">
                {affiliation?.pinvInvoice[0]?.invoicePinv?.status ===
                'to_deliver'
                  ? 'BOZZA FATTURA'
                  : 'FATTURATA'}
              </Badge>
            ) : (
              <Badge className="not-pay">NON FATTURATA</Badge>
            )}

            <i className="fas fa-chevron-down"></i>
          </Col>
        </Row>
      </HeaderCollapse>
      <Collapse isOpen={isOpen}>
        <ContAffiliation>
          <Row>
            <Col sm="12" md="5">
              <H5Styled>{t('DATA_AFFILIATION_TITLE')}</H5Styled>
            </Col>
            <Col sm="12" md="7" className="text-right">
              <ContFlexRight>
                {!affiliation?.pinvInvoice.length > 0 && (
                  <ModalConfirm
                    onConfirm={() => {
                      invoiceAffiliation(affiliation._id);
                    }}
                    text={t('CONFIRM_INVOICE')}
                  >
                    <BtnOutlineGreen type="button" className="uppercase">
                      {t('INVOICE_AFFILIATION')}
                    </BtnOutlineGreen>
                  </ModalConfirm>
                )}
                {affiliation?.pinvInvoice.length > 0 && (
                  <BtnOutlineGreen
                    type="button"
                    className="uppercase"
                    onClick={() => {
                      showInvoicePinv(affiliation?.pinvInvoice[0]._id);
                    }}
                  >
                    {affiliation?.pinvInvoice[0]?.invoicePinv?.status ===
                    'to_deliver'
                      ? t('GOTO_INVOICE_DRAFT_AFFILIATION')
                      : t('GOTO_INVOICE_AFFILIATION')}
                  </BtnOutlineGreen>
                )}
                {affiliation?.pinvInvoice[0]?.invoicePinv?.status !==
                  'delivered' && (
                  <ModalConfirm
                    onConfirm={handleDeleteAffiliation}
                    text={t('CONFIRM_DELETE')}
                  >
                    <BtnOutlineRed type="button" className="uppercase">
                      {t('DELETE_AFFILIATION')}
                    </BtnOutlineRed>
                  </ModalConfirm>
                )}
              </ContFlexRight>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('INVOICE_NUMBER')}</Label>
                <Input
                  type="text"
                  name="invoiceNumber"
                  value={
                    affiliation?.pinvInvoice[0]?.invoicePinv?.status ===
                    'to_deliver'
                      ? ''
                      : affiliation?.pinvInvoice[0]?.invoicePinv?.fullnumber
                  }
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('AFFILIATION_START')}</Label>
                <DateInput value={startDate} readOnly />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('AFFILIATION_END')}</Label>
                <DateInput value={endDate} readOnly />
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('IMPORT_NET')}</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="totalNet"
                    value={totalNet.toFixed(2)}
                    readOnly
                  />
                  <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('TAX_PERCENTAGE')}</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="totalTax"
                    value={totalTax}
                    readOnly
                  />
                  <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm="12" md="6" lg="2">
              <FormGroup>
                <Label>{t('IMPORT_GROSS')}</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="totalGross"
                    value={totalGross.toFixed(2)}
                    readOnly
                  />
                  <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          {!SupplierTypes.includes(profile.type) && (
            <>
              <Row className="master-row">
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('ADMIN')}</Label>
                    <Input
                      type="text"
                      name="admin"
                      value={admin ? admin.name : ''}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('START_DATE')}</Label>
                    <DateInput value={admin.startDate} readOnly />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('END_DATE')}</Label>
                    <DateInput value={admin.endDate} readOnly />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="master-row">
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('COMMERCIAL')}</Label>
                    <Input
                      type="text"
                      name="commercial"
                      value={commercial ? commercial.name : ''}
                      readOnly
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('START_DATE')}</Label>
                    <DateInput value={commercial.startDate} readOnly />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6" lg="4">
                  <FormGroup>
                    <Label>{t('END_DATE')}</Label>
                    <DateInput value={commercial.endDate} readOnly />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
        </ContAffiliation>
      </Collapse>
    </React.Fragment>
  );
};

export default Affiliation;
