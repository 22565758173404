import React from 'react';
import moment from 'moment';

function DateTimeField({ value, format }) {
  const dateTimeFormat = format || 'DD/MM/YYYY HH:mm';
  const momentDate = value && moment(value);

  return (
    <>
      {momentDate && momentDate.isValid()
        ? momentDate.format(dateTimeFormat)
        : '-'}
    </>
  );
}

export default DateTimeField;
