import React from 'react';
import { BtnModalTable } from '../../styled-components';
import { csvExtraInvoice } from '../../lib/fetch/extraInvoice';
import { useAuth } from '../../commons/Auth';
import { has } from 'lodash/object';
import get from 'lodash.get';

function ActionField(props) {
  const [{ token } = {}] = useAuth();
  const { data } = props;
  const { _id } = data;

  const showButton =
    Boolean(get(data, 'contracts', null)) ||
    Boolean(get(data, 'recurrentContracts', null)) ||
    Boolean(get(data, 'insurances', null)) ||
    Boolean(get(data, 'invoices', null));

  return !showButton ? null : (
    <BtnModalTable
      type="button"
      onClick={async () => {
        const { data } = await csvExtraInvoice({
          token,
          _id,
        });
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'fatture-extra-ricevute.csv');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      }}
    >
      {' '}
      Esporta CSV
    </BtnModalTable>
  );
}

export default ActionField;
