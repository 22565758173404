import axios from 'axios';

export const list = async (params = {}) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/sail-invoices`,
      headers: {
        Authorization: params.token,
      },
      params: {
        ...params,
        token: undefined,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const findOne = async ({ token, id } = {}) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/sail-invoices/pinv/${id}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const create = async ({ data, token } = {}) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/sail-invoices/send-einvoice`,
      data,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const saveCached = async ({ data, token, _id } = {}) => {
  try {
    const response = await axios({
      method: 'patch',
      url: `${process.env.REACT_APP_API_URL}/sail-invoices/${_id}`,
      data,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const changeStatus = async ({ data, token } = {}) => {
  try {
    const { id, ...body } = data;
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/sail-invoices/pinv/change-status/${id}`,
      body,
      {
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};
