// import './Invoices.scss';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import ReportProfilesPresentational from './ReportProfilesPresentational';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import axios from 'axios';
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
} from 'use-query-params';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function ReportProfiles(props) {
  const { showLoading, hideLoading } = useLoading();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [{ token } = {}] = useAuth();
  const { t } = useTranslation('DASHBOARD');
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    year: withDefault(NumberParam, new Date().getFullYear()),
    type: withDefault(StringParam, ''),
    order: withDefault(StringParam, ''),
    orderBy: withDefault(StringParam, ''),
  });

  const { search, offset, limit, year, type, order, orderBy } = query;

  const fetchReportProfiles = async () => {
    showLoading();
    setData([]);

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/report-profiles`,
      {
        headers: { Authorization: token },
        params: { search, offset, limit, year, type, order, orderBy },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data } = response;

    setData(data.data || []);
    setLastUpdate(data.lastUpdate);
    setTotal(Number(response.headers['x-total-count']));
  };

  const syncReports = async () => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/report-profiles/sync`,
      {},
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    return alert.success(t('REPORT:SYNC_OK'));
  };

  useEffect(() => {
    fetchReportProfiles();
  }, [search, type, offset, limit, year, order, orderBy]);

  function onFilter(queryDiff) {
    if (queryDiff && typeof queryDiff.search === 'string') {
      localStorage.setItem('ReportProfilesSearch', queryDiff.search);
    }
    if (
      (queryDiff.search && queryDiff.search !== search) ||
      (search && queryDiff.search === '') ||
      (queryDiff.type && queryDiff.type !== type) ||
      (type && queryDiff.type === '') ||
      (queryDiff.year && queryDiff.year !== year) ||
      (year && queryDiff.year === '') ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  function setFilterYear(value) {
    localStorage.setItem('reportProfiles.filterYear', value);
    setQuery({ year: value });
  }

  const onSort = (order) => {
    setQuery((el) => ({ ...el, ...order }));
  };

  return (
    <ReportProfilesPresentational
      data={data}
      lastUpdate={lastUpdate}
      total={total}
      query={query}
      onFilter={onFilter}
      setFilterYear={setFilterYear}
      syncReports={syncReports}
      fetchReportProfiles={fetchReportProfiles}
      onSort={onSort}
      {...props}
    />
  );
}

export default ReportProfiles;
