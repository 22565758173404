import axios from 'axios';

const importClients = async ({ token, file }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/customer-extra/import-customers`,
      data: file,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

const importClientsFromAdmins = async ({ token, file }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/customer-extra/admin-import-customers`,
      data: file,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

const clientsAPIs = {
  importClients,
  importClientsFromAdmins,
};

export default clientsAPIs;
