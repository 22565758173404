import React from 'react';

function Field({ value, maxLength }) {
  if (!value) return <>-</>;

  if (maxLength && typeof value === 'string' && value.length > maxLength) {
    return <>{value.substr(0, maxLength)}...</>;
  }

  return <>{value}</>;
}

export default Field;
