import { has } from 'lodash/object';
import get from 'lodash.get';

function TypeField(props) {
  const { data } = props;
  if (get(data, 'affiliation', null)) return 'Affiliazione';

  if (get(data, 'invoicesStudio', null)) return 'Fattura Studio';

  if (get(data, 'contracts', null)) return 'Energia';

  if (get(data, 'recurrentContracts', null)) return 'Energia Ricorrente';

  if (get(data, 'insurances', null)) return 'Assicurazione';

  if (get(data, 'invoices', null)) return 'Fatture Fornitore';

  return 'Fattura Libera';
}

export default TypeField;
