import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';

function PublicRoute(props) {
  const {
    component: Component,
    isAdmin = false,
    userType = '',
    isAuthenticated = false,
    adminRedirect = '/dashboard',
    supervisorRedirect = '/profiles',
    privateRedirect = '/',
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect
            push={false}
            to={{
              pathname: isAdmin ? userType === 'SUPER_ADMIN' ? adminRedirect : supervisorRedirect : privateRedirect,
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

PublicRoute.propTypes = {
  component: Proptypes.func.isRequired,
  privateDefaultPath: Proptypes.string,
  isAuthenticated: Proptypes.bool,
};

export default PublicRoute;
