import React from 'react';

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH = new Date().getMonth() + 1;
const getColor = ({ month, value, year }) => {
  const notNull = value !== null;
  if (notNull || year > CURRENT_YEAR) return '';
  if (year < CURRENT_YEAR) return notNull ? '' : 'red';
  if (month < CURRENT_MONTH) return notNull ? '' : 'red';
  return '';
};

function InvoicesByMonthField({ month, value, year }) {
  return (
    <span style={{ color: getColor({ month, value, year }) }}>
      {value || 0}
    </span>
  );
}

export default InvoicesByMonthField;
