import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';

import { BtnOutlineGreen } from '../../styled-components';
import _ from 'lodash';
import { getValidationErrorMessage } from './helpers';
import ExportCSVContracts from './ExportCSVContracts';
import ExportCSVRow from './ExportCSVRow';

function ContractsImportFeedbackModal({ toggle, isOpen, data = {} }) {
  const [t] = useTranslation('CONTRACTS');

  const {
    errors = {},
    insertedContracts,
    validationErrors: topValidationErrors,
    updatedContracts,
  } = data;
  let validationErrors = false;
  if (topValidationErrors && Array.isArray(topValidationErrors.message)) {
    validationErrors = topValidationErrors.message;
  }
  if (
    topValidationErrors &&
    topValidationErrors.message &&
    !Array.isArray(topValidationErrors.message)
  ) {
    validationErrors = [topValidationErrors.message];
  }

  const {
    adminsNotFound = [],
    condosNotFound = [],
    contractMalformed = [],
    clientsNotFound = [],
    nonCompliantProducts = [],
  } = errors;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {validationErrors
          ? t('MALFORMED_FILE')
          : t('IMPORTED_CONTRACTS_FEEDBACK')}
      </ModalHeader>

      {!validationErrors && (
        <ModalBody>
          <Row>
            <Col sm="12">
              <h5>
                {t('INSERTED_CONTRACTS_NUMBER')}{' '}
                {insertedContracts?.length || 0}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <h5>
                {t('UPDATED_CONTRACTS_NUMBER')} {updatedContracts?.length || 0}
              </h5>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <h5>
                {t('NOT_INSERTED_CONTRACTS_NUMBER')}:{' '}
                {_.uniqWith(
                  [
                    ...adminsNotFound,
                    ...condosNotFound,
                    ...contractMalformed,
                    ...clientsNotFound,
                    ...nonCompliantProducts,
                  ],
                  _.isEqual
                ).length || 0}
              </h5>
              <ul>
                <ExportCSVRow
                  data={adminsNotFound}
                  text={t('ADMINS_NOT_FOUND')}
                />
                <ExportCSVRow
                  data={condosNotFound}
                  text={t('CONDOS_NOT_FOUND')}
                />
                <ExportCSVRow
                  data={clientsNotFound}
                  text={t('CLIENTS_NOT_FOUND')}
                />
                <ExportCSVRow
                  data={contractMalformed}
                  text={t('MALFORMED_CONTRACTS')}
                />
                <ExportCSVRow
                  data={nonCompliantProducts}
                  text={t('NON_COMPLIANT_CONTRACTS')}
                />
              </ul>
            </Col>
          </Row>
        </ModalBody>
      )}
      {validationErrors && (
        <ModalBody>
          <Row>
            <Col sm="12">
              {validationErrors.map((v) => (
                <h5>{getValidationErrorMessage({ message: v })}</h5>
              ))}
            </Col>
          </Row>
        </ModalBody>
      )}
      <ModalFooter>
        <BtnOutlineGreen type="button" className="uppercase" onClick={toggle}>
          {t('COMMON:CLOSE')}
        </BtnOutlineGreen>
      </ModalFooter>
    </Modal>
  );
}

export default ContractsImportFeedbackModal;
