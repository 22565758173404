import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportCSVCondos from './ExportCSVCondos';

function ExportCSVButton({ data = [], text }) {
  const [t] = useTranslation();

  if (data.length === 0) return null;
  return <ExportCSVCondos data={data} />;
}

export default ExportCSVButton;
