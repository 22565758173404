import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabContent, TabPane, Form, Row, Col } from 'reactstrap';
import {
  ContAffiliation,
  ContFilterYear,
  ContFilterCheckbox,
  ContFlexRight,
  BtnOutlineGreen,
  ContPaginationPage,
} from '../../../styled-components';
import {
  DataTable,
  Field,
  DateTimeField,
  CurrencyField,
  PaymentsDataField,
  BooleanField,
} from '../../../commons/DataTable';
import SearchInput from '../../../commons/SearchInput';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import Label from 'reactstrap/lib/Label';
import TotalTr from './TotalTr';
import generateYearOptions from '../../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../../lib/helpers/generateMonthOptions';
import ModalInvoiceDetail from '../../../commons/ModalInvoiceDetail';
import ModalConfirm from '../../../commons/ModalConfirm';
import currency from 'currency.js';
import ActionsField from '../Royalties/ActionsField';
import CustomerTypeField from '../../../commons/DataTable/CustomerTypeField';
import { USER_ADMIN_TYPES } from '../../../App';
import { useAuth } from '../../../commons/Auth';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';

const InsurancesPresentational = ({
  data,
  query,
  total,
  onFilter,
  onSelectChange,
  setFilterYear,
  selectedInsurances,
  selectedAll,
  onSelectAll,
  onInvoice,
  onStatusChanged,
  // userType = '',
}) => {
  const [t] = useTranslation('ENERGY');
  const { offset, limit, year } = query;

  const [{ userType = '' }] = useAuth();

  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedId: null,
  });
  const [totalInsurancesValue, setTotalInsurancesValue] = useState(0);

  const onRowClick = (row) => {
    const { _id } = row;
    if (!row.pinvInvoice?.length > 0) {
      onSelectChange(_id);
    }
  };

  const onFilterPaidInvoices = (e) => {
    onFilter({
      paid: e.target.checked ? 'true' : '',
    });
  };

  const onOpenModal = () => {
    const itemsForTotalValue = selectedInsurances.map((_id) => {
      return data.find((insurance) => insurance._id === _id).networkShare;
    });
    let total = 0;
    itemsForTotalValue.forEach((value) => {
      total += value;
    });
    setTotalInsurancesValue(
      currency(total, { symbol: '€', precision: 2 }).format()
    );
  };

  return (
    <Form>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12" md="6" lg="4">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        value={year}
                        onChange={(e) => setFilterYear(e.target.value)}
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="12" md="6" lg="4">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectAccountabilityYear"
                        name="accountabilityYear"
                        value={query && query.accountabilityYear}
                        onChange={({
                          target: { value: accountabilityYear },
                        }) => {
                          onFilter({ accountabilityYear });
                        }}
                      >
                        {generateYearOptions(true)}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="12" md="6" lg="4">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="accountabilityMonth"
                        name="accountabilityMonth"
                        disabled={!query.accountabilityYear}
                        value={query && query.accountabilityMonth}
                        onChange={({
                          target: { value: accountabilityMonth },
                        }) => onFilter({ accountabilityMonth })}
                      >
                        {generateMonthOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
          </Row>
          <Row className="compensi-variabili-resp">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.supplier }}
                onFilter={({ search }) => onFilter({ supplier: search })}
                label={t('SEARCH:BY_SUPPLIER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.insuranceNumber }}
                onFilter={({ search }) => onFilter({ insuranceNumber: search })}
                label={t('SEARCH:INSURANCE_NUMBER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.adminName }}
                onFilter={({ search }) => onFilter({ adminName: search })}
                label={t('SEARCH:ADMINISTRATOR')}
              />
            </Col>

            <Col sm="12" md="12" lg="8" xl="6">
              <FormGroup>
                <ContFilterCheckbox>
                  <CustomInput
                    type="checkbox"
                    id="paid"
                    label="Assicurazioni Pagate"
                    inline
                    checked={query && query.paid === 'true'}
                    onChange={onFilterPaidInvoices}
                  />
                </ContFilterCheckbox>
              </FormGroup>
            </Col>
          </Row>

          <ContAffiliation className="m-t-20">
            {(userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
              <DataTable
                data={data}
                total={total}
                offset={offset}
                limit={limit}
                onFilter={onFilter}
                emptyText={t('COMMON:NO_DATA')}
                bottomRow={<TotalTr data={data} userType={userType} />}
              >
                <Field
                  title="Nome Fornitore"
                  source="supplier.businessName"
                  className="text-left"
                />
                <Field title="Contraente" source="contractor" />
                <CustomerTypeField
                  title="Tipologia Contraente"
                  source="customerType"
                />
                <Field title="Assicurazione" source="insuranceNumber" />
                <Field title="Tipo" source="insuranceType" />
                <DateTimeField
                  title="Data inizio"
                  format="DD/MM/YYYY"
                  source="startDate"
                />
                <DateTimeField
                  title="Data fine"
                  format="DD/MM/YYYY"
                  source="endDate"
                />
                <DateTimeField
                  title="Mese Rend."
                  source="accountability"
                  format="MM/YYYY"
                />
                <DateTimeField
                  title="Data prevista incassi"
                  format="DD/MM/YYYY"
                  source="supposedPaymentDate"
                />
                <Field
                  title="Responsabile di condominio"
                  source="admin.businessName"
                />
                <Field title="CF Cliente" source="cfCondominio" />
                <Field
                  title="Numero Sinistri"
                  className="txt-table-right"
                  source="numeroDiSinistri"
                />
                <CurrencyField
                  title="Importo Sinistri"
                  className="txt-table-right"
                  source="importoSinistri"
                />
                <CurrencyField
                  title="Imponibile"
                  className="txt-table-right"
                  source="net"
                />
                <CurrencyField
                  title="Quota Extra"
                  className="txt-table-right"
                  source="networkShare"
                />
                <CurrencyField
                  title="Quota Admin"
                  className="txt-table-right"
                  source="adminShare"
                />
                <BooleanField title="Pagato" source="paid" />
              </DataTable>
            )) || (
              <DataTable
                data={data}
                total={total}
                offset={offset}
                limit={limit}
                onFilter={onFilter}
                emptyText={t('COMMON:NO_DATA')}
                bottomRow={<TotalTr data={data} userType={userType} />}
              >
                <Field
                  title="Nome Fornitore"
                  source="supplier.businessName"
                  className="text-left"
                />
                <Field title="Contraente" source="contractor" />
                <CustomerTypeField
                  title="Tipologia Contraente"
                  source="customerType"
                />
                <Field title="Assicurazione" source="insuranceNumber" />
                <Field title="Tipo" source="insuranceType" />
                <DateTimeField
                  title="Data inizio"
                  format="DD/MM/YYYY"
                  source="startDate"
                />
                <DateTimeField
                  title="Data fine"
                  format="DD/MM/YYYY"
                  source="endDate"
                />
                <DateTimeField
                  title="Mese Rend."
                  source="accountability"
                  format="MM/YYYY"
                />
                <DateTimeField
                  title="Data prevista incassi"
                  format="DD/MM/YYYY"
                  source="supposedPaymentDate"
                />
                <Field
                  title="Responsabile di condominio"
                  source="admin.businessName"
                />
                <Field title="CF Cliente" source="cfCondominio" />
                <Field
                  title="Numero Sinistri"
                  className="txt-table-right"
                  source="numeroDiSinistri"
                />
                <CurrencyField
                  title="Importo Sinistri"
                  className="txt-table-right"
                  source="importoSinistri"
                />
                <CurrencyField
                  title="Imponibile"
                  className="txt-table-right"
                  source="net"
                />
              </DataTable>
            )}
            <ContPaginationPage>
              <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

              <FormGroup>
                <CustomInput
                  type="select"
                  className="pagination-select"
                  id="perPage"
                  name="perPage"
                  value={query.limit}
                  onChange={({ target: { value: limit } }) => {
                    onFilter({ limit });
                  }}
                >
                  {generatePaginationOptions()}
                </CustomInput>
              </FormGroup>
            </ContPaginationPage>
          </ContAffiliation>
        </TabPane>
      </TabContent>
    </Form>
  );
};

export default InsurancesPresentational;
