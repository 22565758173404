import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  Field,
  DateTimeField,
  CurrencyField,
} from '../../commons/DataTable';

function ExtraInvoicesTabel({ data, onFilter, query = {}, total = 0 }) {
  const [t] = useTranslation('COMMON');
  const { offset, limit } = query;

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('NO_DATA')}
    >
      <Field
        title="Nome Fornitore"
        source="supplier.businessName"
        className="text-left"
      />
      <Field title="Numero Fattura" source="number" />
      <DateTimeField title="Data Fattura" source="date" format="DD/MM/YYYY" />
      <DateTimeField
        title="Mese Rend."
        source="accountability"
        format="MM/YYYY"
      />
      <Field title="Condominio" source="customer" />
      <Field title="Responsabile Clienti" source="admin.businessName" />
      <CurrencyField title="Imponibile" source="net" />
      <CurrencyField title="Importo da Incassare" source="gross" />
      <CurrencyField title="Importo Extra" source="extraGross" />
    </DataTable>
  );
}

export default ExtraInvoicesTabel;
