import React, { useState } from 'react';
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap';
import './SearchInput.scss';
// import { useDebouncedCallback } from 'use-debounce';

function SearchInput({ onFilter, query = {}, label, autoFocus = true }) {
  const [search, setSearch] = useState(query.search || '');
  // const [debouncedOnFilter] = useDebouncedCallback(
  //   (value) => onFilter(value || { search }),
  //   600
  // );

  return (
    <InputGroup className="search-bar">
      <Input
        defaultValue={search}
        onChange={(e) => {
          setSearch(e.target.value);
          // debouncedOnFilter({ search: e.target.value });
        }}
        placeholder={label}
        autoFocus={autoFocus}
      />
      <InputGroupAddon addonType="append">
        {/*<Button onClick={() => debouncedOnFilter()}>*/}
        <Button onClick={() => onFilter({ search })}>
          <i className="fas fa-search" />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
}

export default SearchInput;
