import React, { useState, useEffect } from 'react';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import { useDebouncedCallback } from 'use-debounce';
import { getBonuses } from '../../lib/fetch/reports';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import ReceivedIAFPresentational from './ReceivedIAFPresentational';

const MyReceivedIAF = ({ location }) => {
  const [t] = useTranslation();
  const [{ isAdmin, profile = {}, token } = {}] = useAuth();
  const { _id: profileId } = profile;
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [iafs, setIafs] = useState([]);

  const [query, setQuery] = useQueryParams({
    number: withDefault(StringParam, ''),
    invoiceNumber: withDefault(StringParam, ''),
    limit: withDefault(StringParam, '50'),
  });

  const [fetchReports] = useDebouncedCallback(async () => {
    showLoading();
    const { error, data } = await getBonuses({
      token,
      _id: profileId,
      query,
    });

    if (error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setIafs(data);
    hideLoading();
  }, 1000);

  useEffect(() => {
    fetchReports();
  }, [query]);

  if (isAdmin)
    return (
      // Super Admins do not have financial reports
      <Redirect
        push={false}
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );

  function onFilter(queryDiff) {
    setQuery(queryDiff);
  }

  return (
    <ReceivedIAFPresentational
      data={iafs}
      query={query}
      total={iafs.length}
      onFilter={onFilter}
    />
  );
};

export default MyReceivedIAF;
