import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import DateInput from '../../../commons/DateInput';
import { BtnOutlineGreen, BtnOutlineRed } from '../../../styled-components';
import generateYearOptions from '../../../lib/helpers/generateYearOptions';
import variables from '../../../variables';
import styled from 'styled-components';

const Icon = styled.i`
  color: ${variables.primary};
  font-size: 1.3rem;
  cursor: pointer;
`;

const ModifyBonusModal = ({ onConfirm, data }) => {
  const [t] = useTranslation('CONTACTS');
  const [modal, setModal] = useState(false);

  const [type, setType] = useState('');
  const [invoiceRef, setInvoiceRef] = useState('');
  const [date, setDate] = useState('');
  const [adminDate, setAdminDate] = useState('');
  const [accountability, setAccountability] = useState('');

  const [errors, setErrors] = useState({
    invoiceRef: false,
    date: false,
    adminDate: false,
    amount: false,
    accountability: false,
  });

  useEffect(() => {
    setType(data.type);
    setInvoiceRef(data.invoiceRef);
    setDate(data.date);
    setAdminDate(data.adminDate);
    setAccountability(data.accountability);
  }, [data]);

  const toggle = () => setModal(!modal);

  function onAddConfirm() {
    if (!date || !accountability) {
      const errors = {};
      errors.date = !date;
      errors.accountability = !accountability;
      return setErrors(errors);
    }

    onConfirm({
      ...data,
      type,
      invoiceRef,
      date,
      adminDate: adminDate === '' ? null : adminDate,
      accountability,
    });

    toggle();
  }

  return (
    <React.Fragment>
      <Icon className="fa fa-pen" onClick={toggle} />
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('MODAL_SUMMARY_TITLE_UPDATE')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onAddConfirm();
          }}
        >
          <ModalBody>
            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('ROYALTY:IAF_NUMBER')}</Label>
                    <Input
                      type="text"
                      name="number"
                      value={data?.number}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>

            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('ROYALTY:IAF_INVOICE_REF')}</Label>
                    <InputGroup>
                      <Input
                        type="textarea"
                        name="invoiceRef"
                        value={invoiceRef}
                        onChange={({ target }) => setInvoiceRef(target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </div>

            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('ROYALTY:IAF_ACCOUNTABILITY')}</Label>
                    <CustomInput
                      type="select"
                      id="selectAccountability"
                      name="accountability"
                      value={accountability}
                      onChange={({ target: { value } }) =>
                        setAccountability(value)
                      }
                    >
                      {generateYearOptions()}
                    </CustomInput>
                    {errors.accountability && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>

            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('ROYALTY:IAF_DATE')} *</Label>
                    <DateInput name="date" value={date} readOnly={true} />
                    {errors.date && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('ROYALTY:IAF_ADMIN_DATE')}</Label>
                    <DateInput
                      name="date"
                      value={adminDate}
                      onChange={(adminDate) => setAdminDate(adminDate)}
                    />
                    {errors.adminDate && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModifyBonusModal;
