import React from 'react';
import './PageNotFound.scss';
import { Link } from 'react-router-dom';
import { ButtonDelete } from '../../styled-components';

function PageNotFound() {
  return (
    <section>
      <div className="container error-page">
        <div>
          <h1>404</h1>
          <p>
            Siamo spiacenti, non siamo riusciti a trovare la pagina che stavi
            cercando
          </p>
          <br />
          <Link to={'/'}>
            <ButtonDelete>Torna alla home</ButtonDelete>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default PageNotFound;
