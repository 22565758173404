import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CurrencyField, DataTable, Field } from '../../commons/DataTable';
import TotalTr from './TotalTr';

function DashboardDetailTable({ data }) {
  const [t] = useTranslation('COMMON');

  const history = useHistory();

  return (
    <DataTable
      history
      data={data}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onRowClick={({ _id }) => history.push(`/profiles/${_id}`)}
      bottomRow={<TotalTr data={data} />}
    >
      <Field title="Ragione sociale" source="name" className="text-left" />
      <Field title="Tipo utente" source="type" className="text-left" />
      <CurrencyField
        title="Dovuto"
        source="total"
        className="txt-table-right"
      />
    </DataTable>
  );
}

export default DashboardDetailTable;
