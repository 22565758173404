import React, { useState } from 'react';
import {
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  BtnOutlineGreen,
  BtnOutlineRed,
  BtnRadiusRed,
  PNewItem,
} from '../../styled-components';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { ReactComponent as IconPlus } from '../../images/icn-plus.svg';

const INITIAL_COLLABORATOR_STATE = {
  name: '',
  surname: '',
  emailReference: '',
};

const ModalNewCollaborator = ({ onConfirm }) => {
  const [t] = useTranslation('COLLABORATORS');
  const [modal, setModal] = useState(false);
  const [{ token, isAdmin } = {}] = useAuth();
  const alert = useAlert();

  const [formValues, setFormValues] = useState({});

  const [formErrors, setFormErrors] = useState({
    name: false,
    surname: false,
    emailReference: false,
  });

  const toggle = () => {
    setModal(!modal);
    setFormValues({
      ...INITIAL_COLLABORATOR_STATE,
    });
  };

  function onInputChange({ target, uppercase }) {
    setFormValues((state) => ({
      ...state,
      [target.name]: uppercase ? target.value?.toUpperCase() : target.value,
    }));
  }

  const onNewCollaboratorConfirm = async () => {
    const { name, surname, emailReference } = formValues;

    const errors = {};

    if (!name) errors.name = true;
    if (!surname) errors.name = true;
    if (!emailReference) errors.emailReference = true;
    if (Object.keys(errors).length) {
      return setFormErrors(errors);
    }

    try {
      onConfirm({
        ...formValues,
      });
    } catch (error) {
      alert.error(error.message);
    }
    toggle();
  };

  function listenForEscapeEvent() {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  }

  const { name, surname, emailReference } = formValues;

  return (
    <>
      <React.Fragment>
        <BtnRadiusRed onClick={toggle} type="button">
          <IconPlus />
        </BtnRadiusRed>
        <PNewItem
          onClick={toggle}
          style={{ marginLeft: '7px', marginRight: '0', cursor: 'pointer' }}
        >
          {t('NEW_COLLABORATOR')}
        </PNewItem>

        <Modal
          isOpen={modal}
          className="new-customerExtra-modal"
          onOpened={listenForEscapeEvent}
        >
          <ModalHeader toggle={toggle}>{t('NEW_COLLABORATOR')}</ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onNewCollaboratorConfirm(formValues);
            }}
          >
            <ModalBody>
              <Row>
                <Col sm="12">
                  <Row>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('NAME')} *</Label>
                        <Input
                          type="text"
                          name="name"
                          value={name}
                          onChange={onInputChange}
                        />
                        {formErrors.name && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>

                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('SURNAME')} *</Label>
                        <Input
                          type="text"
                          name="surname"
                          value={surname}
                          onChange={onInputChange}
                        />
                        {formErrors.name && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('EMAIL')} *</Label>
                        <Input
                          type="email"
                          name="emailReference"
                          value={emailReference}
                          onChange={onInputChange}
                        />
                        {formErrors.emailReference && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <BtnOutlineRed
                type="button"
                className="uppercase"
                onClick={toggle}
              >
                {t('COMMON:CANCEL')}
              </BtnOutlineRed>
              <BtnOutlineGreen type="submit" className="uppercase">
                {t('COMMON:CONFIRM')}
              </BtnOutlineGreen>
            </ModalFooter>
          </Form>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default ModalNewCollaborator;
