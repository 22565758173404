import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { DataTable, CurrencyField, Field } from '../../commons/DataTable';
import { useAuth } from '../../commons/Auth';

function RevenueCalculationTable({
  dataTable,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
  bottomRow,
  showEmptyTableMessage,
  truncateTable,
  onSelectChange,
  allowedRoles = [],
  subAdmins = false,
}) {
  const [t] = useTranslation('COMMON');
  const [{ isAdmin }] = useAuth();
  const { offset, limit } = query;

  return (
    <DataTable
      offset={offset}
      limit={limit}
      data={[data]}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
    >
      <Field
        title="N.Condomini"
        source="totalCondos"
        className="txt-table-center"
      />
      <Field
        title="Coefficiente"
        source="SUPPLIER_COEFFICIENT"
        className="txt-table-center"
      />
      <CurrencyField
        title="Entrate Totali Previste {[Σ(Importo da incassare)/Mese Corrente] * 12}"
        source="totalRevenue"
        className="txt-table-center"
      />
      <CurrencyField
        title="Entrate Attese [N.Condomini * Coefficiente]"
        source="expectedRevenue"
        className="txt-table-center"
      />
      <Field
        title="Tot. Crediti Previsti [(Entrate Totali Previste / Entrate Attese) * Crediti Massimi]"
        source="expectedTotSupplierScore"
        className="txt-table-center"
      />
      <Field
        title="Tot. Crediti Attuali"
        source="totSupplierScore"
        className="txt-table-center"
      />
      <Field
        title="Crediti Massimi"
        source="SUPPLIER_MAX_SCORE"
        className="txt-table-center"
      />
    </DataTable>
  );
}

export default withRouter(RevenueCalculationTable);
