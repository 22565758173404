import moment from 'moment';
import { IAFType } from '../constants';

export const formatDate = (date) =>
  date ? moment(date).format('DD-MM-YYYY') : '';

const { Intl } = window || {};
let locale;

if (Intl && typeof Intl === 'object') {
  const { language } = navigator || {};
  locale = language;
}

export const formatCurrency = (amount) => {
  if (isNaN(amount)) amount = 0;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
  }).format(amount);
};

export const formatIAFType = (iafType) => {
  switch (iafType) {
    case IAFType.INVOICES:
      return 'Fatture fornitori';
    case IAFType.INSURANCES:
      return 'Assicurazioni';
    case IAFType.INVOICES_SUB_ADMINS:
      return 'Fatture fornitore di amministratori sottoposti';
    case IAFType.ENERGY:
      return 'Contratti energia';
    default:
      return '-';
  }
};

export const formatCurrencyForCSV = (amount) => {
  if (isNaN(amount)) amount = 0;

  return (amount || 0).toFixed(2).replace('.', ',');
};

export const formatAddress = (address) => {
  return `${address?.rue} ${address?.zip} ${address?.city}, ${address?.state}, ${address?.nation}`;
};
