import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  Field,
  CurrencyField,
  BilledField,
} from '../../../commons/DataTable';
import CustomCheckbox from '../../../commons/Checkboxes/CustomCheckboxes';
import { useAuth } from '../../../commons/Auth';

function ProvidersTable({
  dataTable,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
  bottomRow,
  showEmptyTableMessage,
  truncateTable,
}) {
  const [t] = useTranslation('COMMON');
  const [{ isAdmin }] = useAuth();
  const { offset } = query;

  return (
    <DataTable
      offset={offset}
      total={total}
      dataTable={dataTable}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
      bottomRow={bottomRow}
      showEmptyTableMessage={showEmptyTableMessage}
    >
      {!truncateTable &&
        (isAdmin ? (
          <CustomCheckbox onClick={(e) => e.stopPropagation()} />
        ) : (
          <></>
        ))}
      {!truncateTable && (
        <Field title="Nome affiliato" source="name" className="text-left" />
      )}
      <CurrencyField
        title="Importo a contratto"
        source="contractAmount"
        className="txt-table-right"
      />
      {!truncateTable && (
        <Field title="Periodo affiliazione" source="affiliationPeriod" />
      )}
      <CurrencyField
        title="Quota master a contratto"
        source="masterContractAmount"
        className="txt-table-right"
      />
      <CurrencyField
        title="Diritto a Fatturare"
        source="revenue"
        className="txt-table-right"
      />
      {(!truncateTable && isAdmin && (
        <BilledField title="Invitato a Fatturare" className="txt-table-right" />
      )) || <></>}
    </DataTable>
  );
}

export default withRouter(ProvidersTable);
