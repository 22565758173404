import React from 'react';
import { useAlert } from 'react-alert';
import BurgerMenu from '../../commons/BurgerMenu';
import { Col, CustomInput, Form, FormGroup, Label, Row } from 'reactstrap';
import Header from '../../commons/Header';
import {
  ContFilterYear,
  ContFlexBetween,
  ContPaginationPage,
} from '../../styled-components';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import Footer from '../../commons/Footer';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconNetwork } from '../../images/icn-incontro.svg';
import NetworkTable from './NetworkTable';
import { AdminTypes, ProfileStatus, ProfileTypes } from '../../lib/constants';
import SearchInput from '../../commons/SearchInput';

const NetworkPresentational = ({ data, total, query, onFilter }) => {
  const [t] = useTranslation('ALBO');
  const { offset, limit } = query;
  const alert = useAlert();

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <Form>
          <section>
            <Header
              title={t('COMMERCIAL:NETWORK')}
              icon={<IconNetwork fill="#ffffff" style={{ height: '25px' }} />}
            />

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <Form>
                  <ContFlexBetween className="user-filter">
                    <ContFilterYear>
                      <Row>
                        <Col xs="6">
                          <Label>{t('CONTACTS:TITLE_FILTER_TYPE')}</Label>
                        </Col>
                        <Col xs="6">
                          <FormGroup>
                            <CustomInput
                              type="select"
                              id="selectYear"
                              name="year"
                              value={query.type || ''}
                              onChange={(e) =>
                                onFilter({ type: e.target.value })
                              }
                            >
                              <option value="">Tutti</option>
                              {AdminTypes.map((type) => (
                                <option value={type} key={type}>
                                  {t(`CONTACTS:${type}`)}
                                </option>
                              ))}
                            </CustomInput>
                          </FormGroup>
                        </Col>
                      </Row>
                    </ContFilterYear>
                  </ContFlexBetween>
                </Form>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                <SearchInput
                  onFilter={onFilter}
                  query={query}
                  label={t('COMMON:SEARCH')}
                />
              </div>
            </div>
          </section>
        </Form>

        <section className="m-t-20">
          <NetworkTable
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
          />

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit: limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default NetworkPresentational;
