import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  Field,
  DateTimeField,
  ContractEONType,
  CurrencyField,
} from '../../commons/DataTable';
import CustomerTypeField from '../../commons/DataTable/CustomerTypeField';

function ContractsTable({
  data,
  total,
  offset,
  limit,
  onFilter,
  energyUser,
  profile,
}) {
  const [t] = useTranslation('IAF_INVOICES');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="POD/PDR" source="pod_pdr" />
      <Field title="Nome cliente" source="condo_name" className="text-left" />
      <CustomerTypeField title="Tipologia Cliente" source="customerType" />
      <Field title="Partita iva responsabile" source="condo_admin_cf" />
      <Field title="Partita IVA Cliente" source="condo_vat_cf" />
      <DateTimeField
        title="Data creazione"
        source="contract_signing_date"
        format="DD/MM/YYYY"
      />
      <DateTimeField
        title="Data attivazione"
        source="activation_start_date"
        format="DD/MM/YYYY"
      />
      <Field title="Consumo da contratto" source="contractual_consumption" />
      <Field title="Consumo attuale" source="actual_consumption" />
      <ContractEONType title="Nome prodotto" source="product_name" />
      <Field title="Responsabile" source="admin.name" />
      <DateTimeField
        title="Data Scadenza"
        source="contract_end_date"
        format="DD/MM/YYYY"
      />
      {(energyUser && (
        <CurrencyField
          title="Extra COMPENSO Iniziale"
          source="contract_value"
        />
      )) ||
        (profile.type === 'ADMIN_COLLAB' && <></>) || (
          <CurrencyField
            title="Compenso Responsabile Iniziali"
            source="adminShare"
          />
        )}

      {(energyUser && (
        <CurrencyField
          title="Extra COMPENSO Ricorrenti"
          source="sumRecurringContracts"
        />
      )) ||
        (profile.type === 'ADMIN_COLLAB' && <></>) || (
          <CurrencyField
            title="Compenso Responsabile Ricorrenti"
            source="sumRecurringContractsAdminShare"
          />
        )}
    </DataTable>
  );
}

export default ContractsTable;
