import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button } from 'reactstrap';

function ExportCSVCondos({ data = [] }) {
  const [t] = useTranslation();

  const entries = Object.entries(data);
  const csvData = [Object.entries(data[0])?.map(([key]) => key)];
  entries.forEach(([, value]) => {
    csvData.push(Object.entries(value).map(([, value]) => value));
  });

  return (
    <React.Fragment>
      <Button target="_blank" color="primary">
        <CSVLink
          data={csvData}
          filename={`CONDOMINI VALIDI ${moment().format(
            'DD-MM-YYYY hh-mm'
          )}.csv`}
          separator={';'}
          style={{ color: 'white' }}
        >
          {t('CONDOS:EXPORT_CONDOS')}
        </CSVLink>
      </Button>
    </React.Fragment>
  );
}

export default ExportCSVCondos;
