import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import reset from '../../lib/fetch/reset';
import { formatError } from './formatError';
import ResetPresentational from './ResetPresentational';

function Reset(props) {
  const [resetSuccess, setResetSuccess] = useState(false);
  const alert = useAlert();
  const { t } = useTranslation('RESET');
  const onReset = async ({ code = '', password = '' }) => {
    const { error } = await reset(code, password);

    if (error) {
      setResetSuccess(false);
      return alert.error(formatError(error, t));
    }

    setResetSuccess(true);
  };

  return (
    <ResetPresentational
      onReset={onReset}
      resetSuccess={resetSuccess}
      {...props}
    />
  );
}

export default Reset;
