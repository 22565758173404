import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import DashboardListMastersPresentational from './DashboardListMastersPresentational';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import axios from 'axios';

function DashboardListMasters(props) {
  const { showLoading, hideLoading } = useLoading();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [{ token } = {}] = useAuth();
  const { t } = useTranslation('DASHBOARD');
  const alert = useAlert();

  const { percentage, year = new Date().getFullYear() } = queryString.parse(
    location.search
  );

  const fetchMasters = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/dashboard/masters`,
      {
        headers: { Authorization: token },
        params: { percentage, year },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setData(response.data);

    hideLoading();
  };

  useEffect(() => {
    fetchMasters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage]);

  return (
    <DashboardListMastersPresentational
      percentage={percentage}
      data={data}
      year={year}
      {...props}
    />
  );
}

export default DashboardListMasters;
