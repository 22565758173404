import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { DataTable, Field } from '../../commons/DataTable';
import { BadgeField } from '../../commons/DataTableFields';
import { refactoringAdminLevels } from '../../lib/helpers/refactoringAdminLevels';

function ProfilesTable({
  history,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
}) {
  const [t] = useTranslation('COMMON');
  const { limit, offset } = query;

  const refactorData = data.map((profile) => {
    return {
      ...profile,
      type: refactoringAdminLevels(profile.type),
    };
  });

  for (const profile of data) {
    if (profile.profileScore?.length > 0) {
      const score = profile.profileScore[0];

      const {
        EONScoreAuto,
        insuranceScoreAuto,
        insuranceScoreType1Auto,
        insuranceScoreType2Auto,
        supplierScoreAuto,
        bonusScoreAuto,
        energyAndConsumptionScoreAuto,
        trainingScore = 0,
        type,
        EonScoreIsAuto,
        insuranceScoreType1IsAuto,
        insuranceScoreType2IsAuto,
        supplierScoreIsAuto,
        EONScoreManual,
        insuranceScoreType1Manual,
        insuranceScoreType2Manual,
        supplierScoreManual,
      } = score;

      profile['totalScore'] =
        (EonScoreIsAuto === undefined || EonScoreIsAuto === true
          ? EONScoreAuto
          : EONScoreManual) +
        (insuranceScoreType1IsAuto === undefined ||
        insuranceScoreType1IsAuto === true
          ? insuranceScoreType1Auto
          : insuranceScoreType1Manual) +
        (insuranceScoreType2IsAuto === undefined ||
        insuranceScoreType2IsAuto === true
          ? insuranceScoreType2Auto
          : insuranceScoreType2Manual) +
        (supplierScoreIsAuto === undefined || supplierScoreIsAuto === true
          ? supplierScoreAuto
          : supplierScoreManual) +
        bonusScoreAuto +
        energyAndConsumptionScoreAuto +
        trainingScore;
    }
  }

  return (
    <DataTable
      limit={limit}
      offset={offset}
      total={total}
      data={refactorData}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
      onRowClick={({ _id } = {}) =>
        history.push({
          pathname: `/my-collaborators/${_id}`,
          state: { pageBack: 'my-collaborators' },
        })
      }
    >
      <Field title="Nome" source="name" />
      <Field title="Cognome" source="surname" />
      <Field title="Email" source="emailReference" />
      <Field title="Tipologia" source="type" className="type" />
      <BadgeField title="Utente attivo" source="isActive" />
    </DataTable>
  );
}

export default withRouter(ProfilesTable);
