import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportCSVCondos from './ExportCSVCondos';

function ExportCSVButton({ data = [], text }) {
  const [t] = useTranslation();

  const mappedData = data.map((condo) => {
    delete condo['N. PDR'];
    delete condo['N. POD'];
    delete condo['Amministratore in carica'];
    delete condo['Fine gestione'];
    delete condo['Gestione scaduta'];
    delete condo['Inizio gestione'];
    delete condo['Gestore di studio'];

    return condo;
  });

  if (data.length === 0) return null;
  return <ExportCSVCondos data={mappedData} />;
}

export default ExportCSVButton;
