import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {
  DataTable,
  Field,
  CurrencyField,
  DateTimeField,
  PercentageField,
  BooleanField,
} from '../../commons/DataTable';
import InvoiceCheckbox from './InvoiceCheckbox';

function DashboardDetailTable(props) {
  const {
    data = [],
    total = 0,
    selectedInsurances = [],
    topRow,
    bottomRow,
    onSelectChange,
    onFilter,
    query = {},
    onSelectAll = () => {},
  } = props;
  const alert = useAlert();
  const [t] = useTranslation('COMMON');
  const { offset, limit } = query;
  const [selectedAll, setSelectedAll] = useState(false);
  const onRowClick = ({ _id, gabettiInvoice, paid, supplier }) => {
    if (!_id || gabettiInvoice) return;
    if (!paid) return alert.error(t('ROYALTY:NOT_PAID_ERROR'));

    const { _id: supplierId } = supplier;
    const [firstSelectedInsuranceId] = selectedInsurances;
    if (!firstSelectedInsuranceId) return onSelectChange(_id);

    const firstSelectedInsurance =
      data.find(({ _id }) => {
        return _id === firstSelectedInsuranceId;
      }) || {};
    const { supplier: firstSelectedSupplier } = firstSelectedInsurance;
    const { _id: firstSelectedSupplierId } = firstSelectedSupplier || {};
    if (supplierId === firstSelectedSupplierId) return onSelectChange(_id);

    alert.error(t('ROYALTY:DIFFERENT_SUPPLIER_ERROR'));
  };
  const paidInsurances = data.filter(
    ({ paid, gabettiInvoice }) => paid && !gabettiInvoice
  );

  useEffect(() => {
    if (!selectedAll) {
      selectedInsurances.length === paidInsurances.length && onSelectAll([]);
      return;
    }
    if (!paidInsurances || !paidInsurances.length) {
      setSelectedAll(false);
      return;
    }

    const isSameSupplier = paidInsurances
      .map(({ supplier: { _id } = {} }) => _id)
      .every((val, i, arr) => val === arr[0]);

    if (isSameSupplier) {
      onSelectAll(paidInsurances);
      return;
    }

    alert.error(t('ROYALTY:DIFFERENT_SUPPLIER_ERROR'));
    setSelectedAll(false);
  }, [selectedAll]);

  useEffect(() => {
    if (selectedInsurances.length === paidInsurances.length) return;
    setSelectedAll(false);
  }, [selectedInsurances]);

  return (
    <DataTable
      history
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      topRow={topRow}
      bottomRow={bottomRow}
      onRowClick={onRowClick}
      onFilter={onFilter}
    >
      <InvoiceCheckbox
        selected={selectedInsurances}
        onSelectChange={onRowClick}
        onClick={(e) => e.stopPropagation()}
        title={
          <input
            type="checkbox"
            checked={selectedAll}
            onChange={({ target: { checked } }) => setSelectedAll(checked)}
          />
        }
      />
      <Field title="Responsabile" source="admin.businessName" />
      <Field title="Assicurazione" source="insuranceNumber" />
      <Field title="Riferimento Fattura" source="gabettiInvoice.invoiceRef" />
      <Field
        title="Nome Fornitore"
        source="supplier.businessName"
        className="text-left"
      />
      <Field title="Contraente" source="contractor" />
      <Field title="Tipo" source="insuranceType" />
      <DateTimeField
        title="Data inizio"
        format="DD/MM/YYYY"
        source="startDate"
      />
      <DateTimeField title="Data fine" format="DD/MM/YYYY" source="endDate" />
      <DateTimeField
        title="Data incasso prevista"
        format="DD/MM/YYYY"
        source="supposedPaymentDate"
      />
      <CurrencyField
        title="Importo imponibile"
        className="txt-table-right"
        source="net"
      />
      <CurrencyField
        title="Importo da incassare"
        className="txt-table-right"
        source="gross"
      />
      <DateTimeField
        title="Mese Rend."
        source="accountability"
        format="MM/YYYY"
      />
      <CurrencyField title="Quota Extra" source="extraShare" />
      <CurrencyField title="Quota responsabile" source="adminShare" />
      <CurrencyField
        title="Diritto a fatturare responsabile"
        source="billableAdminShare"
      />
      <CurrencyField title="Quota commerciale" source="commercialShare" />
      <CurrencyField
        title="Diritto a fatturare commerciale"
        source="billableCommercialShare"
      />
      <BooleanField title="Pagata" source="paid" />
    </DataTable>
  );
}

export default withRouter(DashboardDetailTable);
