import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';

export default (props) => {
  const {
    allColumns,
    fetchCompensiExport,
    isReady,
    setIsReady,
    children,
    data = [],
    selectedInvoices = [],
    year,
  } = props;

  const hasSelectedInvoices = selectedInvoices && selectedInvoices.length;
  let csvData, invoices;

  if (!allColumns || hasSelectedInvoices) {
    csvData = [
      [
        'NOME FORNITORE',
        'NUMERO FATTURA',
        'DATA FATTURA',
        'MESE RENDICONTAZIONE',
        'CONDOMINIO',
        'AMMINISTRATORE DI CONDOMINIO',
        'IMPONIBILE',
        'IMPORTO DA INCASSARE',
        '% EXTRA 1 (€)',
        '% EXTRA 1',
        '% EXTRA 2 (€)',
        '% EXTRA 2',
      ],
    ];
    invoices = hasSelectedInvoices
      ? data.filter(({ _id }) => selectedInvoices.includes(_id))
      : data;
  } else {
    csvData = [
      [
        'NOME FORNITORE',
        'RIFERIMENTO FATTURA',
        'NUMERO FATTURA',
        'DATA FATTURA',
        'MESE RENDICONTAZIONE',
        'CONDOMINIO',
        'AMMINISTRATORE DI CONDOMINIO',
        'IMPONIBILE',
        'IMPORTO DA INCASSARE',
        'INCASSI',
        '% EXTRA (€)',
        '% EXTRA',
        'NOMINATIVO MASTER',
        'QUOTA MASTER',
        '% QUOTA MASTER',
        'QUOTA MASTER MATURATA',
        'DIRITTO A FATTURARE (MASTER)',
        'PAGATA',
        'REGOLARIZZATA',
      ],
    ];
    invoices = data;
  }

  invoices.forEach((compenso = {}) => {
    const {
      supplier: { businessName: supplierName = '' } = {},
      number,
      date,
      accountability,
      customer,
      admin: { businessName: adminName = '', type: adminType = '' } = {},
      gross,
      net,
      payments = [],
      paid,
      gabettiPerc,
      earnedMasterShares = [],
      masterBillable = [],
      masterShares = [],
      masterSharesPerc = [],
      masters = [],
      gabettiInvoice = {},
      gabettiPercentShare,
    } = compenso;
    const [firstMaster = {}] = masters;
    const reducedMasterShares = masterShares.reduce(
      (acc, share = 0) => acc + share || 0,
      0
    );
    const isGNetAdmin = adminType === 'GNET_ADMIN';

    if (!allColumns || hasSelectedInvoices) {
      csvData.push([
        // 'NOME FORNITORE',
        supplierName,
        // 'NUMERO FATTURA',
        number,
        // 'DATA FATTURA',
        moment(date).format('DD/MM/YYYY'),
        // 'MESE RENDICONTAZIONE',
        accountability ? moment(accountability).format('MM/YYYY') : '',
        // 'CONDOMINIO',
        customer,
        // 'AMMINISTRATORE DI CONDOMINIO',
        adminName,
        // 'IMPONIBILE',
        formatCurrency(net),
        // 'IMPORTO DA INCASSARE',
        formatCurrency(gross),
        // '% EXTRA 1 (€)',
        formatCurrency(gabettiPerc),
        // '% EXTRA 1',
        gabettiPercentShare,
        // '% EXTRA 2 (€)',
        isGNetAdmin ? formatCurrency(reducedMasterShares) : '',
        // '% EXTRA 2',
        isGNetAdmin ? masterSharesPerc[0] || 0 : '',
      ]);
    } else {
      csvData.push([
        // 'NOME FORNITORE',
        supplierName,
        // 'RIFERIMENTO FATTURA',
        gabettiInvoice.invoiceRef || '',
        // 'NUMERO FATTURA',
        number,
        // 'DATA FATTURA',
        moment(date).format('DD/MM/YYYY'),
        // 'MESE RENDICONTAZIONE',
        accountability ? moment(accountability).format('MM/YYYY') : '',
        // 'CONDOMINIO',
        customer,
        // 'AMMINISTRATORE DI CONDOMINIO',
        adminName,
        // 'IMPONIBILE',
        formatCurrency(net),
        // 'IMPORTO DA INCASSARE',
        formatCurrency(gross),
        // 'INCASSI',
        formatCurrency(payments.reduce((acc, val) => acc + val.gross, 0)),
        // '% EXTRA (€)',
        formatCurrency(gabettiPerc),
        // '% EXTRA',
        gabettiPercentShare,
        // 'NOMINATIVO MASTER',
        firstMaster.name || '',
        // 'QUOTA MASTER',
        formatCurrency(masterShares[0] || 0),
        // '% QUOTA MASTER',
        masterSharesPerc[0] || 0,
        // 'QUOTA MASTER MATURATA',
        formatCurrency(earnedMasterShares[0] || 0),
        // 'DIRITTO A FATTURARE (MASTER)
        formatCurrency(masterBillable[0] || 0),
        // 'PAGATA',
        paid ? 'Si' : 'No',
        // 'REGOLARIZZATA',
      ]);
    }
  });

  const handleClick = (event) => {
    if (!isReady) {
      event.preventDefault();
      fetchCompensiExport();
    }
  };

  const csvLinkRef = useRef();
  useEffect(() => {
    if (isReady && csvLinkRef.current) {
      csvLinkRef.current.link.click();
      setIsReady(false);
    }
  }, [isReady]);

  return (
    <CSVLink
      data={csvData}
      filename={`COMPENSI_VARIABILI_${year}.csv`}
      separator={';'}
      onClick={handleClick}
      ref={csvLinkRef}
    >
      {children}
    </CSVLink>
  );
};
