import React from 'react';
import SearchInput from '../SearchInput';
import AddButton from './AddButton';
import './SearchAddCont.scss';
import { useTranslation } from 'react-i18next';

function SearchAddCont({ isLoading, onAdd, addLabel, onFilter, query }) {
  const [t] = useTranslation('COMMON');
  return (
    <div className="row search-add-cont">
      <div className="col-sm-12 col-md-6 col-lg-8">
        <SearchInput onFilter={onFilter} query={query} label={t('SEARCH')} />
      </div>
      <div className="col-sm-12 col-md-6 col-lg-4">
        <AddButton isLoading={isLoading} onAdd={onAdd} addLabel={addLabel} />
      </div>
    </div>
  );
}

export default SearchAddCont;
