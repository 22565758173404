import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ReactComponent as DownloadIcon } from '../../../src/images/icn_download.svg';

function ExportCSVStudioInvoices({ data = [] }) {
  const [t] = useTranslation();

  const entries = Object.entries(data);
  const csvData = [Object.entries(data[0])?.map(([key]) => key)];
  entries.forEach(([, value]) => {
    csvData.push(Object.entries(value).map(([, value]) => value));
  });

  return (
    <React.Fragment>
      <CSVLink
        data={csvData}
        filename={`FATTURE STUDIO NON IMPORTATE ${moment().format(
          'DD-MM-YYYY hh-mm'
        )}.csv`}
        separator={';'}
      >
        <DownloadIcon height={25} width={25} stroke="#000" />
      </CSVLink>
    </React.Fragment>
  );
}

export default ExportCSVStudioInvoices;
