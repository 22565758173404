import React, { useState, useEffect } from 'react';
import { useAuth } from '../../commons/Auth';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAlert } from 'react-alert';
import UpdateCustomerExtraModal from './UpdateCustomerExtraModal';

function ActionsField(props) {
  const {
    data,
    profile,
    onCustomerExtraUpdate,
    truncateTable,
    checkVatCode,
  } = props;

  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation('CustomerExtraS');

  return (
    <React.Fragment>
      <div className="flex-check">
        <UpdateCustomerExtraModal
          administrator={profile}
          onConfirm={onCustomerExtraUpdate}
          defaultValues={data}
        />
      </div>
    </React.Fragment>
  );
}

export default ActionsField;
