import React from 'react';
import { useTranslation } from 'react-i18next';
// import Menu from '../../commons/Menu';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconAffiliations } from '../../images/icn-incontro.svg';
import AffiliationCard from './AffiliationCard';
import { PNoActiveAffiliation } from '../../styled-components';
import variables from '../../variables';

const ReadonlyAffiliationsPresentational = ({ data = [] }) => {
  const [t] = useTranslation('CONTACTS');

  return (
    <div className="App">
      {/* <Menu /> */}
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('AFFILIATIONS')}
            icon={<IconAffiliations fill={variables.white} />}
          />
        </section>
        <section>
          {(!data || !data.length) && (
            <PNoActiveAffiliation>
              {t('NO_AFFILIATIONS_MESSAGE')}
            </PNoActiveAffiliation>
          )}
          {data.map((affiliation, index) => {
            return <AffiliationCard key={index} affiliation={affiliation} />;
          })}
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default ReadonlyAffiliationsPresentational;
