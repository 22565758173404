import React from 'react';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import axios from 'axios';
import { remove, save } from '../../../lib/fetch/profiles';
import CollaboratorInfoPresentational from './CollaboratorInfoPresentational';
import forgot from '../../../lib/fetch/forgot';

const CollaboratorInfo = (props) => {
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const { history, id, profile, setProfile } = props;
  const isUpdating = id !== 'create';

  async function updateProfile(updates) {
    showLoading();

    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/collaborators/${id}`,
      data: updates,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setProfile(response.data);
    hideLoading();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
  }

  async function enable({ active, email, password }) {
    showLoading();
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/collaborators/${id}`,
      data: {
        active,
        email,
        password,
      },
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setProfile({});
    hideLoading();
    if (profile.authId) {
      alert.success(t('COLLABORATORS:ALLERT_NOT_ACTIVE_COLLABORATOR'));
    } else {
      alert.success(t('COLLABORATORS:ALLERT_ACTIVE_COLLABORATOR'));
    }
    history.push('/my-collaborators');
  }

  const forgotPassword = async () => {
    showLoading();
    const { authEmail } = profile;
    const { error } = await forgot(authEmail);
    hideLoading();
    if (error) alert.error(t('COMMON:GENERIC_ERROR'));
    else alert.success(t('USERS:USER_FORGOT_SUCCESS'));
  };

  return (
    <CollaboratorInfoPresentational
      data={profile}
      isUpdating={isUpdating}
      updateProfile={updateProfile}
      enable={enable}
      forgotPassword={forgotPassword}
      {...props}
    />
  );
};

export default CollaboratorInfo;
