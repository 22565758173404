import React from 'react';

function Checkbox({ data, selected = [], onSelectChange = () => {} }) {
  return (
    <input
      type="checkbox"
      checked={selected.includes(data._id)}
      onChange={() => onSelectChange(data._id)}
    />
  );
}

export default Checkbox;
