import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  Field,
  CurrencyField,
  DateTimeField,
} from '../../../commons/DataTable';
import ExportIAFCSVField from '../../../commons/DataTable/ExportIAFCSVField';
import ModifyBonusModal from './ModifyBonusModal';
import AddPaymentDateField from '../../../commons/DataTable/AddPaymentDateField';

function InvoiceBonusTable({
  data,
  handleUpdateBonus,
  isAdmin,
  onFilter,
  addPaymentDate,
}) {
  const [t] = useTranslation('COMMON');

  function generateFields() {
    const fields = [
      <Field
        key={1}
        title="Numero IAF"
        source="number"
        className="text-left"
      />,
      <Field key={2} title="Rif. Fattura Responsabile" source="invoiceRef" />,
      <Field
        key={3}
        title={t('ROYALTY:IAF_ACCOUNTABILITY')}
        source="accountability"
      />,
      <DateTimeField key={4} title="Data" source="date" format="DD/MM/YYYY" />,
      <CurrencyField key={9} title="Importo" source="amount" />,
    ];

    if (isAdmin) {
      fields.push(
        <DateTimeField
          key={5}
          title={'Data pagamento'}
          source="paymentDate"
          format="DD/MM/YYYY"
        />,
        <AddPaymentDateField
          key={6}
          title="Stato pagamento"
          onAddPaymentDate={addPaymentDate}
        />,
        <ModifyBonusModal
          key={7}
          title="Modifica"
          onConfirm={handleUpdateBonus}
        />,
        <ExportIAFCSVField key={8} title={t('COMMON:CSV_EXPORT')} />
      );
    }

    return fields;
  }

  return (
    <DataTable
      onFilter={onFilter}
      data={data}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
    >
      {generateFields()}
    </DataTable>
  );
}

export default withRouter(InvoiceBonusTable);
