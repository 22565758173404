import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import CustomersExtraPresentational from './CustomersExtraPresentational';
import { set, unset } from 'lodash/object';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const CustomersExtra = ({ profile }) => {
  const [customersExtra, setCustomersExtra] = useState([]);

  const [total, setTotal] = useState(0);
  const [{ token, isAdmin } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    profileId: withDefault(StringParam, ''),
    referenceId: withDefault(StringParam, ''),
    businessName: withDefault(StringParam, ''),
    taxCode: withDefault(StringParam, ''),
    businessNameProfile: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    profileId,
    referenceId,
    businessName,
    taxCode,
    businessNameProfile,
  } = query;

  useEffect(() => {
    fetchCustomersExtra();
  }, [
    limit,
    offset,
    profileId,
    referenceId,
    businessName,
    taxCode,
    businessNameProfile,
  ]);

  const fetchCustomersExtra = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/customer-extra`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          profileId,
          referenceId,
          businessName,
          taxCode,
          businessNameProfile,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setCustomersExtra(data);
    setTotal(+headers['x-total-count']);
  };

  function onFilter(queryDiff) {
    if (
      (queryDiff.businessName && queryDiff.businessName !== businessName) || // first search
      (businessName && queryDiff.businessName === '') || // search deleted
      (queryDiff.businessNameProfile &&
        queryDiff.businessNameProfile !== businessNameProfile) || // first search
      (businessNameProfile && queryDiff.businessNameProfile === '') || // search deleted
      (queryDiff.profileId && queryDiff.profileId !== profileId) || // first search
      (profileId && queryDiff.profileId === '') || // search deleted
      (queryDiff.referenceId && queryDiff.referenceId !== referenceId) || // first search
      (referenceId && queryDiff.referenceId === '') || // search deleted
      (queryDiff.taxCode && queryDiff.taxCode !== taxCode) || // first search
      (taxCode && queryDiff.taxCode === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  const createCustomerExtra = async (data) => {
    delete data.profile;
    delete data.estateAgent;
    showLoading();

    if (data?.profile?._id) {
      set(data, 'profileId', data.profile._id);
      unset(data, 'profile');
    }
    if (data?.reference?._id) {
      set(data, 'referenceId', data.reference._id);
      unset(data, 'reference');
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/customer-extra`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchCustomersExtra();
  };

  const updateCustomerExtra = async (data, id) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/customer-extra/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      const message = JSON.parse(response?.request?.response).message;
      return alert.error(message || t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchCustomersExtra();
  };

  const checkTaxCode = async (taxCode, profileId) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/customer-extra/checkTaxCode`,
      {
        taxCode: taxCode,
        profileId: profileId,
      },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      throw new Error('Errore nel controllo della partita IVA');
    }

    return response.data;
  };

  const searchEstateAgents = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/estateAgents`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  return (
    <CustomersExtraPresentational
      data={customersExtra}
      profile={profile}
      isAdmin={isAdmin}
      query={query}
      total={total}
      onFilter={onFilter}
      onNewCustomerExtraConfirm={createCustomerExtra}
      onCustomerExtraUpdate={updateCustomerExtra}
      checkTaxCode={checkTaxCode}
      searchEstateAgents={searchEstateAgents}
    />
  );
};

export default CustomersExtra;
