import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import {
  ButtonGroup,
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useAuth } from '../../../commons/Auth';
import {
  BtnRadiusRed,
  ButtonSave,
  ContFlexLeft,
  ContFlexRight,
  H5Styled,
} from '../../../styled-components';
import FormErrorMessage from '../../../commons/FormErrorMessage';
import useForm from 'react-hook-form';
import TrainingHistory from './TrainingHistory';
import DateInput from '../../../commons/DateInput';
import plusIcon from '../../../images/icn-plus.svg';
import plusTrash from '../../../images/icn-trash.svg';

const ScorePresentational = ({
  data = {},
  profileScore = {},
  saveProfileScoreSettings,
  isAuto,
  setIsAuto,
  EonScoreIsAuto,
  setEonScoreIsAuto,
  insuranceScoreType1IsAuto,
  setInsuranceScoreType1IsAuto,
  insuranceScoreType2IsAuto,
  setInsuranceScoreType2IsAuto,
  supplierScoreIsAuto,
  setSupplierScoreIsAuto,
}) => {
  const [t] = useTranslation('SCORE');
  const alert = useAlert();
  const [{ isAdmin = false, token }] = useAuth();
  const { register, errors, handleSubmit, watch, setValue, reset } = useForm({
    mode: 'onChange',
    defaultValues: profileScore,
  });

  const [formErrors, setFormErrors] = useState({
    nominative: false,
    data: false,
    creditsMin: false,
    creditsMax: false,
  });

  function onSubmit(values) {
    delete values.nominative;
    delete values.credits;

    const formData = {
      ...values,
      trainingCourses,
    };

    const errors = [];

    if (trainingCourses && errors) {
      for (let index = 0; index < trainingCourses.length; index++) {
        if (trainingCourses[index]?.nominative?.length === 0) {
          errors[index] = errors[index] || {};
          errors[index].nominative = true;
        }

        if (trainingCourses[index]?.date?.length === 0) {
          errors[index] = errors[index] || {};
          errors[index].date = true;
        }

        if (trainingCourses[index]?.credits < 0) {
          errors[index] = errors[index] || {};
          errors[index].creditsMin = true;
        }

        if (trainingCourses[index]?.credits > 100) {
          errors[index] = errors[index] || {};
          errors[index].creditsMax = true;
        }
      }
    }

    if (errors.length > 0) {
      return setFormErrors(errors);
    }

    setFormErrors({
      nominative: false,
      data: false,
      creditsMin: false,
      creditsMax: false,
    });
    saveProfileScoreSettings({ formData, token });
  }

  const initialTrainingCourses = {
    nominative: '',
    date: '',
    credits: 0,
  };
  const [trainingCourses, setTrainingCourses] = useState();

  const handleCheck = (index, e, selected) => {
    let temp = [...trainingCourses];
    temp[index][selected] =
      selected === 'credits' ? Number(e.target.value) : e.target.value;

    setTrainingCourses(temp);
  };

  const handleCheckDate = (index, e) => {
    let temp = [...trainingCourses];
    temp[index].date = e;
    setTrainingCourses(temp);
  };

  const handleNewRow = () => {
    setTrainingCourses([
      ...trainingCourses,
      {
        nominative: '',
        date: '',
        credits: 0,
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    trainingCourses.splice(index, 1);
    setTrainingCourses([...trainingCourses]);
  };

  useEffect(() => {
    setTrainingCourses(
      profileScore.trainingCourses?.length > 0
        ? profileScore.trainingCourses
        : []
    );
    reset(profileScore);
  }, [profileScore, reset]);

  let totalTrainingCalculatedData = 0;
  for (let index = 0; index < profileScore?.trainingCourses?.length; index++) {
    totalTrainingCalculatedData += Number(
      profileScore?.trainingCourses[index]?.credits
    );
  }
  if (totalTrainingCalculatedData > 100) totalTrainingCalculatedData = 100;

  return (
    <Form>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="customCheckbox1"
                  name="EonScoreIsAuto"
                  innerRef={register}
                  checked={EonScoreIsAuto}
                  onClick={() => {
                    setEonScoreIsAuto(!EonScoreIsAuto);
                  }}
                >
                  {t('EON_SCORE_VISIBLE')}
                </CustomInput>
                <FormErrorMessage errors={errors} name="EonScoreIsAuto" />
              </FormGroup>
            </Col>

            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="customCheckbox2"
                  name="insuranceScoreType1IsAuto"
                  innerRef={register}
                  checked={insuranceScoreType1IsAuto}
                  onClick={() =>
                    setInsuranceScoreType1IsAuto(!insuranceScoreType1IsAuto)
                  }
                >
                  {t('INSURANCE_SCORE_TYPE_1_VISIBLE')}
                </CustomInput>
                <FormErrorMessage
                  errors={errors}
                  name="insuranceScoreType1IsAuto"
                />
              </FormGroup>
            </Col>

            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="customCheckbox3"
                  name="insuranceScoreType2IsAuto"
                  innerRef={register}
                  checked={insuranceScoreType2IsAuto}
                  onClick={() =>
                    setInsuranceScoreType2IsAuto(!insuranceScoreType2IsAuto)
                  }
                >
                  {t('INSURANCE_SCORE_TYPE_2_VISIBLE')}
                </CustomInput>
                <FormErrorMessage
                  errors={errors}
                  name="insuranceScoreType2IsAuto"
                />
              </FormGroup>
            </Col>

            <Col sm="12" md="6" lg="3">
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="customCheckbox4"
                  name="supplierScoreIsAuto"
                  innerRef={register}
                  checked={supplierScoreIsAuto}
                  onClick={() => setSupplierScoreIsAuto(!supplierScoreIsAuto)}
                >
                  {t('SUPPLIER_SCORE_VISIBLE')}
                </CustomInput>
                <FormErrorMessage errors={errors} name="supplierScoreIsAuto" />
              </FormGroup>
            </Col>
          </Row>

          <br />

          <Row>
            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>Crediti EON</Label>
                <Input
                  type="number"
                  name="EONScoreManual"
                  innerRef={register}
                  defaultValue={profileScore.EONScore || 0}
                  disabled={EonScoreIsAuto}
                />
                <FormErrorMessage errors={errors} name="EONScoreManual" />
              </FormGroup>
            </Col>

            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>Crediti Assicurazione Tipo 1</Label>
                <Input
                  type="number"
                  name="insuranceScoreType1Manual"
                  maxLength="50"
                  innerRef={register({
                    min: {
                      value: 0,
                      message: t('MIN_ADMIN_CREDITS'),
                    },
                    max: {
                      value: 100,
                      message: t('MAX_ADMIN_CREDITS'),
                    },
                  })}
                  defaultValue={profileScore.insuranceScoreType1 || 0}
                  disabled={insuranceScoreType1IsAuto}
                />
                <FormErrorMessage
                  errors={errors}
                  name="insuranceScoreType1Manual"
                />
              </FormGroup>
            </Col>

            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>Crediti Assicurazione Tipo 2</Label>
                <Input
                  type="number"
                  name="insuranceScoreType2Manual"
                  maxLength="50"
                  innerRef={register({
                    min: {
                      value: 0,
                      message: t('MIN_ADMIN_CREDITS'),
                    },
                    max: {
                      value: 100,
                      message: t('MAX_ADMIN_CREDITS'),
                    },
                  })}
                  defaultValue={profileScore.insuranceScoreType2 || 0}
                  disabled={insuranceScoreType2IsAuto}
                />
                <FormErrorMessage
                  errors={errors}
                  name="insuranceScoreType2Manual"
                />
              </FormGroup>
            </Col>

            <Col sm="12" md="6" lg="4">
              <FormGroup>
                <Label>Crediti Fatture</Label>
                <Input
                  type="number"
                  name="supplierScoreManual"
                  maxLength="50"
                  innerRef={register({
                    min: {
                      value: 0,
                      message: t('MIN_ADMIN_CREDITS'),
                    },
                    max: {
                      value: 200,
                      message: t('MAX_ADMIN_CREDITS_SUPPLIER'),
                    },
                  })}
                  defaultValue={profileScore.supplierScore || 0}
                  disabled={supplierScoreIsAuto}
                />
                <FormErrorMessage errors={errors} name="supplierScoreManual" />
              </FormGroup>
            </Col>
          </Row>
        </TabPane>
      </TabContent>

      <br />

      <div className="m-tb-20">
        <Nav tabs>
          <NavItem>
            <NavLink className="active">{t('EDUCATIONAL_HISTORY')}</NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={'1'}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{ display: 'flex', alignItems: 'center', width: '50%' }}
            >
              <H5Styled style={{ margin: '0' }}>
                Aggiungi nuova formazione
              </H5Styled>
              <BtnRadiusRed
                type="button"
                onClick={handleNewRow}
                style={{ marginLeft: '0.5rem' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  class="bi bi-plus-lg"
                  viewBox="0 0 16 16"
                  style={{ fill: 'white', width: '25px', height: '25px' }}
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                  />
                </svg>
              </BtnRadiusRed>
            </div>

            <ContFlexRight>
              <H5Styled style={{ margin: '0' }}>
                Crediti assegnati:{' '}
                {profileScore.trainingScore
                  ? profileScore.trainingScore
                  : totalTrainingCalculatedData}
              </H5Styled>
            </ContFlexRight>
          </div>
          <TabPane tabId="1">
            {trainingCourses?.map((row, index) => (
              <Row key={index}>
                <Col sm="12" md="4" lg="4">
                  <FormGroup>
                    <Label>{t('NOMINATIVE')}</Label>
                    <Input
                      type="text"
                      name="nominative"
                      value={trainingCourses[index].nominative}
                      onChange={(e) => handleCheck(index, e, 'nominative')}
                      innerRef={register}
                    />
                    {formErrors[index]?.nominative && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="12" md="2" lg="2">
                  <FormGroup>
                    <Label>{t('DATE')}</Label>
                    <DateInput
                      name="date"
                      value={trainingCourses[index].date}
                      onChange={(e) => handleCheckDate(index, e, 'date')}
                      innerRef={register}
                    />
                    {formErrors[index]?.date && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="12" md="2" lg="2">
                  <FormGroup>
                    <Label>{t('CREDITS')}</Label>
                    <Input
                      type="number"
                      name="credits"
                      defaultValue={trainingCourses[index].credits || 0}
                      onChange={(e) => handleCheck(index, e, 'credits')}
                      innerRef={register}
                    />
                    {formErrors[index]?.credits && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                    {formErrors[index]?.creditsMin && (
                      <FormText className="error-message">MIN. 0</FormText>
                    )}
                    {formErrors[index]?.creditsMax && (
                      <FormText className="error-message">MAX. 100</FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="12" md="2" lg="2">
                  <ButtonGroup className="mt-4">
                    <BtnRadiusRed
                      type="button"
                      className="mr-3"
                      onClick={() => handleRemoveRow(index)}
                    >
                      <img src={plusTrash} alt="Elimina" />
                    </BtnRadiusRed>
                  </ButtonGroup>
                </Col>
              </Row>
            ))}
          </TabPane>
        </TabContent>
      </div>

      <ContFlexRight>
        <ButtonSave
          type="button"
          onClick={(e) => {
            if (Object.keys(errors).length > 0) window.scrollTo({ top: 0 });
            handleSubmit(onSubmit)(e);
          }}
        >
          {t('BTN_CREATE')}
        </ButtonSave>
      </ContFlexRight>
    </Form>
  );
};

export default ScorePresentational;
