import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

const TotalTr = ({ totals = {} }) => {
  const { lastYear = 0 } = totals;

  return (
    <tr>
      <td />
      <td />
      <td />
      <td className="txt-table-right">
        <strong>SALDO ANNO PRECEDENTE</strong>: {formatCurrency(lastYear)}
      </td>
    </tr>
  );
};

export default TotalTr;
