import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {
  DataTable,
  Field,
  CurrencyField,
  DateTimeField,
  BooleanField,
  PaymentsDataField,
  PercentageField,
} from '../../commons/DataTable';
import { INVOICES_ALLOWED_TYPES } from '../../App';
import ModalSendEmail from './ModalSendEmail';
import { SendOverdueInvoiceEmailCheckBoxes } from '../../commons/Checkboxes/SendOverdueInvoiceEmailCheckboxes';

function DashboardDetailTable(props) {
  const {
    data = [],
    total = 0,
    selectedInvoices = [],
    topRow,
    bottomRow,
    onSelectChange,
    onFilter,
    query = {},
    onSelectAll = () => {},
    handleSendEmails,
    emailOnSelectChange,
  } = props;
  const alert = useAlert();
  const [t] = useTranslation('COMMON');
  const { offset, limit } = query;
  const [selectedAll, setSelectedAll] = useState(false);
  const onRowClick = ({ _id, gabettiInvoice, paid, supplier }) => {
    if (!_id || gabettiInvoice) return;
    if (!paid) return alert.error(t('ROYALTY:NOT_PAID_ERROR'));

    const { _id: supplierId } = supplier;
    const [firstSelectedInvoiceId] = selectedInvoices;
    if (!firstSelectedInvoiceId) return onSelectChange(_id);

    const firstSelectedInvoice =
      data.find(({ _id }) => {
        return _id === firstSelectedInvoiceId;
      }) || {};
    const { supplier: firstSelectedSupplier } = firstSelectedInvoice;
    const { _id: firstSelectedSupplierId } = firstSelectedSupplier || {};
    if (supplierId === firstSelectedSupplierId) return onSelectChange(_id);

    alert.error(t('ROYALTY:DIFFERENT_SUPPLIER_ERROR'));
  };

  const paidInvoices = data.filter(
    ({ paid, gabettiInvoice }) => paid && !gabettiInvoice
  );

  useEffect(() => {
    if (!selectedAll) {
      selectedInvoices.length === paidInvoices.length && onSelectAll([]);
      return;
    }
    if (!paidInvoices || !paidInvoices.length) {
      setSelectedAll(false);
      return;
    }

    const isSameSupplier = paidInvoices
      .map(({ supplier: { _id } = {} }) => _id)
      .every((val, i, arr) => val === arr[0]);

    if (!isSameSupplier) {
      alert.error(t('ROYALTY:DIFFERENT_SUPPLIER_ERROR'));
      setSelectedAll(false);
      return;
    }

    onSelectAll(paidInvoices);
  }, [selectedAll]);

  useEffect(() => {
    if (selectedInvoices.length === paidInvoices.length) return;
    setSelectedAll(false);
  }, [selectedInvoices]);

  const emailOnCheck = (invoice) => {
    emailOnSelectChange(invoice);
  };

  return (
    <DataTable
      history
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      topRow={topRow}
      bottomRow={bottomRow}
      onRowClick={onRowClick}
      onFilter={onFilter}
    >
      <SendOverdueInvoiceEmailCheckBoxes
        onSelectChange={emailOnCheck}
        onClick={(e) => e.stopPropagation()}
      />
      <Field
        title="Nome Fornitore"
        source="supplier.businessName"
        className="text-left"
      />
      <Field
        title="Riferimento Fattura"
        source="extraInvoice.invoicePinv.number"
      />
      <Field title="Numero Fattura" source="number" />
      <DateTimeField title="Data Fattura" source="date" format="DD/MM/YYYY" />
      <DateTimeField
        title="Mese Rend."
        source="accountability"
        format="MM/YYYY"
      />
      <Field title="Condominio" source="customer" />
      <Field title="Responsabile Clienti" source="admin.businessName" />
      <CurrencyField title="Imponibile" source="net" />
      <CurrencyField title="Importo da Incassare" source="gross" />
      <PaymentsDataField title="Incassi" source="payments" />
      <CurrencyField title="% Extra (€)" source="extraShare" />
      <CurrencyField title="Quota responsabile (10%)" source="adminShare" />
      <CurrencyField
        title="Quota responsabile maturata"
        source="earnedAdminShare"
      />
      <CurrencyField
        title="Diritto a fatturare responsabile"
        source="billableAdminShare"
      />
      <CurrencyField title="Quota commerciale" source="commercialShare" />
      <CurrencyField
        title="Quota commerciale maturata"
        source="earnedCommercialShare"
      />
      <CurrencyField
        title="Diritto a fatturare commerciale"
        source="billableCommercialShare"
      />
      <BooleanField title="Pagata" source="paid" />
      <Field title="Numero Solleciti Inviati" source="overdueInvoicesCount" />
      <DateTimeField
        title="Data Ultimo Sollecito"
        source="latestOverdueInvoiceDate"
        className="text-center"
        format="DD/MM/YYYY"
      />
      {/* <ModalSendEmail
        title="Invia Sollecito"
        handleSendEmails={handleSendEmails}
      /> */}
    </DataTable>
  );
}

export default withRouter(DashboardDetailTable);
