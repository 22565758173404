import React from 'react';
import { isEmailCheckboxCheckable } from '../../lib/helpers/isEmailCheckboxCheckable';

export function SendOverdueInvoiceEmailCheckBoxes({
  data = {},
  onSelectChange = () => {},
}) {
  return (
    <>
      {isEmailCheckboxCheckable(data) ? (
        <input
          type="checkbox"
          onChange={(e) => {
            e.stopPropagation();
            onSelectChange(data);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}
