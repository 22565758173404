import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  Field,
  DateTimeField,
  ContractEONType,
} from '../../commons/DataTable';
import CurrencyField from '../../commons/DataTable/CurrencyField';

function ContractsTable({ data, total, offset, limit, onFilter, bottomRow }) {
  const [t] = useTranslation('IAF_INVOICES');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
      bottomRow={bottomRow}
    >
      <Field
        title="Nome cliente"
        source="contract.condo_name"
        className="text-left"
      />
      <Field title="Partita IVA cliente" source="contract.condo_vat_cf" />
      <Field
        title="partita iva responsabile"
        source="admin_profile.fiscalCode"
      />

      <ContractEONType title="Nome prodotto" source="contract.product_name" />
      <Field title="POD/PDR" source="contract.pod_pdr" />
      <Field title="Consumo attuale" source="contract.actual_consumption" />
      <Field
        title="Consumo da contratto"
        source="contract.contractual_consumption"
      />
      <Field title="Responsabile" source="admin_profile.name" />
      <Field title="Causale" source="denomination" />
      <Field title="Percentuale Fee" source="percentage" />
      <CurrencyField title="Profitto Extra" source="extraShare" />
      <CurrencyField title="Profitto Responsabile" source="adminShare" />

      <DateTimeField
        title="Data Ricorrente"
        source="dateFee"
        format="DD/MM/YYYY"
      />
    </DataTable>
  );
}

export default ContractsTable;
