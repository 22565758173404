import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { enableLogin } from '../../../lib/fetch/profiles';
import UserPresentational from './UserPresentational';
import { useAuth } from '../../../commons/Auth';
import forgot from '../../../lib/fetch/forgot';

const User = (props) => {
  const [{ token } = {}] = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation();
  const alert = useAlert();

  const { profile, setProfile } = props;

  async function enable(requestData) {
    setIsLoading(true);
    const { active, email, password } = requestData;
    const { error, data: responseData } = await enableLogin({
      data: {
        _id: profile._id,
        active,
        email,
        password,
      },
      token,
    });

    setIsLoading(false);

    if (error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    if (active) {
      const { authId } = responseData;
      setProfile({
        ...profile,
        authEmail: email,
        authId,
      });
      alert.success(t('USERS:USER_ENABLED_SUCCESSFULLY'));
      return true;
    } else {
      setProfile({
        ...profile,
        authEmail: undefined,
        authId: undefined,
      });
      alert.success(t('USERS:USER_DISABLED_SUCCESSFULLY'));
    }
  }

  const forgotPassword = async () => {
    setIsLoading(true);
    const { authEmail } = profile;
    const { error } = await forgot(authEmail);
    setIsLoading(false);
    if (error) alert.error(t('COMMON:GENERIC_ERROR'));
    else alert.success(t('USERS:USER_FORGOT_SUCCESS'));
  };

  return (
    <UserPresentational
      data={profile}
      enable={enable}
      isLoading={isLoading}
      forgotPassword={forgotPassword}
      {...props}
    />
  );
};

export default User;
