import axios from 'axios';

const importInvoices = async ({ token, file }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/invoices/import-invoices`,
      data: file,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

const invoicesAPIs = {
  importInvoices,
};

export default invoicesAPIs;
