import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import CustomersExtraPresentational from './CustomersExtraPresentational';
import clientsAPIs from '../../lib/fetch/clientsApi';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const CustomersExtra = ({ profile }) => {
  const [customersExtra, setCustomersExtra] = useState([]);

  const [total, setTotal] = useState(0);
  const [{ token, isAdmin } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    profileId: withDefault(StringParam, ''),
    referenceId: withDefault(StringParam, ''),
    businessName: withDefault(StringParam, ''),
    taxCode: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    profileId,
    referenceId,
    businessName,
    taxCode,
  } = query;

  useEffect(() => {
    fetchCustomersExtra();
  }, [limit, offset, profileId, referenceId, businessName, taxCode]);

  const fetchCustomersExtra = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/customer-extra`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          businessName,
          taxCode,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setCustomersExtra(data);
    setTotal(+headers['x-total-count']);
  };

  function onFilter(queryDiff) {
    if (
      (queryDiff.businessName && queryDiff.businessName !== businessName) || // first search
      (businessName && queryDiff.businessName === '') || // search deleted
      (queryDiff.taxCode && queryDiff.taxCode !== taxCode) || // first search
      (taxCode && queryDiff.taxCode === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  const createCustomerExtra = async (data) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/customer-extra`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchCustomersExtra();
  };

  const updateCustomerExtra = async (data, id) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/customer-extra/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      const message = JSON.parse(response?.request?.response).message;
      return alert.error(message || t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchCustomersExtra();
  };

  const checkTaxCode = async (taxCode) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/customer-extra/checkTaxCode`,
      {
        taxCode: taxCode,
      },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      throw new Error('Errore nel controllo della partita IVA');
    }

    return response.data;
  };

  const [hasImportedCustomers, setHasImportedCustomers] = useState(false);
  const [
    customersImportFeedbackData,
    setCustomersImportFeedbackData,
  ] = useState({});
  const toggleShowImportFeedback = () => setHasImportedCustomers((v) => !v);

  const onImportCustomers = async (file) => {
    showLoading();
    const { error, data } = await clientsAPIs.importClientsFromAdmins({
      token,
      file,
    });
    hideLoading();
    toggleShowImportFeedback();
    setCustomersImportFeedbackData({
      ...data,
      validationErrors: error?.response?.data,
    });
    fetchCustomersExtra();
    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
    } else {
      alert.success(t('COMMON:SUCCESS_UPLOAD'));
    }
  };

  return (
    <CustomersExtraPresentational
      data={customersExtra}
      profile={profile}
      isAdmin={isAdmin}
      query={query}
      total={total}
      onFilter={onFilter}
      onNewCustomerExtraConfirm={createCustomerExtra}
      onCustomerExtraUpdate={updateCustomerExtra}
      checkTaxCode={checkTaxCode}
      onImportCustomers={onImportCustomers}
      toggleShowImportFeedback={toggleShowImportFeedback}
      customersImportFeedbackData={customersImportFeedbackData}
      hasImportedCustomers={hasImportedCustomers}
    />
  );
};

export default CustomersExtra;
