import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { formatCurrencyForCSV as formatCurrency } from '../../lib/helpers/formatters';

export default (props) => {
  const {
    allColumns,
    fetchInvoicesExport,
    isReady,
    setIsReady,
    children,
    data = [],
    selectedInvoices = [],
    getTotals,
  } = props;
  let csvData, invoices;

  csvData = [
    [
      'NOME FORNITORE',
      'CLIENTE',
      'TIPO CLIENTE',
      'NUMERO FATTURA',
      'CODICE FISCALE/P.IVA',
      'DATA FATTURA',
      'MESE RENDICONTAZIONE',
      'DATA PREVISTA INCASSI',
      'GESTORE STUDIO',
      'IMPORTO NETTO',
      'PAGATA',
      'PAGAMENTI',
      'NUMERO IAF',
      'INVITATO A FATTURARE',
    ],
  ];
  invoices = data;

  invoices.forEach((invoice = {}) => {
    const {
      supplier,
      number,
      customerType,
      vatCode,
      date,
      supposedPaymentDate,
      accountability,
      customer,
      studioManager,
      invoiceIaf,
      earnedAdminShare,
      net,
      paid,
      payments,
    } = invoice;

    csvData.push([
      // 'NOME FORNITORE',
      supplier.businessName,
      // 'CLIENTE'
      customer,
      // 'TIPO CLIENTE'
      customerType === 'condos'
        ? 'CONDOMINIO'
        : !customerType
        ? 'CONDOMINIO'
        : 'CLIENTE',
      // 'NUMERO FATTURA',
      number,
      // 'CODICE FISCALE/P.IVA'
      vatCode,
      // 'DATA FATTURA',
      moment(date).format('DD/MM/YYYY'),
      // 'MESE RENDICONTAZIONE',
      accountability ? moment(accountability).format('MM/YYYY') : '',
      // 'DATA PREVISTA INCASSI',
      moment(supposedPaymentDate).format('DD/MM/YYYY'),
      // 'GETSORE STUDIO'
      studioManager,
      // 'IMPORTO NETTO',
      formatCurrency(net),
      // 'PAGATA',
      paid ? 'Si' : 'No',
      // 'PAGAMENTI'
      !Array.isArray(payments)
        ? ''
        : formatCurrency(payments.reduce((acc, val) => acc + val['gross'], 0)),
      // 'NUMERO IAF'
      invoiceIaf[0] ? invoiceIaf[0].number : '',
      // 'INVITATO A FATTURARE'
      earnedAdminShare,
    ]);
  });

  csvData.push([
    // 'NOME FORNITORE',
    '',
    // 'CLIENTE'
    '',
    // 'TIPO CLIENTE'
    '',
    // 'NUMERO FATTURA',
    '',
    // 'CODICE FISCALE/P.IVA'
    '',
    // 'DATA FATTURA',
    '',
    // 'MESE RENDICONTAZIONE',
    '',
    // 'DATA PREVISTA INCASSI',
    '',
    // 'GETSORE STUDIO'
    '',
    // 'IMPORTO NETTO',
    formatCurrency(getTotals(data).net),
    // 'PAGATA',
    '',
    // 'PAGAMENTI'
    formatCurrency(getTotals(data).cashed),
    // 'NUMERO IAF'
    '',
    // 'INVITATO A FATTURARE'
    formatCurrency(getTotals(data).iafTotal),
  ]);

  const handleClick = (event) => {
    if (!isReady) {
      event.preventDefault();
      fetchInvoicesExport();
    }
  };

  const csvLinkRef = useRef();
  useEffect(() => {
    if (isReady && csvLinkRef.current) {
      csvLinkRef.current.link.click();
      setIsReady(false);
    }
  }, [isReady]);

  return (
    <CSVLink
      data={csvData}
      filename={`FATTURE E PAGAMENTI ${moment().format(
        'DD-MM-YYYY hh-mm'
      )}.csv`}
      separator={';'}
      onClick={handleClick}
      ref={csvLinkRef}
    >
      {children}
    </CSVLink>
  );
};
