import React from 'react';

function InvoiceNumberField({ data }) {
  return (
    <>
      {data.invoicePinv?.status === 'to_deliver' ? (
        <>-</>
      ) : (
        <>{data.invoicePinv?.fullnumber}</>
      )}
    </>
  );
}

export default InvoiceNumberField;
