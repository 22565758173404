import React from 'react';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { useAlert } from 'react-alert';
import { ContFlexRight, BtnOutlineOrange } from '../../../styled-components';
import ModalConfirm from '../../../commons/ModalConfirm';
import { createPassword } from '../../../lib/helpers/password';

const UserPresentational = (props = {}) => {
  const { data: profile, isLoading, enable, forgotPassword } = props;
  const { authId, authEmail } = profile;
  const [t] = useTranslation('USERS');
  const alert = useAlert();
  const { errors, register, getValues, watch } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  watch(['email']);

  const { email } = getValues();

  const enableLogin = async () => {
    if (authId && authEmail) return;
    const password = createPassword();
    enable({ email, active: true, password });
  };

  const disableLogin = () => {
    if (!authId || !authEmail) return;
    enable({ active: false });
  };

  return (
    <Form>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12" md="6" lg="6">
              <FormGroup>
                <Label>{t('USER_EMAIL')}</Label>
                <Input
                  type="text"
                  name="email"
                  innerRef={register({ pattern: /\S+@\S+\.\S+/ })}
                  defaultValue={authEmail || ''}
                  disabled={!!(authId && authEmail)}
                />
              </FormGroup>
            </Col>
            <Col sm="12" md="3" lg="3">
              <p style={{ paddingTop: '36px' }}>
                {t(`USERS:${authId && authEmail ? 'ENABLED' : 'DISABLED'}`)}
              </p>
            </Col>
          </Row>
          <ContFlexRight>
            {authId && authEmail && (
              <ModalConfirm
                onConfirm={() => disableLogin()}
                text={t('USERS:DISABLE_CONFIRM')}
              >
                <BtnOutlineOrange type="button">
                  {t('USERS:DISABLE')}
                </BtnOutlineOrange>
              </ModalConfirm>
            )}
            {!(authId && authEmail) && email && !(errors && errors.email) && (
              <ModalConfirm
                onConfirm={() => {
                  if (isLoading) return;
                  if (!email || (errors && errors.email))
                    return alert.error(t('USERS:USER_EMAIL_REQUIRED_ERROR'));
                  else return enableLogin();
                }}
                text={t('USERS:ENABLE_CONFIRM')}
              >
                <BtnOutlineOrange type="button">
                  {t('USERS:ENABLE')}
                </BtnOutlineOrange>
              </ModalConfirm>
            )}
            {!(authId && authEmail) && (!email || (errors && errors.email)) && (
              <BtnOutlineOrange type="button" disabled={true}>
                {t('USERS:USER_EMAIL_REQUIRED')}
              </BtnOutlineOrange>
            )}
            {authId && authEmail && (
              <ModalConfirm
                onConfirm={() => !isLoading && forgotPassword()}
                text={t('USERS:FORGOT_PASSWORD_BUTTON_CONFIRM')}
              >
                <BtnOutlineOrange type="button" disabled={isLoading}>
                  {t('FORGOT_PASSWORD_BUTTON')}
                </BtnOutlineOrange>
              </ModalConfirm>
            )}
          </ContFlexRight>
        </TabPane>
      </TabContent>
    </Form>
  );
};

export default UserPresentational;
