import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

const TotalTr = ({ data = [] }) => {
  const total = data.reduce((acc, { total = 0 }) => acc + total || 0, 0);

  return (
    <tr className="summary-tr">
      <td>-</td>
      <td>-</td>
      <td className="txt-table-right">{formatCurrency(total)}</td>
    </tr>
  );
};

export default TotalTr;
