import './MyRoyalties.scss';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import {
  CurrencyField,
  DataTable,
  DateTimeField,
  PaidBadgeWithDate,
  Field,
} from '../../commons/DataTable';
import SearchInput from '../../commons/SearchInput';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import TotalRows from './TotalTr';
import { ContPaginationPage } from '../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import variables from '../../variables';
import IAFTypeField from '../../commons/DataTable/IAFTypeField';
import ExportIAFCSVField from "../../commons/DataTable/ExportIAFCSVField";

const IAFPresentational = ({ data, query, total, onFilter }) => {
  const [t] = useTranslation('ENERGY');
  const { offset, limit } = query;
  const net = useMemo(() => data.reduce((acc, elem) => elem.amount + acc, 0), [
    data,
  ]);

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:IAF_RECEIVED')}
            icon={
              <IconInvoices fill={variables.white} style={{ height: '25px' }} />
            }
          />

          <Row className="search-add-cont">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.number }}
                onFilter={({ search }) => onFilter({ number: search })}
                label={t('SEARCH:BY_IAF_NUMBER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.invoiceNumber }}
                onFilter={({ search }) => onFilter({ invoiceNumber: search })}
                label={t('SEARCH:INVOICE_NUMBER')}
              />
            </Col>
          </Row>
        </section>

        <section className="m-t-20">
          <DataTable
            data={data}
            total={total}
            offset={offset}
            limit={Number(limit)}
            onFilter={onFilter}
            emptyText={t('COMMON:NO_DATA')}
            bottomRow={<TotalRows totals={{ net }} />}
          >
            <Field title="Numero" source="number" />
            <Field key={2} title="Rif. Fattura Amministratore" source="invoiceRef" />
            <IAFTypeField title="Tipo" source="iafType" />
            <DateTimeField title="Data" source="date" />
            <CurrencyField title="Importo" source="amount" />
            <DateTimeField key={6} title="Data Pagamento" source="paymentDate" />
            <PaidBadgeWithDate key={7} title="Pagata" source="paymentDate" />
            <ExportIAFCSVField title={t('COMMON:CSV_EXPORT')} />
          </DataTable>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default IAFPresentational;
