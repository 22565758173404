import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import axios from 'axios';
import { useQueryParams, NumberParam, withDefault } from 'use-query-params';
import InvoicesByMonthPresentational from './InvoicesByMonthPresentational';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function InvoicesByMonth(props) {
  const { showLoading, hideLoading } = useLoading();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ token } = {}] = useAuth();
  const { t } = useTranslation('DASHBOARD');
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    year: withDefault(NumberParam, new Date().getFullYear()),
  });

  const { offset, limit, year } = query;

  const fetchInvoices = async () => {
    showLoading();
    setData([]);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/invoices-by-month`,
      {
        headers: { Authorization: token },
        params: { offset, limit, year },
      }
    );
    hideLoading();
    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }
    setData(response.data || []);
    setTotal(Number(response.headers['x-total-count']));
  };

  useEffect(() => {
    fetchInvoices();
  }, [offset, limit, year]);

  const onNoInvoiceConfirm = async ({ supplier, month, year }) => {
    showLoading();
    setData([]);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/invoices-by-month/${supplier}`,
      { month, year },
      {
        headers: { Authorization: token },
      }
    );
    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }
    await fetchInvoices();
  };

  function onFilter(queryDiff) {
    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  return (
    <InvoicesByMonthPresentational
      data={data}
      total={total}
      query={query}
      onFilter={onFilter}
      onNoInvoiceConfirm={onNoInvoiceConfirm}
      fetchTotalInvoices={fetchInvoices}
      {...props}
    />
  );
}

export default InvoicesByMonth;
