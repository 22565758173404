import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import {
  ContAffiliation,
  H5Styled,
  ContFilterYear,
  BtnOutlineOrange,
  BtnOutlineGreen,
} from '../../../styled-components';
import ProvidersTable from '../FinancialReports/ProvidersTable';
import TotalsRow from '../FinancialReports/TotalTr';
import CSVClientsExport from '../FinancialReports/CSVClientsExport';
import generateYearOptions from '../../../lib/helpers/generateYearOptions';
import ReportingModal from '../../../commons/ReportingModal';
import { useAuth } from '../../../commons/Auth';
import { isCheckable } from '../../../lib/helpers/isCheckboxCheckable';
import variables from '../../../variables';

const FinancialReportsPresentational = ({
  data = {},
  profile,
  query,
  onFilter,
  onAddBonus,
}) => {
  const [t] = useTranslation('CONTACTS');
  const [{ isAdmin }] = useAuth();
  const { categories = {} } = data;
  const { supplier = {} } = categories;
  const { data: suppliersData = [] } = supplier;
  const [isOpen, setIsOpen] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const alert = useAlert();
  const [amount, setAmount] = useState(0);

  const origin = window.location.origin;
  let hash = window.location.hash.split('/');
  hash.pop();
  const prevPath =
    origin +
    '/' +
    hash.join('/') +
    `${query.year ? '?year=' + query.year : ''}`;

  const toggle = ({ selectAll: toggleSelectAll = false } = {}) => {
    if (isOpen) {
      setSelectAll(false);
      return setIsOpen(false);
    }

    setSelectAll(toggleSelectAll);
    const checkedRows = toggleSelectAll
      ? suppliersData.filter(isCheckable)
      : suppliersData.filter(({ checked }) => checked);

    if (checkedRows.length) {
      checkedRows.forEach((affiliation) => {
        const { revenue, iafs = [] } = affiliation;
        const alreadyBilled =
          iafs.reduce((acc, { amount = 0 }) => {
            return acc + amount || 0;
          }, 0) || 0;
        affiliation.iafRevenue = +(revenue - alreadyBilled).toFixed(2);
      });
      const iafRevenueTotal =
        checkedRows.reduce((acc, { iafRevenue = 0 }) => {
          return acc + iafRevenue || 0;
        }, 0) || 0;
      setAmount(+iafRevenueTotal.toFixed(2));
      setIsOpen(!isOpen);
    } else {
      const message = toggleSelectAll
        ? t('ROYALTY:NO_ROW_IAF_SELECTABLE')
        : t('ROYALTY:SELECT_AT_LEAST_ONE');
      alert.error(message);
    }
  };

  const onSubmit = (formData) => {
    const checkedRows = selectAll
      ? suppliersData.filter(isCheckable)
      : suppliersData.filter(({ checked }) => checked);

    const data = {
      ...formData,
      iafType: 'SUPPLIER',
      suppliers: checkedRows.map(
        ({
          _id,
          affiliationPeriod,
          contractAmount,
          businessName,
          endDate,
          masterContractAmount,
          revenue,
          iafRevenue,
          startDate,
        }) => ({
          _id,
          affiliationPeriod,
          contractAmount,
          businessName,
          endDate,
          masterContractAmount,
          revenue,
          iafRevenue,
          startDate,
        })
      ),
      amount,
    };
    onAddBonus(data);
    toggle();
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <ContFilterYear>
            <Row>
              <Col xs="6">
                <Label>{t('TITLE_FILTER_YEAR')}</Label>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <CustomInput
                    type="select"
                    id="selectYear"
                    name="year"
                    value={query && query.year}
                    onChange={({ target: { value: year } }) =>
                      onFilter({ year })
                    }
                  >
                    {generateYearOptions()}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </ContFilterYear>
          <div
            style={{
              fontWeight: 700,
              color: '#333333',
              marginBottom: '20px',
              fontSize: '20px',
            }}
          >
            <a
              style={{
                color: variables.primary,
              }}
              href={prevPath}
            >
              Rendicontazioni
            </a>{' '}
            / <a>{t('PROVIDERS')}</a>
          </div>
          <ContAffiliation>
            <Row>
              <Col xs="6">
                <H5Styled>{t('PROVIDERS')}</H5Styled>
              </Col>
              <Col xs="6" className="text-right">
                {isAdmin && (
                  <BtnOutlineGreen
                    type="button"
                    onClick={toggle}
                    style={{ marginRight: '16px' }}
                  >
                    {t('ROYALTY:INVITE_TO_INVOICE')}
                  </BtnOutlineGreen>
                )}
                {isAdmin && (
                  <BtnOutlineGreen
                    type="button"
                    onClick={() => toggle({ selectAll: true })}
                    style={{ marginRight: '16px' }}
                  >
                    {t('ROYALTY:INVITE_TO_INVOICE_ALL')}
                  </BtnOutlineGreen>
                )}
                <CSVClientsExport
                  data={supplier}
                  clientType="fornitori"
                  businessName={profile ? profile.businessName : ''}
                  year={query && query.year}
                >
                  <BtnOutlineOrange type="button" className="uppercase">
                    {t('COMMON:CSV_EXPORT')}
                  </BtnOutlineOrange>
                </CSVClientsExport>
              </Col>
            </Row>
            <br />
            <ProvidersTable
              data={suppliersData || []}
              showEmptyTableMessage={true}
              bottomRow={
                <TotalsRow
                  route="providers"
                  activeRoute={false}
                  totals={supplier.totals}
                />
              }
              onFilter={() => {}}
            />
          </ContAffiliation>
        </TabPane>
      </TabContent>
      <ReportingModal
        toggle={toggle}
        isOpen={isOpen}
        onSubmit={onSubmit}
        amount={amount}
        query={query}
      />
    </Form>
  );
};

export default FinancialReportsPresentational;
