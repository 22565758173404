import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { PDocument } from '../../../styled-components';

function FilesList({
  files,
  handleDelete,
  handleFileListView,
  canDelete = true,
}) {
  const [t] = useTranslation('CONTACTS');

  return (
    <Row>
      <Col sm="12" md="6" lg="6">
        <FormGroup>
          <Label for="selectFile">{t('FILE')}</Label>
          {Array.isArray(files) && files.length ? (
            files.map((file, index) => {
              return (
                <div key={index} onClick={() => handleFileListView(file.key)}>
                  <PDocument>
                    {canDelete && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();

                          handleDelete(file.key);
                        }}
                      >
                        <i class="fas fa-times-circle"></i>
                      </Button>
                    )}
                    {file.name}
                  </PDocument>
                </div>
              );
            })
          ) : (
            <PDocument>Nessun file allegato</PDocument>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
}

export default FilesList;
