import './MyRoyalties.scss';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Label, Row } from 'reactstrap';
import {
  CurrencyField,
  DataTable,
  DateTimeField,
  PaidBadge,
  Field,
} from '../../commons/DataTable';
import SearchInput from '../../commons/SearchInput';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import TotalRows from './TotalTr';
import {
  BtnModalTable,
  ContFilterYear,
  ContPaginationPage,
} from '../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import variables from '../../variables';
import IAFTypeField from '../../commons/DataTable/IAFTypeField';
import ExportIAFCSVField from '../../commons/DataTable/ExportIAFCSVField';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import { Link } from 'react-router-dom';
import { csvExtraInvoice } from '../../lib/fetch/extraInvoice';
import ActionField from './ActionField';
import TypeField from './TypeField';

const ReceivedExtraInvoicePresentational = ({
  data,
  query,
  total,
  onFilter,
}) => {
  const [t] = useTranslation('ENERGY');
  const { offset, limit } = query;
  const net = useMemo(
    () =>
      data.reduce((acc, elem) => elem.invoicePinv.total_price.total + acc, 0),
    [data]
  );

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:EXTRA_INVOICE_RECEIVED')}
            icon={
              <IconInvoices fill={variables.white} style={{ height: '25px' }} />
            }
          />

          <Row className="search-add-cont">
            <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        value={query.year}
                        onChange={({ target: { value: year } }) =>
                          onFilter({ year })
                        }
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.search }}
                onFilter={({ search }) => onFilter({ search: search })}
                label={t('SEARCH:INVOICE_NUMBER')}
              />
            </Col>
          </Row>
        </section>

        <section className="m-t-20">
          <DataTable
            data={data}
            total={total}
            offset={offset}
            limit={Number(limit)}
            onFilter={onFilter}
            emptyText={t('COMMON:NO_DATA')}
            bottomRow={<TotalRows totals={{ net }} />}
          >
            <Field title="Anno" source="invoicePinv.year" />
            <Field title="Numero" source="invoicePinv.fullnumber" />
            <DateTimeField title="Data Emissione" source="invoicePinv.date" />
            <CurrencyField
              title="Importo"
              source="invoicePinv.total_price.total"
            />
            <TypeField title="Tipologia" />
            <PaidBadge key={7} title="Pagata" source="sailInvoice.collected" />
            <ActionField width={200} />
          </DataTable>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default ReceivedExtraInvoicePresentational;
