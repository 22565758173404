import React from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CustomInput,
  CardFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import LogoHeader from '../../commons/LogoHeader';
import './Login.scss';
import { ButtonLogin, H1Login } from '../../styled-components';
import { ReactComponent as IconUsername } from '../../images/icn_username.svg';
import { ReactComponent as IconPassword } from '../../images/icn_password.svg';

function LoginPresentational({ match, onLogin }) {
  const { t } = useTranslation('LOGIN');
  const { handleSubmit, register, errors } = useForm();

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">
          <H1Login>{t('DO_LOGIN')}</H1Login>

          <Card className="u-center-horizontal login-card">
            <Form onSubmit={handleSubmit(onLogin)}>
              <CardBody>
                <FormGroup>
                  <Label>
                    <IconUsername /> {t('COMMON:EMAIL')}
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    innerRef={register({
                      required: t('COMMON:REQUIRED'),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
                        message: t('COMMON:INVALID_EMAIL'),
                      },
                    })}
                  />
                  {errors.email && errors.email.message && (
                    <FormText className="error-message">
                      {errors.email.message}
                    </FormText>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label>
                    <IconPassword /> {t('PASSWORD')}
                  </Label>
                  <Input
                    type="password"
                    name="password"
                    innerRef={register({
                      required: t('COMMON:REQUIRED'),
                      pattern: {
                        minLength: 6,
                        maxLength: 20,
                        message: t('COMMON:INVALID_PASSWORD'),
                      },
                    })}
                  />
                  {errors.password && errors.password.message && (
                    <FormText className="error-message">
                      {errors.password.message}
                    </FormText>
                  )}
                </FormGroup>

                <FormGroup>
                  <div className="row">
                    <div className="col-6">
                      <CustomInput
                        type="checkbox"
                        id="Checkbox"
                        label={t('REMEMBER_ME')}
                      />
                    </div>
                    <div className="col-6 text-right">
                      <Label className="link-psw">
                        <Link to={'/forgot'}>
                          <span>{t('FORGOT')}</span>
                        </Link>
                      </Label>
                      {/* <br />
                      <Label className="link-psw">
                        <Link to={'/reset'}>
                          <span>{t('RESET')}</span>
                        </Link>
                      </Label> */}
                    </div>
                  </div>
                </FormGroup>
              </CardBody>
              <CardFooter>
                <ButtonLogin type="submit" className="u-center-horizontal">
                  {t('LOGIN')}
                </ButtonLogin>
              </CardFooter>
            </Form>
          </Card>
        </div>
      </section>
    </div>
  );
}

LoginPresentational.propTypes = {
  onLogin: Proptypes.func.isRequired,
};

export default LoginPresentational;
