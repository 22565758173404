import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  CustomInput,
  FormText,
} from 'reactstrap';
import { BtnOutlineRed, BtnOutlineGreen } from '../../styled-components';
import { ReactComponent as IconPlus } from '../../images/icn_plus_red.svg';
import DateInput from '../../commons/DateInput';
import variables from '../../variables';

const { REACT_APP_TAX_PERCENTAGE } = process.env;

const ModalReceipt = (props) => {
  const [t] = useTranslation('CONTACTS');
  const { invoice, className, onAddPayment } = props;



  const [errors, setErrors] = useState({
    date: false,
    type: false,
    net: false,
    gross: false,
  });

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const onAddPaymentSubmit = async () => {
    if (!date || !type || !gross) {
      const errors = {};

      errors.date = !date;
      errors.type = !type;
      errors.gross = !gross;

      return setErrors(errors);
    }

    const { payments = [] } = invoice;
    const alreadyPaid = payments.reduce((acc, { gross = 0 }) => acc + gross, 0);
    const notPaid = invoice.gross - alreadyPaid - gross || 0;

    onAddPayment({
      invoiceId: invoice._id,
      data: {
        date,
        type,
        gross: parseFloat(gross),
        notPaid,
      },
    });

    setDate('');
    setType('');
    setGross(notPaid.toFixed(2));
    setErrors({});

    toggle();
  };

  const { payments = [], gross: invoiceGross = 0 } = invoice;
  const remainderToPay =
    invoiceGross - payments.reduce((acc, val) => acc + val.gross, 0);

  const [gross, setGross] = useState(invoiceGross - remainderToPay == 0 ? invoiceGross.toFixed(2) : remainderToPay.toFixed(2));
  const [date, setDate] = useState('');
  const [type, setType] = useState('');

  return (
    <React.Fragment>
      <BtnOutlineRed type="button" onClick={toggle}>
        <IconPlus stroke={variables.red} /> {t('BTN_NEW_RECEIPT')}
      </BtnOutlineRed>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>
          {t('ROYALTY:MODAL_RECEIPT_INVOICE_TITLE')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onAddPaymentSubmit();
          }}
        >
          <ModalBody>
            <div className="max-width-form">
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('RECEIPT_DATE')} *</Label>
                    <DateInput value={date} onChange={setDate} />
                    {errors.date && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('IMPORT_TYPE')} *</Label>
                    <CustomInput
                      type="select"
                      id="type"
                      name="customSelect"
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="">{t('IMPORT_TYPE_PLACEHOLDER')}</option>
                      <option value="TRANSFER">{t('TRANSFER')}</option>
                      <option value="CARD">{t('CARD')}</option>
                      <option value="RIBA">{t('RIBA')}</option>
                      <option value="CASH">{t('CASH')}</option>
                      <option value="OTHER">{t('OTHER')}</option>
                    </CustomInput>
                    {errors.type && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <Label>{t('IMPORT')} *</Label>
                    <InputGroup>
                      <Input
                        type="number"
                        name="gross"
                        placeholder="0.00"
                        step="0.01"
                        value={gross}
                        max={remainderToPay < 0 ? 0 : remainderToPay.toFixed(2)}
                        onChange={({ target }) => {
                          if (/^-?[\d]*\.?[\d]{0,2}$/.test(target.value)) {
                            setGross(target.value);
                          }
                        }}
                      />
                      <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                    </InputGroup>
                    {errors.gross && (
                      <FormText className="error-message">
                        {t('COMMON:REQUIRED')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModalReceipt;
