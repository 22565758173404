import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  FormText,
  CustomInput,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnRadiusRed,
  PNewItem,
} from '../../styled-components';
import './Invoices.scss';
import DateInput from '../../commons/DateInput';
import { formatDate } from '../../lib/helpers/formatters';
import { ReactComponent as IconPlus } from '../../images/icn-plus.svg';
import { AGENT_TYPES } from '../../App';

const INITIAL_INVOICE_STATE = {
  customer: '',
  admin: {
    _id: '',
    businessName: '',
  },
  number: '',
  date: '',
  net: '',
  vatCode: '',
  customerType: '',
  customerId: '',
};

const NewInvoiceModal = ({
  administrator,
  condos,
  customersExtra,
  onConfirm,
}) => {
  const [t] = useTranslation('ROYALTY');
  const [modal, setModal] = useState(false);
  const INITIAL_ADMIN = {
    _id: administrator._id,
    businessName: administrator.businessName,
  };

  const [formValues, setFormValues] = useState({
    ...INITIAL_INVOICE_STATE,
    admin: INITIAL_ADMIN,
  });

  const [formErrors, setFormErrors] = useState({
    customer: false,
    number: false,
    date: false,
    net: false,
    vatCode: false,
  });

  const [currentCondoAdmin, setCurrentCondoAdmin] = useState({});

  useEffect(() => {
    if (moment(formValues.date).isValid()) {
      setFormValues((state) => ({
        ...state,
      }));
    }
  }, [formValues.date]);

  const toggle = () => {
    setModal(!modal);
    setFormValues({
      ...INITIAL_INVOICE_STATE,
      admin: INITIAL_ADMIN,
    });
  };

  function onInputChange({ target }) {
    setFormValues((state) => ({ ...state, [target.name]: target.value }));
  }

  function onNumericInputChange({ target }) {
    if (/^-?[\d]*\.?[\d]{0,2}$/.test(target.value)) {
      setFormValues((state) => ({
        ...state,
        [target.name]: target.value,
      }));
    }
  }

  const onNewInvoiceConfirm = () => {
    const { customer, number, date, net, customerType } = formValues;

    const errors = {};

    if (!customer || customer === '') errors.customer = true;
    if (!number) errors.number = true;
    if (!date) errors.date = true;
    if (customerType === 'condos') {
      if (
        moment(date).isBefore(currentCondoAdmin.startDate) ||
        moment(date).isAfter(currentCondoAdmin.endDate)
      ) {
        errors.managementPeriod = true;
      }
    }
    if (!net) errors.net = true;

    if (Object.keys(errors).length) {
      return setFormErrors(errors);
    }

    onConfirm({
      ...formValues,
      admin: formValues.admin._id,
      net: parseFloat(formValues.net),
    });

    toggle();
  };

  function listenForEscapeEvent() {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  }

  const onSelectCondo = (selectedCondoId) => {
    if (selectedCondoId === '') {
      setFormValues((state) => ({
        ...state,
        customer: '',
        vatCode: '',
        customerType: '',
        customerId: '',
      }));
      formErrors.customer = true;
      return;
    }

    formErrors.customer = false;
    const selectedCondo = condos.filter(
      (condo) => condo._id === selectedCondoId
    )[0];

    setCurrentCondoAdmin(selectedCondo.currentAdmin);

    setFormValues((state) => ({
      ...state,
      customer: selectedCondo.name,
      vatCode: selectedCondo.vatCode,
      customerType: 'condos',
      customerId: selectedCondo._id,
    }));
  };

  const onSelectCustomerExtra = (selectedCustomerExtraId) => {
    if (selectedCustomerExtraId === '') {
      setFormValues((state) => ({
        ...state,
        customer: '',
        vatCode: '',
        customerType: '',
        customerId: '',
      }));
      formErrors.customerExtra = true;
      return;
    }

    formErrors.customerExtra = false;
    const selectedCustomerExtra = customersExtra.filter(
      (customer) => customer._id === selectedCustomerExtraId
    )[0];

    setFormValues((state) => ({
      ...state,
      customer: selectedCustomerExtra.businessName,
      vatCode: selectedCustomerExtra.taxCode,
      customerType: 'customerExtra',
      customerId: selectedCustomerExtra._id,
    }));
  };

  const { number, date, net } = formValues;

  return (
    <React.Fragment>
      <BtnRadiusRed onClick={toggle} type="button">
        <IconPlus />
      </BtnRadiusRed>
      <PNewItem
        onClick={toggle}
        style={{ marginLeft: '7px', marginRight: '0', cursor: 'pointer' }}
      >
        {t('NEW_INVOICE')}
      </PNewItem>

      <Modal
        isOpen={modal}
        className="new-invoice-modal"
        onOpened={listenForEscapeEvent}
      >
        <ModalHeader toggle={toggle}>{t('NEW_INVOICE')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onNewInvoiceConfirm(formValues);
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <p>{t('ADD_MANUAL')}</p>
                <Row>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>{t('CONDO')} *</Label>
                      <CustomInput
                        type="select"
                        id="type"
                        name="customer"
                        onChange={(e) => onSelectCondo(e.target.value)}
                        disabled={
                          formValues.customerType === 'customerExtra'
                            ? true
                            : false
                        }
                      >
                        <option value="">{t('SELECT_CONDO')}</option>
                        {condos.map((condo) => (
                          <option key={condo._id} value={condo._id}>
                            {`${condo.name} - ${condo.vatCode}`}
                          </option>
                        ))}
                      </CustomInput>
                      {formValues.admin._id === '' && (
                        <FormText className="error-message">
                          {t('SELECT_ADMIN_ERROR')}
                        </FormText>
                      )}
                      {formValues.admin._id !== '' && condos.length === 0 && (
                        <FormText className="error-message">
                          {t('NO_CONDO_ERROR')}
                        </FormText>
                      )}
                      {formErrors.customer && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>{t('CUSTOMER_EXTRA')} *</Label>
                      <CustomInput
                        type="select"
                        id="type"
                        name="customerExtra"
                        onChange={(e) => onSelectCustomerExtra(e.target.value)}
                        disabled={
                          formValues.customerType === 'condos' ||
                          customersExtra.length === 0
                            ? true
                            : false
                        }
                      >
                        <option value="">{t('SELECT_CUSTOMER')}</option>
                        {customersExtra.map((customer) => (
                          <option key={customer._id} value={customer._id}>
                            {`${customer.businessName} - ${customer.taxCode}`}
                          </option>
                        ))}
                      </CustomInput>
                      {formValues.admin._id === '' && (
                        <FormText className="error-message">
                          {t('SELECT_ADMIN_CUSTOMER_ERROR')}
                        </FormText>
                      )}
                      {/* {formValues.admin._id !== '' &&
                        customersExtra.length === 0 && (
                          <FormText className="error-message">
                            {t('NO_CUSTOMER_ERROR')}
                          </FormText>
                        )} */}
                      {formErrors.customer && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INVOICE_NUMBER')} *</Label>
                      <Input
                        type="text"
                        name="number"
                        value={number}
                        onChange={onInputChange}
                      />
                      {formErrors.number && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INVOICE_DATE')} *</Label>
                      <DateInput
                        name="date"
                        value={date}
                        onChange={(date) =>
                          setFormValues((state) => ({ ...state, date }))
                        }
                      />
                      {formErrors.date && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                      {formErrors.managementPeriod && (
                        <FormText className="error-message">
                          {t('MANAGEMENT_PERIOD_ERROR')}
                          {`inizio ${formatDate(currentCondoAdmin.startDate)}`}
                          {currentCondoAdmin.endDate &&
                            ` fine ${formatDate(currentCondoAdmin.endDate)}`}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CONTACTS:IMPORT_NET')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="net"
                          value={net}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.net && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default NewInvoiceModal;
