import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import { ContFlexLeft, ContFilterYear } from '../../styled-components';
import SearchInput from '../../commons/SearchInput';
import InsuranceCollapse from './InsuranceCollapse';
import ModalNewInsurance from './ModalNewInsurance';
import ModalImportInsurances from './ModalImportInsurances';
import ModalImportInsurancesHelp from './ModalImportInsurancesHelp';
import { Row, Col, Button } from 'reactstrap';
import Label from 'reactstrap/lib/Label';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import Form from 'reactstrap/lib/Form';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../lib/helpers/generateMonthOptions';
import variables from '../../variables';

function MyInsurancesPresentational({
  data = [],
  query,
  onFilter,
  searchAdministrators,
  searchEstateAgents,
  onNewInsuranceConfirm,
  onInsuranceUpdate,
  onAddPayment,
  onDeletePayment,
  onValidateInsurance,
  onInsuranceDelete,
  onInsurancesUpload,
  onInsurancesUploadSuccess,
  fetchAdminCondos,
  fetchAdminCustomers,
  condos,
  customersExtra,
}) {
  const [t] = useTranslation('INSURANCES');

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:INSURANCES')}
            icon={
              <IconInvoices fill={variables.white} style={{ height: '25px' }} />
            }
          />

          <Row>
            <Col xs={12} style={{ marginBottom: 30 }}>
              <ContFlexLeft>
                <Button
                  href={`/documentation/Extra_Importazione_assicurazioni.pdf`}
                  target="_blank"
                  color="primary"
                >
                  SCARICA DOCUMENTAZIONE IMPORTAZIONE
                </Button>
              </ContFlexLeft>
            </Col>
          </Row>

          <Row>
            <Col sm="12" md="2">
              <ContFlexLeft>
                <ModalNewInsurance
                  searchAdministrators={searchAdministrators}
                  searchEstateAgents={searchEstateAgents}
                  onConfirm={onNewInsuranceConfirm}
                  fetchAdminCondos={fetchAdminCondos}
                  fetchAdminCustomers={fetchAdminCustomers}
                  condos={condos}
                  customersExtra={customersExtra}
                />
              </ContFlexLeft>
            </Col>
            <Col sm="12" md="2">
              <ContFlexLeft>
                <ModalImportInsurances
                  onUpload={onInsurancesUpload}
                  onUploadEnd={onInsurancesUploadSuccess}
                />
              </ContFlexLeft>
            </Col>
            <Col sm="12" md="4">
              <ContFlexLeft>
                <ModalImportInsurancesHelp />
              </ContFlexLeft>
            </Col>
          </Row>
          <Form className="m-t-20">
            <Row className="compensi-variabili-resp">
              <Col lg="6" xl="3">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectYear"
                          name="year"
                          value={query && query.year}
                          onChange={({ target: { value: year } }) =>
                            onFilter({ year })
                          }
                        >
                          {generateYearOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
              <Col lg="6" xl="3">
                <SearchInput
                  query={{ search: query && query.insuranceNumber }}
                  onFilter={({ search }) =>
                    onFilter({ insuranceNumber: search })
                  }
                  label={t('SEARCH:INSURANCE_NUMBER')}
                />
              </Col>
              <Col lg="6" xl="3">
                <SearchInput
                  query={{ search: query && query.contractor }}
                  onFilter={({ search }) => onFilter({ contractor: search })}
                  label={t('SEARCH:CONTRACTOR')}
                />
              </Col>
              <Col lg="6" xl="3">
                <SearchInput
                  query={{ search: query && query.adminName }}
                  onFilter={({ search }) => onFilter({ adminName: search })}
                  label={t('SEARCH:ADMINISTRATOR')}
                />
              </Col>
              <Col lg="6" xl="3">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="selectAccountabilityYear"
                          name="accountabilityYear"
                          className="year-select"
                          value={query && query.accountabilityYear}
                          onChange={({
                            target: { value: accountabilityYear },
                          }) => {
                            onFilter({ accountabilityYear });
                          }}
                        >
                          {generateYearOptions(true)}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
              <Col lg="6" xl="3">
                <ContFilterYear className="no-width">
                  <Row>
                    <Col xs="6">
                      <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                    </Col>
                    <Col xs="6">
                      <FormGroup>
                        <CustomInput
                          type="select"
                          id="accountabilityMonth"
                          name="accountabilityMonth"
                          className="year-select"
                          disabled={!query.accountabilityYear}
                          value={
                            query && query.accountabilityMonth === null
                              ? ''
                              : query.accountabilityMonth
                          }
                          onChange={({
                            target: { value: accountabilityMonth },
                          }) => onFilter({ accountabilityMonth })}
                        >
                          {generateMonthOptions()}
                        </CustomInput>
                      </FormGroup>
                    </Col>
                  </Row>
                </ContFilterYear>
              </Col>
            </Row>
          </Form>
        </section>

        <section>
          {data.map((insurance, index) => (
            <InsuranceCollapse
              key={insurance._id}
              insurance={insurance}
              searchAdministrators={searchAdministrators}
              searchEstateAgents={searchEstateAgents}
              onInsuranceUpdate={onInsuranceUpdate}
              onAddPayment={onAddPayment}
              onDeletePayment={onDeletePayment}
              onValidateInsurance={onValidateInsurance}
              onInsuranceDelete={onInsuranceDelete}
            />
          ))}
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default MyInsurancesPresentational;
