import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';
import { getSection } from '../../lib/helpers/getReportSection';

function BilledField({ data = {}, subAdmins }) {
  let { iafs = [], iafsSubAdmins = [] } = data;
  iafs = iafs || [];
  iafsSubAdmins = iafsSubAdmins || [];

  let totalPaid = 0;

  if (subAdmins) {
    totalPaid = iafsSubAdmins.reduce(
      (acc, iaf = {}) => acc + (iaf['invoices'][0]?.earnedCommercialShare || 0),
      0
    );
  } else {
    const section = getSection(data);

    totalPaid = iafs.reduce(
      (acc, iaf = {}) => acc + (iaf[section][0]?.earnedCommercialShare || 0),
      0
    );
  }

  if (typeof Number(totalPaid) !== 'number') return <>{formatCurrency(0)}</>;

  return <>{formatCurrency(totalPaid)}</>;
}

export default BilledField;
