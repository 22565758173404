import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  BtnOutlineGreen,
  BtnOutlineRed,
  ContFilterCheckbox,
} from '../../../styled-components';
import DateInput from '../../../commons/DateInput';
import { useAuth } from '../../../commons/Auth';
import { ADMIN_TYPES } from '../../../App';

const ADMIN_CONDO_FILE_TYPES = [
  'Verbale assembleare nomina amministratore',
  'Registro anagrafe sicurezza (RAS) condominiale',
  'Fascicolo del fabbricato',
  'Libero - Non Classificato',
];

const CERTIFIER_CONDO_FILE_TYPES = [
  'Registro anagrafe sicurezza (RAS) condominiale',
  'Fascicolo del fabbricato',
];

const INITAL_FILE_FORM_VALUES = {
  expireDate: '',
  file: '',
  field: '',
  needed: false,
  type: '',
  noExpireDate: false,
  rasCard: '',
};

const RAS_CARDS = [
  'Scale interne',
  'Locale rifiuti',
  'Piani interrati',
  'Corselli',
  'Verde di pertinenza condominiale',
  'Parapetti, balconi e terrazze',
  'Vetrate e lucernari',
  'Locale tecnico ascensore e fossa',
  'Locale contatori',
  'Documento principale',
];

function AddFileButton({ profile = {}, uploadFile }) {
  const [t] = useTranslation('FILES');
  const [{ isAdmin } = {}] = useAuth();
  const [addNewFileModal, setAddNewFileModal] = useState(false);
  const [formValues, setFormValues] = useState(INITAL_FILE_FORM_VALUES);
  const [errors, setErrors] = useState({
    expireDate: false,
    type: false,
    file: false,
    rasCard: false,
  });
  const [firstTry, setFirtsTry] = useState(true);

  useEffect(() => {}, [errors]);

  const toggleAddNewFileModal = () => {
    setFormValues(INITAL_FILE_FORM_VALUES);
    setAddNewFileModal(!addNewFileModal);
    setFirtsTry(true);
  };

  function handleFileChange() {
    const field = formValues.field;
    const needed = formValues.needed;
    const noExpireDate = formValues.noExpireDate;
    const file = formValues.file;
    const type = formValues.type;
    const expireDate = formValues.expireDate;
    const rasCard = formValues.rasCard;

    const fileSize = (file.size / 1024 ** 2).toFixed(2);

    if (fileSize > 20) {
      alert.info('File troppo grande. Max 20Mb');
    } else {
      const data = new FormData();
      data.append('file', file);
      data.append('field', field);
      data.append('type', type);
      data.append('expireDate', expireDate);
      data.append('isAdmin', isAdmin);
      data.append('needed', needed);
      data.append('noExpireDate', noExpireDate);
      data.append('rasCard', rasCard);

      setFirtsTry(false);
      errors.file = false;
      errors.type = false;
      errors.expireDate = false;
      errors.rasCard = false;

      if (file === '') errors.file = true;
      if (type === '') errors.type = true;
      if (expireDate === '' && noExpireDate === false) errors.expireDate = true;

      const arrayErrors = Object.values(errors);
      if (!arrayErrors.includes(true)) {
        uploadFile(data, toggleAddNewFileModal);
      }
    }
  }

  return (
    <React.Fragment>
      <Button
        target="_blank"
        color="primary"
        style={{ marginTop: 0 }}
        onClick={(event) => {
          event.stopPropagation();
          toggleAddNewFileModal();
        }}
      >
        Aggiungi
      </Button>

      <Modal
        isOpen={addNewFileModal}
        toggle={toggleAddNewFileModal}
        className="new-file-modal"
      >
        <ModalHeader toggle={toggleAddNewFileModal}>
          {t('FILES:ADD')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleFileChange();
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label for="selectFile">
                    {t('FILES:CHOOSE_FILE')}
                    &nbsp;({t('FILES:MAX_UPLOAD_FILE_SIZE')})
                  </Label>
                  <CustomInput
                    type="file"
                    id="fileCommercialContract"
                    name="fileCommercialContract"
                    onChange={(event) =>
                      setFormValues({
                        ...formValues,
                        file: event.target.files[0],
                        field: 'genericFiles',
                      })
                    }
                  />
                  {formValues.file === '' && !firstTry && (
                    <FormText className="error-message">
                      {t('FILE_ERROR')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
            {formValues.file !== '' && (
              <div
                style={{
                  margin: '5px',
                  backgroundColor: '#F7F8FA',
                }}
              >
                <Row>
                  <Col sm="12">
                    <span style={{ overflowX: 'hidden' }}>
                      {formValues.file?.name}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label>{t('TYPE')}</Label>
                      <CustomInput
                        type="select"
                        id="type"
                        name="type"
                        value={formValues.type}
                        defaultValue={formValues.type}
                        onChange={({ target: { value } }) => {
                          if (
                            value !==
                            'Registro anagrafe sicurezza (RAS) condominiale'
                          ) {
                            setFormValues({
                              ...formValues,
                              type: value,
                              rasCard: '',
                            });
                          } else {
                            setFormValues({
                              ...formValues,
                              type: value,
                            });
                          }
                        }}
                      >
                        <option value={''}>Seleziona un tipo</option>
                        {ADMIN_TYPES.includes(profile.type) ||
                        profile.type === 'ADMIN_COLLAB'
                          ? ADMIN_CONDO_FILE_TYPES.map((type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))
                          : CERTIFIER_CONDO_FILE_TYPES.map((type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))}
                      </CustomInput>
                      {formValues.type === '' && !firstTry && (
                        <FormText className="error-message">
                          {t('TYPE_ERROR')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  {formValues.type ===
                    'Registro anagrafe sicurezza (RAS) condominiale' && (
                    <Col sm="12">
                      <FormGroup>
                        <Label>{t('RAS_CARDS')}</Label>
                        <CustomInput
                          type="select"
                          id="rasCard"
                          name="rasCard"
                          value={formValues.rasCard}
                          defaultValue={formValues.rasCard}
                          onChange={({ target: { value } }) => {
                            setFormValues({
                              ...formValues,
                              rasCard: value,
                            });
                          }}
                        >
                          <option value={''}>Seleziona un tipo</option>
                          {RAS_CARDS.map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          ))}
                        </CustomInput>
                        {formValues.type ===
                          'Registro anagrafe sicurezza (RAS) condominiale' &&
                          formValues.rasCard === '' &&
                          !firstTry && (
                            <FormText className="error-message">
                              {t('TYPE_ERROR')}
                            </FormText>
                          )}
                      </FormGroup>
                    </Col>
                  )}
                  <Col xs="12">
                    <FormGroup>
                      <Label>{t('EXPIRE_DATE')}</Label>
                      <DateInput
                        name="expireDate"
                        value={formValues.expireDate}
                        onChange={(val) =>
                          setFormValues({
                            ...formValues,
                            expireDate: val,
                          })
                        }
                        disabled={formValues.noExpireDate}
                      />
                      {formValues.expireDate === '' &&
                        formValues.noExpireDate === false &&
                        !firstTry && (
                          <FormText className="error-message">
                            {t('DATES_ERROR')}
                          </FormText>
                        )}
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <FormGroup>
                      <ContFilterCheckbox>
                        <CustomInput
                          type="checkbox"
                          id="noExpireDate"
                          name="noExpireDate"
                          label={t('NO_EXPIRE_DATE')}
                          onChange={(e) => {
                            e.stopPropagation();
                            setFormValues({
                              ...formValues,
                              noExpireDate: !formValues.noExpireDate,
                              expireDate: '',
                            });
                          }}
                        />
                      </ContFilterCheckbox>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed
              type="button"
              className="uppercase"
              onClick={toggleAddNewFileModal}
            >
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default AddFileButton;
