export default [
  {
    nome: 'Abruzzo',
    capoluoghi: ['Chieti', "L'Aquila", 'Pescara', 'Teramo'],
    province: ['CH', 'AQ', 'PE', 'TE'],
  },
  {
    nome: 'Basilicata',
    capoluoghi: ['Matera', 'Potenza'],
    province: ['MT', 'PZ'],
  },
  {
    nome: 'Calabria',
    capoluoghi: [
      'Catanzaro',
      'Cosenza',
      'Crotone',
      'Reggio Calabria',
      'Vibo Valentia',
    ],
    province: ['CZ', 'CS', 'KR', 'RC', 'VV'],
  },
  {
    nome: 'Campania',
    capoluoghi: ['Avellino', 'Benevento', 'Caserta', 'Napoli', 'Salerno'],
    province: ['AV', 'BN', 'CE', 'NA', 'SA'],
  },
  {
    nome: 'Emilia-Romagna',
    capoluoghi: [
      'Bologna',
      'Ferrara',
      'Forli-Cesena',
      'Modena',
      'Parma',
      'Piacenza',
      'Ravenna',
      'Reggio Emilia',
      'Rimini',
    ],
    province: ['BO', 'FE', 'FC', 'MO', 'PR', 'PC', 'RA', 'RE', 'RN'],
  },
  {
    nome: 'Friuli-Venezia Giulia',
    capoluoghi: ['Gorizia', 'Pordenone', 'Trieste', 'Udine'],
    province: ['GO', 'PN', 'TS', 'UD'],
  },
  {
    nome: 'Lazio',
    capoluoghi: ['Frosinone', 'Latina', 'Rieti', 'Roma', 'Viterbo'],
    province: ['FR', 'LT', 'RI', 'RM', 'VT'],
  },
  {
    nome: 'Liguria',
    capoluoghi: ['Genova', 'Imperia', 'La Spezia', 'Savona'],
    province: ['GE', 'IM', 'SP', 'SV'],
  },
  {
    nome: 'Lombardia',
    capoluoghi: [
      'Bergamo',
      'Brescia',
      'Como',
      'Cremona',
      'Lecco',
      'Lodi',
      'Mantova',
      'Milano',
      'Monza e Brianza',
      'Pavia',
      'Sondrio',
      'Varese',
    ],
    province: [
      'BG',
      'BS',
      'CO',
      'CR',
      'LC',
      'LO',
      'MN',
      'MI',
      'MB',
      'PV',
      'SO',
      'VA',
    ],
  },
  {
    nome: 'Marche',
    capoluoghi: [
      'Ancona',
      'Ascoli Piceno',
      'Fermo',
      'Macerata',
      'Pesaro e Urbino',
    ],
    province: ['AN', 'AP', 'FM', 'MC', 'PU'],
  },
  {
    nome: 'Molise',
    capoluoghi: ['Campobasso', 'Isernia'],
    province: ['CB', 'IS'],
  },
  {
    nome: 'Piemonte',
    capoluoghi: [
      'Alessandria',
      'Asti',
      'Biella',
      'Cuneo',
      'Novara',
      'Torino',
      'Verbano-Cusio-Ossola',
      'Vercelli',
    ],
    province: ['AL', 'AT', 'BI', 'CN', 'NO', 'TO', 'VB', 'VC'],
  },
  {
    nome: 'Puglia',
    capoluoghi: [
      'Bari',
      'Barletta-Andria-Trani',
      'Brindisi',
      'Lecce',
      'Foggia',
      'Taranto',
    ],
    province: ['BA', 'BT', 'BR', 'FG', 'LE', 'TA'],
  },
  {
    nome: 'Sardegna',
    capoluoghi: [
      'Cagliari',
      'Carbonia-Iglesias',
      'Medio Campidano',
      'Nuoro',
      'Ogliastra',
      'Olbia-Tempio',
      'Oristano',
      'Sassari',
    ],
    province: ['CA', 'CI', 'VS', 'NU', 'OG', 'OT', 'OR', 'SS'],
  },
  {
    nome: 'Sicilia',
    capoluoghi: [
      'Agrigento',
      'Caltanissetta',
      'Catania',
      'Enna',
      'Messina',
      'Palermo',
      'Ragusa',
      'Siracusa',
      'Trapani',
    ],
    province: ['AG', 'CL', 'CT', 'EN', 'ME', 'PA', 'RG', 'SR', 'TP'],
  },
  {
    nome: 'Toscana',
    capoluoghi: [
      'Arezzo',
      'Firenze',
      'Grosseto',
      'Livorno',
      'Lucca',
      'Massa e Carrara',
      'Pisa',
      'Pistoia',
      'Prato',
      'Siena',
    ],
    province: ['AR', 'FI', 'GR', 'LI', 'LU', 'MS', 'PI', 'PT', 'PO', 'SI'],
  },
  {
    nome: 'Trentino-Alto Adige',
    capoluoghi: ['Bolzano', 'Trento'],
    province: ['BZ', 'TN'],
  },
  {
    nome: 'Umbria',
    capoluoghi: ['Perugia', 'Terni'],
    province: ['PG', 'TR'],
  },
  {
    nome: "Valle d'Aosta",
    capoluoghi: ['Aosta'],
    province: ['AO'],
  },
  {
    nome: 'Veneto',
    capoluoghi: [
      'Belluno',
      'Padova',
      'Rovigo',
      'Treviso',
      'Venezia',
      'Verona',
      'Vicenza',
    ],
    province: ['BL', 'PD', 'RO', 'TV', 'VE', 'VR', 'VI'],
  },
];
