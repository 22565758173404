import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TabContent,
  TabPane,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
} from 'reactstrap';
import {
  ContAffiliation,
  H5Styled,
  ContFilterYear,
} from '../../../styled-components';
import Summary from './Summary';
import TotalRevenuesRow from './TotalTrRevenue';
import TotalEnergyRevenuesRow from './EnergyDetails/TotalTrEnergyRevenue';
import TotalRecurrentEnergyRevenuesRow from './RecurrentEnergyDetails/TotalTrRecurrentEnergyRevenue';
import TotalInsuranceRevenuesRow from './TotalTrInsuranceRevenue';
import RoyaltyTable from './RoyaltyTable';
import InvoiceBonusTable from './InvoiceBonusTable';
import { useAuth } from '../../../commons/Auth';
import generateYearOptions from '../../../lib/helpers/generateYearOptions';
import InsurancesRoyaltyTable from './InsurancesRoyaltyTable';
import EnergyRoyaltyTable from './EnergyDetails/EnergyRoyaltyTable';
import RecurrentEnergyRoyaltyTable from './RecurrentEnergyDetails/RecurrentEnergyRoyaltyTable';
import { ROYALTIES_ALLOWED_TYPES } from '../../../App';

const FinancialReportsPresentational = ({
  data = {},
  onBonusDelete,
  onBonusUpdate,
  query,
  onFilter,
  profileType,
  addPaymentDate,
  profile,
}) => {
  const [{ isAdmin } = {}] = useAuth();
  const [t] = useTranslation('CONTACTS');
  const { categories = {}, totals = {} } = data;
  const {
    gnet_admin = {},
    supplier = {},
    bonuses = {},
    variableRevenuesSubAdmins = {},
    variableRevenues = {},
    variableRevenuesInsurances = {},
    variableRevenuesEnergy = {},
    variableRevenuesRecurrentEnergy = {},
  } = categories;

  const { data: dataRevSubAdmins = [] } = variableRevenuesSubAdmins;
  const { data: dataRev = [] } = variableRevenues;
  const { data: dataRevInsurances = [] } = variableRevenuesInsurances;
  const { data: dataRevEnergy = [] } = variableRevenuesEnergy;
  const { data: dataRevRecurrentEnergy = [] } = variableRevenuesRecurrentEnergy;

  const getVariableRevenuesTotals = (dataRev) => {
    const cashedTotals = dataRev.reduce(
      (prev, cur) => prev + cur?.paidAmount || 0,
      0
    );
    const netTotals = dataRev.reduce((prev, cur) => prev + cur?.net || 0, 0);
    const grossTotals = dataRev.reduce(
      (prev, cur) => prev + cur?.gross || 0,
      0
    );
    const adminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur?.adminShare,
      0
    );
    const earnedAdminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur?.earnedAdminShare,
      0
    );
    const billableAdminShareTotals = dataRev.reduce((prev, cur) => {
      if (!cur.hasOwnProperty('iafs') || cur.iafs.length === 0)
        return prev + cur?.billableAdminShare;
      return prev;
    }, 0);
    return {
      cashedTotals,
      netTotals,
      grossTotals,
      adminShareTotals,
      earnedAdminShareTotals,
      billableAdminShareTotals,
    };
  };

  const getVariableRevenuesEnergyTotals = (dataRev) => {
    const cashedTotals = dataRev.reduce((prev, cur) => prev + cur.gross, 0);
    const netTotals = dataRev.reduce((prev, cur) => prev + cur?.net, 0);
    const grossTotals = dataRev.reduce((prev, cur) => prev + cur?.gross, 0);
    const adminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur?.adminShare,
      0
    );
    const earnedAdminShareTotals = dataRev.reduce(
      (prev, cur) => prev + cur?.earnedAdminShare,
      0
    );
    const billableAdminShareTotals = dataRev.reduce((prev, cur) => {
      return prev + cur?.billableAdminShare;
    }, 0);
    return {
      cashedTotals,
      netTotals,
      grossTotals,
      adminShareTotals,
      earnedAdminShareTotals,
      billableAdminShareTotals,
    };
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <ContFilterYear>
            <Row>
              <Col xs="6">
                <Label>{t('TITLE_FILTER_YEAR')}</Label>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <CustomInput
                    type="select"
                    id="selectYear"
                    name="year"
                    value={query && query.year}
                    onChange={({ target: { value: year } }) =>
                      onFilter({ year })
                    }
                  >
                    {generateYearOptions()}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </ContFilterYear>
          {ROYALTIES_ALLOWED_TYPES.includes(profileType) && (
            <>
              <ContAffiliation>
                <Row>
                  <Col xs="6">
                    <H5Styled>{t('ROYALTY:TITLE')}</H5Styled>
                  </Col>
                  <Col xs="6" className="text-right" />
                </Row>
                <br />
                <br />
                <RoyaltyTable
                  data={[]}
                  profile={profile}
                  truncateTable={true}
                  onFilter={() => {}}
                  bottomRow={
                    <TotalRevenuesRow
                      totals={getVariableRevenuesTotals(dataRev)}
                      route={`variable-revenues${
                        query.year ? '?year=' + query.year : ''
                      }`}
                      activeRoute={true}
                      truncateTotals={true}
                    />
                  }
                  showEmptyTableMessage={false}
                />
              </ContAffiliation>

              <ContAffiliation>
                <Row>
                  <Col xs="6">
                    <H5Styled>{t('INSURANCES:ROYALTIES_TITLE')}</H5Styled>
                  </Col>
                  <Col xs="6" className="text-right" />
                </Row>
                <br />
                <br />
                <InsurancesRoyaltyTable
                  data={[]}
                  truncateTable={true}
                  onFilter={() => {}}
                  showEmptyTableMessage={false}
                  bottomRow={
                    <TotalInsuranceRevenuesRow
                      totals={getVariableRevenuesTotals(dataRevInsurances)}
                      route={`variable-revenues-insurances${
                        query.year ? '?year=' + query.year : ''
                      }`}
                      activeRoute={true}
                      truncateTotals={true}
                    />
                  }
                />
              </ContAffiliation>
              <ContAffiliation>
                <Row>
                  <Col xs="6">
                    <H5Styled>{t('ENERGY:ROYALTIES_TITLE')}</H5Styled>
                  </Col>
                  <Col xs="6" className="text-right" />
                </Row>
                <br />
                <br />
                <EnergyRoyaltyTable
                  data={[]}
                  truncateTable={true}
                  onFilter={() => {}}
                  showEmptyTableMessage={false}
                  bottomRow={
                    <TotalEnergyRevenuesRow
                      totals={getVariableRevenuesEnergyTotals(dataRevEnergy)}
                      route={`variable-revenues-energy${
                        query.year ? '?year=' + query.year : ''
                      }`}
                      activeRoute={true}
                      truncateTotals={true}
                    />
                  }
                />
              </ContAffiliation>

              <ContAffiliation>
                <Row>
                  <Col xs="6">
                    <H5Styled>{t('ENERGY_RECURRENT:ROYALTIES_TITLE')}</H5Styled>
                  </Col>
                  <Col xs="6" className="text-right" />
                </Row>
                <br />
                <br />
                <RecurrentEnergyRoyaltyTable
                  data={[]}
                  truncateTable={true}
                  onFilter={() => {}}
                  showEmptyTableMessage={false}
                  bottomRow={
                    <TotalRecurrentEnergyRevenuesRow
                      totals={getVariableRevenuesEnergyTotals(
                        dataRevRecurrentEnergy
                      )}
                      route={`variable-revenues-recurrent-energy${
                        query.year ? '?year=' + query.year : ''
                      }`}
                      activeRoute={true}
                      truncateTotals={true}
                    />
                  }
                />
              </ContAffiliation>
            </>
          )}

          <ContAffiliation>
            <Row>
              <Col xs="6">
                <H5Styled>{t('LABEL_INVOICE_BONUS')}</H5Styled>
              </Col>
            </Row>
            <br />
            <InvoiceBonusTable
              data={bonuses.data || []}
              handleDeleteBonus={onBonusDelete}
              handleUpdateBonus={onBonusUpdate}
              isAdmin={isAdmin}
              onFilter={() => {}}
              addPaymentDate={addPaymentDate}
            />
          </ContAffiliation>

          <ContAffiliation>
            <H5Styled style={{ marginBottom: '5px' }}>
              {t('COMMON:SUMMARY')}
            </H5Styled>
            <Summary totals={totals} profileType={profileType} />
          </ContAffiliation>
        </TabPane>
      </TabContent>
    </Form>
  );
};

export default FinancialReportsPresentational;
