import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { useAuth } from '../../commons/Auth';
import { ReactComponent as IconProfile } from '../../images/icn_profilo.svg';
import './Info.scss';
import variables from '../../variables';

function Info() {
  const [t] = useTranslation();
  const [{ email, isAdmin, profile = {}, userType } = {}] = useAuth();
  const { type } = profile;

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('COMMON:INFO')}
            icon={<IconProfile fill={variables.white} />}
          />
          <Card>
            <CardBody>
              <Form className="profile-form">
                <Row>
                  <Col sm="6" md="4">
                    <FormGroup>
                      <Label>{t('USERS:EMAIL')}</Label>
                      <p>
                        <strong>{email}</strong>
                      </p>
                    </FormGroup>
                  </Col>
                  <Col sm="6" md="4">
                    <FormGroup>
                      <Label>{t('USERS:ROLE')}</Label>
                      <p>
                        <strong>
                          {userType === 'SUPER_ADMIN'
                            ? t('USERS:SUPER_ADMIN')
                            : userType === 'SUPERVISOR'
                            ? t('USERS:SUPERVISOR')
                            : userType === 'SUPERVISOR_USER'
                            ? t('USERS:SUPERVISOR_USER')
                            : t('CONTACTS:' + type)}
                        </strong>
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
            {/* <CardFooter className="email-support">
              <p>
                <IconSupport fill={variables.primary} /> {t('COMMON:SUPPORT')}:{' '}
                <a href="mailto:g.pagni@gabettilab.it">g.pagni@gabettilab.it</a>
              </p>
            </CardFooter> */}
          </Card>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default Info;
