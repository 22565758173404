import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

function BilledField({ data = {} }) {
  let { invoiceIaf: iafs = [], iafsSubAdmins = [] } = data;
  iafs = iafs || [];
  iafsSubAdmins = iafsSubAdmins || [];

  let totalPaid = data?.earnedAdminShare;

  if (typeof Number(totalPaid) !== 'number') return <>{formatCurrency(0)}</>;

  return <>{formatCurrency(totalPaid)}</>;
}

export default BilledField;
