import React from 'react';
import {
  ContractEONType,
  DataTable,
  DateTimeField,
  Field,
} from '../../../commons/DataTable';
import { useTranslation } from 'react-i18next';
import { USER_ADMIN_TYPES } from '../../../App';

function NetworkTableBackoffice({ data, total, offset, limit, onFilter }) {
  const [t] = useTranslation('COMMERCIAL');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field
        title="Ragione Sociale"
        source="businessName"
        className="text-left"
      />
      <Field title="Indirizzo" source="address" />
      <Field title="Email" source="emailReference" />
      <Field title="Tipologia" source="type" className="type" />
    </DataTable>
  );
}

export default NetworkTableBackoffice;
