import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  CustomInput,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
} from '../../styled-components';

function PaidButtonField(props) {
  const { data, onPaidChange, showButton } = props;
  const [t] = useTranslation('IAF_INVOICES');
  const [modal, setModal] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(data.paid);
  const toggle = () => setModal(!modal);

  function onRegularConfirm() {
    onPaidChange(data._id, selectedRadio);
    toggle();
  }

  const { paid } = data;

  return (
    <React.Fragment>
      {showButton && (
        <div className="flex-check">
          <BtnModalTable
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              toggle();
            }}
          >
            {t('CHANGE')}
          </BtnModalTable>
        </div>
      )}

      {paid ? (
        <p className="regolarizzata">{t('PAID')}</p>
      ) : (
        <p className="non-regolarizzata">{t('NOT_PAID')}</p>
      )}

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('MODAL_PAID_TITLE')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onRegularConfirm();
          }}
        >
          <ModalBody>
            <div className="max-width-form">
              <FormGroup>
                <div
                  className="radio-modal-table"
                  onChange={({ target }) => setSelectedRadio(target.value)}
                >
                  <CustomInput
                    type="radio"
                    id="paidRadio"
                    name="paidRadio"
                    label="Pagata"
                    value={true}
                    defaultChecked={paid}
                  />
                  <CustomInput
                    type="radio"
                    id="notPaidRadio"
                    name="paidRadio"
                    label="Non Pagata"
                    value={false}
                    defaultChecked={!paid}
                  />
                </div>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default PaidButtonField;
