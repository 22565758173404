import React, { useState, useEffect } from 'react';
import { useAuth } from '../../commons/Auth';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
  SecondaryBtnModalTable,
} from '../../styled-components';
import { Row, Col, Label, FormText } from 'reactstrap/lib';
import DateInput from '../../commons/DateInput';
import AutoSuggest from '../../commons/Autosuggest';
import axios from 'axios';
import { useAlert } from 'react-alert';
import moment from 'moment';
import UpdateCondoModal from './UpdateCondoModal';
import { useParams, useHistory } from 'react-router-dom';

const INITIAL_ADMIN = { _id: '', businessName: '' };

function ActionsField(props) {
  const {
    data,
    onChangeAdmin,
    onExtendAdminManagement,
    profile,
    onCondoUpdate,
    truncateTable,
    checkVatCode,
    condoId,
  } = props;

  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation('CONDOS');
  const [changeAdminModal, setChangeAdminModal] = useState(false);
  const [changeModal, setAdminModal] = useState(false);

  const [admin, setAdmin] = useState(INITIAL_ADMIN);
  const [sameAdminError, setSameAdminError] = useState(false);
  const [emptyAdminError, setEmptyAdminError] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDateError, setEndDateError] = useState(false);
  const [datesError, setDatesError] = useState(false);
  const [datesEndChangeAdminError, setDatesEndChangeAdminError] = useState(
    false
  );
  const [datesStartChangeAdminError, setDatesStartChangeAdminError] = useState(
    false
  );

  const [limitChangeAdminStartDate, setLimitChangeAdminStartDate] = useState(
    null
  );

  const toggleChangeAdminModal = () => {
    setDatesError(false);
    setEmptyAdminError(false);
    setDatesEndChangeAdminError(false);
    setDatesStartChangeAdminError(false);
    setSameAdminError(false);
    setChangeAdminModal(!changeAdminModal);
  };

  const toggleChangeModal = () => {
    setEndDateError(false);
    setDatesError(false);
    setAdminModal(!changeModal);
  };

  const searchAdministrators = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/admins`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;

    return data;
  };

  const renderAdminSuggestion = ({ businessName = '' }) => businessName;
  const getAdminSuggestionValue = ({ businessName = '' }) => businessName;

  function onAdminSuggestionSelect({ _id, businessName }) {
    setAdmin({ _id: _id, businessName: businessName });
  }

  function onChangeAdminConfirm() {
    setDatesStartChangeAdminError(false);
    setDatesEndChangeAdminError(false);
    setEmptyAdminError(false);
    setSameAdminError(false);

    if (moment(startDate).isBefore(limitChangeAdminStartDate)) {
      setDatesStartChangeAdminError(true);
      return;
    }
    if (moment(startDate).isAfter(endDate)) {
      setDatesEndChangeAdminError(true);
      return;
    }

    if (admin._id === '') {
      setEmptyAdminError(true);
      return;
    }
    if (!sameAdminError) {
      onChangeAdmin({
        id: data._id,
        admin: admin._id,
        endDate: endDate,
        startDate: startDate,
      });
      toggleChangeAdminModal();
    }
  }

  function onChangeConfirm() {
    if (!endDate) setEndDateError(true);
    else if (moment(data.currentAdmin.startDate).isAfter(endDate))
      setDatesError(true);
    else {
      onExtendAdminManagement({ id: data._id, endDate: endDate });
      toggleChangeModal();
      setAdmin(INITIAL_ADMIN);
    }
  }

  useEffect(() => {
    if (admin._id === data.currentAdmin._id) {
      setSameAdminError(true);
    } else setSameAdminError(false);

    if (data.currentAdmin?.endDate) {
      setEndDate(
        moment(data.currentAdmin?.endDate).add(1, 'year').toISOString()
      );
      setStartDate(
        moment(data.currentAdmin?.endDate).add(1, 'day').toISOString()
      );
      setLimitChangeAdminStartDate(moment(data.currentAdmin?.endDate));
    }
  }, [data]);

  // const { id } = useParams();
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="flex-check">
        {!truncateTable ? (
          <BtnModalTable
            type="button"
            className="mx-2 px-2"
            onClick={(event) => {
              event.stopPropagation();
              toggleChangeAdminModal();
            }}
          >
            {t('CHANGE_ADMIN')}
          </BtnModalTable>
        ) : (
          <></>
        )}

        <BtnModalTable
          type="button"
          className="mx-2 px-2"
          onClick={(event) => {
            event.stopPropagation();
            history.push(`/my-condos/${data._id}`);
          }}
        >
          {t('INFO_CONDO')}
        </BtnModalTable>

        {/* <UpdateCondoModal
          administrator={profile}
          onConfirm={onCondoUpdate}
          searchAdministrators={searchAdministrators}
          defaultValues={data}
          checkVatCode={checkVatCode}
        /> */}

        {profile?.type !== 'CERTIFIER' && (
          <SecondaryBtnModalTable
            type="button"
            style={{ marginLeft: '10px' }}
            onClick={(event) => {
              event.stopPropagation();
              toggleChangeModal();
            }}
          >
            {t('EXTENDS')}
          </SecondaryBtnModalTable>
        )}
      </div>

      <Modal
        isOpen={changeAdminModal}
        toggle={toggleChangeAdminModal}
        className="new-condo-modal"
      >
        <ModalHeader toggle={toggleChangeAdminModal}>
          {t('CHANGE_ADMIN')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onChangeAdminConfirm();
          }}
        >
          <ModalBody>
            <FormGroup>
              <Label>{t('NEW_ADMIN')}</Label>
              <AutoSuggest
                placeholder="Cerca un amministratore..."
                onFetch={searchAdministrators}
                onSelect={onAdminSuggestionSelect}
                renderSuggestion={renderAdminSuggestion}
                getSuggestionValue={getAdminSuggestionValue}
                value={admin?.businessName}
                onChange={(businessName) =>
                  setAdmin({ ...admin, businessName: businessName })
                }
              />
              {sameAdminError && (
                <FormText className="error-message">
                  {t('SAME_ADMIN_ERROR')}
                </FormText>
              )}
              {emptyAdminError && (
                <FormText className="error-message">
                  {t('COMMON:REQUIRED')}
                </FormText>
              )}
            </FormGroup>
            <Row>
              <Col sm="12">
                <Row>
                  <Col sm="12">
                    <p className="text-bold mb-2">{t('MANAGEMENT_PERIOD')}:</p>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('START_DATE')}</Label>
                      <DateInput
                        name="startDate"
                        value={startDate}
                        onChange={setStartDate}
                      />
                      {datesStartChangeAdminError && (
                        <FormText className="error-message">
                          {t('DATES_START_ERROR')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('END_DATE')}</Label>
                      <DateInput
                        name="endDate"
                        value={endDate}
                        onChange={setEndDate}
                      />
                      {datesEndChangeAdminError && (
                        <FormText className="error-message">
                          {t('DATES_ERROR')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed
              type="button"
              className="uppercase"
              onClick={toggleChangeAdminModal}
            >
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={changeModal} className="new-condo-modal">
        <ModalHeader toggle={toggleChangeModal}>{t('EXTENDS')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onChangeConfirm();
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <Row>
                  <Col sm="12">
                    <p className="text-bold mb-2">{t('MANAGEMENT_PERIOD')}:</p>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('START_DATE')}</Label>
                      <DateInput name="startDate" value={startDate} readOnly />
                      {datesError && (
                        <FormText className="error-message">
                          {t('DATES_ERROR')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('END_DATE')} *</Label>
                      <DateInput
                        name="endDate"
                        value={endDate}
                        onChange={setEndDate}
                      />
                      {endDateError && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed
              type="button"
              className="uppercase"
              onClick={toggleChangeModal}
            >
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default ActionsField;
