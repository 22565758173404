import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BooleanField,
  DataTable,
  DateTimeField,
  Field,
} from '../../../commons/DataTable';
import ActionsField from './ActionsField';
import {
  deleteFile,
  getAllProfileFiles,
  getSignedUrl,
} from '../../../lib/fetch/files';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import { useAuth } from '../../../commons/Auth';
import { Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import AddFileButton from './AddFileButton';
import { uploadFileByUser } from '../../../lib/fetch/profiles';
import SwitchField from './SwitchField';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { ContFilterYear, ContPaginationPage } from '../../../styled-components';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const MyDocuments = ({ uploadFile, handleDelete }) => {
  const [t] = useTranslation();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [{ token, profile = {} } = {}] = useAuth();
  const [allProfileFiles, setAllProfileFiles] = useState([]);
  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    active: withDefault(StringParam, localStorage.getItem('active')),
  });

  function onFilter(queryDiff) {
    if (
      (queryDiff.active && queryDiff.active !== active) ||
      (active && queryDiff.active === '') ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  const { limit, offset, active } = query;

  async function handleFileListView(key) {
    showLoading();

    const { data, error } = await getSignedUrl(key, token);

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    window.open(data.url, '_blank');
  }

  async function getProfileFiles() {
    showLoading();

    const { data, error, headers = {} } = await getAllProfileFiles(
      profile._id,
      limit,
      offset,
      token,
      active
    );

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setAllProfileFiles(data);
    setTotal(+headers['x-total-count']);
    hideLoading();
  }

  useEffect(() => {
    getProfileFiles();
  }, [limit, offset, active]);

  return (
    <>
      <Row className="align-items-center">
        <Col sm="12" md="4">
          <ContFilterYear className="no-width">
            <Row>
              <Col xs="6">
                <Label>Stato Documenti</Label>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <CustomInput
                    type="select"
                    id="active"
                    name="active"
                    value={active}
                    onChange={({ target: { value: active } }) =>
                      onFilter({ active })
                    }
                  >
                    <option value="" defaultChecked>
                      Tutti
                    </option>
                    <option value="ACTIVE">Validi</option>
                    <option value="NOT_ACTIVE">Scaduti</option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </ContFilterYear>
        </Col>
        <Col xs={8} style={{ marginBottom: 20, textAlign: 'end' }}>
          <AddFileButton uploadFile={uploadFile} profile={profile} />
        </Col>
      </Row>

      <DataTable
        data={allProfileFiles}
        total={total}
        offset={offset}
        limit={limit}
        onFilter={onFilter}
        emptyText={t('COMMON:NO_DATA')}
      >
        <Field title="Nome" source="name" className="text-left" />
        {/* <Field title="Tipologia" source="type" className="text-left" /> */}
        <Field title="Tipologia" source="field" className="text-left" />
        <DateTimeField
          title="Data Caricamento"
          source="createdAt"
          format="DD/MM/YYYY"
        />
        <DateTimeField
          title="Data Scadenza"
          source="expireDate"
          format="DD/MM/YYYY"
        />
        <Field title="Caricato da" source="user" />
        <Field title="Email" source="userEmail" />
        <BooleanField title="Valido" source="expired" />
        <SwitchField
          title="Necessario per contratto"
          source="needed"
          profile={profile}
        />

        <ActionsField
          title="Azioni"
          handleFileListView={handleFileListView}
          deleteFileConfirm={handleDelete}
        />
      </DataTable>

      <ContPaginationPage>
        <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

        <FormGroup>
          <CustomInput
            type="select"
            className="pagination-select"
            id="perPage"
            name="perPage"
            value={query.limit}
            onChange={({ target: { value: limit } }) => {
              onFilter({ limit });
            }}
          >
            {generatePaginationOptions()}
          </CustomInput>
        </FormGroup>
      </ContPaginationPage>
    </>
  );
};

export default MyDocuments;
