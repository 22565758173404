import './Insurances.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useLoading } from '../../commons/Loading';
import InsurancesPresentational from './InsurancesPresentational';
import { profileScoreFormatter } from '../../lib/helpers/profileScoreFormatter';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function Insurances({ profile }) {
  const [insurances, setInsurances] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();
  const [profileScore, setProfileScore] = useState({});

  const [query, setQuery] = useQueryParams({
    all: withDefault(StringParam, 'false'),
    search: withDefault(
      StringParam,
      localStorage.getItem('myCompensiVariabiliSearch')
    ),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    insuranceNumber: withDefault(StringParam, ''),
    adminName: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    year: withDefault(NumberParam, new Date().getFullYear()),
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
  });

  const {
    all,
    offset,
    limit,
    search,
    adminName,
    supplier,
    insuranceNumber,
    year,
    accountabilityMonth,
    accountabilityYear,
  } = query;

  useEffect(() => {
    fetchInsurances();
  }, [
    all,
    limit,
    offset,
    search,
    adminName,
    supplier,
    insuranceNumber,
    year,
    accountabilityMonth,
    accountabilityYear,
  ]);

  const fetchInsurances = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/insurances/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          all,
          limit,
          offset,
          adminName,
          supplier,
          insuranceNumber,
          year,
          accountabilityMonth,
          accountabilityYear,
        },
      }
    );

    const scores = await axios.get(`${process.env.REACT_APP_API_URL}/scores`, {
      headers: { Authorization: token },
    });

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setInsurances(data);
    setProfileScore(
      profileScoreFormatter(scores.data, scores.data.type || 'auto')
    );
    setTotal(+headers['x-total-count']);
  };

  function onFilter(queryDiff) {
    if (queryDiff && typeof queryDiff.search === 'string') {
      localStorage.setItem('insurancesSearch', queryDiff.search);
    }

    if (
      (queryDiff.search && queryDiff.search !== search) || // first search
      (search && queryDiff.search === '') || // search deleted
      (queryDiff.adminName && queryDiff.adminName !== adminName) || // first search
      (adminName && queryDiff.adminName === '') || // search deleted
      (queryDiff.insuranceNumber &&
        queryDiff.insuranceNumber !== insuranceNumber) || // first search
      (insuranceNumber && queryDiff.insuranceNumber === '') || // search deleted
      (queryDiff.supplier && queryDiff.supplier !== supplier) || // first search
      (supplier && queryDiff.supplier === '') ||
      (queryDiff.accountabilityMonth &&
        queryDiff.accountabilityMonth !== accountabilityMonth) ||
      queryDiff.accountabilityMonth === '' ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  return (
    <InsurancesPresentational
      data={insurances}
      profileScore={profileScore}
      query={query}
      total={total}
      onFilter={onFilter}
      profile={profile}
    />
  );
}

export default Insurances;
