import axios from 'axios';

export const getDataCalculation = async (props) => {
  const { token, profileId } = props;

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/credit-calculation`,
      params: {
        profileId,
      },
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};
