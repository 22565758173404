export const refactoringAdminLevels = (adminType) => {
  const adminLevels = [
    'LIVELLO TRIANGOLO',
    'LIVELLO QUADRATO',
    'LIVELLO PENTAGONO',
    'LIVELLO ESAGONO',
    'LIVELLO OTTAGONO',
  ];
  let adminLevel = '';
  switch (adminType) {
    case 'BUILDING_ADMIN_1':
      adminLevel = adminLevels[0];
      return adminLevel;
    case 'BUILDING_ADMIN_2':
      adminLevel = adminLevels[1];
      return adminLevel;
    case 'BUILDING_ADMIN_3':
      adminLevel = adminLevels[2];
      return adminLevel;
    case 'BUILDING_ADMIN_4':
      adminLevel = adminLevels[3];
      return adminLevel;
    case 'BUILDING_ADMIN_5':
      adminLevel = adminLevels[4];
      return adminLevel;
    default:
      return adminType;
  }
};
