import React, { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import NetworkPresentational from './NetworkPresentational';
import axios from 'axios';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const NetworkAdmin = ({ profile }) => {
  const [{ token, userType = '' } = {}] = useAuth();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [t] = useTranslation('CONTACTS');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const [query, setQuery] = useQueryParams({
    type: withDefault(StringParam, localStorage.getItem('profileType')),
    profileStatus: withDefault(
      StringParam,
      localStorage.getItem('profileStatus')
    ),
    search: withDefault(StringParam, localStorage.getItem('profileSearch')),
    offset: NumberParam,
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
  });

  const { search, offset, limit, type } = query;

  useEffect(() => {
    const fetchProfiles = async () => {
      showLoading();

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/network`,
        {
          headers: { Authorization: token },
          params: {
            search,
            offset,
            limit,
            type,
            profileId: profile._id,
          },
        }
      );

      hideLoading();

      if (response instanceof Error) {
        return alert.error(t('COMMON:GENERIC_ERROR'));
      }

      const { data = [], headers = {} } = response;

      setData(data);
      setTotal(Number(headers['x-total-count']));
      hideLoading();
    };

    fetchProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, offset, limit, type]);

  function onFilter(query) {
    if (
      (query.search && query.search !== search) || // first search
      (search && query.search === '') || // search deleted
      (query.type && query.type !== type) ||
      (query.limit && query.limit !== limit)
    ) {
      query.offset = 0; // return to first page
    }
    setQuery(query);
  }

  return (
    <NetworkPresentational
      data={data}
      total={total}
      query={query}
      onFilter={onFilter}
      userType={userType}
    />
  );
};

export default NetworkAdmin;
