import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

const TotalTr = ({ totals = {} }) => {
  const { totalExtraShare = 0, totalAdminShare = 0 } = totals;

  return (
    <tr className="summary-tr">
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td className="txt-table-right">{formatCurrency(totalExtraShare)}</td>
      <td className="txt-table-right">{formatCurrency(totalAdminShare)}</td>
      <td>-</td>
    </tr>
  );
};

export default TotalTr;
