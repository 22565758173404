import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Label, Row } from 'reactstrap';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconCalendar } from '../../images/icn-calendar.svg';
import {
  ContFilterYear,
  ContFlexBetween,
  ContPaginationPage,
  H4Styled,
} from '../../styled-components';
import Table from './Table';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import variables from '../../variables';
import { useAuth } from '../../commons/Auth';

function InvoicesByMonthPresentational({
  data = [],
  total,
  query = {},
  onFilter,
  onNoInvoiceConfirm,
  fetchTotalInvoices,
}) {
  const [t] = useTranslation();
  const [{ profile = {} } = {}] = useAuth();

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:INVOICES_BY_MONTH')}
            icon={<IconCalendar fill={variables.white} />}
          />
        </section>

        <section>
          <Form>
            <ContFlexBetween>
              <H4Styled>{t('INVOICES:BY_MONTH_TITLE')}</H4Styled>

              <ContFilterYear>
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        value={query.year}
                        onChange={({ target: { value: year } }) =>
                          onFilter({ year })
                        }
                      >
                        {generateYearOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </ContFlexBetween>

            <br />

            <Table
              data={data}
              total={total}
              query={query}
              onFilter={onFilter}
              onNoInvoiceConfirm={onNoInvoiceConfirm}
              fetchTotalInvoices={fetchTotalInvoices}
            />
          </Form>

          {!profile._id && (
            <ContPaginationPage>
              <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

              <FormGroup>
                <CustomInput
                  type="select"
                  className="pagination-select"
                  id="perPage"
                  name="perPage"
                  value={query.limit}
                  onChange={({ target: { value: limit } }) => {
                    onFilter({ limit });
                  }}
                >
                  {generatePaginationOptions()}
                </CustomInput>
              </FormGroup>
            </ContPaginationPage>
          )}
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default InvoicesByMonthPresentational;
