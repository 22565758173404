import React from 'react';
import { useTranslation } from 'react-i18next';
import ExportCSVContracts from './ExportCSVContracts';

function ExportCSVRow({ data = [], text }) {
  const [t] = useTranslation();

  if (data.length === 0) return null;
  return (
    <li>
      {text}: {data.length} <ExportCSVContracts data={data} />
    </li>
  );
}

export default ExportCSVRow;
