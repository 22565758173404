import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import variables from '../../variables';
import { BtnOutlineGreen, BtnOutlineRed } from '../../styled-components';

const Icon = styled.i`
  color: ${variables.primary};
  font-size: 1.3rem;
  cursor: pointer;
`;

export default ({ isOpen, toggle, onConfirm }) => {
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} fade={false} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('CONDOS:NEW_CONDO_CONFIRM')}
        </ModalHeader>
        <ModalBody>
          <p>{t('CONDOS:NEW_CONDO_CONFIRM_MESSAGE')}</p>
        </ModalBody>
        <ModalFooter>
          <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
            {t('COMMON:CANCEL')}
          </BtnOutlineRed>
          <BtnOutlineGreen
            type="button"
            className="uppercase"
            onClick={onConfirm}
          >
            {t('COMMON:CONFIRM')}
          </BtnOutlineGreen>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
