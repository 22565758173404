import React from 'react';
import Badge from '../DataTableFields/Badge';

const PaidBadgeWithDate = ({ value }) => {

  return (
    <div className={value !== undefined && value !== "-"   ? 'master-yes' : 'master-no'}>
      <Badge>{value !== undefined && value !== "-" ? 'Pagato' : 'Non Pagato'}</Badge>
    </div>
  );
};

export default PaidBadgeWithDate;
