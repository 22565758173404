import React from 'react';
import Badge from '../DataTableFields/Badge';

const PaidBadge = ({ value }) => {
  return (
    <div className={value === true ? 'master-yes' : 'master-no'}>
      <Badge>{value === true ? 'Pagato' : 'Non Pagato'}</Badge>
    </div>
  );
};

export default PaidBadge;
