import React, { useEffect, useState } from 'react';
import SubAdminsRevenuesDetailPresentational from './SubAdminsRevenuesDetailPresentational';
import { useLoading } from '../../../commons/Loading';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useDebouncedCallback } from 'use-debounce';
import {
  createBonus,
  getReportsVariableRevenuesAdmin,
} from '../../../lib/fetch/reports';

const SubAdminsRevenuesDetail = (props) => {
  const { profile = {} } = props;

  const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;
  const { _id: profileId, type: profileType = '' } = profile;
  const { showLoading, hideLoading } = useLoading();
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation('CONTACTS');
  const [reports, setReports] = useState({
    categories: { gnet_admin: {}, supplier: {}, bonuses: { data: [] } },
    totals: {},
  });

  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    year: withDefault(NumberParam, new Date().getFullYear()),
    subAdminsInvoicesNumber: withDefault(StringParam, ''),
    subAdminsInvoicesAdmin: withDefault(StringParam, ''),
    subAdminsInvoicesSupplier: withDefault(StringParam, ''),
    subAdminsInvoicesAccountabilityYear: NumberParam,
    subAdminsInvoicesAccountabilityMonth: NumberParam,
  });

  const {
    limit,
    offset,
    year,
    search,
    subAdminsInvoicesSupplier,
    subAdminsInvoicesNumber,
    subAdminsInvoicesAdmin,
    subAdminsInvoicesAccountabilityYear,
    subAdminsInvoicesAccountabilityMonth,
  } = query;

  const [fetchReports] = useDebouncedCallback(async () => {
    showLoading();
    const response = await getReportsVariableRevenuesAdmin({
      limit,
      offset,
      year,
      search,
      subAdminsInvoicesSupplier,
      subAdminsInvoicesNumber,
      subAdminsInvoicesAdmin,
      subAdminsInvoicesAccountabilityYear,
      subAdminsInvoicesAccountabilityMonth,
      token,
      profileId,
    });

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;
    setReports(data);
    setTotal(data.categories?.variableRevenuesSubAdmins?.count);
  }, 1000);

  useEffect(() => {
    fetchReports();
  }, [
    limit,
    offset,
    year,
    search,
    subAdminsInvoicesSupplier,
    subAdminsInvoicesNumber,
    subAdminsInvoicesAdmin,
    subAdminsInvoicesAccountabilityYear,
    subAdminsInvoicesAccountabilityMonth,
  ]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.subAdminsInvoicesAccountabilityYear &&
        queryDiff.subAdminsInvoicesAccountabilityYear !==
          subAdminsInvoicesAccountabilityYear) ||
      queryDiff.subAdminsInvoicesAccountabilityYear === ''
    ) {
      queryDiff.subAdminsInvoicesAccountabilityMonth = '';
    }

    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  async function onAddBonus(bonus) {
    const { error } = await createBonus({
      token,
      bonus: { ...bonus, profileId },
    });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    fetchReports();
    alert.success(t('COMMON:SUCCESS_CREATE'));
  }

  return (
    <SubAdminsRevenuesDetailPresentational
      data={reports}
      onAddBonus={onAddBonus}
      onFilter={onFilter}
      query={query}
      total={total}
      profileType={profile.type}
      {...props}
    />
  );
};

export default SubAdminsRevenuesDetail;
