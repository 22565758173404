import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  FormText,
  CustomInput,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
  ContFilterCheckbox,
  BtnDisabled,
} from '../../../styled-components';
import '../../MyInvoices/Invoices.scss';
import DateInput from '../../../commons/DateInput';
import AutoSuggest from '../../../commons/Autosuggest';
import CustomCheckbox from '../../../commons/Checkboxes/CustomCheckboxes';
import { ADMIN_TYPES } from '../../../App';

const ModalEditInvoice = ({
  searchAdministrators,
  searchEstateAgents,
  profileType,
  fetchAdminCondos,
  fetchAdminCustomers,
  condos,
  customersExtra,
  onConfirm,
  data: invoice = {},
}) => {
  const [t] = useTranslation('ROYALTY');
  const [modal, setModal] = useState(false);
  const getInitialFormState = () => ({
    customer: invoice.customer,
    admin: invoice.admin,
    number: invoice.number,
    date: invoice.date,
    supposedPaymentDate: invoice.supposedPaymentDate,
    gross: invoice.gross,
    net: invoice.net,
    accountability: invoice.accountability,
    validated: !!invoice.validated,
    customerType: invoice.customerType || '',
    customerId: invoice.customerId || '',
  });
  const [formValues, setFormValues] = useState(getInitialFormState());
  const [formErrors, setFormErrors] = useState({
    customer: false,
    admin: false,
    number: false,
    date: false,
    supposedPaymentDate: false,
    gross: false,
    net: false,
    accountability: false,
  });

  const [currentCondoAdmin, setCurrentCondoAdmin] = useState({});

  useEffect(() => {
    if (moment(formValues.date).isValid()) {
      setFormValues((state) => ({
        ...state,
        supposedPaymentDate: moment(formValues.date).add(60, 'days').toDate(),
      }));
    }
  }, [formValues.date]);

  const toggle = (e) => {
    !!e && e.stopPropagation();
    setModal(!modal);
    setFormValues(getInitialFormState());

    fetchAdminCondos(invoice.admin._id);
    fetchAdminCustomers(invoice.admin._id);
  };

  function onInputChange({ target }) {
    setFormValues((state) => ({ ...state, [target.name]: target.value }));
  }

  function onNumericInputChange({ target }) {
    if (/^[\d]*\.?[\d]{0,2}$/.test(target.value)) {
      setFormValues((state) => ({
        ...state,
        [target.name]: target.value,
      }));
    }
  }

  const renderSuggestion = ({ businessName = '' }) => businessName;
  const getSuggestionValue = ({ businessName = '' }) => businessName;
  const onSuggestionSelect = ({ _id, businessName }) => {
    setFormValues((state) => ({
      ...state,
      admin: { _id, businessName },
    }));

    fetchAdminCondos(_id);
    fetchAdminCustomers(_id);
  };

  const onEditInvoiceConfirm = () => {
    const {
      customer,
      admin,
      number,
      date,
      supposedPaymentDate,
      gross,
      net,
      accountability,
      validated,
    } = formValues;

    const errors = {};

    if (!customer) errors.customer = true;
    if (!admin._id) errors.admin = true;
    if (!number) errors.number = true;
    if (!date) errors.date = true;
    if (!supposedPaymentDate) errors.supposedPaymentDate = true;
    if (!gross) errors.gross = true;
    if (!net) errors.net = true;
    if (!accountability) errors.accountability = true;

    if (Object.keys(errors).length > 0) {
      return setFormErrors(errors);
    }

    onConfirm({
      ...formValues,
      _id: invoice._id,
      admin: formValues.admin._id,
      net: parseFloat(formValues.net),
      gross: parseFloat(formValues.gross),
      validated,
    });

    toggle();
  };

  function listenForEscapeEvent() {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  }

  const onSelectCondo = (selectedCondoId) => {
    if (selectedCondoId === '') {
      setFormValues((state) => ({
        ...state,
        customer: '',
        vatCode: '',
        customerType: '',
        customerId: '',
      }));
      formErrors.customer = true;
      return;
    }

    formErrors.customer = false;
    const selectedCondo = condos.filter(
      (condo) => condo._id === selectedCondoId
    )[0];

    setCurrentCondoAdmin(selectedCondo.currentAdmin);

    setFormValues((state) => ({
      ...state,
      customer: selectedCondo.name,
      vatCode: selectedCondo.vatCode,
      customerType: 'condos',
      customerId: selectedCondo._id,
    }));
  };

  const onSelectCustomerExtra = (selectedCustomerExtraId) => {
    if (selectedCustomerExtraId === '') {
      setFormValues((state) => ({
        ...state,
        customer: '',
        vatCode: '',
        customerType: '',
        customerId: '',
      }));
      formErrors.customerExtra = true;
      return;
    }

    formErrors.customerExtra = false;
    const selectedCustomerExtra = customersExtra.filter(
      (customer) => customer._id === selectedCustomerExtraId
    )[0];

    setFormValues((state) => ({
      ...state,
      customer: selectedCustomerExtra.name,
      vatCode: selectedCustomerExtra.taxCode,
      customerType: 'customerExtra',
      customerId: selectedCustomerExtra._id,
    }));
  };

  const {
    admin,
    estateAgent,
    number,
    date,
    supposedPaymentDate,
    gross,
    net,
    accountability,
    validated,
  } = formValues;

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ marginLeft: '10px' }}>
          {(invoice.pinvInvoice?.length > 0 && (
            <BtnDisabled
              disabled={invoice.pinvInvoice?.length > 0}
              type="button"
              onClick={toggle}
            >
              MODIFICA
            </BtnDisabled>
          )) || (
            <BtnModalTable
              disabled={invoice.pinvInvoice?.length > 0}
              type="button"
              onClick={toggle}
            >
              MODIFICA
            </BtnModalTable>
          )}
        </div>
      </div>

      <Modal
        isOpen={modal}
        className="new-invoice-modal"
        onOpened={listenForEscapeEvent}
      >
        <ModalHeader toggle={toggle}>{t('EDIT_INVOICE')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onEditInvoiceConfirm(formValues);
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <p>{t('ADD_MANUAL')}</p>
                <Row>
                  {ADMIN_TYPES.includes(profileType) && (
                    <Col xs="12" md="6">
                      <FormGroup>
                        <Label>{t('AMMINISTRATOR')} *</Label>
                        <AutoSuggest
                          placeholder="Cerca un amministratore..."
                          onFetch={searchAdministrators}
                          onSelect={onSuggestionSelect}
                          renderSuggestion={renderSuggestion}
                          getSuggestionValue={getSuggestionValue}
                          value={admin.businessName}
                          onChange={(businessName) =>
                            setFormValues((state) => ({
                              ...state,
                              admin: { ...admin, businessName },
                            }))
                          }
                        />
                        {formErrors.admin && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  )}

                  {['AGENCY'].includes(profileType) && (
                    <Col xs="12" md="6">
                      <FormGroup>
                        <Label>{t('AGENCY')} *</Label>
                        <AutoSuggest
                          placeholder="Cerca un agenzia..."
                          onFetch={searchEstateAgents}
                          onSelect={onSuggestionSelect}
                          renderSuggestion={renderSuggestion}
                          getSuggestionValue={getSuggestionValue}
                          value={admin.businessName}
                          onChange={(businessName) =>
                            setFormValues((state) => ({
                              ...state,
                              admin: { ...admin, businessName },
                            }))
                          }
                        />
                        {formErrors.estateAgent && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  )}

                  {ADMIN_TYPES.includes(profileType) && (
                    <Col xs="12" md="6">
                      <FormGroup>
                        <Label>{t('CONDO')} *</Label>
                        <CustomInput
                          type="select"
                          id="type"
                          name="customer"
                          value={
                            condos.find(
                              (condo) => condo.name === formValues.customer
                            )?._id
                          }
                          onChange={(e) => onSelectCondo(e.target.value)}
                          disabled={
                            formValues.customerType === 'customerExtra'
                              ? true
                              : false
                          }
                        >
                          <option value="">{t('SELECT_CONDO')}</option>
                          {condos.map((condo) => (
                            <option key={condo._id} value={condo._id}>
                              {condo.name}
                            </option>
                          ))}
                        </CustomInput>
                        {formValues.admin._id === '' && (
                          <FormText className="error-message">
                            {t('SELECT_ADMIN_ERROR')}
                          </FormText>
                        )}
                        {formValues.admin._id !== '' && condos.length === 0 && (
                          <FormText className="error-message">
                            {t('NO_CONDO_ERROR')}
                          </FormText>
                        )}
                        {formErrors.customer && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  )}

                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>{t('CUSTOMER_EXTRA')} *</Label>
                      <CustomInput
                        type="select"
                        id="type"
                        name="customerExtra"
                        value={
                          customersExtra.find(
                            (customerExtra) =>
                              customerExtra.name === formValues.customer
                          )?._id
                        }
                        onChange={(e) => onSelectCustomerExtra(e.target.value)}
                        disabled={
                          formValues.customerType === 'CONDOMINIO'
                            ? true
                            : false
                        }
                      >
                        <option value="">{t('SELECT_CUSTOMER')}</option>
                        {customersExtra.map((customer) => (
                          <option key={customer._id} value={customer._id}>
                            {`${customer.name} - ${customer.taxCode}`}
                          </option>
                        ))}
                      </CustomInput>
                      {formValues.admin._id === '' && (
                        <FormText className="error-message">
                          {t('SELECT_ADMIN_CUSTOMER_ERROR')}
                        </FormText>
                      )}
                      {formValues.admin._id !== '' &&
                        customersExtra.length === 0 && (
                          <FormText className="error-message">
                            {t('NO_CUSTOMER_ERROR')}
                          </FormText>
                        )}
                      {formErrors.customer && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INVOICE_NUMBER')} *</Label>
                      <Input
                        type="text"
                        name="number"
                        value={number}
                        onChange={onInputChange}
                      />
                      {formErrors.number && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INVOICE_DATE')} *</Label>
                      <DateInput
                        name="date"
                        value={date}
                        onChange={(date) =>
                          setFormValues((state) => ({ ...state, date }))
                        }
                      />
                      {formErrors.date && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('EXPIRY_DATE')} *</Label>
                      <DateInput
                        name="supposedPaymentDate"
                        value={supposedPaymentDate}
                        onChange={(supposedPaymentDate) =>
                          setFormValues((state) => ({
                            ...state,
                            supposedPaymentDate,
                          }))
                        }
                      />
                      {formErrors.supposedPaymentDate && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CONTACTS:IMPORT_NET')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="net"
                          value={net}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.net && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CONTACTS:IMPORT_TO_RECEIVE')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="gross"
                          value={gross}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.gross && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('ACC_DATE')} *</Label>
                      <DateInput
                        name="accountability"
                        value={accountability}
                        showOnlyMonth
                        setMidday
                        onChange={(accountability) => {
                          setFormValues((state) => ({
                            ...state,
                            accountability,
                          }));
                        }}
                      />
                      {formErrors.accountability && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <ContFilterCheckbox>
                        <CustomInput
                          type="checkbox"
                          label="Validata"
                          inline
                          id="validatedToUpdate"
                          checked={validated}
                          onChange={(e) => {
                            const validated = e.target.checked;
                            setFormValues((state) => ({
                              ...state,
                              validated,
                            }));
                          }}
                        />
                      </ContFilterCheckbox>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModalEditInvoice;
