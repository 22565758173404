import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Collapse,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Badge,
  FormText,
  CustomInput,
} from 'reactstrap';
import {
  HeaderCollapse,
  ContAffiliation,
  H5Styled,
  ContFlexRight,
  ButtonSave,
  SummaryAmountCont,
  YellowAlert,
  BtnOutlineGreen,
  BtnOutlineRed,
} from '../../styled-components';
import DateInput from '../../commons/DateInput';
import './Invoices.scss';
import { formatCurrency } from '../../lib/helpers/formatters';
import ModalConfirm from '../../commons/ModalConfirm';
import { formatDate } from '../../lib/helpers/formatters';

const InvoiceCollapse = ({
  invoice = {},
  condos,
  customersExtra,
  onUpdate,
  onAddPayment,
  onValidateInvoice,
  onInvoiceDelete,
}) => {
  const [t] = useTranslation('ROYALTY');
  const [isOpen, setIsOpen] = useState(false);

  const [selectedCondoId, setSelectedCondoId] = useState('');
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [currentCondoAdmin, setCurrentCondoAdmin] = useState({});

  const [formValues, setFormValues] = useState(
    JSON.parse(JSON.stringify(invoice))
  );

  const [formErrors, setFormErrors] = useState({
    customer: false,
    admin: false,
    number: false,
    date: false,
    net: false,
    vatCode: false,
  });

  useEffect(() => {
    setFormValues(JSON.parse(JSON.stringify(invoice)));
    setSelectedCondoId(
      condos.find((condo) => condo.name === invoice.customer)?._id
    );
  }, [invoice]);

  useEffect(() => {
    const selectedCondo = condos.find((condo) => condo._id === selectedCondoId);
    setCurrentCondoAdmin(selectedCondo?.currentAdmin);
  }, [selectedCondoId]);

  const toggle = () => setIsOpen(!isOpen);

  const {
    customer,
    admin,
    number,
    date,
    net,
    vatCode,
    payments = [],
    validated = false,
  } = formValues;

  function onInputChange({ target }) {
    setFormValues((state) => ({ ...state, [target.name]: target.value }));
  }

  function onNumericInputChange({ target }) {
    if (/^[\d]*\.?[\d]{0,2}$/.test(target.value)) {
      setFormValues((state) => ({
        ...state,
        [target.name]: target.value,
      }));
    }
  }

  const onCondoSelection = (selectedCondoId) => {
    if (selectedCondoId === '') {
      setSelectedCondoId('');
      setFormValues((state) => ({
        ...state,
        customer: '',
        vatCode: '',
        customerType: '',
        customerId: '',
      }));
      formErrors.customer = true;
      return;
    }

    formErrors.customer = false;
    const selectedCondo = condos.find((condo) => condo._id === selectedCondoId);

    setSelectedCondoId(selectedCondo._id);
    setCurrentCondoAdmin(selectedCondo.currentAdmin);
    setFormValues((state) => ({
      ...state,
      customer: selectedCondo.name,
      vatCode: selectedCondo.vatCode,
      customerType: 'condos',
      customerId: selectedCondo._id,
    }));
  };

  const onSelectCustomerExtra = (selectedCustomerExtraId) => {
    if (selectedCustomerExtraId === '') {
      setFormValues((state) => ({
        ...state,
        customer: '',
        vatCode: '',
        customerType: '',
        customerId: '',
      }));
      formErrors.customerExtra = true;
      return;
    }

    formErrors.customerExtra = false;
    const selectedCustomerExtra = customersExtra.filter(
      (customer) => customer._id === selectedCustomerExtraId
    )[0];

    setSelectedCustomerId(selectedCustomerExtra._id);

    setFormValues((state) => ({
      ...state,
      customer: selectedCustomerExtra.name,
      vatCode: selectedCustomerExtra.taxCode,
      customerType: 'customerExtra',
      customerId: selectedCustomerExtra._id,
    }));
  };

  function onInvoiceUpdate() {
    const errors = {};

    if (!customer) errors.customer = true;
    if (!admin._id) errors.admin = true;
    if (!number) errors.number = true;
    if (!date) errors.date = true;
    if (
      currentCondoAdmin &&
      (moment(date).isBefore(currentCondoAdmin?.startDate) ||
        moment(date).isAfter(currentCondoAdmin?.endDate))
    )
      errors.managementPeriod = true;
    if (!net) errors.net = true;

    if (Object.keys(errors).length) {
      return setFormErrors(errors);
    }

    onUpdate({
      ...formValues,
      net: parseFloat(formValues.net),
    });
  }

  function handleInvoiceDelete() {
    onInvoiceDelete(invoice._id);
  }

  const totalPayments = payments.reduce((acc, val) => acc + val.net, 0);
  const toPay = totalPayments || 0;

  return (
    <React.Fragment>
      <HeaderCollapse onClick={toggle}>
        <Row className="align-center">
          <Col xs="8">
            <h6>
              Clienti: <span className="text-bold">{customer}</span>
              <br />
              Fattura n. <span className="text-bold">{number}</span> del{' '}
              <span className="text-bold">
                {moment(date).format('DD-MM-YYYY')}
              </span>
              <br />
              Da incassare:{' '}
              <span className="text-bold">{formatCurrency(toPay)}</span> di{' '}
            </h6>
          </Col>
          <Col xs="4" className="text-right">
            {validated ? (
              <Badge className="pay">CONVALIDATA</Badge>
            ) : (
              <Badge className="not-pay">NON CONVALIDATA</Badge>
            )}
            <i className="fas fa-chevron-down"></i>
          </Col>
        </Row>
      </HeaderCollapse>
      <Collapse isOpen={isOpen}>
        <ContAffiliation>
          <Form>
            <Row>
              <Col sm="4" md="4">
                <H5Styled>Dati fattura</H5Styled>
              </Col>
              {!validated && (
                <Col sm="8" md="8" className="text-right">
                  <BtnOutlineGreen
                    type="button"
                    className="uppercase"
                    onClick={onInvoiceUpdate}
                  >
                    Salva
                  </BtnOutlineGreen>{' '}
                  <ModalConfirm
                    text={t('INVOICES:CONFIRM_DELETE')}
                    style={{ display: 'inline-block' }}
                    onConfirm={handleInvoiceDelete}
                  >
                    <BtnOutlineRed type="button" className="uppercase">
                      Elimina
                    </BtnOutlineRed>
                  </ModalConfirm>
                </Col>
              )}
            </Row>

            <Row>
              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('CUSTOMER_EXTRA')} *</Label>
                  {validated ? (
                    <Input
                      type="text"
                      name="customer"
                      value={customer}
                      readOnly
                    />
                  ) : formValues.customerType === 'condos' ? (
                    <>
                      <CustomInput
                        type="select"
                        id="type"
                        name="customer"
                        value={selectedCondoId}
                        onChange={(e) => onCondoSelection(e.target.value)}
                      >
                        <option value="">{t('SELECT_CONDO')}</option>
                        {condos.map((condo) => (
                          <option key={condo._id} value={condo._id}>
                            {condo.name}
                          </option>
                        ))}
                      </CustomInput>
                      {formValues.admin._id === '' && (
                        <FormText className="error-message">
                          {t('SELECT_ADMIN_ERROR')}
                        </FormText>
                      )}
                      {formValues.admin._id !== '' && condos.length === 0 && (
                        <FormText className="error-message">
                          {t('NO_CONDO_ERROR')}
                        </FormText>
                      )}
                      {formErrors.customer && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </>
                  ) : (
                    <>
                      <CustomInput
                        type="select"
                        id="type"
                        name="customerExtra"
                        value={formValues.customerId}
                        onChange={(e) => onSelectCustomerExtra(e.target.value)}
                        disabled={invoice?.validated}
                      >
                        <option value="">{t('SELECT_CUSTOMER')}</option>
                        {customersExtra.map((customer) => (
                          <option key={customer._id} value={customer._id}>
                            {`${customer.businessName} - ${customer.taxCode}`}
                          </option>
                        ))}
                      </CustomInput>
                      {formValues.admin._id === '' && (
                        <FormText className="error-message">
                          {t('SELECT_ADMIN_CUSTOMER_ERROR')}
                        </FormText>
                      )}
                      {formValues.admin._id !== '' &&
                        customersExtra.length === 0 && (
                          <FormText className="error-message">
                            {t('NO_CUSTOMER_ERROR')}
                          </FormText>
                        )}
                      {formErrors.customer && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </>
                  )}
                </FormGroup>
              </Col>
              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('CONTACTS:VAT_FISCAL')}</Label>
                  <Input type="text" name="vatCode" value={vatCode} readOnly />
                </FormGroup>
              </Col>
              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('INVOICE_NUMBER')} *</Label>
                  <Input
                    type="text"
                    name="number"
                    value={number}
                    onChange={onInputChange}
                    readOnly={validated}
                  />
                  {formErrors.number && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('INVOICE_DATE')} *</Label>
                  <DateInput
                    name="date"
                    value={date}
                    onChange={(date) => {
                      if (validated) return;
                      setFormValues((state) => ({ ...state, date }));
                    }}
                    readOnly={validated}
                  />
                  {formErrors.date && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                  {formErrors.managementPeriod && (
                    <FormText className="error-message">
                      {t('MANAGEMENT_PERIOD_ERROR')}
                      {`inizio ${formatDate(currentCondoAdmin?.startDate)}`}
                      {currentCondoAdmin?.endDate &&
                        ` fine ${formatDate(currentCondoAdmin?.endDate)}`}
                    </FormText>
                  )}
                </FormGroup>
              </Col>

              <Col xs="12" md="6" lg="4">
                <FormGroup>
                  <Label>{t('CONTACTS:IMPORT_NET')} *</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      step="0.01"
                      placeholder="0.00"
                      name="net"
                      value={net}
                      onChange={onNumericInputChange}
                      readOnly={validated}
                    />
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </InputGroup>
                  {formErrors.net && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>

            {!validated && (
              <ContFlexRight className="responsive-alert">
                <YellowAlert>
                  <p className="title">
                    <span>ATTENZIONE!</span>
                  </p>
                  <p>
                    I dati non sono ancora stati convalidati.
                    <br />
                    Una volta trasmessi, non saranno più modificabili
                  </p>
                </YellowAlert>
                <ModalConfirm
                  text={t('CONTACTS:CONFRIM_VALIDATE_INVOICE')}
                  onConfirm={() => {
                    onValidateInvoice(invoice._id);
                  }}
                >
                  <ButtonSave type="button">{t('VALIDATION_BNT')}</ButtonSave>
                </ModalConfirm>
              </ContFlexRight>
            )}

            <ContFlexRight>
              <SummaryAmountCont style={{ marginBottom: '0' }}>
                <Row>
                  <Col xs="6">
                    <p>{t('CONTACTS:TOTAL_TO_RECEIVED')}</p>
                  </Col>
                  <Col xs="6" className="text-right">
                    <p>{formatCurrency(totalPayments)}</p>
                  </Col>
                </Row>
              </SummaryAmountCont>
            </ContFlexRight>
          </Form>
        </ContAffiliation>
      </Collapse>
    </React.Fragment>
  );
};

export default InvoiceCollapse;
