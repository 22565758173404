export const profileScoreFormatter = (profileScore) => {
  let formattedObject = {
    EONScore: profileScore.EONScoreAuto,
    insuranceScore: profileScore.insuranceScoreAuto,
    insuranceScoreType1: profileScore.insuranceScoreType1Auto,
    insuranceScoreType2: profileScore.insuranceScoreType2Auto,
    supplierScore: profileScore.supplierScoreAuto,
    bonusScore: profileScore.bonusScoreAuto,
    energyAndConsumptionScore: profileScore.energyAndConsumptionScoreAuto,
    trainingScore: profileScore.trainingScoreAuto,
    profileId: profileScore.profileId,
    type: profileScore.type,
    EonScoreIsAuto: profileScore.EonScoreIsAuto,
    insuranceScoreType1IsAuto: profileScore.insuranceScoreType1IsAuto,
    insuranceScoreType2IsAuto: profileScore.insuranceScoreType2IsAuto,
    supplierScoreIsAuto: profileScore.supplierScoreIsAuto,
    trainingCourses: profileScore.trainingCourses || [],
    trainingScore: profileScore.trainingScore || 0,
  };

  if (profileScore.EonScoreIsAuto === false) {
    formattedObject = {
      ...formattedObject,
      EONScore: profileScore.EONScoreManual,
    };
  }

  if (profileScore.insuranceScoreType1IsAuto === false) {
    formattedObject = {
      ...formattedObject,
      insuranceScoreType1: profileScore.insuranceScoreType1Manual,
    };
  }

  if (profileScore.insuranceScoreType2IsAuto === false) {
    formattedObject = {
      ...formattedObject,
      insuranceScoreType2: profileScore.insuranceScoreType2Manual,
    };
  }

  formattedObject = {
    ...formattedObject,
    insuranceScore:
      profileScore.insuranceScoreType1Manual +
      profileScore.insuranceScoreType2Manual,
  };

  if (profileScore.supplierScoreIsAuto === false) {
    formattedObject = {
      ...formattedObject,
      supplierScore: profileScore.supplierScoreManual,
    };
  }

  return formattedObject;
};
