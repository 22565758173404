import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { PROVINCE_REGEX, VAT_CODE_REGEX } from '../../lib/regex';
import {
  BtnOutlineGreen,
  BtnOutlineRed,
  BtnRadiusRed,
  PNewItem,
} from '../../styled-components';
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import AutoSuggest from '../../commons/Autosuggest';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconPlus } from '../../images/icn-plus.svg';
import { ADMIN_TYPES, AGENT_TYPES } from '../../App';
import './MyClients.scss';

//da capire come impostare initialstate
const INITIAL_CLIENT_STATE = {
  admin: {
    _id: '',
    businessName: '',
    type: '',
  },
  estateAgent: {
    _id: '',
    businessName: '',
    type: '',
  },
  profileId: '',
  typeProfile: '',
  type: 'AZIENDA',
  name: '',
  surname: '',
  businessName: '',
  taxCode: '',
  street: '',
  postalCode: '',
  city: '',
  province: '',
  phone: '',
  emailReference: '',
};

const ModalNewClient = ({
  administrator,
  onConfirm,
  checkTaxCode,
  searchAdministrators,
  searchEstateAgents,
}) => {
  const [t] = useTranslation('CUSTOMER_EXTRA');
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const isAdminUser = AGENT_TYPES.includes(administrator.type);
  const alert = useAlert();

  const [formValues, setFormValues] = useState({
    ...INITIAL_CLIENT_STATE,
  });
  const [formErrors, setFormErrors] = useState({
    type: false,
    businessName: false,
    taxCode: false,
    street: false,
    postalCode: false,
    city: false,
    province: false,
    phone: false,
    emailReference: false,
  });

  const toggle = () => {
    setModal(!modal);
    setFormValues({
      ...INITIAL_CLIENT_STATE,
    });
  };

  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  function onInputChange({ target, uppercase }) {
    setFormValues((state) => ({
      ...state,
      [target.name]: uppercase ? target.value?.toUpperCase() : target.value,
    }));
  }

  const renderAdminSuggestion = ({ businessName = '' }) => businessName;
  const getAdminSuggestionValue = ({ businessName = '' }) => businessName;
  function onAdminSuggestionSelect({ _id, type }) {
    setFormValues((state) => ({
      ...state,
      profileId: _id,
      typeProfile: ADMIN_TYPES.includes(type) ? 'Amministratore' : 'Agenzia',
    }));
  }

  const onNewClientConfirm = async () => {
    const {
      name,
      surname,
      businessName,
      taxCode,
      street,
      city,
      province,
      phone,
      postalCode,
      emailReference,
      profileId,
    } = formValues;

    const errors = {};

    if (!profileId) errors.profileId = true;
    if (type === 'PRIVATO' && !name) errors.name = true;
    if (type === 'PRIVATO' && !surname) errors.name = true;
    if (type === 'AZIENDA' && !businessName) errors.businessName = true;
    if (!taxCode) errors.taxCode = true;
    if (!street) errors.street = true;
    if (!city) errors.city = true;
    if (!phone) errors.phone = true;
    if (!emailReference) errors.emailReference = true;
    if (!postalCode) errors.postalCode = true;
    if (!province) errors.province = true;
    if (province && !PROVINCE_REGEX.test(province))
      errors.provinceFormat = true;
    if (Object.keys(errors).length) {
      return setFormErrors(errors);
    }

    try {
      const { isDuplicated } = await checkTaxCode(taxCode, profileId);
      if (isDuplicated) {
        throw new Error('P.IVA / CF già presente nel sistema.');
      } else {
        onConfirm({
          ...formValues,
        });
      }
    } catch (error) {
      alert.error(error.message);
    }
    toggle();
  };

  function listenForEscapeEvent() {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  }

  const {
    admin,
    estateAgent,
    type,
    name,
    surname,
    businessName,
    taxCode,
    street,
    postalCode,
    city,
    province,
    phone,
    emailReference,
  } = formValues;

  return (
    <>
      <React.Fragment>
        <BtnRadiusRed onClick={toggle} type="button">
          <IconPlus />
        </BtnRadiusRed>
        <PNewItem
          onClick={toggle}
          style={{ marginLeft: '7px', marginRight: '0', cursor: 'pointer' }}
        >
          {t('NEW_CUSTOMER')}
        </PNewItem>

        <Modal
          isOpen={modal}
          className="new-client-modal"
          onOpened={listenForEscapeEvent}
        >
          <ModalHeader toggle={toggle}>{t('NEW_CUSTOMER')}</ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onNewClientConfirm(formValues);
            }}
          >
            <ModalBody>
              <Row>
                <Col sm="12">
                  <Row>
                    <Col xs="12" md="6">
                      <FormGroup>
                        <Label>{t('ROYALTY:AMMINISTRATOR')} *</Label>
                        <AutoSuggest
                          placeholder="Cerca un amministratore..."
                          onFetch={searchAdministrators}
                          onSelect={onAdminSuggestionSelect}
                          renderSuggestion={renderAdminSuggestion}
                          getSuggestionValue={getAdminSuggestionValue}
                          value={admin.businessName}
                          onChange={(businessName) => {
                            if (businessName === '') {
                              setFormValues((state) => ({
                                ...state,
                                admin: {
                                  _id: '',
                                  businessName: '',
                                },
                              }));
                            } else {
                              setFormValues((state) => ({
                                ...state,
                                admin: { ...admin, businessName },
                              }));
                            }
                          }}
                          disabled={
                            formValues.estateAgent.businessName !== ''
                              ? true
                              : false
                          }
                        />
                        {formErrors.profileId && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6">
                      <FormGroup>
                        <Label>{t('AGENCY')} *</Label>
                        <AutoSuggest
                          placeholder="Cerca un agenzia..."
                          onFetch={searchEstateAgents}
                          onSelect={onAdminSuggestionSelect}
                          renderSuggestion={renderAdminSuggestion}
                          getSuggestionValue={getAdminSuggestionValue}
                          value={estateAgent.businessName}
                          onChange={(businessName) => {
                            if (businessName === '') {
                              setFormValues((state) => ({
                                ...state,
                                estateAgent: {
                                  _id: '',
                                  businessName: '',
                                },
                              }));
                            } else {
                              setFormValues((state) => ({
                                ...state,
                                estateAgent: { ...estateAgent, businessName },
                              }));
                            }
                          }}
                          disabled={
                            formValues.admin.businessName !== '' ? true : false
                          }
                        />
                        {formErrors.estateAgent && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6">
                      <FormGroup>
                        <Label>{t('TYPE')}</Label>
                        <Input
                          type="select"
                          name="type"
                          value={type}
                          onChange={onInputChange}
                        >
                          <option value={'AZIENDA'}>AZIENDA</option>
                          <option value={'PRIVATO'}>PRIVATO</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    {formValues.type === 'AZIENDA' ? (
                      <Col xs="12" md="6" lg="4">
                        <FormGroup>
                          <Label>{t('NAME')}</Label>
                          <Input
                            type="text"
                            name="name"
                            value={name}
                            onChange={onInputChange}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col xs="12" md="6" lg="4">
                        <FormGroup>
                          <Label>{t('NAME')} *</Label>
                          <Input
                            type="text"
                            name="name"
                            value={name}
                            onChange={onInputChange}
                          />
                          {formErrors.name && (
                            <FormText className="error-message">
                              {t('COMMON:REQUIRED')}
                            </FormText>
                          )}
                        </FormGroup>
                      </Col>
                    )}
                    {formValues.type === 'AZIENDA' ? (
                      <Col xs="12" md="6" lg="4">
                        <FormGroup>
                          <Label>{t('SURNAME')}</Label>
                          <Input
                            type="text"
                            name="surname"
                            value={surname}
                            onChange={onInputChange}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col xs="12" md="6" lg="4">
                        <FormGroup>
                          <Label>{t('SURNAME')} *</Label>
                          <Input
                            type="text"
                            name="surname"
                            value={surname}
                            onChange={onInputChange}
                          />
                          {formErrors.name && (
                            <FormText className="error-message">
                              {t('COMMON:REQUIRED')}
                            </FormText>
                          )}
                        </FormGroup>
                      </Col>
                    )}
                    {formValues.type === 'AZIENDA' ? (
                      <Col xs="12" md="6" lg="4">
                        <FormGroup>
                          <Label>{t('BUSINESS_NAME')} *</Label>
                          <Input
                            type="text"
                            name="businessName"
                            value={businessName}
                            onChange={onInputChange}
                          />
                          {formErrors.businessName && (
                            <FormText className="error-message">
                              {t('COMMON:REQUIRED')}
                            </FormText>
                          )}
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col xs="12" md="6" lg="4">
                        <FormGroup>
                          <Label>{t('BUSINESS_NAME')}</Label>
                          <Input
                            type="text"
                            name="businessName"
                            value={businessName}
                            onChange={onInputChange}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('TAX_CODE')} *</Label>
                        <Input
                          type="text"
                          name="taxCode"
                          value={taxCode}
                          onChange={onInputChange}
                        />
                        {formErrors.taxCode && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('PHONE')} *</Label>
                        <Input
                          type="text"
                          name="phone"
                          value={phone}
                          onChange={onInputChange}
                        />
                        {formErrors.phone && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('EMAIL')} *</Label>
                        <Input
                          type="email"
                          name="emailReference"
                          value={emailReference}
                          onChange={onInputChange}
                        />
                        {formErrors.emailReference && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="8" md="4">
                      <FormGroup>
                        <Label>{t('STREET')} *</Label>
                        <Input
                          type="text"
                          name="street"
                          value={street}
                          onChange={onInputChange}
                        />
                        {formErrors.street && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="4" md="2">
                      <FormGroup>
                        <Label>{t('POSTAL_CODE')} *</Label>
                        <Input
                          type="text"
                          name="postalCode"
                          value={postalCode}
                          onChange={onInputChange}
                        />
                        {formErrors.postalCode && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                        {formErrors.postalCodeFormat && (
                          <FormText className="error-message">
                            {t('CUSTOMER_EXTRAS:POSTAL_CODE_FORMAT_ERROR')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="8" md="4">
                      <FormGroup>
                        <Label>{t('CITY')} *</Label>
                        <Input
                          type="text"
                          name="city"
                          value={city}
                          onChange={onInputChange}
                        />
                        {formErrors.city && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="4" md="2">
                      <FormGroup>
                        <Label>{t('PROVINCE')} *</Label>
                        <Input
                          type="text"
                          name="province"
                          value={province}
                          onChange={({ target }) =>
                            onInputChange({ target, uppercase: true })
                          }
                          placeholder="es. MI"
                        />
                        {formErrors.province && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                        {formErrors.provinceFormat && (
                          <FormText className="error-message">
                            {t('CUSTOMER_EXTRAS:PROVINCE_FORMAT_ERROR')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <BtnOutlineRed
                type="button"
                className="uppercase"
                onClick={toggle}
              >
                {t('COMMON:CANCEL')}
              </BtnOutlineRed>
              <BtnOutlineGreen type="submit" className="uppercase">
                {t('COMMON:CONFIRM')}
              </BtnOutlineGreen>
            </ModalFooter>
          </Form>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default ModalNewClient;
