import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  FormText,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnRadiusRed,
  PNewItem,
} from '../../styled-components';
import './Insurances.scss';
import DateInput from '../../commons/DateInput';
import AutoSuggest from '../../commons/Autosuggest';
import CustomInput from 'reactstrap/lib/CustomInput';
import { ReactComponent as IconPlus } from '../../images/icn-plus.svg';

const INITIAL_INSURANCE_STATE = {
  contractor: '',
  admin: {
    _id: '',
    businessName: '',
  },
  estateAgent: {
    _id: '',
    businessName: '',
  },
  insuranceNumber: '',
  startDate: '',
  endDate: '',
  supposedPaymentDate: '',
  gross: '',
  net: '',
  insuranceType: '',
  customerType: '',
  customerId: '',
};

const ModalNewInsurance = ({
  searchAdministrators,
  searchEstateAgents,
  onConfirm,
  fetchAdminCondos,
  fetchAdminCustomers,
  condos,
  customersExtra,
}) => {
  const [t] = useTranslation('ROYALTY');
  const [modal, setModal] = useState(false);

  const [formValues, setFormValues] = useState({
    ...INITIAL_INSURANCE_STATE,
    admin: { ...INITIAL_INSURANCE_STATE.admin },
    estateAgent: { ...INITIAL_INSURANCE_STATE.estateAgent },
  });
  const [formErrors, setFormErrors] = useState({
    contractor: false,
    admin: false,
    estateAgent: false,
    insuranceNumber: false,
    startDate: false,
    endDate: false,
    supposedPaymentDate: false,
    gross: false,
    net: false,
    insuranceType: false,
    accountability: false,
    cfCondominio: false,
    importoSinistri: false,
    numeroDiSinistri: false,
  });

  useEffect(() => {
    if (moment(formValues.startDate).isValid()) {
      setFormValues((state) => ({
        ...state,
        endDate: moment(formValues.startDate).add({ year: 1 }).toISOString(),
        supposedPaymentDate: moment(formValues.startDate)
          .add(60, 'days')
          .toISOString(),
      }));
    }
  }, [formValues.startDate]);

  const toggle = () => {
    setModal(!modal);
    setFormValues({
      ...INITIAL_INSURANCE_STATE,
      admin: { ...INITIAL_INSURANCE_STATE.admin },
      estateAgent: { ...INITIAL_INSURANCE_STATE.estateAgent },
    });
  };

  function onInputChange({ target }) {
    setFormValues((state) => ({ ...state, [target.name]: target.value }));
  }

  function onNumericInputChange({ target }) {
    if (/^-?[\d]*\.?[\d]{0,2}$/.test(target.value)) {
      setFormValues((state) => ({
        ...state,
        [target.name]: target.value,
      }));
    }
  }

  const renderSuggestion = ({ businessName = '' }) => businessName;
  const getSuggestionValue = ({ businessName = '' }) => businessName;
  function onSuggestionSelect({ _id, businessName }) {
    setFormValues((state) => ({
      ...state,
      admin: { _id, businessName },
      typeOfManager: 'ADMIN',
    }));

    fetchAdminCondos(_id);
    fetchAdminCustomers(_id);
  }

  function onEstateAgentsSuggestionSelect({ _id, businessName }) {
    setFormValues((state) => ({
      ...state,
      estateAgent: { _id, businessName },
      typeOfManager: 'AGENCY',
    }));

    fetchAdminCondos(_id);
    fetchAdminCustomers(_id);
  }

  const onNewInsuranceConfirm = () => {
    const {
      contractor,
      admin,
      estateAgent,
      insuranceNumber,
      startDate,
      endDate,
      supposedPaymentDate,
      gross,
      net,
      insuranceType,
      numeroDiSinistri,
      importoSinistri,
      cfCondominio,
    } = formValues;

    const errors = {};

    if (!contractor) errors.contractor = true;
    if (!cfCondominio) errors.cfCondominio = true;
    if (!admin._id && !estateAgent._id) errors.admin = true;
    if (!estateAgent._id && !admin._id) errors.estateAgent = true;
    if (!insuranceNumber) errors.insuranceNumber = true;
    if (!startDate) errors.startDate = true;
    if (!endDate) errors.endDate = true;
    if (!supposedPaymentDate) errors.supposedPaymentDate = true;
    if (!gross) errors.gross = true;
    if (!net) errors.net = true;
    if (!insuranceType) errors.insuranceType = true;

    if (Object.keys(errors).length > 0) {
      return setFormErrors(errors);
    }

    onConfirm({
      ...formValues,
      admin: formValues.admin._id,
      estateAgent: formValues.estateAgent._id,
      net: parseFloat(formValues.net),
      gross: parseFloat(formValues.gross),
    });

    toggle();
  };

  function listenForEscapeEvent() {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  }

  const onSelectCondo = (selectedCondoId) => {
    if (selectedCondoId === '') {
      setFormValues((state) => ({
        ...state,
        contractor: '',
        customerType: '',
        customerId: '',
        cfCondominio: '',
      }));
      formErrors.contractor = true;
      return;
    }

    formErrors.contractor = false;
    const selectedCondo = condos.filter(
      (condo) => condo._id === selectedCondoId
    )[0];

    setFormValues((state) => ({
      ...state,
      contractor: selectedCondo.name,
      customerType: 'condos',
      customerId: selectedCondo._id,
      cfCondominio: selectedCondo.vatCode,
    }));
  };

  const onSelectCustomerExtra = (selectedCustomerExtraId) => {
    if (selectedCustomerExtraId === '') {
      setFormValues((state) => ({
        ...state,
        contractor: '',
        customerType: '',
        customerId: '',
        cfCondominio: '',
      }));
      formErrors.customerExtra = true;
      return;
    }

    formErrors.customerExtra = false;
    const selectedCustomerExtra = customersExtra.filter(
      (customer) => customer._id === selectedCustomerExtraId
    )[0];

    setFormValues((state) => ({
      ...state,
      contractor: selectedCustomerExtra.name,
      customerType: 'customerExtra',
      customerId: selectedCustomerExtra._id,
      cfCondominio: selectedCustomerExtra.taxCode,
    }));
  };

  const {
    contractor,
    admin,
    estateAgent,
    insuranceNumber,
    startDate,
    endDate,
    supposedPaymentDate,
    gross,
    net,
    insuranceType,
    accountability,
    cfCondominio,
    importoSinistri,
    numeroDiSinistri,
  } = formValues;

  return (
    <React.Fragment>
      <BtnRadiusRed onClick={toggle} type="button">
        <IconPlus />
      </BtnRadiusRed>
      <PNewItem
        onClick={toggle}
        style={{ marginLeft: '7px', marginRight: '0', cursor: 'pointer' }}
      >
        {t('INSURANCES:NEW_INSURANCE')}
      </PNewItem>

      <Modal
        isOpen={modal}
        className="new-insurance-modal"
        onOpened={listenForEscapeEvent}
      >
        <ModalHeader toggle={toggle}>
          {t('INSURANCES:NEW_INSURANCE')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onNewInsuranceConfirm(formValues);
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <p>{t('ADD_MANUAL')}</p>
                <Row>
                  <Col xs="12" md="6" lg="6">
                    <FormGroup>
                      <Label>{t('AMMINISTRATOR')} *</Label>
                      <AutoSuggest
                        placeholder="Cerca un amministratore..."
                        onFetch={searchAdministrators}
                        onSelect={onSuggestionSelect}
                        renderSuggestion={renderSuggestion}
                        getSuggestionValue={getSuggestionValue}
                        value={admin.businessName}
                        onChange={(businessName) => {
                          if (businessName === '') {
                            setFormValues((state) => ({
                              ...state,
                              admin: { ...INITIAL_INSURANCE_STATE.admin },
                              typeOfManager: '',
                              customer: '',
                              vatCode: '',
                              customerType: '',
                              customerId: '',
                            }));
                          } else {
                            setFormValues((state) => ({
                              ...state,
                              admin: { ...admin, businessName },
                            }));
                          }
                        }}
                        disabled={
                          formValues.estateAgent.businessName !== ''
                            ? true
                            : false
                        }
                      />
                      {formErrors.admin && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>{t('AGENCY')} *</Label>
                      <AutoSuggest
                        placeholder="Cerca un agenzia..."
                        onFetch={searchEstateAgents}
                        onSelect={onEstateAgentsSuggestionSelect}
                        renderSuggestion={renderSuggestion}
                        getSuggestionValue={getSuggestionValue}
                        value={estateAgent.businessName}
                        onChange={(businessName) => {
                          if (businessName === '') {
                            setFormValues((state) => ({
                              ...state,
                              estateAgent: {
                                ...INITIAL_INSURANCE_STATE.estateAgent,
                              },
                              typeOfManager: '',
                              customer: '',
                              vatCode: '',
                              customerType: '',
                              customerId: '',
                            }));
                          } else {
                            setFormValues((state) => ({
                              ...state,
                              estateAgent: { ...estateAgent, businessName },
                            }));
                          }
                        }}
                        disabled={
                          formValues.admin.businessName !== '' ? true : false
                        }
                      />
                      {formErrors.estateAgent && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="4">
                    <FormGroup>
                      <Label>{t('CONDO')} *</Label>
                      <CustomInput
                        type="select"
                        id="type"
                        name="customer"
                        onChange={(e) => onSelectCondo(e.target.value)}
                        disabled={
                          formValues.customerType === 'customerExtra'
                            ? true
                            : formValues.estateAgent._id !== ''
                            ? true
                            : false
                        }
                      >
                        <option value="">{t('SELECT_CONDO')}</option>
                        {condos.map((condo) => (
                          <option key={condo._id} value={condo._id}>
                            {`${condo.name} - ${condo.vatCode}`}
                          </option>
                        ))}
                      </CustomInput>
                      {formValues.estateAgent._id === '' &&
                        formValues.admin._id === '' && (
                          <FormText className="error-message">
                            {t('SELECT_ADMIN_ERROR')}
                          </FormText>
                        )}
                      {formValues.admin._id !== '' && condos.length === 0 && (
                        <FormText className="error-message">
                          {t('NO_CONDO_ERROR')}
                        </FormText>
                      )}
                      {formErrors.contractor && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="4">
                    <FormGroup>
                      <Label>{t('CUSTOMER_EXTRA')} *</Label>
                      <CustomInput
                        type="select"
                        id="type"
                        name="customerExtra"
                        onChange={(e) => onSelectCustomerExtra(e.target.value)}
                        disabled={
                          formValues.customerType === 'condos' ? true : false
                        }
                      >
                        <option value="">{t('SELECT_CUSTOMER')}</option>
                        {customersExtra.map((customer) => (
                          <option key={customer._id} value={customer._id}>
                            {`${customer.name} - ${customer.taxCode}`}
                          </option>
                        ))}
                      </CustomInput>
                      {formValues.estateAgent._id === '' &&
                        formValues.admin._id === '' && (
                          <FormText className="error-message">
                            {t('SELECT_ADMIN_ERROR')}
                          </FormText>
                        )}
                      {formValues.admin._id !== '' && condos.length === 0 && (
                        <FormText className="error-message">
                          {t('NO_CUSTOMER_ERROR')}
                        </FormText>
                      )}
                      {formErrors.contractor && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:LABEL')} *</Label>
                      <Input
                        type="text"
                        name="insuranceNumber"
                        value={insuranceNumber}
                        onChange={onInputChange}
                      />
                      {formErrors.insuranceNumber && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:CONTRAENTE')} *</Label>
                      <Input
                        type="text"
                        name="contractor"
                        value={contractor}
                        onChange={onInputChange}
                        disabled
                      />
                      {formErrors.contractor && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:CF_CLIENTE')} *</Label>
                      <Input
                        type="text"
                        name="cfCondominio"
                        value={cfCondominio}
                        onChange={onInputChange}
                        disabled
                      />
                      {formErrors.cfCondominio && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:TYPE')} *</Label>
                      <CustomInput
                        type="select"
                        id="insuranceType"
                        name="insuranceType"
                        value={insuranceType}
                        onChange={onInputChange}
                      >
                        <option value="">
                          {t('INSURANCES:TYPES_PLACEHOLDER')}
                        </option>
                        <option value="Globale Fabbricati">
                          {t('INSURANCES:TYPES_1')}
                        </option>
                      </CustomInput>
                      {formErrors.type && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:START_DATE')} *</Label>
                      <DateInput
                        name="startDate"
                        value={startDate}
                        onChange={(date) =>
                          setFormValues((state) => ({
                            ...state,
                            startDate: moment(date).toISOString(),
                          }))
                        }
                      />
                      {formErrors.startDate && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:END_DATE')} *</Label>
                      <DateInput
                        name="endDate"
                        value={endDate}
                        onChange={(date) =>
                          setFormValues((state) => ({
                            ...state,
                            endDate: moment(date).toISOString(),
                          }))
                        }
                      />
                      {formErrors.endDate && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:COLLECTION_DATE')} *</Label>
                      <DateInput
                        name="supposedPaymentDate"
                        value={supposedPaymentDate}
                        onChange={(date) =>
                          setFormValues((state) => ({
                            ...state,
                            supposedPaymentDate: moment(date).toISOString(),
                          }))
                        }
                      />
                      {formErrors.supposedPaymentDate && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:AWARD_IMPONIBILE')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="net"
                          value={net}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.net && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:AWARD')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="gross"
                          value={gross}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.gross && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('ACC_DATE')}</Label>
                      <DateInput
                        name="accountability"
                        value={accountability}
                        showOnlyMonth
                        setMidday
                        onChange={(accountability) => {
                          setFormValues((state) => ({
                            ...state,
                            accountability: moment(
                              accountability
                            ).toISOString(),
                          }));
                        }}
                      />
                      {formErrors.accountability && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:NUMERO_SINISTRI')}</Label>
                      <Input
                        type="text"
                        name="numeroDiSinistri"
                        value={numeroDiSinistri}
                        onChange={onInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INSURANCES:IMPORTO_SINISTRI')}</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="importoSinistri"
                          value={importoSinistri}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ModalNewInsurance;
