import React from 'react';

function ManagerField({ value, maxLength, managers }) {
  if (!value) return <>-</>;

  if (maxLength && typeof value === 'string' && value.length > maxLength) {
    return <>{value.substr(0, maxLength)}...</>;
  }

  for (const manager of managers) {
    if (manager._id === value) {
      value = manager.name;
    }
  }

  return <>{value}</>;
}

export default ManagerField;
