import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

function TableRow({ data = [], onClick = () => {} }) {
  return data.map((element, index) => {
    const { title = '', total = 0, cashed = 0 } = element;

    return (
      <tr key={index} onClick={() => onClick && onClick(element)}>
        <td className="title">{title}</td>
        <td className="txt-table-right">-</td>
        <td className="txt-table-right">{formatCurrency(total)}</td>
        <td className="txt-table-right">{formatCurrency(cashed)}</td>
      </tr>
    );
  });
}

export default TableRow;
