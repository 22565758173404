import React from 'react';
import { Link } from 'react-router-dom';
import './LogoHeader.scss';
import LogoExtra from '../../images/logo-extra.png';

function LogoHeader() {
  return (
    <section className="sct-start">
      <header className="App-header">
        <div className="header-logo">
          <div className="container">
            <Link to="/">
              <img
                src={LogoExtra}
                alt="Extra Franchising"
                className="u-center-center img-fluid"
              />
            </Link>
          </div>
        </div>
      </header>
    </section>
  );
}

export default LogoHeader;
