import React, { useState, useEffect } from 'react';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import EmittedIAFPresentational from './EmittedIAFPresentational';
import { useDebouncedCallback } from 'use-debounce';
import { getAllBonuses, addBonusPaymentDate, updateBonus } from '../../lib/fetch/reports';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { list } from '../../lib/fetch/profiles';
import axios from 'axios';

const MyEmittedIAF = ({ location }) => {
  const [t] = useTranslation();
  const [{ isAdmin, profile = {}, token } = {}] = useAuth();
  const { _id: profileId } = profile;
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [iafs, setIafs] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const [query, setQuery] = useQueryParams({
    number: withDefault(StringParam, ''),
    invoiceNumber: withDefault(StringParam, ''),
    admin: withDefault(StringParam, ''),
    limit: withDefault(StringParam, '50'),
    offset: withDefault(StringParam, '0'),
  });

  const [fetchReports] = useDebouncedCallback(async () => {
    showLoading();
    const { error, data } = await getAllBonuses({
      token,
      query,
    });

    if (error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setIafs(data);
    hideLoading();
  }, 1000);

  useEffect(() => {
    const fetchProfiles = async () => {
      showLoading();
      const { error, data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/profiles/admins`,
        {
          headers: { Authorization: token },
        }
      );
      if (error) {
        setSubjects([]);
        return;
      }
      setSubjects(data);
    };

    fetchProfiles();
  }, []);

  useEffect(() => {
    fetchReports();
  }, [query]);

  function onFilter(queryDiff) {
    setQuery(queryDiff);
  }

  async function addPaymentDate(data) {
    const { error } = await addBonusPaymentDate({ token, data });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    fetchReports();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
  }

  async function onBonusUpdate(data) {
    const { error } = await updateBonus({ token, data });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    fetchReports();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
  }

  return (
    <EmittedIAFPresentational
      data={iafs}
      subjects={subjects}
      query={query}
      total={iafs.length}
      onFilter={onFilter}
      addPaymentDate={addPaymentDate}
      handleUpdateBonus={onBonusUpdate}
    />
  );
};

export default MyEmittedIAF;
