import React from 'react';

function InsuranceCheckbox({ data, selected = [], onSelectChange = () => {} }) {
  if (data?.pinvInvoice?.length > 0) {
    return null;
  }

  return (
    <input
      type="checkbox"
      checked={selected.includes(data._id)}
      onChange={(e) => {
        e.stopPropagation();
        onSelectChange(data);
      }}
    />
  );
}

export default InsuranceCheckbox;
