//Ref. https://reacttraining.com/react-router/web/example/auth-workflow

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';

function AdminRoute(props) {
  const {
    component: Component,
    appLoaded,
    isAdmin = false,
    isAuthenticated = false,
    privateRedirect = '/',
    publicRedirect = '/',
    supervisorRedirect = '/profiles',
    adminRedirect = '/dashboard',
    userType = '',
    path = '',
    ...rest
  } = props;

  const unvalidBackofficePaths = [
    '/dashboard',
    '/my-emitted-iaf',
    '/overdue-invoices',
  ];

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated &&
        isAdmin &&
        (userType === 'SUPER_ADMIN' ||
          userType === 'SUPERVISOR' ||
          (userType === 'SUPERVISOR_USER' &&
            !unvalidBackofficePaths.some((validPath) =>
              path.startsWith(validPath)
            ))) ? (
          appLoaded ? (
            <Component {...props} />
          ) : (
            ''
          )
        ) : isAuthenticated &&
          ((isAdmin && userType === '') || userType === undefined) ? (
          appLoaded ? (
            <Component {...props} path="/" />
          ) : (
            ''
          )
        ) : (
          <Redirect
            push={false}
            to={{
              pathname: isAuthenticated
                ? userType === 'SUPER_ADMIN'
                  ? adminRedirect
                  : supervisorRedirect
                : privateRedirect,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

AdminRoute.propTypes = {
  component: Proptypes.func.isRequired,
  isAdmin: Proptypes.bool,
  isAuthenticated: Proptypes.bool,
};

export default AdminRoute;
