import React from 'react';
import Badge from '../DataTableFields/Badge';

const BooleanField = ({ value, data, ...props }) => (
  <div
    className={value !== '-' && value ? 'master-yes' : 'master-no'}
    {...props}
  >
    <Badge>{value !== '-' && value ? 'Si' : 'No'}</Badge>
  </div>
);

export default BooleanField;
