import './MyCsutomersExtra.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import SearchInput from '../../commons/SearchInput';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconProfile } from '../../images/icn_profilo.svg';
import {
  ContFlexLeft,
  ContFlexRight,
  ContPaginationPage,
} from '../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import ModalNewCustomerExtra from './ModalNewCustomerExtra';
import CustomersExtraTable from './CustomersExtraTable';

const CustomersExtraPresentational = ({
  data,
  profile,
  isAdmin,
  query,
  total,
  onFilter,
  onNewCustomerExtraConfirm,
  onCustomerExtraUpdate,
  checkTaxCode,
  searchEstateAgents,
}) => {
  const [t] = useTranslation('CUSTOMER_EXTRA');
  const { offset, limit } = query;

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:CUSTOMER_EXTRA')}
            icon={<IconProfile fill="#ffffff" style={{ height: '25px' }} />}
          />

          <Row className="align-items-center">
            <Col sm="12" md="4">
              <ContFlexLeft>
                <ModalNewCustomerExtra
                  onConfirm={onNewCustomerExtraConfirm}
                  checkTaxCode={checkTaxCode}
                  searchEstateAgents={searchEstateAgents}
                />
              </ContFlexLeft>
            </Col>
            <Col className="">
              <ContFlexRight>
                Numero di clienti:&nbsp;<strong>{total}</strong>
              </ContFlexRight>
            </Col>
          </Row>

          <Row className="search-add-cont m-t-20">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.businessNameProfile }}
                onFilter={({ search }) =>
                  onFilter({ businessNameProfile: search })
                }
                label={t('SEARCH:PROFILE_ID')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.referenceId }}
                onFilter={({ search }) => onFilter({ referenceId: search })}
                label={t('SEARCH:REFERENCE_ID')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.name }}
                onFilter={({ search }) => onFilter({ businessName: search })}
                label={t('SEARCH:BUSINESS_NAME')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.vatCode }}
                onFilter={({ search }) => onFilter({ taxCode: search })}
                label={t('SEARCH:TAX_CODE')}
              />
            </Col>
          </Row>
        </section>

        <section className="m-t-20">
          <CustomersExtraTable
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            onCustomerExtraUpdate={onCustomerExtraUpdate}
            profile={profile}
            truncateTable={profile && profile._id}
          />

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default CustomersExtraPresentational;
