import React, { useEffect, useState } from 'react';
import SailInvoicesPresentational from './SailInvoicesPresentational';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { list } from '../../lib/fetch/profiles';
import {
  create,
  list as sailInvoicesList,
  saveCached,
} from '../../lib/fetch/sailInvoices';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function SailInvoices(props) {
  const { showLoading, hideLoading } = useLoading();
  const [data, setData] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const { t } = useTranslation('SAILINVOICE');
  const [total, setTotal] = useState(0);
  const [{ token } = {}] = useAuth();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    year: withDefault(NumberParam, new Date().getFullYear()),
    offset: withDefault(NumberParam, 0),
    customer: withDefault(StringParam, ''),
    search: withDefault(StringParam, ''),
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
  });

  const { year, offset, limit, customer, search } = query;

  const fetchSailInvoices = async () => {
    showLoading();
    const { error, data, headers } = await sailInvoicesList({
      token,
      year,
      search,
      offset,
      limit,
      customer,
    });
    if (error) {
      setData([]);
      alert.error(t('COMMON:GENERIC_ERROR'));
      hideLoading();
      return;
    }

    setData(data);
    setTotal(Number(headers['x-total-count']));
    hideLoading();
  };

  useEffect(() => {
    const fetchProfiles = async () => {
      showLoading();
      const { error, data } = await list({
        token,
        limit: 200,
      });
      if (error) {
        setSubjects([]);
        return;
      }
      setSubjects(data);
    };

    fetchProfiles();
  }, []);

  useEffect(() => {
    fetchSailInvoices();
  }, [year, offset, limit, customer, search]);

  const onSendInvoice = async (invoice) => {
    showLoading();
    const { error, data } = await create({
      token,
      data: invoice,
    });
    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      hideLoading();
      return;
    }

    hideLoading();
    fetchSailInvoices();
  };

  function onFilter(queryDiff) {
    setQuery(queryDiff);
  }

  const onCollectInvoice = async ({ invoice }) => {
    showLoading();
    // Prendo solo la parte che mi serve per settare l'incasso
    const sailInvoice = {
      sailInvoice: {
        collected: invoice.sailInvoice?.collected || false,
        collectedDate: invoice.sailInvoice?.collectedDate || null,
      },
    };

    const { error, data, headers } = await saveCached({
      data: sailInvoice,
      token,
      _id: invoice._id,
    });
    if (error) {
      setData([]);
      alert.error(t('COMMON:GENERIC_ERROR'));
      hideLoading();
      return;
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchSailInvoices();
  };

  return (
    <SailInvoicesPresentational
      subjects={subjects}
      data={data}
      total={total}
      query={query}
      onFilter={onFilter}
      onSendInvoice={onSendInvoice}
      onCollectInvoice={onCollectInvoice}
      onStatusChanged={() => fetchSailInvoices()}
      {...props}
    />
  );
}

export default SailInvoices;
