import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { DataTable, Field, DateTimeField } from '../../commons/DataTable';

function LogsTable({ data = [], total = 0, query = {}, onFilter = () => {} }) {
  const [t] = useTranslation('COMMON');

  if (data && data.length > 0) {
    data = data.map((log) => ({
      ...log,
      action: log.action === 'login' ? 'Login' : log.action,
      type: log.type === 'login' ? 'Accesso' : log.type,
    }));
  }

  return (
    <DataTable
      history
      data={data}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      total={total}
      offset={query.offset}
      limit={query.limit}
      onFilter={onFilter}
    >
      <DateTimeField title="Data" source="createdAt" />
      <Field title="Azione" source="action" />
      <Field title="Tipo" source="type" />
      <Field title="Utente" source="userEmail" />
    </DataTable>
  );
}

export default withRouter(LogsTable);
