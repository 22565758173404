import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

const TotalTr = ({ data = [] } = {}) => {
  if (!Array.isArray(data)) return null;

  const totals = data.reduce(
    (acc, row = {}) => {
      const {
        gross = 0,
        net = 0,
        extraShare = 0,
        payments = [],
        adminShare = 0,
        billableAdminShare = 0,
        earnedAdminShare = 0,
        commercialShare = 0,
        billableCommercialShare = 0,
        earnedCommercialShare = 0,
      } = row;
      const reducedPayments = payments.reduce(
        (acc, { gross } = {}) => acc + (gross || 0),
        0
      );
      return {
        gross: acc.gross + gross,
        net: acc.net + net,
        extraShare: acc.extraShare + extraShare,
        payments: acc.payments + reducedPayments,
        adminShares: acc.adminShares + adminShare,
        adminBillable: acc.adminBillable + billableAdminShare,
        earnedAdminShares: acc.earnedAdminShares + earnedAdminShare,
        commercialShare: acc.commercialShare + commercialShare,
        billableCommercialShare:
          acc.billableCommercialShare + billableCommercialShare,
        earnedCommercialShare:
          acc.earnedCommercialShare + earnedCommercialShare,
      };
    },
    {
      gross: 0,
      net: 0,
      extraShare: 0,
      payments: 0,
      adminShares: 0,
      adminBillable: 0,
      earnedAdminShares: 0,
      commercialShare: 0,
      billableCommercialShare: 0,
      earnedCommercialShare: 0,
    }
  );

  return (
    <tr className="summary-tr">
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>{formatCurrency(totals.net)}</td>
      <td>{formatCurrency(totals.gross)}</td>
      <td>{formatCurrency(totals.payments)}</td>
      <td>{formatCurrency(totals.extraShare)}</td>
      <td>{formatCurrency(totals.adminShares)}</td>
      <td>{formatCurrency(totals.earnedAdminShares)}</td>
      <td>{formatCurrency(totals.adminBillable)}</td>
      <td>{formatCurrency(totals.commercialShare)}</td>
      <td>{formatCurrency(totals.earnedCommercialShare)}</td>
      <td>{formatCurrency(totals.billableCommercialShare)}</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
    </tr>
  );
};

export default TotalTr;
