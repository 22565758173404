import React from 'react';
import { formatCurrency } from '../../../../lib/helpers/formatters';
import { useAuth } from '../../../../commons/Auth';

const TotalTr = ({
  totals = {},
  route = '',
  activeRoute = false,
  truncateTotals = false,
}) => {
  const [{ isAdmin }] = useAuth();
  const {
    cashedTotals = 0,
    netTotals = 0,
    grossTotals = 0,
    adminShareTotals = 0,
    earnedAdminShareTotals = 0,
    billableAdminShareTotals = 0,
  } = totals;

  return (
    <tr
      className="summary-tr"
      onClick={() => {
        return activeRoute === true
          ? (window.location.href = `${
              window.location.href.split('?')[0]
            }/${route}`)
          : null;
      }}
    >
      {!truncateTotals && isAdmin && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && <td>-</td>}
      {!truncateTotals && isAdmin && <td>-</td>}
      {!truncateTotals && isAdmin && <td>-</td>}
      {!truncateTotals && <td>-</td>}

      {isAdmin && (
        <td className="txt-table-right">{formatCurrency(netTotals)}</td>
      )}
      {isAdmin && (
        <td className="txt-table-right">{formatCurrency(grossTotals)}</td>
      )}
      {isAdmin && (
        <td className="txt-table-right">{formatCurrency(cashedTotals)} </td>
      )}
      <td className="txt-table-right">{formatCurrency(adminShareTotals)}</td>
      <td className="txt-table-right">
        {formatCurrency(earnedAdminShareTotals)}
      </td>
      <td className="txt-table-right">
        {formatCurrency(billableAdminShareTotals)}{' '}
      </td>
      {!truncateTotals && isAdmin && <td>-</td>}
      {!truncateTotals && isAdmin && <td>-</td>}
    </tr>
  );
};

export default TotalTr;
