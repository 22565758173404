import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  CustomInput,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
} from '../../styled-components';
import { isAfter } from '../../lib/helpers/date';
import { ACCOUNTABILITY_LIMIT_DATE } from '../../lib/constants';

function ButtonModalField(props) {
  const { data, profile = {}, onRegularChange, showButton } = props;
  const [selectedRadio, setSelectedRadio] = useState(data.regular);
  const [t] = useTranslation('CONTACTS');
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { type } = profile || {};
  let regRatio = 0;

  switch (type) {
    case 'BUILDING_ADMIN_1':
    case 'BUILDING_ADMIN_2':
    case 'BUILDING_ADMIN_3':
    case 'BUILDING_ADMIN_4':
    case 'BUILDING_ADMIN_5':
    case 'ADMIN_AGENT':
      regRatio = 10;
      break;
  }

  function onRegularConfirm() {
    onRegularChange(data._id, selectedRadio);
    toggle();
  }

  const { admin = {}, regular, accountability } = data;
  const canRegularize = !isAfter(accountability, ACCOUNTABILITY_LIMIT_DATE);

  return (
    <React.Fragment>
      {(showButton || (profile._id === admin._id && canRegularize)) && (
        <div className="flex-check">
          <BtnModalTable
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              toggle();
            }}
          >
            {t('REGOLARIZZA')}
          </BtnModalTable>
        </div>
      )}

      {canRegularize ? (
        regular ? (
          <p className="regolarizzata">{t('REGOLARIZZATA')}</p>
        ) : (
          <p className="non-regolarizzata">{t('NON_REGOLARITTATA')}</p>
        )
      ) : (
        ''
      )}

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('MODAL_REGOLARIZZA_TITLE')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onRegularConfirm();
          }}
        >
          <ModalBody>
            <div className="max-width-form">
              <FormGroup>
                <div
                  className="radio-modal-table"
                  onChange={({ target }) =>
                    setSelectedRadio(target.value === '1')
                  }
                >
                  <CustomInput
                    type="radio"
                    id="regularRadio"
                    name="regularRadio"
                    label="Regolarizza"
                    value="1"
                    defaultChecked={regular}
                  />
                  <CustomInput
                    type="radio"
                    id="notRegularRadio"
                    name="regularRadio"
                    label="Non regolarizzare"
                    value="0"
                    defaultChecked={!regular}
                  />
                </div>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default ButtonModalField;
