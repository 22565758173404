export const createPassword = () => {
  const digits = [];
  for (let i = 0; i < 6; i++) {
    // Since the security code is a number (and not a string), the first digit must be at least one.
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
    const randomNumber = Math.random() * (i ? 10 : 9);
    digits.push(Math.floor(randomNumber) + (i ? 0 : 1));
  }
  return digits.join('');
};
