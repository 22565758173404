import React from 'react';
import { formatCurrency } from '../../lib/helpers/formatters';

const TotalTr = ({ totals = {} }) => {
  const { contractAmount = 0 } = totals;

  return (
    <tr className="summary-tr">
      <td>-</td>
      <td>-</td>
      <td className="txt-table-right">{formatCurrency(contractAmount)}</td>
    </tr>
  );
};

export default TotalTr;
