import React from 'react';

function DocumentTypeField({ value, maxLength, data }) {
  if (!value) return <>-</>;

  if (maxLength && typeof value === 'string' && value.length > maxLength) {
    return <>{value.substr(0, maxLength)}...</>;
  }

  return (
    <>
      {value} {data.rasCard?.length > 0 ? `- ${data.rasCard}` : ''}
    </>
  );
}

export default DocumentTypeField;
