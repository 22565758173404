import { useTranslation } from 'react-i18next';
import './MyRoyalties.scss';
import React, { useMemo } from 'react';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { DataTable, DateTimeField, Field } from '../../commons/DataTable';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IconInvoices } from '../../images/icn_fatture.svg';
import { Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import { ContFilterYear, ContPaginationPage } from '../../styled-components';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import Footer from '../../commons/Footer';
import variables from '../../variables';
import SearchInput from '../../commons/SearchInput';
import ModalViewLastMail from './ModalViewLastMail';

const OverdueInvoicesPresentational = ({ data, query, total, onFilter }) => {
  const [t] = useTranslation('ENERGY');
  const { offset, limit, admin } = query;
  const [{ token, isAdmin } = {}] = useAuth();
  const alert = useAlert();

  function generateFields() {
    const fields = [
      <Field key={1} title="Responsabile" source="admin.businessName" />,
      <Field key={2} title="Fornitore" source="supplier.businessName" />,
      <Field key={3} title="Numero Fattura" source="invoice.number" />,
      <DateTimeField
        key={4}
        title="Data Invio"
        source="date"
        format="DD/MM/YYYY"
      />,
      <Field key={5} title="Tipo" source="type" />,
      <Field
        key={6}
        title="Numero Solleciti automatici"
        source="numberAutoOverdue"
      />,
      <ModalViewLastMail key={7} title="Testo Sollecito" />,
    ];
    return fields;
  }

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:OVERDUE_INVOICES')}
            icon={
              <IconInvoices fill={variables.white} style={{ height: '25px' }} />
            }
          />

          <Row className="search-add-cont">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.admin }}
                onFilter={({ search }) => onFilter({ admin: search })}
                label={t('SEARCH:ADMINISTRATOR')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.supplier }}
                onFilter={({ search }) => onFilter({ supplier: search })}
                label={t('SEARCH:BY_SUPPLIER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.invoiceNumber }}
                onFilter={({ search }) => onFilter({ invoiceNumber: search })}
                label={t('SEARCH:INVOICE_NUMBER')}
              />
            </Col>
            <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>Tipo Sollecito</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="type"
                        name="type"
                        onChange={({ target: { value: type } }) =>
                          onFilter({ type })
                        }
                      >
                        <option value="" defaultChecked>
                          Tutti
                        </option>
                        <option value="MANUAL">MANUALE</option>
                        <option value="AUTO">AUTOMATICO</option>
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>

            <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>N. Invii Automatici</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="numberAutoOverdue"
                        name="numberAutoOverdue"
                        onChange={({ target: { value: numberAutoOverdue } }) =>
                          onFilter({ numberAutoOverdue })
                        }
                      >
                        <option value="" defaultChecked>
                          Tutti
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
          </Row>
        </section>

        <section className="m-t-20">
          <DataTable
            data={data}
            total={total}
            offset={offset}
            limit={Number(limit)}
            onFilter={onFilter}
            emptyText={t('COMMON:NO_DATA')}
          >
            {generateFields()}
          </DataTable>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default OverdueInvoicesPresentational;
