import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useDebouncedCallback } from 'use-debounce';
import Header from '../../../commons/Header';
import BurgerMenu from '../../../commons/BurgerMenu';
import { useAuth } from '../../../commons/Auth';
import { ReactComponent as IconInvoices } from '../../../images/icn_fatture.svg';
import './CreditCalculation.scss';
import { useLoading } from '../../../commons/Loading';
import variables from '../../../variables';
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import { ContAffiliation, H5Styled } from '../../../styled-components';
import RevenueCalculationTable from './RevenueCalculationTable';
import EonCalculationTable from './EonCalculationTable';
import InsuranceCalculationTable from './InsuranceCalculationTable';
import { getDataCalculation } from './getDataCalculation';
import TrainingCalculationTable from './TrainingCalculationTable';

function CreditCalculationAdmin({ location, match = {}, profile = {} }) {
  const [t] = useTranslation();
  const [{ isAdmin, token } = {}] = useAuth();
  const {
    _id: profileId,
    master: isProfileMaster,
    type: profileType = '',
  } = profile;
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [dataCalculation, setDataCalculation] = useState({});
  const [fetchData] = useDebouncedCallback(async () => {
    showLoading();
    const { error, data } = await getDataCalculation({
      profileId,
      token,
    });

    if (error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setDataCalculation(data);

    hideLoading();
  }, 1000);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <TabContent activeTab={'1'}>
      <TabPane tabId="1">
        <>
          <ContAffiliation>
            <Row>
              <Col xs="6">
                <H5Styled>{t('CREDIT_CALCULATION:INVOICES')}</H5Styled>
              </Col>
              <Col xs="6" className="text-right" />
            </Row>
            <br />
            <br />
            <RevenueCalculationTable
              data={dataCalculation.InvoicesCreditsData}
              truncateTable={true}
              onFilter={() => {}}
              showEmptyTableMessage={false}
            />
          </ContAffiliation>

          <ContAffiliation>
            <Row>
              <Col xs="6">
                <H5Styled>{t('CREDIT_CALCULATION:ENERGY')}</H5Styled>
              </Col>
              <Col xs="6" className="text-right" />
            </Row>
            <br />
            <br />
            <EonCalculationTable
              data={dataCalculation.EonCreditsData}
              truncateTable={true}
              onFilter={() => {}}
              showEmptyTableMessage={false}
            />
          </ContAffiliation>

          <ContAffiliation>
            <Row>
              <Col xs="6">
                <H5Styled>{t('CREDIT_CALCULATION:INSURANCES')}</H5Styled>
              </Col>
              <Col xs="6" className="text-right" />
            </Row>
            <br />
            <br />
            <InsuranceCalculationTable
              data={dataCalculation.InsurancesCreditsData}
              truncateTable={true}
              onFilter={() => {}}
              showEmptyTableMessage={false}
            />
          </ContAffiliation>

          <ContAffiliation>
            <Row>
              <Col xs="6">
                <H5Styled>{t('CREDIT_CALCULATION:TRAINING_CREDITS')}</H5Styled>
              </Col>
              <Col xs="6" className="text-right" />
            </Row>
            <br />
            <br />
            <TrainingCalculationTable
              data={dataCalculation.TrainingsCreditsData}
              truncateTable={true}
              onFilter={() => {}}
              showEmptyTableMessage={false}
            />
          </ContAffiliation>
        </>
      </TabPane>
    </TabContent>
  );
}

export default withRouter(CreditCalculationAdmin);
