import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { DataTable, CurrencyField, Field } from '../../../commons/DataTable';
import { useAuth } from '../../../commons/Auth';

function EonCalculationTable({
  dataTable,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
  bottomRow,
  showEmptyTableMessage,
  truncateTable,
  onSelectChange,
  allowedRoles = [],
  subAdmins = false,
}) {
  const [t] = useTranslation('COMMON');
  const [{ isAdmin }] = useAuth();
  const { offset, limit } = query;

  return (
    <DataTable
      data={[data]}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
    >
      <Field
        title="N. Condomini"
        source="totalCondos"
        className="txt-table-center"
      />
      <Field
        title="Condomini con servizi"
        source="condosWithService"
        className="txt-table-center"
      />
      <Field
        title="Tot. Crediti Previsti [(Condomini con servizi/N.Condomini) * Crediti Massimi Energia]"
        source="expectedTotalEonScore"
        className="txt-table-center"
      />
      <Field
        title="Tot. Crediti Attuali"
        source="totalEonScore"
        className="txt-table-center"
      />
      <Field
        title="Crediti Massimi Energia"
        source="EON_MAX_POINT"
        className="txt-table-center"
      />
    </DataTable>
  );
}

export default withRouter(EonCalculationTable);
