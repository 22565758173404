import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TabContent, TabPane, Form, Row, Col } from 'reactstrap';
import {
  ContAffiliation,
  ContFilterYear,
  ContFilterCheckbox,
  BtnOutlineGreen,
  ContFlexRight,
  ContPaginationPage,
} from '../../../styled-components';
import {
  DataTable,
  Field,
  DateTimeField,
  PaidBadge,
  PaymentsDataField,
  CurrencyField,
} from '../../../commons/DataTable';
import SearchInput from '../../../commons/SearchInput';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import Label from 'reactstrap/lib/Label';
import TotalTr from './TotalTr';
import generateYearOptions from '../../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../../lib/helpers/generateMonthOptions';
import ModalEditInvoice from './ModalEditInvoice';
import ModalAddPayment from './ModalAddPayment';
import InvoiceCheckbox from '../../DashboardDetailAssicurazioni/InvoiceCheckbox';
import {
  ADMIN_TYPES,
  DELETE_INVOICES_TYPE,
  INVOICES_ALLOWED_TYPES,
  USER_ADMIN_TYPES,
} from '../../../App';
import ModalConfirm from '../../../commons/ModalConfirm';
import ActionsField from './ActionsField';
import ModalInvoiceDetail from '../../../commons/ModalInvoiceDetail';
import InvoiceNumberField from './InvoiceNumberField';
import currency from 'currency.js';
import ValidatedBadge from '../../../commons/DataTable/ValidatedBadge';
import CustomerTypeField from '../../../commons/DataTable/CustomerTypeField';
import axios from 'axios';
import { useLoading } from '../../../commons/Loading';
import { useAlert } from 'react-alert';
import ModalSendEmail from './ModalSendEmail';
import ModalDeleteInvoice from './ModalDeleteInvoice';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import { useAuth } from '../../../commons/Auth';

const RoyaltiesPresentational = ({
  data,
  isAdmin,
  query,
  total,
  onFilter,
  selectedInvoices,
  onSelectChange,
  setFilterYear,
  searchAdministrators,
  searchEstateAgents,
  fetchAdminCondos,
  fetchAdminCustomers,
  condos,
  customersExtra,
  onInvoiceUpdate,
  onAddPayment,
  profileType,
  onInvoice,
  onStatusChanged,
  onSelectAll,
  selectedAll,
  deleteInvoice,
}) => {
  const [t] = useTranslation('ENERGY');
  const { offset, limit, year } = query;
  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedId: null,
  });
  const [totalInvoicesValue, setTotalInvoicesValue] = useState(0);
  const [modal, setModal] = useState(false);
  const [idForInvoiceEmail, setIdForInvoiceEmail] = useState(0);
  const alert = useAlert();

  const [{ userType }] = useAuth();

  const toggle = (id = null) => {
    if (id === null) {
      setModal(!modal);
      return;
    }

    const invoiceClicked = data.filter((invoice) => invoice._id === id)[0];

    if (
      invoiceClicked.paid === null ||
      invoiceClicked.paid === undefined ||
      invoiceClicked.paid === false
    ) {
      setModal(!modal);
    } else {
      alert.error('Questa fattura risulta gia pagata!');
    }
  };

  const onConfirm = () => {
    toggle();
  };

  const onOpenModal = () => {
    const itemsForTotalValue = selectedInvoices.map((_id) => {
      return data.find((invoice) => invoice._id === _id).extraGross;
    });
    let total = 0;
    itemsForTotalValue.forEach((value) => {
      total += value;
    });
    setTotalInvoicesValue(
      currency(total, { symbol: '€', precision: 2 }).format()
    );
  };

  const getTotals = (data) => {
    const netTotals = data.reduce((prev, cur) => prev + cur.net, 0);
    const grossTotals = data.reduce((prev, cur) => prev + cur.gross, 0);
    const paymentsTotals = data.reduce((rowsAcc, { payments }) => {
      return (
        rowsAcc +
          (payments || []).reduce((paymentsAcc, { gross }) => {
            return paymentsAcc + (gross || 0);
          }, 0) || 0
      );
    }, 0);

    return {
      netTotals,
      grossTotals,
      paymentsTotals,
    };
  };

  const onFilterValidatedInvoices = (e) => {
    onFilter({
      validated: e.target.checked ? 'false' : 'true',
    });
  };

  const onFilterToBillInvoices = (e) => {
    onFilter({
      toBill: e.target.checked ? 'true' : 'false',
    });
  };
  const onFilterPaidInvoices = (e) => {
    onFilter({
      paid: e.paid,
    });
  };

  const onRowClick = (row) => {
    const { _id, paid = false } = row;
    if (!row.pinvInvoice?.length > 0) {
      paid &&
        INVOICES_ALLOWED_TYPES.includes(profileType) &&
        onSelectChange(_id);
    }
  };

  return (
    <>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12" md="6" lg="4">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('CONTACTS:TITLE_FILTER_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectYear"
                        name="year"
                        value={year}
                        onChange={({ target: { value: currentYear } }) => {
                          setFilterYear({ year: currentYear });
                        }}
                      >
                        {generateYearOptions(true)}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="12" md="6" lg="4">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="selectAccountabilityYear"
                        name="accountabilityYear"
                        value={query && query.accountabilityYear}
                        onChange={({
                          target: { value: accountabilityYear },
                        }) => {
                          onFilter({ accountabilityYear });
                        }}
                      >
                        {generateYearOptions(true)}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col sm="12" md="6" lg="4">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="accountabilityMonth"
                        name="accountabilityMonth"
                        disabled={!query.accountabilityYear}
                        value={query && query.accountabilityMonth}
                        onChange={({
                          target: { value: accountabilityMonth },
                        }) => onFilter({ accountabilityMonth })}
                      >
                        {generateMonthOptions()}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
          </Row>
          <Row className="compensi-variabili-resp">
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.supplier }}
                onFilter={({ search }) => onFilter({ supplier: search })}
                label={t('SEARCH:BY_SUPPLIER')}
                autoFocus={false}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.number }}
                onFilter={({ search }) => onFilter({ number: search })}
                label={t('SEARCH:INVOICE_NUMBER')}
                autoFocus={false}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.customer }}
                onFilter={({ search }) => onFilter({ customer: search })}
                label={t('SEARCH:CUSTOMER')}
                autoFocus={false}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.admin }}
                onFilter={({ search }) => onFilter({ admin: search })}
                label={t('SEARCH:ADMINISTRATOR')}
                autoFocus={false}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: '20px', paddingTop: '20px' }}>
            <Col sm="12" md="6" lg="3">
              <ContFilterYear className="no-width">
                <Row>
                  <Col xs="6">
                    <Label>Stato Fatture</Label>
                  </Col>
                  <Col xs="6">
                    <FormGroup>
                      <CustomInput
                        type="select"
                        id="paid"
                        name="paid"
                        onChange={({ target: { value: paid } }) =>
                          onFilterPaidInvoices({ paid })
                        }
                        value={query.paid || ''}
                      >
                        <option value="" defaultChecked>
                          Tutti
                        </option>
                        <option value="PAID">Pagate</option>
                        <option value="NOT_PAID">Non Pagate</option>
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
              </ContFilterYear>
            </Col>
            <Col>
              <ContFilterCheckbox>
                {isAdmin && (
                  <CustomInput
                    type="checkbox"
                    id="validated"
                    label="Fatture da convalidare"
                    inline
                    checked={query && query.validated === 'false'}
                    onChange={onFilterValidatedInvoices}
                  />
                )}
                {isAdmin &&
                  [...INVOICES_ALLOWED_TYPES, ...ADMIN_TYPES].includes(
                    profileType
                  ) && (
                    <CustomInput
                      type="checkbox"
                      id="toBill"
                      label="Fatture da retrocedere"
                      inline
                      checked={query && query.toBill === 'true'}
                      onChange={onFilterToBillInvoices}
                    />
                  )}
              </ContFilterCheckbox>
            </Col>
          </Row>

          <ContAffiliation className="m-t-20">
            <DataTable
              data={data}
              total={total}
              offset={offset}
              limit={limit}
              onFilter={onFilter}
              emptyText={t('COMMON:NO_DATA')}
              onRowClick={onRowClick}
              bottomRow={
                <TotalTr profileType={profileType} totals={getTotals(data)} />
              }
            >
              <InvoiceCheckbox
                selected={selectedInvoices}
                onSelectChange={onRowClick}
                onClick={(e) => e.stopPropagation()}
                title={
                  <input
                    type="checkbox"
                    checked={selectedAll}
                    onChange={({ target: { checked } }) => onSelectAll(checked)}
                  />
                }
                visible={INVOICES_ALLOWED_TYPES.includes(profileType)}
              />
              <InvoiceNumberField title="Fattura di riferimento" />
              <Field
                title="Nome Fornitore"
                source="supplier.businessName"
                className="text-left"
              />
              <Field title="Cliente" source="customer" className="text-left" />
              <CustomerTypeField title="Tipo Cliente" source="customerType" />
              <Field title="Numero Fattura" source="number" />
              <Field
                title="Codice fiscale / partita IVA cliente"
                source="vatCode"
              />
              <DateTimeField
                title="Data Fattura"
                source="date"
                format="DD/MM/YYYY"
              />
              <DateTimeField
                title="Mese Rend."
                source="accountability"
                format="MM/YYYY"
              />
              <DateTimeField
                title="Data prevista incassi"
                source="supposedPaymentDate"
                format="DD/MM/YYYY"
              />
              <Field title="Responsabile cliente" source="admin.businessName" />
              <CurrencyField
                title="Imponibile"
                source="net"
                class="txt-table-right"
              />
              <CurrencyField
                title="Importo da Incassare"
                source="gross"
                class="txt-table-right"
              />
              <CurrencyField
                title="Importo Extra"
                source="extraGross"
                class="txt-table-right"
                visible={INVOICES_ALLOWED_TYPES.includes(profileType)}
              />
              <PaymentsDataField
                title="Incassi"
                source="payments"
                type="list"
                field="gross"
                class="txt-table-right"
              />
              <Field
                title="Numero Solleciti Inviati"
                source="overdueInvoicesCount"
                visible={ADMIN_TYPES.includes(profileType)}
              />

              <DateTimeField
                title="Data Ultimo Sollecito"
                source="latestOverdueInvoiceDate"
                className="text-center"
                format="DD/MM/YYYY"
                visible={ADMIN_TYPES.includes(profileType)}
              />
              <PaidBadge title="Pagato" source="paid" />
              <ValidatedBadge title="Validata" source="validated" />
              <ModalEditInvoice
                title="Modifica"
                onConfirm={onInvoiceUpdate}
                searchAdministrators={searchAdministrators}
                searchEstateAgents={searchEstateAgents}
                fetchAdminCondos={fetchAdminCondos}
                fetchAdminCustomers={fetchAdminCustomers}
                condos={condos}
                customersExtra={customersExtra}
                profileType={profileType}
              />
              <ModalAddPayment
                title="Aggiungi incasso"
                onAddPayment={onAddPayment}
              />
              <ModalDeleteInvoice
                title="Cancella Fattura"
                deleteInvoice={deleteInvoice}
                visible={
                  DELETE_INVOICES_TYPE.includes(profileType) &&
                  userType !== USER_ADMIN_TYPES.SUPERVISOR_USER
                }
              />
              <ActionsField
                title="Fattura Extra"
                onViewInvoice={(extraInvoiceId) =>
                  setModalState({ isOpen: true, selectedId: extraInvoiceId })
                }
                visible={INVOICES_ALLOWED_TYPES.includes(profileType)}
              ></ActionsField>
            </DataTable>

            <ModalInvoiceDetail
              modalState={modalState}
              setModalState={setModalState}
              onStatusChanged={onStatusChanged}
            />

            <ContPaginationPage>
              <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

              <FormGroup>
                <CustomInput
                  type="select"
                  className="pagination-select"
                  id="perPage"
                  name="perPage"
                  value={query.limit}
                  onChange={({ target: { value: limit } }) => {
                    onFilter({ limit });
                  }}
                >
                  {generatePaginationOptions()}
                </CustomInput>
              </FormGroup>
            </ContPaginationPage>

            <ContFlexRight className="mt-5">
              {selectedInvoices.length > 0 &&
                INVOICES_ALLOWED_TYPES.includes(profileType) && (
                  <ModalConfirm
                    onConfirm={() => {
                      onInvoice();
                    }}
                    onOpen={onOpenModal}
                    text={
                      `Il valore totale delle fatture é: ${totalInvoicesValue} ` +
                      '\n' +
                      t('CONTACTS:CONFIRM_INVOICE')
                    }
                  >
                    <BtnOutlineGreen type="button" className="uppercase">
                      FATTURA SELEZIONATI
                    </BtnOutlineGreen>
                  </ModalConfirm>
                )}
            </ContFlexRight>
          </ContAffiliation>
        </TabPane>
      </TabContent>
    </>
  );
};

export default RoyaltiesPresentational;
